import * as React from 'react';
import Checkbox from '../../components/MUI/Checkbox';
import AccountList from './AccountList';
import CharacterList from './CharacterList';

interface IMembersProps {
}

const Members: React.FunctionComponent<IMembersProps> = (props) => {
    const [accountsOrCharacters, setAccountsOrCharacters] = React.useState<boolean>(true);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleCheckboxChanged = React.useCallback(() => {
        setAccountsOrCharacters((current) => !current);
    }, []);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (accountsOrCharacters === true) {
        content = <AccountList />;
    } else {
        content = <CharacterList />;
    }

    return (
        <div className='w-100 px-2'>
            <div className='box-shadow'>
                <div className='title-bar px-2'>
                    <span>Members</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <Checkbox
                        checked={accountsOrCharacters}
                        label='View list of accounts'
                        checkboxClasses='table-checkbox'
                        controlClasses='dark-red-checkbox'
                        onChange={handleCheckboxChanged}
                    />

                    <Checkbox
                        checked={!accountsOrCharacters}
                        label='View list of characters'
                        checkboxClasses='table-checkbox'
                        controlClasses='dark-red-checkbox'
                        onChange={handleCheckboxChanged}
                    />

                    {content}
                </div>
            </div>
        </div>
    );
};

export default Members;

import { Button, Modal } from '@material-ui/core';
import * as React from 'react';
import { IMoveRequest } from '../../../containers/Moderator/ModeratorMoveRequests';
import { getDate, getTime } from '../../../utilities/date';
import Input from '../../MUI/Input';
import api from '../../../api/api';

interface IMoveRequestDenyModalProps {
    moveRequest: IMoveRequest;
    onClose: () => void;
    onRequestDenied: () => void;
}

const MoveRequestDenyModal: React.FunctionComponent<IMoveRequestDenyModalProps> = ({
    moveRequest,
    onClose,
    onRequestDenied
}) => {
    const [denyReason, setDenyReason] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    const [saving, setSaving] = React.useState<boolean>(false);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleDenyClicked = () => {
        // Verify if set
        if (denyReason.trim() === "") {
            setError("Please enter a deny reason.");
            return;
        }

        // Verify length
        if (denyReason.length > 45) {
            setError("Max length is 45 characters.");
            return;
        }

        // Re-init
        setError("");
        setSaving(true);

        api.post('forumTopicMoveRequest/moderator/deny', {
            id: moveRequest.id,
            reason: denyReason
        })
        .then(() => {
            onRequestDenied();
        })
        .catch(() => {
            setError("Failed to deny the request on the server.");
            setSaving(false);
        });
    }

    /***************************************************
     * Render
     ***************************************************/
    let requestedTime = new Date(moveRequest.requestedTime);
    return (
        <Modal
            open
            aria-label='Deny move request modal'
            onClose={onClose}
            className='d-flex align-items-center justify-content-center'
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>Deny move request</span>
                    <div className='d-flex ml-auto align-items-center justify-content-center'>
                        <i className='fas fa-times text-white clickable' onClick={onClose} />
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                <span className="font-small">
                        Topic
                    </span>
                    <span>
                        {moveRequest.title}
                    </span>
                    <span className='mt-2 font-small'>
                        Current
                    </span>
                    <span>
                        {moveRequest.forumName}{' > '}{moveRequest.categoryName}
                    </span>
                    <span className="mt-2 font-small">
                        Suggested
                    </span>
                    <span>
                        {moveRequest.suggestedForumName}{' > '}{moveRequest.suggestedCategoryName}
                    </span>
                    <span className="mt-2 font-small">
                        Requested by
                    </span>
                    <span>
                        {moveRequest.requestedBy}
                    </span>
                    <span className='mt-2 font-small'>
                        Requested time
                    </span>
                    <span>
                        {getDate(requestedTime)}; {getTime(requestedTime)}
                    </span>
                    <span className='mt-2 font-small'>
                        Reason
                    </span>
                    <span>
                        {moveRequest.reason}
                    </span>
                    <Input
                        id='deny-reason'
                        label='Reason to deny request'
                        className='mt-2'
                        value={denyReason}
                        required
                        onChange={(event) => setDenyReason(event.target.value)}
                    />
                    { error === "" ? null : (
                        <div className="alert alert-danger mt-2 mb-0 rounded-0">
                            <span>{error}</span>
                        </div>
                    )}
                    <Button 
                        variant='contained' 
                        className='mt-2 w-100 dark-red-button text-white text-normal rounded-0' 
                        disabled={saving}
                        onClick={handleDenyClicked}
                    >
                        Deny
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default MoveRequestDenyModal;

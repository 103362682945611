import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import InstallationGuideEdit from '../../components/Admin/ManageInstallationGuides/InstallationGuideEdit';
import InstallationGuides from '../../components/Admin/ManageInstallationGuides/InstallationGuides';
import InstallationGuideUsers from '../../components/Admin/ManageInstallationGuides/InstallationGuideUsers';
import InstallationGuideVersionDisplay from '../../components/Admin/ManageInstallationGuides/InstallationGuideVersionDisplay';
import InstallationGuideVersions from '../../components/Admin/ManageInstallationGuides/InstallationGuideVersions';

const AdminInstallationGuides: React.FunctionComponent = (props) => {
    return (
        <div id="admin" className="w-100 px-2">
            <Switch>
                <Route path="/admin/installationGuides/create" component={InstallationGuideEdit} />
                <Route path="/admin/installationGuides/:id/update" component={InstallationGuideEdit} />
                <Route path="/admin/installationGuides/:id/users" component={InstallationGuideUsers} />
                <Route path="/admin/installationGuides/:id/versions/:versionId" component={InstallationGuideVersionDisplay} />
                <Route path="/admin/installationGuides/:id/versions" component={InstallationGuideVersions} />
                <Route path="/admin/installationGuides" component={InstallationGuides} />
                <Redirect to="/admin/installationGuides" />
            </Switch>
        </div>
    )
};

export default AdminInstallationGuides;

import { Button } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../api/api';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import CreateVersionModal from './CreateVersionModal';
import ManageGuideVersion from './ManageGuideVersion';

interface IParams {
    id?: string;
}

export interface IVersion {
    id: string;
    versionNumber: number;
    isLive: boolean;
    isValidationRequired: boolean;
    validationTime: number | undefined;
    createdTime: number;
    validatedBy: string;
    createdBy: string;
}

const ManageGuideVersions: React.FunctionComponent = () => {
    const [display, setDisplay] = React.useState<boolean>(false);
    const [versions, setVersions] = React.useState<IVersion[] | null>(null);
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const history = useHistory();
    const { id } = useParams<IParams>();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (!id) {
            history.push('/download/manage');
            return;
        }

        api.get<IVersion[]>('installationGuideVersion/manage/' + id)
        .then((response) => {
            setVersions(response.data);
        })
        .catch((error) => {
            console.log('[ManageGuideVersions] Failed to fetch the versions', error, error.response);
        });
    }, [id, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleCreateClicked = React.useCallback(() => {
        if (!uid) {
            return;
        }

        if (versions === null) {
            return;
        }

        if (versions.length === 0) {
            history.push('/download/manage/' + id + '/versions/create');
            return;
        }

        setDisplay(true);
    }, [uid, id, versions, history]);

    const handleCloseClicked = React.useCallback(() => {
        setDisplay(false);
    }, []);

    const handlePreviewClicked = React.useCallback((versionId: string) => {
        history.push('/download/manage/' + id + '/versions/' + versionId + '/display');
    }, [id, history]);

    const handleEditClicked = React.useCallback((versionId: string) => {
        history.push('/download/manage/' + id + '/versions/' + versionId + '/update');
    }, [id, history]);

    const handleDeleteClicked = React.useCallback((versionId) => {
        api.delete('installationGuideVersion/' + id + '/' + versionId)
        .then(() => {
            setVersions((curr) => {
                if (curr === null) {
                    return null;
                }

                return curr.filter((v) => v.id !== versionId);
            });
        })
        .catch((error) => {
            alert("Failed to delete the version.");
            console.log("Error: ", error, error.response);
        });
    }, [id]);

    const handleBackClicked = React.useCallback(() => {
        history.push('/download/manage');
    }, [history]);

    /***************************************************
     * Render
     ***************************************************/
    let modal = null;
    let content = null;
    let createButton = null;

    if (versions === null) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                <span>Loading the versions of the installation guide...</span>
            </div>
        );
    } else if (versions.length === 0) {
        if (uid) {
            createButton = (
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button variant='contained' className='dark-red-button rounded-0 text-normal w-100' onClick={handleCreateClicked}>
                            <i className='fas fa-plus mr-2' />
                            <span>Create version</span>
                        </Button>
                    </div>
                </div>
            );
        }
        
        content = (
            <div className="mt-2 alert alert-warning rounded-0 mb-0">
                <span>No versions found.</span>
            </div>
        );
    } else {
        let hasWorkingVersion = false;
        content = versions.map((version) => {
            if (version.validationTime) {
                hasWorkingVersion= true;
            }

            return (
                <ManageGuideVersion
                    key={version.id}
                    version={version}
                    onPreviewClicked={handlePreviewClicked}
                    onEditClicked={handleEditClicked}
                    onDeleteClicked={handleDeleteClicked}
                />
            );
        });

        if (hasWorkingVersion) {
            createButton = (
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button variant='contained' className='dark-red-button rounded-0 text-normal w-100' onClick={handleCreateClicked}>
                            <i className='fas fa-plus mr-2' />
                            <span>Create version</span>
                        </Button>
                    </div>
                </div>
            );
        }

        if (display) {
            modal = (
                <CreateVersionModal
                    id={id!}
                    versions={versions}
                    onClose={handleCloseClicked}
                />
            )
        }
    }

    return (
        <div className="w-100 px-2">
            <div className="row mb-2">
                <div className="col-12 col-md-6 col-lg-4">
                    <Button onClick={handleBackClicked} variant='contained' className='w-100 dark-red-button text-normal rounded-0'>
                        <i className="fas fa-step-backward mr-2"></i>
                        <span>Go back</span>
                    </Button>
                </div>
            </div>
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Installation guide versions</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    {createButton}
                    <div className="mt-2 title-bar px-2 dark">
                        <div className="row w-100">
                            <div className="col-8 col-md-2">
                                <span>Version</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Created</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Creator</span>
                            </div>
                            <div className="col-4 col-md-1">
                                <span>Live</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Validation</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Validation time</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-end col-1">
                                <span>Actions</span>
                            </div>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
            {modal}
        </div>
    );
};

export default ManageGuideVersions;

import { Modal } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { AuthReducerProps, RoleProps } from '../../../store/reducers/authReducer';
import { validateEmail } from '../../../utilities/validateEmail';
import Input from '../../MUI/Input';
import SaveButton, { saveStatuses, saveTypes } from '../../MUI/SaveButton';
import Select, { SelectOptionProps } from '../../MUI/Select';
import { DataProps } from './Users';

import api from '../../../api/api';
import { useSelector } from 'react-redux';

interface Props {
    roles: RoleProps[];
    closePopup: () => void;
    setData: React.Dispatch<React.SetStateAction<DataProps>>;
};

interface ErrorData {
    email: boolean,
    password: boolean,
    confirmPassword: boolean,
    username: boolean,
    passwordsMatch: boolean,
    roleId: boolean,
    message: string
};

const CreateUser: React.FC<Props> = ({ roles, closePopup, setData }) => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [roleId, setRoleId] = useState<string | null>(null);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [error, setError] = useState<ErrorData>({ 
        email: false, 
        password: false,
        confirmPassword: false,
        username: false, 
        passwordsMatch: false,
        roleId: false,
        message: ""
    });

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);
    
    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    const handleRoleChanged = useCallback((event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        let role = roles.find(r => r.id === value);
        if (role) {
            setRoleId(role.id);
        }
    }, [roles]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Initialise error data
            let errorData = {
                email: false,
                password: false,
                confirmPassword: false,
                username: false,
                passwordsMatch: false,
                roleId: false,
                message: ""
            };

            // Verify email address
            if (!validateEmail(email)) {
                errorData.email = true;
            }

            // Verify password
            if (password.trim().length < 6) {
                errorData.password = true;
            }

            // Verify confirm password
            if (confirmPassword.trim().length < 6) {
                errorData.confirmPassword = true;
            }

            // Verify if passwords match
            if (password.trim().length > 5 && confirmPassword.trim().length > 5 && password !== confirmPassword) {
                errorData.passwordsMatch = true;
            }

            // Verify username
            if (username.trim().length < 2) {
                errorData.username = true;
            }

            if (roleId === null) {
                errorData.roleId = true;
            }

            // Verify form errors
            setError(errorData);
            if (errorData.email || errorData.username || errorData.password || errorData.confirmPassword || errorData.passwordsMatch || errorData.roleId) {
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Try to create
            api.post("user/admin", {
                username,
                email,
                password,
                confirmPassword,
                roleId
            })
            .then((response) => {
                setData((data) => {
                    let users = [ response.data, ...data.users ];
                    return {
                        ...data,
                        users: users
                    }
                });
                closePopup();
            })
            .catch((error) => {
                let message = "Server error";
                if (error?.response?.data?.message) {
                    message = error.response.data.message;
                }

                setStatus(saveStatuses.ERROR);
                setError(currentError => {
                    return {
                        ...currentError,
                        message: message
                    }
                });
            });
        }
    }, [uid, status, username, password, email, roleId, confirmPassword, closePopup, setData]);

    let options: SelectOptionProps[] = roles.map((role) => {
        return {
            value: role.id,
            label: role.name
        }
    });

    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby="create-account-popup"
            aria-describedby="Create an account"
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>Create an account</span>
                </div>
                <div className="d-flex flex-column p-2">
                    <Input
                        id="create-account-popup-username"
                        label="Username"
                        value={username}
                        error={error.username && username.trim().length < 2}
                        helperText={"The username must be a minimum length of 2"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Input
                        id="create-account-popup-email"
                        label="Email"
                        value={email}
                        error={error.email && !validateEmail(email)}
                        helperText={"Please fill in a correct email"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Input 
                        id="create-account-popup-password"
                        label="Password"
                        className="mt-2"
                        value={password}
                        error={error.password && password.trim().length < 6}
                        helperText={"The password must be a minimum length of 6"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        onKeyDown={handleKeyDown}
                        type="password"
                    />
                    <Input 
                        id="create-account-popup-confirm-password"
                        label="Confirm password"
                        className="mt-2"
                        value={confirmPassword}
                        error={error.confirmPassword && confirmPassword.trim() === ""}
                        helperText={"Please fill in the confirmation password"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value)}
                        onKeyDown={handleKeyDown}
                        type="password"
                    />
                    <Select
                        id="create-account-popup-select-role"
                        label="Select role"
                        className={["w-100", "mt-2"]}
                        value={roleId}
                        options={options}
                        error={error.roleId && roleId === null}
                        helperText="Please select a role"
                        onChange={handleRoleChanged}
                        onKeyDown={handleKeyDown}
                    />

                    { error.message.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.message}
                        </div>
                    ) : null }

                    { error.passwordsMatch ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            The passwords must match
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                            saveContent="Create"
                            savingContent={(<><i className="fas fa-sync fa-spin mr-2" /> Creating...</>)}
                            errorContent={(<><i className="fas fa-times mr-2" /> Error</>)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateUser;
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import './Admin.scss';
import AdminAnnouncements from './AdminAnnouncements';

import AdminDashboard from './AdminDashboard';
import AdminForums from './AdminForums';
import AdminRoles from './AdminRoles';
import AdminUsers from './AdminUsers';
import AdminSettings from './AdminSettings';
import AdminInstallationGuides from './AdminInstallationGuides';

const Admin: React.FC<{}> = () => {
    return (
        <Switch>
            <Route path="/admin/dashboard" component={AdminDashboard} />
            <Route path="/admin/users" component={AdminUsers} />
            <Route path="/admin/roles" component={AdminRoles} />
            <Route path="/admin/forums" component={AdminForums} />
            <Route path="/admin/announcements" component={AdminAnnouncements} />
            <Route path="/admin/installationGuides" component={AdminInstallationGuides} />
            <Route path="/admin/settings" component={AdminSettings} />
            <Redirect to="/admin/dashboard" />
        </Switch>
    );
};

export default Admin;
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Downloads from '../../components/Downloads/Downloads';
import InstallationGuideDisplay from '../../components/Downloads/InstallationGuideDisplay';
import ManageGuides from '../../components/Downloads/ManageGuides';
import ManageGuideVersionDisplay from '../../components/Downloads/ManageGuideVersionDisplay';
import ManageGuideVersionEdit from '../../components/Downloads/ManageGuideVersionEdit';
import ManageGuideVersions from '../../components/Downloads/ManageGuideVersions';

const DownloadPage: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path='/download/manage/:id/versions/create' component={ManageGuideVersionEdit} />
            <Route path="/download/manage/:id/versions/:versionId/display" component={ManageGuideVersionDisplay} />
            <Route path='/download/manage/:id/versions/:versionId/update' component={ManageGuideVersionEdit} />
            <Route path="/download/manage/:id/versions" component={ManageGuideVersions}  />
            <Route path="/download/manage" component={ManageGuides} />
            <Route path="/download/:id/:versionId" component={InstallationGuideDisplay} />
            <Route path="/download" component={Downloads} />
            <Redirect to="/download" />
        </Switch>
    );
};

export default DownloadPage;

import React, { useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { UserProps, AuthReducerProps } from '../../../../store/reducers/authReducer';
import * as actions from '../../../../store/actions/index';

import AccountRegister from './AccountRegister';
import AccountLogin from './AccountLogin';

interface PopupDataProps {
    open: boolean,
    type: number,
}

const Account: React.FC<{ collapse: () => void; }> = ({ collapse }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const me: UserProps | null = useSelector((state: AuthReducerProps) => state.me);
    const [popupData, setPopupData] = useState<PopupDataProps>({
        open: false,
        type: 0
    });

    const closePopup = useCallback(() => {
        setPopupData({
            open: false,
            type: 0
        });
    }, []);

    const handleProfileClicked = useCallback(() => {
        collapse();
        history.push("/profile");
    }, [history]);

    const handleLogoutClicked = useCallback(() => {
        dispatch(actions.authLogout(history));
    }, [history, dispatch]);

    let content = null;
    let popup = null;

    if (me) {
        content = (
            <React.Fragment>
                <span>Hello, {me?.username}</span>
                <Button variant="contained" className="dark-red-button text-normal rounded-0 my-1" onClick={handleProfileClicked}>
                    Profile
                </Button>
                <Button variant="contained" className="dark-red-button text-normal rounded-0" onClick={handleLogoutClicked}>
                    Log out
                </Button>
            </React.Fragment>
        )
    } 
    else {
        content = (
            <React.Fragment>
                <span>Hello, Guest</span>
                <Button variant="contained" className="dark-red-button text-normal rounded-0 my-1" onClick={() => setPopupData({ open: true, type: 0 })}>
                    Log in
                </Button>
                <Button variant="contained" className="dark-red-button text-normal rounded-0" onClick={() => setPopupData({ open: true, type: 1 })}>
                    Register
                </Button>
            </React.Fragment>
        );

        if (popupData.open) {
            switch(popupData.type) {
                case 0: popup = <AccountLogin open={popupData.open} closePopup={closePopup} />; break;
                case 1: popup = <AccountRegister open={popupData.open} closePopup={closePopup} />; break;
                default: break;
            }
        }
    }

    return (
        <div className="w-100 px-2 pb-1">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Account</span>
                </div>
                <div className="bg-light-gray p-1 bl-xs br-xs bb-xs d-flex flex-column">
                    {content}
                    {popup}
                </div>
            </div>
        </div>
    );
};

export default Account;
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IVersionGuide } from './ManageGuideVersionEdit';

import api from '../../api/api';
import DisplayVersion from './DisplayVersion';
import { Button } from '@material-ui/core';

interface IParams {
    id?: string;
    versionId?: string;
}

const ManageGuideVersionDisplay: React.FunctionComponent = () => {
    const [data, setData] = React.useState<IVersionGuide | null>(null);
    const [saving, setSaving] = React.useState<boolean>(false);

    const history = useHistory();
    const { id, versionId } = useParams<IParams>();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (!id || !versionId) {
            history.push('/download');
            return;
        }

        api.get('installationGuideVersion/manage/' + id + '/' + versionId)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.log('[ManageGuideVersionDisplay] Failed to download the version', error, error.response);
        });
    }, [id, versionId, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleBackClicked = () => {
        //@ts-ignore
        history.goBack();
    }

    const handleCancelValidationRequestClicked = () => {
        if (saving || !data) {
            return;
        }

        setSaving(true);
        api.post('installationGuideVersion/cancelValidationRequest/' + id + '/' + versionId)
        .then(() => {
            setData({
                ...data!,
                isValidationRequired: false
            });
        })
        .catch(() => {
            alert("Failed to cancel validation request.");
        })
        .finally(() => {
            setSaving(false);
        })
    }

    const handleSendValidationRequestClicked = () => {
        if (saving || !data) {
            return;
        }

        setSaving(true);
        api.post('installationGuideVersion/sendValidationRequest/' + id + '/' + versionId)
        .then(() => {
            setData({
                ...data!,
                isValidationRequired: true
            });
        })
        .catch(() => {
            alert("Failed to send validation request.");
        })
        .finally(() => {
            setSaving(false);
        });
    }

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (data === null) {
        content = (
            <div className="w-100 px-2">
                <div className="row">
                    <div className="col-12 d-flex flex-column">
                        <div className="alert alert-info rounded-0 mb-0">
                            Loading data...
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        let extraContent = null;
        if (data.hasBeenValidated === false) {
            if (data.isValidationRequired) {
                extraContent = (
                    <div className="row mt-2">
                        <div className="col-12 col-md-6 col-lg-4 d-flex flex-column">
                            <Button disabled={saving} variant='contained' className='dark-red-button text-normal rounded-0' onClick={handleCancelValidationRequestClicked}>
                                Cancel validation request
                            </Button>
                        </div>
                    </div>
                )
            } else if (data.isValidationRequestDenied) {
                extraContent = (
                    <div className="row mt-2">
                        <div className="col-12 col-md-6 col-lg-4 d-flex flex-column">
                            <div className="alert alert-danger rounded-0 mb-2 d-flex flex-column">
                                <strong>Validation request denied</strong>
                                <span>{data.validationDenyReason}</span>
                            </div>
                            <Button disabled={saving} variant='contained' className='dark-red-button text-normal rounded-0' onClick={handleSendValidationRequestClicked}>
                                Send validation request
                            </Button>
                        </div>
                    </div>
                );
            } else {
                extraContent = (
                    <div className="row mt-2">
                        <div className="col-12 col-md-6 col-lg-4 d-flex flex-column">
                            <Button disabled={saving} variant='contained' className='dark-red-button text-normal rounded-0' onClick={handleSendValidationRequestClicked}>
                                Send validation request
                            </Button>
                        </div>
                    </div>
                );
            }
        } else {
            extraContent = (
                <div className="row mt-2">
                    <div className="col-12 col-md-6 col-lg-4 d-flex flex-column">
                        <div className="alert alert-success mb-0 rounded-0">
                            Validation request has been accepted
                        </div>
                    </div>
                </div>
            );
        }

        content = (
            <React.Fragment>
                <div className="w-100 px-2 mb-2">
                    <div className="box-shadow">
                        <div className="title-bar px-2">
                            <span>Installation Guide Display</span>
                        </div>
                        <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                            <span>This is the preview or display of the version you have selected.</span>
                            {extraContent}
                        </div>
                    </div>
                </div>
                <DisplayVersion version={data} />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="w-100 px-2 mb-2">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button onClick={handleBackClicked} variant='contained' className='w-100 dark-red-button text-normal rounded-0'>
                            <i className="fas fa-step-backward mr-2"></i>
                            <span>Go back</span>
                        </Button>
                    </div>
                </div>
            </div>
            {content}
        </React.Fragment>
    );
};

export default ManageGuideVersionDisplay;

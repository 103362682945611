import React, { useCallback, useEffect, useState, useRef } from 'react';
import tinymce from 'tinymce';
import { useSelector } from 'react-redux';

import api, { baseApiUrl } from '../../api/api';
import { getDate, getTime } from '../../utilities/date';

import SaveButton, { saveStatuses, saveTypes } from '../../components/MUI/SaveButton';
import { AuthReducerProps, UserProps } from '../../store/reducers/authReducer';
import Select from '../../components/MUI/Select';
import Input from '../../components/MUI/Input';
import Checkbox from '../../components/MUI/Checkbox';
import Editor from '../../components/Editor/Editor';
import { FormControlLabel, Switch } from '@material-ui/core';

interface SettingsProps {
    forumPlayerArtCategoryId: string | null,
    forumPlayerStoryCategoryId: string | null,
    forumRtqCategoryId: string | null
}

interface ForumCategoryProps {
    value: string,
    label: string,
    forum: string
}

interface TopicProps {
    title: string;
    isSticky: boolean;
    isLocked: boolean;
}

interface ErrorProps {
    status: boolean;
    message: string;
}

const AdminAnnouncements: React.FC<{}> = () => {
    const [displayPreview, setDisplayPreview] = useState<boolean>(false);
    const [initialContent, setInitialContent] = useState<string>("");
    const [selectedForumCategory, setSelectedForumCategory] = useState<number | null>(null);
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [settings, setSettings] = useState<SettingsProps | null>(null);
    const [forumCategories, setForumCategories] = useState<ForumCategoryProps[]>([]);
    const [topic, setTopic] = useState<TopicProps>({ title: "", isSticky: false, isLocked: false });
    const [error, setError] = useState<ErrorProps>({ status: false, message: "" });

    const me: UserProps | null = useSelector((state: AuthReducerProps) => state.me);
    const tinyMceRef = useRef<any>(null);
    
    /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        api.get('settings/admin')
        .then((response) => {
            setSettings(response.data);
        })
        .catch((error) => {
            console.log("[AdminSettings] Failed to fetch the settings.");
            console.log("error", error);
            console.log("error.response", error.response);
        });

        api.get('settings/admin/categories')
        .then((response) => {
            setForumCategories(response.data);
        })
        .catch((error) => {
            console.log("[AdminSettings] Failed to fetch the categories.");
            console.log("error", error);
            console.log("error.response", error.response);
        });
    }, []);

    /***************************************************
     * Event handlers
     ***************************************************/
    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Verify title
            if (topic.title.trim() === "") {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify forum category
            if (selectedForumCategory === null) {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify content
            let content = "";
            if (displayPreview) {
                content = initialContent;
            } else if (tinyMceRef.current?.editor?.id) {
                let id = tinyMceRef.current.editor.id;
                let editor = tinymce.get(id);
                if (editor) {
                    content = editor.getContent();
                }
            }

            if (!content || (content && content.trim() === "")) {
                setError({ status: true, message: "Please fill in some content" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Post data
            setError({ status: false, message: "" });
            api.post('announcement', {
                forumCategoryId: selectedForumCategory,
                title: topic.title,
                content: content,
                isSticky: topic.isSticky,
                isLocked: topic.isLocked
            })
            .then(() => {
                setStatus(saveStatuses.SAVED);
                setTopic({ title: '', isSticky: false, isLocked: false });
                setSelectedForumCategory(null);
                
                if (tinyMceRef.current?.editor?.id) {
                    let id = tinyMceRef.current.editor.id;
                    let editor = tinymce.get(id);
                    if (editor) {
                        editor.setContent('');
                    }
                }
            })
            .catch((error) => {
                console.log("error", error, error.response);
                setError({ status: true, message: "Error creating the announcement" });
                setStatus(saveStatuses.ERROR);
            });
        }
    }, [status, topic, selectedForumCategory, displayPreview, initialContent]);

    const handleSelectedForumCategoryChanged = useCallback((event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        setSelectedForumCategory(value);
    }, []);

    const handleImageUpload = (blobInfo: any, success: any, failure: any) => {
        // Calculate sizes
        let imageSize = blobInfo.blob().size / 1000; // size in kb
        let maximumSize = 5000;

        // Throw error if too large
        if (imageSize > maximumSize) {
            failure("Your image is too large (" + imageSize + "kb). Maximum size is 5MB. Please use an external image upload service or lower your image resolution.");
            return;
        }

        // Create data
        let data = new FormData();
        data.append('file', blobInfo.blob(), blobInfo.filename());
        
        // Send request to the server
        api.post("attachment/topic", data)
        .then((response) => {
            success(baseApiUrl + "attachments/topics/" + response.data);
        })
        .catch((error) => {
            console.log("error", error, error.response, error.response.data);
            if (error.response.data) {
                failure(error.response.data);
            } else {
                failure("Failed to upload the image.");
            }
        });
    }

    const handlePreviewChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (displayPreview) {
            setDisplayPreview(false);
        } else {
            let editorContent = "";
            if (tinyMceRef.current?.editor?.id) {
                let id = tinyMceRef.current.editor.id;
                let editor = tinymce.get(id);

                if (editor) {
                    editorContent = editor.getContent();
                }
            }

            setInitialContent(editorContent);
            setDisplayPreview(true);
        }
    }

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    // Verify settings found
    if (!settings) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading settings...
            </div>
        );
    } else {
        let options = forumCategories.filter(fc => fc.value !== settings.forumPlayerArtCategoryId 
            && fc.value !== settings.forumPlayerStoryCategoryId 
            && fc.value !== settings.forumRtqCategoryId);

        let innerContent = null;
        if (displayPreview) {
            let posted = new Date();
            let author = me?.username;

            innerContent = (
                <>
                    {/* Desktop Title */}
                    <div className="d-none d-md-flex title-bar dark px-2">
                        <div className="d-flex w-100">
                            <div className="post-left-side">
                                <span>Author</span>
                            </div>
                            <div className="flex-grow-1">
                                <span>Message</span>
                            </div>
                        </div>
                    </div>

                    {/* Mobile title */}
                    <div className="d-md-none title-bar dark px-2">
                        <span>{topic.title}</span>
                    </div>

                    <div className="alternate-table-entry bg-white p-2">
                        {/* Mobile */}
                        <div className="d-flex flex-column d-md-none w-100">
                            <div className="d-flex align-items-center bb-xs">
                                <div className="mobile-image-post-avatar">
                                    <i className="fas fa-user-circle text-dark fa-2x"></i>
                                </div>
                                <div className="d-flex-grow-1 p-2 d-flex flex-column">
                                    <span className='font-weight-bold'>{author}</span>
                                    <span>{getDate(posted)}, {getTime(posted)}</span>
                                </div>
                            </div>
                            <div className="dangerous-container pt-2" dangerouslySetInnerHTML={{ __html: initialContent }} />
                        </div>
                        
                        {/* Desktop */}
                        <div className="d-none d-md-block w-100">
                            {/* Message header */}
                            <div className="d-flex py-1">
                                {/* Author name */}
                                <div className="post-left-side">
                                    <strong>{author}</strong>
                                </div>

                                {/* Subject */}
                                <div className="d-flex flex-grow-1">
                                    <span><strong>Posted:</strong> {getDate(posted)}, {getTime(posted)}</span>
                                </div>
                            </div>

                            {/* Actual message */}
                            <div className="d-flex p-y1">
                                {/* Profile pic */}
                                <div className="post-left-side">
                                    <i className="fas fa-user-circle text-dark fa-5x"></i>
                                </div>

                                {/* Message */}
                                <div className='dangerous-container flex-grow-1' dangerouslySetInnerHTML={{ __html: initialContent }} />
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            innerContent = (
                <Editor
                    initialValue={initialContent}
                    tinyMceRef={tinyMceRef}
                    minHeight={150}
                    height={500}
                    onImageUpload={handleImageUpload}
                />
            )
        }

        content = (
            <React.Fragment>
                <div className="row w-100">
                    <div className="d-flex flex-column col-12 col-md-6">
                        {/* Display preview */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={displayPreview}
                                    onChange={handlePreviewChanged}
                                    color="primary"
                                />
                            }
                            label="Display preview"
                            className='ml-1'
                        />
                        <div className="d-block w-100 ml-2 mb-2">
                            <Select
                                id="select-announcement-category"
                                label="Select forum category"
                                className={["w-100", "mt-2"]}
                                value={selectedForumCategory}
                                onChange={handleSelectedForumCategoryChanged}
                                error={error.status && !selectedForumCategory}
                                helperText="Please select a forum category"
                                options={options}
                                grouped
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="d-flex flex-column w-100 ml-2 mb-2">
                            {/* Sticky */}
                            <Checkbox
                                label="Sticky"
                                checked={topic.isSticky}
                                disabled={false}
                                checkboxClasses="table-checkbox"
                                controlClasses="dark-red-checkbox"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTopic({ ...topic, isSticky: event.target.checked })}  
                            />

                            {/* Locked */}
                            <Checkbox
                                label="Locked"
                                checked={topic.isLocked}
                                disabled={false}
                                checkboxClasses="table-checkbox"
                                controlClasses="dark-red-checkbox"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTopic({ ...topic, isLocked: event.target.checked })}  
                            />

                            {/* Error */}
                            { error.status && error.message.trim() !== "" ? (
                                <div className="mb-3 alert alert-danger rounded-0 mb-0">
                                    {error.message}
                                </div>
                            ) : null }

                            {/* Title */}
                            <Input
                                id="announcement-title"
                                label="Title"
                                className="mb-2"
                                value={topic.title}
                                error={error.status && topic.title.trim() === ""}
                                disabled={false}
                                helperText="Please fill in a title"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTopic({ ...topic, title: event.target.value })}
                            />

                            {/* Editor or preview */}
                            {innerContent}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="d-block w-100 ml-2 mb-2">
                            <SaveButton
                                type={saveTypes.CONTROLLED}
                                status={status}
                                setStatus={setStatus}
                                showFeedback
                                hideCancel
                                saveClasses={["dark-red-button"]}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <div id="admin" className="w-100 px-2 pt-2 pb-1">
            {/* Header */}
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Create announcement</span>
                </div>
            </div>

            {/* Content */}
            <div className="d-flex align-items-center bg-light-gray p-2 mb-2">
                {content}
            </div>
        </div>
    )
};

export default AdminAnnouncements;
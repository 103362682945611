import { Button } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../api/api';

import { AuthReducerProps, RoleProps } from '../../../store/reducers/authReducer';
import ForumRightItem from './ForumRightItem';
import ForumRightModal from './ForumRightModal';

export interface ForumRightProps {
    id: string;
    forumId: string;
    roleId: string;
    allowedToView: boolean;
    allowedToUse: boolean;
}

export interface ForumProps {
    id: string;
    name: string;
    sortOrder: string;
    forumRights: ForumRightProps[];
};

interface ParamProps {
    id: string;
};

interface PopupDataProps {
    status: boolean;
    name: string;
    role: string;
    forumRight: ForumRightProps | null
}

const ForumSecurity: React.FC<{}> = () => {
    const [forum, setForum] = useState<ForumProps | null>(null);
    const [roles, setRoles] = useState<RoleProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [popupData, setPopupData] = useState<PopupDataProps>({ status: false, name: "", role: "", forumRight: null });

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);
    const params: ParamProps = useParams<ParamProps>();
    const history = useHistory();

     /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        api.get('role')
        .then((response) => {
            setRoles(response.data);
        })
        .catch((error) => {
            console.log("Error loading roles: ", error, error.response);
        });
    }, [uid]);

    useEffect(() => {
        if (!params.id || params.id.trim() === '') {
            history.push("/admin/forums");
        } else {
            api.get('forumRight/admin/' + params.id)
            .then((response) => {
                setForum(response.data);
            })
            .catch((error) => {
                console.log("Failed to load forum rights", error, error.response);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, [uid, params, history]);

    /***************************************************
    * Event handlers
     ***************************************************/
    const goBack = useCallback(() => {
        history.push("/admin/forums");
    }, [history]);

    const closePopup = useCallback(() => {
        setPopupData({ status: false, name: "", role: "", forumRight: null });
    }, []);

    const update = useCallback((forumRight: ForumRightProps) => {
        let forumRights = [];
        if (forum) {
            forumRights = forum.forumRights.map((fr) => {
                if (fr.id === forumRight.id) {
                    return { ...forumRight };
                } else {
                    return fr;
                }
            });

            let updatedForum = {
                ...forum,
                forumRights: forumRights
            };

            setForum(updatedForum);
        }
    }, [forum]);

    const handleEditClicked = useCallback((id) => {
        let forumRight: ForumRightProps | undefined = forum?.forumRights.find(fr => fr.id === id);
        let role: RoleProps | undefined = roles.find(r => r.id === forumRight?.roleId);

        if (forumRight && role) {
            setPopupData({
                status: true,
                name: forum ? forum.name : "",
                role: role.name,
                forumRight: forumRight
            });
        }
    }, [forum, roles]);

    /***************************************************
    * Render
     ***************************************************/
    let content = null;
    if (loading) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading data...
            </div>
        )
    }
    else if (forum == null) {
        content = (
            <div className="alert alert-info rounded-0 mb-0">
                No roles found.
            </div>
        );
    }
    else {
        content = forum.forumRights.map((fr, index) => (
            <ForumRightItem 
                key={index}
                forumRight={fr}
                roles={roles}
                onEditClicked={handleEditClicked}
            />
        ));
    }

    let modal = null;
    if (popupData.status && popupData.forumRight) {
        modal = (
            <ForumRightModal
                forumRight={popupData.forumRight}
                name={popupData.name}
                role={popupData.role}
                closePopup={closePopup}
                update={update}
            />
        );
    }

    return (
        <React.Fragment>
            {modal}
            <div className="w-100">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <Button variant="contained" className="mr-2 action-button bg-white text-dark-red rounded-0" onClick={goBack}>
                            <i className="fas fa-step-backward"></i>
                        </Button>
                        <div className="flex-grow-1 d-flex flex-column">
                            <span>Manage forum rights</span>
                            <span className="text-overflow w-100">Forum: {forum?.name}</span>
                        </div>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="mt-2 title-bar px-2 dark">
                            <div className="row w-100">
                                <div className="col-12 col-md-6"><span>Role</span></div>
                                <div className="d-none d-md-flex col-2"><span>View</span></div>
                                <div className="d-none d-md-flex col-2"><span>Use</span></div>
                                <div className="d-none d-md-flex justify-content-end col-2"><span>Actions</span></div>
                            </div>
                        </div>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForumSecurity;
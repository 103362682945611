import React from 'react';
import { JsxElement } from 'typescript';
import { 
    Checkbox as MuiCheckbox, 
    FormControlLabel, 
    FormGroup, 
    FormControl, 
    FormHelperText, 
    FormLabel 
} from '@material-ui/core';

interface Props {
    checked: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checkboxClasses?: string;
    controlClasses?: string;
    formControlClasses?: string;
    formGroupClasses?: string;
    required?: boolean;
    error?: boolean;
    disabled?: boolean;
    helperText?: string | JSX.Element | JsxElement;
    label?: string | JSX.Element | JsxElement;
    formLabel?: string | JSX.Element | JsxElement;
    color?: "primary" | "secondary" | "default";
};

const Checkbox: React.FC<Props> = (props) => {
    let checkboxClasses = props.checkboxClasses ? props.checkboxClasses + " oberin-checkbox" : "oberin-checkbox";
    let controlClasses = props.controlClasses ? props.controlClasses + " oberin-checkbox-control" : "oberin-checkbox-control";
    let formControlClasses = props.formControlClasses ? props.formControlClasses + " oberin-checkbox-form-control" : "oberin-checkbox-form-control";
    let formGroupClasses = props.formGroupClasses ? props.formGroupClasses + " oberin-checkbox-form-group" : "oberin-checkbox-form-group";

    return (
        <div className={formControlClasses}>
            <FormControl required={props.required} error={props.error}>
                {props.formLabel ? <FormLabel>{props.formLabel}</FormLabel> : null}
                <FormGroup className={formGroupClasses}>
                    <FormControlLabel
                        label={props.label}
                        className={checkboxClasses}
                        control={<MuiCheckbox
                            className={controlClasses}
                            checked={props.checked}
                            onChange={props.onChange}
                            color={props.color ? props.color : "primary"}
                            disabled={props.disabled}
                        />}
                    />
                </FormGroup>
                {props.helperText && props.error ? <FormHelperText>{props.helperText}</FormHelperText> : null}
            </FormControl>
        </div>
    );
};

export default Checkbox;
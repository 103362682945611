import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Role } from '../../../utilities/roles';
import Checkbox from '../../MUI/Checkbox';
import SaveButton, { saveStatuses, saveTypes } from '../../MUI/SaveButton';
import { CategoryRightProps } from './CategorySecurity';

import api from '../../../api/api';
import { useSelector } from 'react-redux';
import { AuthReducerProps } from '../../../store/reducers/authReducer';

interface Props {
    forumCategoryRight: CategoryRightProps;
    role: string;
    forumName: string;
    forumCategoryName: string;
    closePopup: () => void;
    update: (updatedForumCategoryRight: CategoryRightProps) => void;
};

const CategoryRightModal: React.FC<Props> = ({ role, forumName, forumCategoryName, forumCategoryRight, closePopup, update }) => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [allowedToView, setAllowedToView] = useState<boolean>(forumCategoryRight.allowedToView);
    const [allowedToCreate, setAllowedToCreate] = useState<boolean>(forumCategoryRight.allowedToCreate);
    const [allowedToReply, setAllowedToReply] = useState<boolean>(forumCategoryRight.allowedToReply);
    const [allowedToModerate, setAllowedToModerate] = useState<boolean>(forumCategoryRight.allowedToModerate);
    const [error, setError] = useState<string>("");

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Event handlers
     ***************************************************/
    useEffect(() => {
        if (forumCategoryRight.roleId === Role.ADMIN) {
            closePopup();
        }
    }, [forumCategoryRight.roleId, closePopup]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Verify role
            if (forumCategoryRight.roleId === Role.ADMIN) {
                setError("Admin role can't be edited");
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify booleans
            if ((allowedToCreate || allowedToReply) && !allowedToView) {
                setError("You can only use something if you can view it");
                setStatus(saveStatuses.ERROR);
                return;
            }

            if (allowedToModerate && !allowedToReply) {
                setError("You can only moderate something if you can use it");
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Create model
            let model = {
                ...forumCategoryRight,
                allowedToCreate: allowedToCreate,
                allowedToReply: allowedToReply,
                allowedToView: allowedToView,
                allowedToModerate: allowedToModerate
            };

            api.put("forumCategoryRight/admin/" + model.id, model)
            .then(() => {
                update(model);
                closePopup();
            })
            .catch((error) => {
                let message = "Error on the server.";
                if (error?.response?.data?.message) {
                    message = error.response.data.message;
                }

                setError(message);
            });
        }
    }, [uid, status, forumCategoryRight, allowedToCreate, allowedToReply, allowedToView, allowedToModerate, update, closePopup]);

    /***************************************************
     * Render
     ***************************************************/
    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby={"edit-forum-category-rights-popup"}
            aria-describedby={"Edit the forum category rights"}
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white d-flex flex-column">
                    <span className="w-100 text-oerflow">{forumName}: {forumCategoryName}</span>
                    <span className="w-100 text-overflow">Role: {role}</span>
                </div>
                <div className="d-flex flex-column p-2">
                    <Checkbox
                        checked={allowedToView}
                        label="Allowed to view"
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllowedToView(event.target.checked)} 
                    />
                    <Checkbox
                        checked={allowedToCreate}
                        label="Allowed to create"
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllowedToCreate(event.target.checked)}  
                    />
                    <Checkbox
                        checked={allowedToReply}
                        label="Allowed to reply"
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllowedToReply(event.target.checked)}  
                    />
                    <Checkbox
                        checked={allowedToModerate}
                        label="Allowed to moderate"
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllowedToModerate(event.target.checked)}  
                    />

                    { error.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error}
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CategoryRightModal;
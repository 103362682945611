import * as React from 'react';
import { Modal } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';

import api from '../../../api/api';
import Input from '../../MUI/Input';
import { IUser } from './InstallationGuideUsers';

interface IUserModalProps {
    users: IUser[] | null;
    onClose: () => void;
    onUserAdded: (user: IUser) => void;
};

interface IAuthor extends IUser {
    alreadySelected: boolean;
}

interface IData {
    page: number;
    totalPages: number;
    authors: IAuthor[]
};

const debounceFunction = () => {return;};
const debounce = AwesomeDebouncePromise(debounceFunction, 350);

let cancelTokenSource = axios.CancelToken.source();

const UserModal: React.FunctionComponent<IUserModalProps> = ({
    users,
    onClose,
    onUserAdded
}) => {
    const [page, setPage] = React.useState<number>(1);
    const [search, setSearch] = React.useState<string>("");
    const [inputValue, setInputValue] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);

    const [data, setData] = React.useState<IData>({
        page: 1,
        totalPages: 1,
        authors: []
    });

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        // Set cancel
        cancelTokenSource.cancel("Operation cancelled due to new user search request.");
        cancelTokenSource = axios.CancelToken.source();

        // Set loading
        setLoading(true);

        api.get<IData>('/members/authors/' + page + '/1?search=' + search)
        .then((response) => {
            setData({
                ...response.data,
                authors: response.data.authors.map((author) => {
                    let alreadySelected = false;
                    if (users) {
                        alreadySelected = users.findIndex(u => u.id === author.id) !== -1;
                    }

                    return {
                        ...author,
                        alreadySelected: alreadySelected
                    };
                })
            });
        })
        .catch(() => {
            setData({
                page: 1,
                totalPages: 1,
                authors: []
            })
        })
        .finally(() => {
            setLoading(false);
        })
    }, [page, search, users]);

    /***************************************************
     * Event handlers
     ***************************************************/
     const handleInputChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setInputValue(value);

        await debounce();

        setSearch(value);
        setPage(1);
    }

    const handleAuthorSelected = React.useCallback((author: IAuthor) => {
        onUserAdded({
            id: author.id,
            name: author.name
        });
    }, [onUserAdded]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (loading) {
        content = (
            <div className='alert alert-info rounded-0 mb-0'>
                Loading users...
            </div>
        );
    } else if (data.authors.length === 0) {
        content = (
            <div className="alert alert-danger rounded-0 mb-0">
                No users found.
            </div>
        );
    } else {
        let items = data.authors.map((author) => {
            return (
                <div key={author.id} className='alternate-table-entry p-2 d-flex align-items-center'>
                    <span>{author.name}</span>
                    { author.alreadySelected ? (
                        <i className='ml-auto h-100 p-2 fas fa-check text-success' />
                    ) : (
                        <i className='ml-auto h-100 p-2 fas fa-plus-circle text-dark-red clickable' onClick={() => handleAuthorSelected(author)} />
                    )}
                </div>   
            )
        })

        content = (
            <>
                <div className='title-bar dark px-2'>
                    <div className="row w-100">
                        <div className="d-flex align-items-center col-12">
                            <span>User</span>
                        </div>
                    </div>
                </div>
                {items}
                <div className='pagination-container w-100 mt-2'>
                    <Pagination
                        page={page}
                        count={data.totalPages}
                        onChange={(event, page) => setPage(page)}
                        siblingCount={0}
                        boundaryCount={1}
                        variant='outlined'
                        shape='rounded'
                    />
                </div>
            </>
        );
    }

    return (
        <Modal
            open
            aria-label='Search a user'
            aria-describedby='Search a user'
            onClose={onClose}
            className='d-flex align-items-center justify-content-center'
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>Search a user</span>
                    <div className='d-flex ml-auto align-items-center justify-content-center'>
                        <i className='fas fa-times text-white clickable' onClick={onClose} />
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <Input
                        id='search-user'
                        label='Search by name'
                        value={inputValue}
                        onChange={handleInputChanged}
                    />
                    {content}
                </div>
            </div>
        </Modal>
    );
};

export default UserModal;

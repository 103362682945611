import { Button } from '@material-ui/core';
import * as React from 'react';
import EditPart from './EditPart';
import { IEditPart, IVersionGuide } from './ManageGuideVersionEdit';

interface IEditPartsProps {
    data: IVersionGuide;
    editing: number | null;
    setData: React.Dispatch<React.SetStateAction<IVersionGuide | null>>;
    onCancelClicked: () => void;
    onEditClicked: (index: number) => void;
}

const EditParts: React.FunctionComponent<IEditPartsProps> = ({
    data,
    editing,
    setData,
    onCancelClicked,
    onEditClicked
}) => {
    /***************************************************
     * Event handlers
     ***************************************************/
    const handleAddPartAtStartClicked = React.useCallback(() => {
        if (editing) {
            alert('Please save changes before adding new parts');
            return;
        }

        setData((currentData) => {
            if (currentData === null) {
                return null;
            }

            let updatedParts: IEditPart[] = [{
                id: '',
                title: '',
                content: '',
                sortOrder: 1,
                parts: []
            }];

            currentData.parts.forEach((part) => {
                updatedParts.push({
                    ...part,
                    sortOrder: part.sortOrder + 1
                });
            });

            return {
                ...currentData,
                parts: updatedParts
            };
        });
    }, [editing, setData]);

    const handleAddPartClicked = React.useCallback((partIndex: number) => {
        if (editing) {
            alert('Please save changes before adding new parts');
            return;
        }

        setData((currentData) => {
            if (currentData === null) {
                return null;
            }

            // Init update parts
            let updatedParts: IEditPart[] = [];

            // Add parts up to the one we clicked the button from
            let sortOrder = 0;
            for(let i = 0; i <= partIndex; i++) {
                sortOrder = currentData.parts[i].sortOrder;
                updatedParts.push({
                    ...currentData.parts[i]
                });
            }

            // Increment sort order
            sortOrder++;

            // Add the new part
            updatedParts.push({
                id: '',
                title: '',
                content: '',
                sortOrder: sortOrder,
                parts: []
            });

            // Add the remaining parts if necessary
            for(let i = partIndex + 1; i < currentData.parts.length; i++) {
                updatedParts.push({
                    ...currentData.parts[i],
                    sortOrder: currentData.parts[i].sortOrder + 1
                });
            }

            return {
                ...currentData,
                parts: updatedParts
            }
        });
    }, [editing, setData]);

    const handleSaveClicked = React.useCallback((part: IEditPart, partIndex: number) => {
        setData((currentData) => {
            if (currentData === null) {
                return null;
            }

            let updatedParts = [...currentData.parts];
            updatedParts[partIndex] = {...part};

            return {
                ...currentData,
                parts: updatedParts
            };
        });
    }, [setData]);

    const handleDeleteClicked = React.useCallback((partIndex: number, subPartIndex: number | null) => {
        setData((currentData) => {
            if (currentData === null) {
                return null;
            }

            if (subPartIndex === null) {
                return {
                    ...currentData,
                    parts: currentData.parts.filter((p, index) => index !== partIndex)
                }
            }

            return {
                ...currentData,
                parts: currentData.parts.map((part, index) => {
                    if (index !== partIndex) {
                        return part;
                    }
    
                    return {
                        ...part,
                        parts: part.parts.filter((sp, subIndex) => subIndex !== subPartIndex)
                    }
                })
            }
        });
    }, [setData]);

    const handleUpClicked = React.useCallback((partIndex: number) => {
        setData((currentData) => {
            if (currentData === null) {
                return null;
            }

            // Verify if already at the start of the list
            let currentPart = currentData.parts[partIndex];
            if (currentPart.sortOrder === 1) {
                return currentData;
            }

            // Add the parts before
            let updatedParts: IEditPart[] = [];
            for(let i = 0; i < partIndex - 1; i++) {
                updatedParts.push({ ...currentData.parts[i] });
            }

            // Add the part now
            updatedParts.push({ ...currentPart });

            // Add the part before the current part
            updatedParts.push({ ...currentData.parts[partIndex - 1] });

            // Add the next parts
            for(let i = partIndex + 1; i < currentData.parts.length; i++) {
                updatedParts.push({ ...currentData.parts[i] });
            }

            // Loop over the parts to set the sort order
            let sortOrder = 1;
            updatedParts.forEach((updatedPart) => {
                updatedPart.sortOrder = sortOrder;
                sortOrder++;
            });

            return {
                ...currentData,
                parts: updatedParts
            };
        });
    }, [setData]);

    const handleDownClicked = React.useCallback((partIndex: number) => {
        setData((currentData) => {
            if (currentData === null) {
                return null;
            }

            let currentParts = [...currentData.parts];

            // Verify if already at the end of the list
            if (partIndex === currentParts.length - 1) {
                return currentData;
            }

            // Add the parts before
            let updatedParts: IEditPart[] = [];
            for(let i = 0; i < partIndex; i++) {
                updatedParts.push({ ...currentParts[i] });
            }

            // Add part after
            if (currentParts[partIndex + 1]) {
                updatedParts.push({ ...currentParts[partIndex + 1] });
            }

            // Add the part now
            updatedParts.push({ ...currentParts[partIndex] });

             // Add next parts
             for(let i = partIndex + 2; i < currentParts.length; i++) {
                updatedParts.push({...currentParts[i]});
            }

            // Set sort orders
            let sortOrder = 1;
            updatedParts.forEach((updatedPart) => {
                updatedPart.sortOrder = sortOrder;
                sortOrder++;
            });

            return {
                ...currentData,
                parts: updatedParts
            };
        });
    }, [setData]);

    const handleSubPartUpClicked = React.useCallback((partIndex: number, subPartIndex: number) => {
        setData((currentData) => {
            // Verify if current parts are set
            if (currentData === null) {
                return null;
            }

            return {
                ...currentData,
                parts: currentData.parts.map((part, index) => {
                    // Verify main part index
                    if (index !== partIndex) {
                        return part;
                    }

                    let currentPart = part.parts[subPartIndex];
                    if (!currentPart || currentPart.sortOrder === 1) {
                        return part;
                    }

                    // Add parts before
                    let updatedParts: IEditPart[] = [];
                    for(let i = 0; i < subPartIndex - 1; i++) {
                        updatedParts.push({ ...part.parts[i] });
                    }

                    // Add current part
                    updatedParts.push({...currentPart});

                    // Add part before current part
                    updatedParts.push({ ...part.parts[subPartIndex - 1] });

                    // Add remaining parts
                    for(let i = subPartIndex + 1; i < part.parts.length; i++) {
                        updatedParts.push({ ...part.parts[i] });
                    }

                    // Set sort orders
                    let sortOrder = 1;
                    updatedParts.forEach((updatedPart) => {
                        updatedPart.sortOrder = sortOrder;
                        sortOrder++;
                    });

                    return {
                        ...part,
                        parts: updatedParts
                    };
                })
            }
        });
    }, [setData]);

    const handleSubPartDownClicked = React.useCallback((partIndex: number, subPartIndex: number) => {
        setData((currentData) => {
            // Verify if there are current parts
            if (currentData === null) {
                return null;
            }

            // Loop over the map to find and return the right part
            return {
                ...currentData,
                parts: currentData.parts.map((part, index) => {
                    // Verify if correct parent part
                    if (index !== partIndex) {
                        return part;
                    }
    
                    // Verify if at end of list
                    if (subPartIndex === part.parts.length - 1) {
                        return part;
                    }
    
                    // Verify if part exists
                    let currentPart = part.parts[subPartIndex];
                    if (currentPart === undefined || currentPart === null) {
                        return part;
                    }
    
                    // Add parts before current part
                    let updatedParts: IEditPart[] = [];
                    for(let i = 0; i < subPartIndex; i++) {
                        updatedParts.push({...part.parts[i]});
                    }
    
                    // Add part after
                    if (part.parts[subPartIndex + 1]) {
                        updatedParts.push({...part.parts[subPartIndex + 1]});
                    }
    
                    // Add current part
                    updatedParts.push({...currentPart});
    
                    // Add next parts
                    for(let i = subPartIndex + 2; i < part.parts.length; i++) {
                        updatedParts.push({...part.parts[i]});
                    }
    
                    // Set sort orders
                    let sortOrder = 1;
                    updatedParts.forEach((updatedPart) => {
                        updatedPart.sortOrder = sortOrder;
                        sortOrder++;
                    });
    
                    return {
                        ...part,
                        parts: updatedParts
                    };
                })
            }
        });
    }, [setData]);

    /***************************************************
     * Render
     ***************************************************/
    let content = data.parts.map((part, partIndex) => {
        return (
            <React.Fragment key={partIndex}>
                <EditPart
                    part={part}
                    partIndex={partIndex}
                    editing={editing}
                    onCancelClicked={onCancelClicked}
                    onEditClicked={onEditClicked}
                    onSaveClicked={handleSaveClicked}
                    onDeleteClicked={handleDeleteClicked}
                    onUpClicked={handleUpClicked}
                    onDownClicked={handleDownClicked}
                    onSubPartUpClicked={handleSubPartUpClicked}
                    onSubPartDownClicked={handleSubPartDownClicked}
                />
                <div className="w-100 px-2 mt-2">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <Button disabled={editing !== null} variant='contained' className='w-100 dark-red-button text-normal rounded-0' onClick={() => handleAddPartClicked(partIndex)}>
                                Add part
                            </Button>
                        </div>
                    </div>
                </div>   
            </React.Fragment>
        );
    });

    return (
        <>
            {/* Title */}
            <div className='w-100 px-2 mb-2'>
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <span>{data.name} (Version: {data.versionNumber})</span>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <span>{data.description}</span>
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className="w-100 px-2">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button disabled={editing !== null} variant='contained' className='w-100 dark-red-button text-normal rounded-0' onClick={handleAddPartAtStartClicked}>
                            Add part
                        </Button>
                    </div>
                </div>
            </div>
            {content}
        </>
    )
};

export default EditParts;

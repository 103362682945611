import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { AuthReducerProps, RoleProps } from '../../../store/reducers/authReducer';
import api from '../../../api/api';

import CategoryRightItem from './CategoryRightItem';
import CategoryRightModal from './CategoryRightModal';
import { useSelector } from 'react-redux';

export interface CategoryRightProps {
    id: string;
    forumCategoryId: string;
    roleId: string;
    allowedToView: boolean;
    allowedToCreate: boolean;
    allowedToReply: boolean;
    allowedToModerate: boolean;
}

export interface CategoryProps {
    forumId: string;
    forumCategoryId: string;
    forumName: string;
    forumCategoryName: string;
    forumCategoryRights: CategoryRightProps[];
}

interface ParamProps {
    id: string;
    categoryId: string;
};

interface PopupDataProps {
    status: boolean;
    role: string;
    forumCategoryRight: CategoryRightProps | null
};

const CategorySecurity: React.FC<{}> = () => {
    const [category, setCategory] = useState<CategoryProps | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [roles, setRoles] = useState<RoleProps[]>([]);
    const [popupData, setPopupData] = useState<PopupDataProps>({ status: false, role: "", forumCategoryRight: null });
    const params: ParamProps = useParams<ParamProps>();

    const history = useHistory();
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Startup
     ***************************************************/
    /*
     * Fetch the various roles
     */
    useEffect(() => {
        api.get('role')
        .then((response) => {
            setRoles(response.data);
        })
        .catch((error) => {
            console.log("Failed to load the roles", error, error.response);
        });
    }, [uid]);

    /*
     * Fetch the category rights
     */
    useEffect(() => {
        if (!params.categoryId || params.categoryId.trim() === '') {
            history.push("/admin/forums");
        } else {
            api.get('forumCategoryRight/admin/' + params.categoryId)
            .then((response) => {
                setCategory(response.data);
            })
            .catch((error) => {
                console.log("Failed to load the forum category rights", error, error.response);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, [uid, params, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const goBack = useCallback(() => {
        history.push("/admin/forums/" + params.id + "/categories");
    }, [history, params]);

    const closePopup = useCallback(() => {
        setPopupData({ status: false, role: "", forumCategoryRight: null });
    }, []);

    const update = useCallback((forumCategoryRight: CategoryRightProps) => {
        let forumCategoryRights = [];
        if (category) {
            forumCategoryRights = category.forumCategoryRights.map((fcr) => {
                if (fcr.id === forumCategoryRight.id) {
                    return { ...forumCategoryRight };
                } else {
                    return fcr;
                }
            });

            let updatedCategory = {
                ...category,
                forumCategoryRights: forumCategoryRights
            };

            setCategory(updatedCategory);
        }
    }, [category]);

    const handleEditClicked = useCallback((id: string) => {
        let forumCategoryRight: CategoryRightProps | undefined = category?.forumCategoryRights.find(fcr => fcr.id === id);
        let role: RoleProps | undefined = roles.find(r => r.id === forumCategoryRight?.roleId);

        if (forumCategoryRight && role) {
            setPopupData({
                status: true,
                role: role.name,
                forumCategoryRight: forumCategoryRight
            });
        }
    }, [category, roles]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;
    if (loading) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading data...
            </div>
        );
    }
    else if (category == null) {
        content = (
            <div className="alert alert-info rounded-0 mb-0">
                No roles found.
            </div>
        );
    }
    else {
        content = category.forumCategoryRights.map((fcr, index) => (
            <CategoryRightItem
                key={index}
                forumCategoryRight={fcr}
                roles={roles}
                onEditClicked={handleEditClicked}
            />
        ));
    }

    let modal = null;
    if (popupData.status && popupData.forumCategoryRight) {
        modal = (
            <CategoryRightModal
                forumCategoryRight={popupData.forumCategoryRight}
                role={popupData.role}
                forumName={category ? category.forumName : ""}
                forumCategoryName={category ? category.forumCategoryName : ""}
                closePopup={closePopup}
                update={update}
            />
        );
    }

    return (
        <React.Fragment>
            {modal}
            <div className="w-100">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <Button variant="contained" className="mr-2 action-button bg-white text-dark-red rounded-0" onClick={goBack}>
                            <i className="fas fa-step-backward"></i>
                        </Button>
                        <div className="flex-grow-1 d-flex flex-column">
                            <span>Manage forum category rights</span>
                            <span className="text-overflow w-100">{category?.forumName}: {category?.forumCategoryName}</span>
                        </div>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="mt-2 title-bar px-2 dark">
                            <div className="row w-100">
                                <div className="col-12 col-md-5">
                                    <span>Role</span>
                                </div>
                                <div className="d-none d-md-flex col-1">
                                    <span>View</span>
                                </div>
                                <div className="d-none d-md-flex col-1">
                                    <span>Create</span>
                                </div>
                                <div className="d-none d-md-flex col-1">
                                    <span>Reply</span>
                                </div>
                                <div className="d-none d-md-flex col-2">
                                    <span>Moderate</span>
                                </div>
                                <div className="d-none d-md-flex justify-content-end col-2">
                                    <span>Actions</span>
                                </div>
                            </div>
                        </div>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CategorySecurity;
import React from 'react';

import './SidebarRight.scss';

import ServerOnline from '../../assets/online.png';
import DiscordIcon from '../../assets/discord-icon.svg';
import FacebookIcon from '../../assets/facebook-icon.jpg';
import RecentPosts from '../../components/Layout/Sidebar/RecentPosts/RecentPosts';
// import ServerOffline from '../../assets/offline.png';

interface Props {

};

const SidebarRight: React.FC<Props> = React.memo(() => {
    const handleDiscordClicked = () => {
        window.open('https://discord.gg/32j3DsT', '_blank');
    }

    const handleFacebookClicked = () => {
        window.open('https://www.facebook.com/groups/OberinGroup/about', '_blank');
    }

    return (
        <section id="sidebar-right" className="d-none d-lg-block">
            {/* Pladeholder: Server status */}
            <div className="w-100 px-2 pb-1">
                <div className="box-shadow">
                    <div className="title-bar px-2">Server Status</div>
                    <div className="bg-light-gray p-1 bl-xs br-xs bb-xs">
                        <img src={ServerOnline} alt="Server is online" />
                        <div className="text-muted">
                            *Approximation only
                        </div>
                    </div>
                </div>
            </div>

             {/* Pladeholder: Random Screenshot */}
             <div className="w-100 px-2 py-1">
                <div className="box-shadow">
                    <div className="title-bar px-2">Random Screenshot</div>
                    <div className="bg-light-gray p-1 bl-xs br-xs bb-xs" style={{ height: 150 }}>
                    </div>
                </div>
            </div>

             {/* Player Stories */}
             <div className="w-100 px-2 py-1">
                <div className="box-shadow">
                    <div className="title-bar px-2">Player Stories</div>
                    <div className="recent-post-container bg-light-gray bl-xs br-xs bb-xs">
                        <RecentPosts url="forumCategory/story" />
                    </div>
                </div>
            </div>

             {/* Player Art */}
             <div className="w-100 px-2 pt-1">
                <div className="box-shadow">
                    <div className="title-bar px-2">Player Art</div>
                    <div className="recent-post-container bg-light-gray bl-xs br-xs bb-xs">
                        <RecentPosts url="forumCategory/art" />
                    </div>
                </div>
            </div>

            {/* Social Media */}
            <div className="w-100 px-2 py-1">
                <div className="box-shadow">
                    <div className="title-bar px-2">Social media</div>
                    <div className="bg-light-gray p-1 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="d-flex align-items-center clickable" onClick={handleDiscordClicked}>
                            <img className='discord-image img-fluid' alt='discord invite' src={DiscordIcon} />
                            <span className='ml-2'>Discord Invite</span>
                        </div>
                        <div className="d-flex align-items-center clickable" onClick={handleFacebookClicked}>
                            <img className='discord-image img-fluid' alt='facebook page' src={FacebookIcon} />
                            <span className='ml-2'>Facebook page</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
});

export default SidebarRight;
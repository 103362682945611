import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../api/api';

import CategoryItem from './CategoryItem';
import CategoryModal from './CategoryModal';

export interface ForumCategoryProps {
    id?: string;
    name: string;
    description: string;
    sortOrder: number;
}

interface ForumProps {
    forumId: string;
    name: string;
    forumCategories: ForumCategoryProps[];
}

interface PopupDataProps {
    status: boolean;
    name: string;
    forumCategory: ForumCategoryProps | null
}

interface ParamProps {
    id: string;
};

const Categories: React.FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [forum, setForum] = useState<ForumProps | null>(null);
    const [popupData, setPopupData] = useState<PopupDataProps>({ status: false, name: "", forumCategory: null });
    
    const history = useHistory();
    const params: ParamProps = useParams<ParamProps>();

    /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        if (params.id === null || params.id === undefined || params.id.trim() === "") {
            history.push("/admin/forums");
        } else {
            api.get('forumCategory/admin/' + params.id)
            .then((response) => {
                setForum(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, [params, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const updateForum = useCallback((forumCategories) => {
        if (forum) {
            setForum({
                ...forum,
                forumCategories: forumCategories
            });
        }
    }, [forum]);

    const goBack = useCallback(() => {
        history.push("/admin/forums");
    }, [history]);

    const closePopup = useCallback(() => {
        setPopupData({ status: false, name: "", forumCategory: null });
    }, []);

    const viewSecurity = useCallback((id: string) => {
        history.push("/admin/forums/" + forum?.forumId + "/categories/" + id + "/security");
    }, [history, forum]);

    const createForumCategory = useCallback(() => {
        setPopupData({
            status: true,
            name: forum ? forum.name : "",
            forumCategory: {
                name: "",
                description: "",
                sortOrder: forum ? forum.forumCategories.length + 1 : 1
            }
        });
    }, [forum]);

    const editForumCategory = useCallback((id: string) => {
        let forumCategory = forum?.forumCategories.find(fc => fc.id === id);
        if (forumCategory) {
            setPopupData({
                status: true,
                name: forum ? forum.name : "",
                forumCategory: forumCategory
            });
        }
    }, [forum]);

    const deleteForumCategory = useCallback((id: string) => {
        api.delete("forumCategory/admin/" + id)
        .then(() => {
            let forumCategories: ForumCategoryProps[] = [];
            if (forum) {
                let count = 1;
                forum.forumCategories.forEach((fc) => {
                    if (fc.id === id) {
                        return;
                    }

                    let updatedForumCategory = {
                        ...fc,
                        sortOrder: count
                    };

                    count++;

                    forumCategories.push(updatedForumCategory);
                });

                let updatedForum = {
                    ...forum,
                    forumCategories: forumCategories
                };

                setForum(updatedForum);
            }
        });
    }, [forum]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;
    if (loading) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading data...
            </div>
        );
    }
    else if (forum?.forumCategories.length === 0) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                No categories found for {forum.name}
            </div>
        );
    }
    else {
        content = forum?.forumCategories.map((forumCategory, index) => (
            <CategoryItem
                key={index}
                forumCategory={forumCategory}
                onDeleteClicked={deleteForumCategory}
                onEditClicked={editForumCategory}
                onSecurityClicked={viewSecurity}
            />
        ));
    }

    let modal = null;
    if (forum && popupData.status && popupData.forumCategory) {
        let maximum = 1;
        if (forum) {
            maximum = forum.forumCategories.length;
            if (!popupData.forumCategory.id) {
                maximum++;
            }
        }
        modal = (
            <CategoryModal
                forumId={forum.forumId}
                forumName={forum.name}
                forumCategory={popupData.forumCategory}
                closePopup={closePopup}
                maximum={maximum}
                updateForum={updateForum}
            />
        );
    }

    return (
        <React.Fragment>
            {modal}
            <div className="w-100">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <Button variant="contained" className="mr-2 action-button bg-white text-dark-red rounded-0" onClick={goBack}>
                            <i className="fas fa-step-backward"></i>
                        </Button>
                        <div className="flex-grow-1 d-flex flex-column">
                            <span>Manage forum categories</span>
                            <span className="text-overflow w-100">Forum: {forum?.name}</span>
                        </div>
                    </div>
                    <div className="mobile-forum-list bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="row">
                            <div className="col-12 col-6-md col-lg-4">
                                <Button variant="contained" className="dark-red-button rounded-0 text-normal w-100" onClick={createForumCategory}>
                                    <i className="fas fa-plus mr-2"></i> Create category
                                </Button>
                            </div>
                        </div>
                        <div className="mt-2 title-bar px-2 dark">
                            <div className="row w-100">
                                <div className="col-12 col-md-3">
                                    <span>Category</span>
                                </div>
                                <div className="d-none d-md-flex col-5">
                                    <span>Description</span>
                                </div>
                                <div className="d-none d-md-flex col-2">
                                    <span>Sort order</span>
                                </div>
                                <div className="d-none d-md-flex justify-content-end col-2">
                                    <span>Actions</span>
                                </div>
                            </div>
                        </div>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Categories;
import { Button } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { IInstallationGuide } from './Downloads';

interface IInstallationGuideProps {
    installationGuide: IInstallationGuide
}

const InstallationGuide: React.FunctionComponent<IInstallationGuideProps> = ({ installationGuide }) => {
    const [display, setDisplay] = React.useState<boolean>(false);
    const history = useHistory();

    /***************************************************
     * Event handlers
     ***************************************************/
    const toggleDisplay = React.useCallback(() => {
        setDisplay((currentDisplay) => !currentDisplay);
    }, []);

    const handleGuideClicked = () => {
        history.push('/download/' + installationGuide.id + '/' + installationGuide.liveVersionId);
    }

    /***************************************************
     * Render
     ***************************************************/
    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="row w-100">
                    <div className="d-flex align-items-center col-12">
                        <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={toggleDisplay}>
                            { display ? <i className='fas fa-chevron-down' /> : <i className='fas fa-chevron-right' /> }
                        </Button>
                        <div className="ml-2 flex-grow-1">
                            <span>{installationGuide.name}</span>
                        </div>
                    </div>
                </div>

                {/* Detailed information */}
                { display === false ? null : (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Name
                        </span>
                        <span>{installationGuide.name}</span>
                        <span className='mt-2 font-small'>
                            Description
                        </span>
                        <span>{installationGuide.description}</span>
                        <div className="mt-2 d-flex">
                            <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={handleGuideClicked}>
                                <i className='fas fa-info' />
                            </Button>
                        </div>
                    </div>
                ) }
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-4">
                    <span>{installationGuide.name}</span>
                </div>
                <div className="d-flex align-items-center col-6">
                    <span>{installationGuide.description}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={handleGuideClicked}>
                        <i className='fas fa-info' />
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default InstallationGuide;

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '../../api/api';

import AvatarUpload from '../../components/Profile/AvatarUpload';
import CharacterInformation from '../../components/Profile/CharacterInformation';
import GeneralInformation from '../../components/Profile/GeneralInformation';
import { AuthReducerProps } from '../../store/reducers/authReducer';

export interface CharacterProps {
    id: string;
    name: string;
    profilePicture: string;
    isVerified: boolean;
    isPublic: boolean;
}

interface InformationProps {
    id: string;
    username: string;
    email: string;
    profilePicture: string;
    characters: CharacterProps[];
    isAllowedToAddCharacters: boolean;
}

const Profile: React.FC<{}> = () => {
    const [information, setInformation] = useState<InformationProps | null>(null);
    const [avatar, setAvatar] = useState<{ id: string | null, characterId: string | null }>({ id: null, characterId: null });

    const history = useHistory();
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    useEffect(() => {
        api.get('profile')
        .then((response) => {
            setInformation(response.data);
        })
        .catch((error) => {
            console.log("error", error, error.response);
            history.push("/home/login");
        })
    }, [history, uid]);

    const handleProfilePictureChanged = (updatedPicture: string) => {
        let updatedInforamtion: InformationProps | null = null;

        if (information) {
            updatedInforamtion = {
                ...information,
                profilePicture: updatedPicture
            };

            setInformation(updatedInforamtion);
        }
    }

    const handleAvatarPictureChanged = (updatedPicture: string) => {
        let updatedInforamtion: InformationProps | null = null;

        if (information) {
            updatedInforamtion = {
                ...information,
                characters: information.characters.map((character) => {
                    if (character.id === avatar.characterId) {
                        return {
                            ...character,
                            profilePicture: updatedPicture
                        }
                    }

                    return character;
                })
            }

            setInformation(updatedInforamtion);
        }
    }

    const handleChangeCharacterPictureClicked = (id: string) => {
        if (information) {
            setAvatar({
                id: information.id,
                characterId: id
            });
        }
    }

    const handleCharacterAdded = useCallback((character: CharacterProps) => {
        if (information) {
            let characters = [ ...information.characters, character ];
            let updatedInforamtion = {
                ...information,
                characters: characters
            };

            setInformation(updatedInforamtion);
        }
    }, [information]);

    const handleAvatarButtonClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (information) {
            setAvatar({
                id: information.id,
                characterId: null
            });
        }
    }

    if (information) {
        let avatarUpload = null;
        if (avatar.characterId) {
            let character = information.characters.find(c => c.id === avatar.characterId);
            if (character) {
                avatarUpload = (
                    <AvatarUpload
                        url={"attachment/avatar/character/" + avatar.characterId}
                        label={"Avatar for " + character.name}
                        picture={character.profilePicture}
                        onPictureUploaded={handleAvatarPictureChanged}
                        onClosePopup={() => setAvatar({ id: null, characterId: null })}
                    />
                );
            }
        } else if (avatar.id) {
            avatarUpload = (
                <AvatarUpload
                    url="attachment/avatar/account"
                    label="Avatar for account"
                    picture={information.profilePicture}
                    onPictureUploaded={handleProfilePictureChanged}
                    onClosePopup={() => setAvatar({ id: null, characterId: null })}
                />
            );
        }

        return (
            <React.Fragment>
                {avatarUpload}
                <GeneralInformation 
                    username={information.username} 
                    email={information.email} 
                    profilePicture={information.profilePicture}
                    onAvatarButtonClicked={handleAvatarButtonClicked}
                />
                <CharacterInformation 
                    characters={information.characters}
                    isAllowedToAddCharacters={information.isAllowedToAddCharacters}
                    addCharacter={handleCharacterAdded} 
                    onChangePicture={handleChangeCharacterPictureClicked}
                />
            </React.Fragment>
        );
    } else {
        return (
            <div className="w-100 px-2">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <span>General Information</span>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="alert alert-info rounded-0 mb-0">
                            Loading Information...
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Profile;
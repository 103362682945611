import { Button } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';

import { AuthReducerProps, RoleProps, UserProps as AuthUserProps } from '../../../store/reducers/authReducer';
import getQueryData from '../../../utilities/query';
import api from '../../../api/api';

import Select from '../../MUI/Select';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import InfoUser from './InfoUser';
import User from './User';
import UserCharacters from './UserCharacters';
import VerificationAccounts from './VerificationAccounts';

export interface CharacterProps {
    id: string;
    name: string;
    isVerified: boolean;
    isPublic: boolean;
    verifiedBy?: string;
    verifiedOn?: number;
}

export interface UserProps {
    id: string;
    username: string;
    email: string;
    isVerified: boolean;
    isAllowedToAddCharacters: boolean;
    created: string;
    roleId: string;
    characters: CharacterProps[];
}

interface PopupDataProps {
    status: boolean;
    type: "edit" | "create" | "info" | "characters";
    user: UserProps | null;
}

export interface DataProps {
    totalPages: number;
    currentPage: number;
    users: UserProps[];
}

const sortOptions = [
    { value: "username", label: "Username" },
    { value: "email", label: "Email" },
    { value: "created", label: "Created" },
    { value: "role", label: "Role" }
];

const Users: React.FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [accountsToVerify, setAccountsToVerify] = useState<UserProps[]>([]);
    const [verifyAccounts, setVerifyAccounts] = useState<boolean>(false);
    const [data, setData] = useState<DataProps>({ totalPages: 1, currentPage: 1, users: [] });
    const [popupData, setPopupData] = useState<PopupDataProps>({ status: false, type: 'edit', user: null });
    const [roles, setRoles] = useState<RoleProps[]>([]);
    const me: AuthUserProps | null = useSelector((state: AuthReducerProps) => state.me);

    const history = useHistory();
    const location = useLocation();
    const query = getQueryData(location.search, [
        { property: "page", defaultValue: "1" },
        { property: "sort", defaultValue: "username" },
        { property: "desc", defaultValue: "false" }
    ]);

    const closePopup = useCallback(() => {
        setPopupData({ status: false, type: "edit", user: null });
    }, []);

    /*
     * Get the accounts to verify
     */
    useEffect(() => {
        api.get('user/admin/verify')
        .then((response) => {
            setAccountsToVerify(response.data);
        })
        .catch((error) => {
            console.log("Error fetching accounts to verify", error, error.response);
        });
    }, [me]);

    const updateAccountsToVerify = useCallback((accountId: string, characterId: string, deleted?: boolean) => {
        // Initialise updated lists
        let updatedVerifyAccounts: UserProps[] = [];
        let updatedUsers: UserProps[] = [];

        // Loop over accounts to verify
        accountsToVerify.forEach((account) => {
            if (account.id !== accountId) {
                updatedVerifyAccounts.push(account);
            } else {
                let characters = account.characters.filter(c => c.id !== characterId);
                if (characters.length > 0) {
                    updatedVerifyAccounts.push({
                        ...account,
                        characters: characters
                    });
                }
            }
        });
        setAccountsToVerify(updatedVerifyAccounts);

        // Loop over the data
        if (data) {
            data.users.forEach((account) => {
                if (account.id !== accountId) {
                    updatedUsers.push(account);
                } else if (deleted) {
                    updatedUsers.push({
                        ...account,
                        characters: account.characters.filter((c => c.id !== characterId))
                    });
                } else {
                    let characters = account.characters.map((character) => {
                        if (character.id === characterId) {
                            return {
                                ...character,
                                isVerified: true,
                                verifiedBy: me?.username,
                                verifiedOn: Math.round(new Date(new Date().toUTCString()).getTime() / 1000)
                            }
                        } else {
                            return character
                        }
                    });

                    updatedUsers.push({
                        ...account,
                        characters: characters
                    });
                }
            });

            let updatedData = {
                ...data,
                users: updatedUsers
            };

            setData(updatedData);
        }

        // Loop over the users
    }, [accountsToVerify, data, me]);

    /*
     * Get all the required roles to modify stuff
     */
    useEffect(() => {
        api.get('role')
        .then((response) => {
            setRoles(response.data);
        })
        .catch((error) => {
            console.log("Error fetching roles", error, error.response);
        });
    }, [me]);
    
    /*
     * Get the actual data
     */
    useEffect(() => {
        const currentQuery = getQueryData(location.search, [
            { property: "page", defaultValue: "1" },
            { property: "sort", defaultValue: "username" },
            { property: "desc", defaultValue: "false" }
        ]);

        // Init properties
        let page: string = currentQuery.data.page;
        let sort: string = currentQuery.data.sort;
        let desc: string = currentQuery.data.desc;
        let badQueryData: boolean = false;

        // Verify page
        let pageNumber = Number(page);
        if (isNaN(pageNumber) || !pageNumber || pageNumber <= 0) {
            page = "1";
            badQueryData = true;
        }

        // Verify sort
        if (sortOptions.findIndex(so => so.value === sort) === -1) {
            sort = "username";
            badQueryData = true;
        }

        // Verify desc
        if (!(desc === "true" || desc === "false")) {
            desc = "false";
            badQueryData = true;
        }

        if (badQueryData) {
            history.replace("/admin/users/overview?page=" + page + "&sort=" + sort + "&desc=" + desc);
        } else {
            api.get('user/admin?page=' + page + "&property=" + sort + "&desc=" + desc)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log("Error fetching the users", error, error.response);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, [me, location, history]);

    const handleCreateUserClicked = useCallback(() => {
        setPopupData({
            status: true,
            type: 'create',
            user: null
        });
    }, []);

    const handleEditUserClicked = useCallback((id: string) => {
        let user = data?.users.find(u => u.id === id);
        if (user) {
            setPopupData({
                status: true,
                type: 'edit',
                user: user
            });
        }
    }, [data]);

    const handleViewCharactersClicked = useCallback((id: string) => {
        let user = data?.users.find(u => u.id === id);
        if (user) {
            setPopupData({
                status: true,
                type: 'characters',
                user: user
            });
        }
    }, [data]);

    const handleViewInfoClicked = useCallback((id: string) => {
        let user = data?.users.find(u => u.id === id);
        if (user) {
            setPopupData({
                status: true,
                type: 'info',
                user: user
            });
        }
    }, [data]);

    const handleSortValueChanged = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        if (query.data.sort !== value) {
            let url = "/admin/users/overview?page=" + query.data.page + "&sort=" + value + "&desc=" + query.data.desc;
            history.push(url);
        }
    };

    const onAscendClicked = () => {
        if (query.data.desc === "true") {
            history.push("/admin/users/overview?page=" + query.data.page + "&sort=" + query.data.sort + "&desc=false");
        }
    }

    const onDescendClicked = () => {
        if (query.data.desc === "false") {
            history.push("/admin/users/overview?page=" + query.data.page + "&sort=" + query.data.sort + "&desc=true");
        }
    }

    /*
     * Change the url to reflect the changed page in the users table
     */
    const handlePageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
        history.push("/admin/users/overview?page=" + page + "&sort=" + query.data.sort + "&desc=" + query.data.desc);
    }

    let content = null;
    if (loading) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading users...
            </div>
        );
    }
    else if (data.users.length === 0) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                No users found.
            </div>
        );
    }
    else {
        content = data.users.map((user, index) => (
            <User
                key={index}
                user={user}
                roles={roles}
                onEditClicked={handleEditUserClicked}
                onCharactersClicked={handleViewCharactersClicked}
                onInfoClicked={handleViewInfoClicked}
            />
        ));
    }

    let modal = null;
    if (popupData.status) {
        switch(popupData.type) {
            case "create":
                modal = (
                    <CreateUser
                        roles={roles}
                        closePopup={closePopup}
                        setData={setData}
                    />
                );
                break;
            case "edit":
                if (popupData.user) {
                    modal = (
                        <EditUser
                            user={popupData.user}
                            roles={roles}
                            closePopup={closePopup}
                            setData={setData}
                        />
                    );
                }
                break;
            case "info":
                if (popupData.user) {
                    modal = (
                        <InfoUser
                            user={popupData.user}
                            roles={roles}
                            closePopup={closePopup}
                        />
                    );
                }
                break;
            case "characters":
                if (popupData.user) {
                    modal = (
                        <UserCharacters
                            user={popupData.user}
                            closePopup={closePopup}
                        />
                    );
                }
                break;
            default: break;
        }
    }

    let verificationButton = null;
    if (accountsToVerify.length > 0) {
        verificationButton = (
            <div className="mt-2 row">
                <div className="col-12 col-md-6 col-lg-4">
                    <Button variant="contained" className="dark-red-button rounded-0 text-normal w-100" onClick={() => setVerifyAccounts(true)}>
                        <i className="fas fa-check mr-2"></i> Verify characters ({accountsToVerify.length})
                    </Button>
                </div>
            </div>
        );
    }

    if (verifyAccounts) {
        return (
            <React.Fragment>
                <div className="box-shadow">
                    <div className="title-bar py-1 px-2">
                        <Button variant="contained" className="mr-2 action-button bg-white text-dark-red rounded-0" onClick={() => setVerifyAccounts(false)}>
                            <i className="fas fa-step-backward"></i>
                        </Button>
                        <div className="flex-grow-1 d-flex flex-column">
                            <span>Verify account characters</span>
                        </div>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <VerificationAccounts 
                            accounts={accountsToVerify}
                            updateAccounts={updateAccountsToVerify}  
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {modal}
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Manage the users</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <Button variant="contained" className="dark-red-button rounded-0 text-normal w-100" onClick={handleCreateUserClicked}>
                                <i className="fas fa-plus mr-2"></i> Create user
                            </Button>
                        </div>
                    </div>
                    {verificationButton}
                    <div className="mt-3 row">
                        <div className="col-12 col-md-6 col-lg-4 d-flex">
                            <div className="flex-grow-1">
                                <Select
                                    id="create-account-popup-select-role"
                                    label="Sort by"
                                    value={query.data.sort}
                                    options={sortOptions}
                                    onChange={handleSortValueChanged}
                                />
                            </div>
                            <Button variant="contained" onClick={onAscendClicked} className={query.data.desc === "true" ? "select-button dark-red-button rounded-0 ml-2" : "select-button bg-dark-gray text-white rounded-0 ml-2"}>
                                <i className="fas fa-caret-down"></i>
                            </Button>
                            <Button variant="contained" onClick={onDescendClicked} className={query.data.desc === "true" ? "select-button bg-dark-gray text-white rounded-0 ml-2" : "select-button dark-red-button rounded-0 ml-2"}>
                                <i className="fas fa-caret-up"></i>
                            </Button>
                        </div>    
                    </div>

                    {/* Table header */}
                    <div className="mt-2 title-bar px-2 dark">
                        <div className="row w-100">
                            <div className="col-12 col-md-6">
                                <span>Username</span>
                            </div>
                            <div className="d-none d-md-flex col-4">
                                <span>Role</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-end col-2">
                                <span>Actions</span>
                            </div>
                        </div>
                    </div>

                    {/* Table content */}
                    {content}

                    {/* Pagination */}
                    <div className="pagination-container w-100 mt-2">
                        <Pagination 
                            page={Number(query.data.page)} 
                            count={data.totalPages} 
                            onChange={handlePageChanged} 
                            showFirstButton 
                            showLastButton 
                            variant="outlined" 
                            shape="rounded" 
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Users;
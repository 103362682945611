import React from 'react';
import { Select as MuiSelect, FormControl, InputLabel, MenuItem, FormHelperText, ListSubheader } from '@material-ui/core';
import { JsxElement } from 'typescript';

const PADDING_TOP = 8;
const MENU_PROPS = {
    PaperProps: {
        style: {
            maxHeight: 165 + PADDING_TOP,
            width: 250
        }
    }
};

export interface SelectOptionProps {
    label: string,
    value: any,
    groupBy?: string
};

interface Props {
    id: string;
    label: JsxElement | JSX.Element | string;
    value: any;
    options: SelectOptionProps[];
    className?: string[];
    helperText?: string;
    required?: boolean;
    error?: boolean;
    disabled?: boolean;
    margin?: "dense" | undefined;
    variant?: 'standard' | 'outlined' | 'filled';
    grouped?: true;
    onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
};

const Select: React.FC<Props> = (props) => {
    const onChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode) => {
        let value: any = event.target.value;
        if (value === "__display_null__") {
            value = null;
        } else if (value === "__display_empty__") {
            value = '';
        }

        props.onChange(event, value);
    }

    // Set classname
    let className: string[] = ["w-100"];
    if (props.className) {
        className = props.className;
    }
    className.push("oberin-select");

    // Initialise value
    let value = '';

    let options: any = null;

    if (props.grouped) {
        let sortedOptions = props.options.sort((a, b) => {
            if (!a.groupBy) {
                return 1;
            }

            if (!b.groupBy) {
                return -1;
            }

            return a.groupBy.localeCompare(b.groupBy);
        });

        let currentGroup: string | null = null;
        options = [];
        sortedOptions.forEach((option) => {
            let groupBy = option.groupBy ? option.groupBy : "Default";

            if (currentGroup !== groupBy) {
                options.push(<ListSubheader key={groupBy} disableSticky>{groupBy}</ListSubheader>);
                currentGroup = groupBy;
            }

            let optionValue = "__display_null__";
            if (option.value === '') {
                optionValue = "__display_empty__";
            } else {
                optionValue = option.value;
            }

            if (option.value === props.value) {
                value = optionValue;
            }

            options.push(<MenuItem key={optionValue} value={optionValue}>{option.label}</MenuItem>);
        });
    } else {
        options = props.options.map((option) => {
            let optionValue = "__display_null__";
            if (option.value === '') {
                optionValue = "__display_empty__";
            } else {
                optionValue = option.value;
            }

            if (option.value === props.value) {
                value = optionValue;
            }

            return <MenuItem key={optionValue} value={optionValue}>{option.label}</MenuItem>;
        });
    }
    
    // Get the options
    // let options: SelectOptionProps[] = props.options.map((option) => {
    //     let updatedOption = { ...option };

    //     if (updatedOption.value === null) {
    //         updatedOption.value = "__display_null___";
    //     }

    //     if (updatedOption.value === '') {
    //         updatedOption.value = "__display_empty__";
    //     }

    //     if (option.value === props.value) {
    //         value = updatedOption.value;
    //     }

    //     return updatedOption;
    // });

    return (
        <FormControl 
            required={props.required} 
            error={props.error}
            disabled={props.disabled}
            variant={props.variant ? props.variant : "outlined"}
            className={className.join(' ')}
        >
            <InputLabel id={props.id + "-select-label"} margin={props.margin ? props.margin : "dense"}>
                {props.label}
            </InputLabel>
            <MuiSelect
                className="rounded-0"
                label={props.label}
                labelId={props.id + "-select-label"}
                value={value}
                margin={props.margin ? props.margin : "dense"}
                onChange={onChange}
                MenuProps={MENU_PROPS}
            >
                {/* {options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>  
                ))} */}
                {options}
            </MuiSelect>
            {props.error && props.helperText && props.helperText.trim() !== "" ? (
                <FormHelperText>
                    {props.helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    )
};

export default Select;
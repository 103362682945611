import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import tinymce from 'tinymce';

import api, { baseApiUrl } from '../../api/api';

import Editor from '../Editor/Editor';
import Checkbox from '../MUI/Checkbox';
import Input from '../MUI/Input';
import SaveButton, { saveStatuses, saveTypes } from '../MUI/SaveButton';
import Select, { SelectOptionProps } from '../MUI/Select';
import { AuthReducerProps, UserProps } from '../../store/reducers/authReducer';
import { FormControlLabel, Switch } from '@material-ui/core';
import { getDate, getTime } from '../../utilities/date';

interface ParamProps {
    id: string;
}

interface InformationProps {
    id: string;
    forumId: string;
    name: string;
    forumName: string;
    allowedToModerate: boolean;
    allowedToCreate: boolean;
}

interface TopicProps {
    title: string;
    isSticky: boolean;
    isLocked: boolean;
    postAsCharacter: boolean;
    characterId: null | string;
}

interface CharacterProps {
    id: string;
    name: string;
    isPublic: boolean;
    isVerified: boolean;
}

interface ErrorProps {
    status: boolean;
    message: string;
}

const CreateTopic: React.FC<{}> = () => {
    const [displayPreview, setDisplayPreview] = useState<boolean>(false);
    const [initialContent, setInitialContent] = useState<string>("");
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [loading, setLoading] = useState<boolean>(true);
    const [information, setInformation] = useState<InformationProps | null>(null);
    const [topic, setTopic] = useState<TopicProps>({ 
        title: "", 
        isSticky: false, 
        isLocked: false,
        postAsCharacter: false,
        characterId: null
    });
    const [error, setError] = useState<ErrorProps>({ status: false, message: "" });
    const [characters, setCharacters] = useState<CharacterProps[]>([]);

    const tinyMceRef = useRef<any>(null);

    const history = useHistory();
    const params = useParams<ParamProps>();

    const me: UserProps | null = useSelector((state: AuthReducerProps) => state.me);

    /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        setLoading(true);
        setTopic({ title: "", isSticky: false, isLocked: false, postAsCharacter: false, characterId: null });

        tinyMceRef.current = null;

        api.get('forumCategory/information/' + params.id)
        .then((response) => {
            if (response.data.allowedToCreate === false) {
                history.push("/forum");
                return;
            }

            setInformation(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log("Failed to fetch information", error, error.response);
            history.push("/forum");
        });

        api.get('character')
        .then((response) => {
            setCharacters(response.data);
        })
        .catch((error) => {
            console.log("Failed to fetch characters", error, error.response);
        });
    }, [params, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    useEffect(() => {
        if (status === saveStatuses.CANCEL_TRIGGERED) {
            history.push("/forum/category/" + params.id);
        }
    }, [status, history, params]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Verify title
            if (topic.title.trim() === "") {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }
            
            // Verify isSticky & isLoading
            if ((topic.isSticky || topic.isLocked) && !information?.allowedToModerate) {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify character
            if (topic.postAsCharacter && !topic.characterId) {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify content
            let content = "";
            if (displayPreview) {
                content = initialContent;
            } else if (tinyMceRef.current?.editor?.id) {
                let id = tinyMceRef.current.editor.id;

                let editor = tinymce.get(id);
                if (editor) {
                    content = editor.getContent();
                }
            }

            if (!content || (content && content.trim() === "")) {
                setError({ status: true, message: "Please fill in some content" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            setError({ status: false, message: "" });
            api.post('forumTopic', {
                forumId: information?.forumId,
                forumCategoryId: information?.id,
                title: topic.title,
                content: content,
                isSticky: topic.isSticky,
                isLocked: topic.isLocked,
                characterId: topic.characterId
            })
            .then((response) => {
                history.push("/forum/category/" + information?.id + "/topic/" + response.data);
            })
            .catch((error) => {
                console.log("error", error, error.response);
                setError({ status: true, message: "Error creating the topic" });
                setStatus(saveStatuses.ERROR);
            });
        }
    }, [status, topic, information, history, displayPreview, initialContent]);

    const handleImageUpload = (blobInfo: any, success: any, failure: any) => {
        // Calculate sizes
        let imageSize = blobInfo.blob().size / 1000; // size in kb
        let maximumSize = 5000;

        // Throw error if too large
        if (imageSize > maximumSize) {
            failure("Your image is too large (" + imageSize + "kb). Maximum size is 5MB. Please use an external image upload service or lower your image resolution.");
            return;
        }

        // Create data
        let data = new FormData();
        data.append('file', blobInfo.blob(), blobInfo.filename());
        
        // Send request to the server
        api.post("attachment/topic", data)
        .then((response) => {
            success(baseApiUrl + "attachments/topics/" + response.data);
        })
        .catch((error) => {
            console.log("error", error, error.response, error.response.data);
            if (error.response.data) {
                failure(error.response.data);
            } else {
                failure("Failed to upload the image.");
            }
        });
    }

    const handleCharacterChanged = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        if (topic.characterId !== value ) {
            setTopic({
                ...topic,
                characterId: value
            });
        }
    };

    const handlePostCharacterChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setTopic({
                ...topic,
                postAsCharacter: true
            });
        } else {
            setTopic({
                ...topic,
                postAsCharacter: false,
                characterId: null
            });
        }
    }

    const handlePreviewChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (displayPreview) {
            setDisplayPreview(false);
        } else {
            let editorContent = "";
            if (tinyMceRef.current?.editor?.id) {
                let id = tinyMceRef.current.editor.id;
                let editor = tinymce.get(id);

                if (editor) {
                    editorContent = editor.getContent();
                }
            }

            setInitialContent(editorContent);
            setDisplayPreview(true);
        }
    }

    /***************************************************
     * Render
     ***************************************************/
    if (loading) {
        return (
            <div className="w-100 p-2">
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="mt-2 alert alert-info mb-0 rounded-0">
                            Loading necessary information...
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (!information) {
        return (
            <div className="w-100 p-2">
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="mt-2 alert alert-danger mb-0 rounded-0">
                            Unable to load information
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let options: SelectOptionProps[] = characters.map((character) => {
        return {
            value: character.id,
            label: character.name
        };
    });

    let content = null;
    if (displayPreview) {
        let posted = new Date();
        let author = me?.username;
        if (topic.postAsCharacter && topic.characterId) {
            author = characters.find(c => c.id === topic.characterId)?.name;
        }

        content = (
            <>
                {/* Desktop Title */}
                <div className="d-none d-md-flex title-bar dark px-2">
                    <div className="d-flex w-100">
                        <div className="post-left-side">
                            <span>Author</span>
                        </div>
                        <div className="flex-grow-1">
                            <span>Message</span>
                        </div>
                    </div>
                </div>

                {/* Mobile title */}
                <div className="d-md-none title-bar dark px-2">
                    <span>{topic.title}</span>
                </div>

                <div className="alternate-table-entry bg-white p-2">
                    {/* Mobile */}
                    <div className="d-flex flex-column d-md-none w-100">
                        <div className="d-flex align-items-center bb-xs">
                            <div className="mobile-image-post-avatar">
                                <i className="fas fa-user-circle text-dark fa-2x"></i>
                            </div>
                            <div className="d-flex-grow-1 p-2 d-flex flex-column">
                                <span className='font-weight-bold'>{author}</span>
                                <span>{getDate(posted)}, {getTime(posted)}</span>
                            </div>
                        </div>
                        <div className="pt-2 dangerous-container" dangerouslySetInnerHTML={{ __html: initialContent }} />
                    </div>

                    {/* Desktop */}
                    <div className="d-none d-md-block w-100">
                        {/* Message header */}
                        <div className="d-flex py-1">
                            {/* Author name */}
                            <div className="post-left-side">
                                <strong>{author}</strong>
                            </div>

                            {/* Subject */}
                            <div className="d-flex flex-grow-1">
                                <span><strong>Posted:</strong> {getDate(posted)}, {getTime(posted)}</span>
                            </div>
                        </div>

                        {/* Actual message */}
                        <div className="d-flex p-y1">
                            {/* Profile pic */}
                            <div className="post-left-side">
                                <i className="fas fa-user-circle text-dark fa-5x"></i>
                            </div>

                            {/* Message */}
                            <div className='flex-grow-1 dangerous-container' dangerouslySetInnerHTML={{ __html: initialContent }} />
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        content = (
            <Editor
                initialValue={initialContent}
                tinyMceRef={tinyMceRef}
                minHeight={150}
                height={500}
                onImageUpload={handleImageUpload}
            />
        );
    }

    return (
        <div className="w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    {information.forumName} <i className="fas mx-2 fa-chevron-right"></i> {information.name}
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    {/* Display preview */}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={displayPreview}
                                onChange={handlePreviewChanged}
                                color="primary"
                            />
                        }
                        label="Display preview"
                    />

                    {/* Title */}
                    <Input
                        id="topic-title"
                        label="Title"
                        value={topic.title}
                        error={error.status && topic.title.trim() === ""}
                        disabled={false}
                        helperText="Please fill in a title"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTopic({ ...topic, title: event.target.value })}
                    />

                    {/* Sticky */}
                    { information.allowedToModerate ? (
                        <Checkbox
                            label="Sticky"
                            checked={topic.isSticky}
                            error={!information.allowedToModerate && topic.isSticky}
                            disabled={false}
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-red-checkbox"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTopic({ ...topic, isSticky: event.target.checked })}  
                        />
                    ) : null}

                    {/* Locked */}
                    { information.allowedToModerate ? (
                        <Checkbox
                            label="Locked"
                            checked={topic.isLocked}
                            error={!information.allowedToModerate && topic.isLocked}
                            disabled={false}
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-red-checkbox"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTopic({ ...topic, isLocked: event.target.checked })} 
                        />
                    ) : null}

                    {/* Post as Character */}
                    <Checkbox
                        label="Post as Character"
                        checked={topic.postAsCharacter}
                        disabled={false}
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={handlePostCharacterChanged} 
                    />

                    {/* Select the character */}
                    <Select
                        id="create-topic-character"
                        label="Character"
                        className={["w-100", "mb-3"]}
                        error={error.status && topic.postAsCharacter && !topic.characterId}
                        value={topic.characterId}
                        disabled={!topic.postAsCharacter}
                        options={options}
                        onChange={handleCharacterChanged}
                        helperText="Please select a character"
                    />

                    { error.status && error.message.trim() !== "" ? (
                        <div className="mb-3 alert alert-danger rounded-0 mb-0">
                            {error.message}
                        </div>
                    ) : null }

                    {/* Editor or Preview */}
                    {content}

                    {/* Save Button */}
                    <div className="mt-3 row justify-content-end">
                        <div className="col-12 col-md-6 col-lg-4">
                            <SaveButton
                                type={saveTypes.CONTROLLED}
                                status={status}
                                setStatus={setStatus}
                                showFeedback
                                saveClasses={["dark-red-button"]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTopic;
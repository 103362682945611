import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Topbar from './Layout/Topbar';
import Sidebar from './Layout/Sidebar';
import ContentContainer from './Layout/ContentContainer';
import SidebarRight from './Layout/SidebarRight';

const App: React.FC<{}> = () => {
    const location = useLocation();
    const [toggled, setToggled] = useState<boolean>(false);

    const handleToggled = useCallback(() => {
        setToggled(currentToggle => !currentToggle);
    }, []);

    const collapse = useCallback(() => {
        setToggled(false);
    }, []);

    const displaySidebarRight = !location.pathname.startsWith("/forum");
    return (
        <React.Fragment>
            <Topbar onToggle={handleToggled} />
            <Sidebar toggled={toggled} collapse={collapse} displaySidebarRight={displaySidebarRight} />
            <ContentContainer displaySidebarRight={displaySidebarRight} />
            { displaySidebarRight ? <SidebarRight /> : null }
        </React.Fragment>
    );
};

export default App;
import { Modal } from '@material-ui/core';
import React from 'react';
import UserCharacter from './UserCharacter';
import { UserProps } from './Users';

interface Props {
    user: UserProps;
    closePopup: () => void;
};

const UserCharacters: React.FC<Props> = ({ user, closePopup }) => {
    return (
        <Modal
            open={true}
            onClose={closePopup}
            aria-labelledby="info-account-popup"
            aria-describedby="Accountt information"
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>Characters: {user.username}</span>
                </div>
                <div className="mt-3 py-1 px-2 bg-dark-gray text-white">
                    <span>Name</span>
                </div>
                { user.characters.map((character, index) => <UserCharacter key={index} character={character} />)}
            </div>
        </Modal>
    )
};

export default UserCharacters;
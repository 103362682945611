import { Button, Modal } from '@material-ui/core';
import * as React from 'react';
import Input from '../../MUI/Input';

interface IValidationDenyModalProps {
    onClose: () => void;
    onDenyClicked: (reason: string) => void;
}

const ValidationDenyModal: React.FunctionComponent<IValidationDenyModalProps> = ({
    onClose,
    onDenyClicked
}) => {
    const [reason, setReason] = React.useState<string>("");

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleDenyRequestClicked = () => {
        onDenyClicked(reason);
    }

    /***************************************************
     * Render
     ***************************************************/
    return (
        <Modal
            open
            onClose={onClose}
            aria-labelledby='Deny the validation request'
            aria-describedby='Deny the validation request'
            className='d-flex align-items-center justify-content-center'
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white d-flex flex-column">
                    <span>Deny the request</span>
                </div>
                <div className="d-flex flex-column p-2">
                    <Input
                        id='deny-reason-input'
                        label='Deny reason'
                        value={reason}
                        onChange={(event) => setReason(event.target.value)}
                    />
                    <Button variant='contained' className='mt-2 dark-red-button text-white text-normal rounded-0' onClick={handleDenyRequestClicked}>
                        Deny request
                    </Button>
                </div>
            </div>
        </Modal>
    )
};

export default ValidationDenyModal;

const startDate = new Date(1904, 0, 1, 0, 0, 0);
const totalDays = 2400;
const moonCycles = [
  { name: 'Waking Moon', days: 239, tag: 'waking' },
  { name: 'Growing Moon', days: 250, tag: 'growing' },
  { name: 'Bleeding Moon', days: 230, tag: 'bleeding' },
  { name: 'Red Moon', days: 242, tag: 'red' },
  { name: 'Harvest Moon', days: 233, tag: 'harvest' },
  { name: 'Dancing Moon', days: 253, tag: 'dancing' },
  { name: 'Lucky Moon', days: 232, tag: 'lucky' },
  { name: 'Blue Moon', days: 249, tag: 'blue' },
  { name: 'Fighting Moon', days: 230, tag: 'fighting' },
  { name: 'Sleeping Moon', days: 242, tag: 'sleeping' }
];

const getOberinDate = (date: Date) => {
    // Initialise properties
    let currentDay = 0;
    let currentYear = 0;
    let currentMoon = 0;
    let currentMinutes = 0;
    let currentHours = 0;

    // Calculate base seconds
    let seconds = Math.floor((date.getTime() - startDate.getTime()) / 1000);

    // Remove timezone offset
    seconds += (date.getTimezoneOffset() * 60);

    // Remove server offset
    seconds = seconds - (6 * 24 * 3600) - (8 * 3600) - (28 * 60) - 7;
  
    // Oberin minutes since year 0 (without offset)
    let totalOberinMinutes = seconds / 5;
    let totalOberinDays = totalOberinMinutes / 1440;

    // Get the remaining days
    let remainingDays = totalOberinDays % totalDays;

    // Get the current year + offset of 240
    currentYear = Math.floor(totalOberinDays / totalDays) + 240;  
  
    // Get the current moon
    let count = 0;
    while(true) {
        if (count + moonCycles[currentMoon].days <= Math.floor(remainingDays)) {
            count += moonCycles[currentMoon].days;
            currentMoon++;
        } else {
            break;
        }
    }
  
    // Get the current day
    currentDay = Math.floor(remainingDays) - count + 1;
  
    // Get final time props
    let remainingMinutes = Math.floor((remainingDays - Math.floor(remainingDays)) * 24 * 60);
    currentMinutes = Math.floor(remainingMinutes % 60);
    currentHours = Math.floor(remainingMinutes / 60);
  
    // Get moon props
    let moonState = 0;
    let cpt2 = Math.floor(moonCycles[currentMoon].days / 8);
  
    while (cpt2 < currentDay) {
        moonState++;
        cpt2 += Math.floor(moonCycles[currentMoon].days / 8);
    }

    // Reset moonstate to empty for display
    if (moonState === 8) {
        moonState = 0;
    }

    return {
        currentDay,
        currentHours,
        currentMinutes,
        currentMoon: moonCycles[currentMoon],
        moonState,
        currentYear
    }
}

export default getOberinDate;
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PrivateRoute from '../../components/Private/PrivateRoute';
import { Role } from '../../utilities/roles';

import Admin from '../Admin/Admin';
import Forum from '../Forum/Forum';
import Home from '../Home/Home';
import Profile from '../Profile/Profile';
import CharacterPage from '../Members/CharacterPage';
import AccountPage from '../Members/AccountPage';
import Members from '../Members/Members';
import MoonCycle from '../General/MoonCycle';import DownloadPage from '../General/DownloadPage';
import ContactPage from '../General/ContactPage';
import ScreenshotPage from '../General/ScreenshotPage';
import TermsOfServicePage from '../General/TermsOfServicePage';

import './ContentContainer.scss';
import PrivateComponent from '../../components/Private/PrivateComponent';
import ModeratorRouter from '../Moderator/ModeratorRouter';


interface Props {
    displaySidebarRight: boolean;
}

const ContentContainer: React.FC<Props> = React.memo(({ displaySidebarRight }) => (
    <section id="content-container" className={displaySidebarRight ? "with-sidebar-right" : ""}>
        <Switch>
            <Route path="/home" component={Home} />
            <PrivateRoute roles={[Role.ADMIN]} path="/admin" component={Admin} />
            <PrivateRoute roles={[Role.USER, Role.ADMIN]} path="/profile" component={Profile} />
            <PrivateRoute roles={[Role.USER, Role.ADMIN]} path='/members/account/:accountId' component={AccountPage} />
            <PrivateRoute roles={[Role.USER, Role.ADMIN]} path='/members/character/:characterId' component={CharacterPage} />
            <PrivateRoute roles={[Role.USER, Role.ADMIN]} path="/members" component={Members} />
            <PrivateRoute roles={[Role.USER, Role.ADMIN]} path="/moderator">
                <PrivateComponent rules={['isAllowedToModerate']}>
                    <ModeratorRouter />
                </PrivateComponent>
            </PrivateRoute>
            <Route path="/forum" component={Forum} />
            <Route path="/moonCycle" component={MoonCycle} />
            <Route path="/download" component={DownloadPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/screenshots" component={ScreenshotPage} />
            <Route path="/termsOfService" component={TermsOfServicePage} />
            <Redirect to="/home" />
        </Switch>
    </section>
));

export default ContentContainer;
import { Button } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import api from '../../api/api';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import InstallationGuide from './InstallationGuide';

export interface IInstallationGuide {
    id: string;
    name: string;
    description: string;
    liveVersionId: string;
}

const Downloads: React.FunctionComponent = () => {
    const [installationGuides, setInstallationGuides] = React.useState<IInstallationGuide[] | null>(null);
    const [isAllowedToManage, setIsAllowedToManage] = React.useState<boolean>(false);
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);
    
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get<IInstallationGuide[]>('installationGuide')
        .then((response) => {
            setInstallationGuides(response.data);
        })
        .catch((error) => {
            console.log('[Downloads] Failed to fetch installation guides', error, error.response);
        });
    }, []);

    React.useEffect(() => {
        if (uid) {
            api.get<boolean>("installationGuide/manage/" + uid)
            .then((response) => {
                setIsAllowedToManage(response.data);
            })
            .catch((error) => {
                console.log('[Downloads] Failed to fetch manage information', error, error.response);
            });
        }
    }, [uid]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleManageInstallationGuidesClicked = () => {
        history.push('/download/manage');
    }

    /***************************************************
     * Render
     ***************************************************/
    let content = null;
    if (installationGuides === null) {
        content = (
            <div className="alert alert-info rounded-0 mb-0">
                <span>Loading installation guides...</span>
            </div>
        );
    } else if (installationGuides.length === 0) {
        content = (
            <div className="alert alert-warning rounded-0 mb-0">
                <span>There are currently no installation guides available.</span>
            </div>
        );
    } else {
        content = installationGuides.map((installationGuide) => (
            <InstallationGuide key={installationGuide.id} installationGuide={installationGuide} />
        ));
    }

    return (
        <div className='w-100 px-2'>
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Download: Information</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <p>
                        Oberin is a work in progress. It has its fair share of bugs and parts that aren't yet completed. <br />
                        The game currently runs on computers who have OS up until Mac OS 10.6.8. If you have anything other that than 
                         (aka modern computers), you'll require emulation. Installation guides, emulation guides and downloads will be down below.
                    </p>
                    <p>
                        Once online, you can summon a Game Master (short: GM) by typing '<strong>/help</strong>'. 
                        Game Masters will active your character and help you with most questions you may have. The community itself is very helpful too: Please check out the discord!
                    </p>
                    <p>
                        Game Masters and the server itself won't be up 24/7. We're a small team and unable to cover all hours of every day. If you have any issues, need help, and so forth, please be patient. It might be faster trying to reach us on Discord then in-game.
                    </p>
                    <p>
                        <strong>Character name rules</strong> <br />
                        Your character name will not be visible to other players until it is approved.
                        <strong>Your character name will not be approved if it does not meet the following standard:</strong> <br />
                        - Use a proper name (not: 'the dude', 'eLit3', 'zero cool', ...4) <br />
                        - No curse words or anything crude, vulgar, offensive, ... <br />
                        - No sentences or statements
                        - No titles (lady, king, sir, lord, queen, ...)
                        - Nothing that might make someone think you are an Oberin official like putting GM in your name
                        - No names of animals or monsters
                        - A GM's word on approving your name is final. You may request them to get a second opinion from another GM.
                    </p>
                    <p className='mb-0'>
                        <strong>Proceeding beyond this point means you approve of the above mentioned things as well as the privacy rules and the terms of service</strong> <br />
                        Click this link to read them: <a href="/termsOfService" className='text-dark-red' onClick={(event) => { 
                            event.preventDefault();
                            history.push('/termsOfService');
                        }}>
                            here
                        </a>
                    </p>
                </div>
            </div>
            <div className="mt-2 box-shadow">
                <div className="title-bar px-2">
                    <span>Download: Guides</span>
                </div>
                <div className='bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column'>
                    { isAllowedToManage ? (
                        <div className="row mb-2">
                            <div className="col-12 col-md-6 col-lg-4">
                                <Button variant='contained' className='w-100 dark-red-button text-normal rounded-0' onClick={handleManageInstallationGuidesClicked}>
                                    Manage
                                </Button>
                            </div>
                        </div>   
                    ) : null}
                    <span>
                        Below a list of the downloads and installation guides on how to play the game.
                    </span>
                    <span className='text-muted font-small'>
                        If you want to add your own installation guide, please contact us through discord or the forum.
                    </span>
                    <div className="mt-2 title-bar px-2 dark">
                        <div className="row w-100">
                            <div className="col-12 col-md-4">
                                <span>Name</span>
                            </div>
                            <div className="d-none d-md-flex col-6">
                                <span>Description</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-end col-2">
                                <span>Actions</span>
                            </div>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Downloads;

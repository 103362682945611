import { Modal } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { CharacterProps } from '../../containers/Profile/Profile';
import api from '../../api/api';
import Checkbox from '../MUI/Checkbox';
import Input from '../MUI/Input';
import SaveButton, { saveStatuses, saveTypes } from '../MUI/SaveButton';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

interface Props {
    closePopup: () => void;
    addCharacter: (character: CharacterProps) => void;
}

interface ErrorProps {
    name: boolean;
    message: string
};

const AddCharacter: React.FC<Props> = ({ closePopup, addCharacter }) => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [requestSent, setRequestSent] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps>({ name: false, message: "" });

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            let errorData: ErrorProps = {
                name: false,
                message: ""
            };

            if (name.trim() === "") {
                errorData.name = true;
            };

            if (errorData.name) {
                console.log("Name not ok");
                setError(errorData);
                setStatus(saveStatuses.ERROR);
                return;
            }

            api.post('profile/character', {
                name,
                isPublic
            })
            .then((response) => {
                setRequestSent(true);
                setStatus(saveStatuses.NEUTRAL);
                addCharacter(response.data);
            })
            .catch((error) => {
                let message = "Server error";
                if (error.response.data === "Name is already in use") {
                    message = error.response.data;
                }

                setError({ name: false, message: message });
                setStatus(saveStatuses.ERROR);
            });
        }
    }, [uid, status, name, isPublic, addCharacter]);

    let content = null;
    if (requestSent) {
        content = (
            <div className="alert alert-info mb-0 rounded-0">
                Your request has been sent to add the character
            </div>
        );
    }
    else {
        content = (
            <React.Fragment>
                <Input
                    id="create-account-popup-username"
                    label="Character name"
                    value={name}
                    error={error.name && name.trim() === ""}
                    helperText={"Please fill in a valid character name"}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <Checkbox
                    checked={isPublic}
                    label="Is public"
                    checkboxClasses="table-checkbox"
                    controlClasses="dark-red-checkbox"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsPublic(event.target.checked)}  
                />

                { error.message.trim() !== "" ? (
                    <div className="mt-2 mb-0 alert alert-danger rounded-0">
                        {error.message}
                    </div>
                ) : null }

                <div className="w-50 mt-2 ml-auto">
                    <SaveButton
                        type={saveTypes.CONTROLLED}
                        status={status}
                        setStatus={setStatus}
                        showFeedback
                        hideCancel
                        saveClasses={["dark-red-button"]}
                        saveContent="Send request"
                        savingContent={(<><i className="fas fa-sync fa-spin mr-2" /> Sending...</>)}
                        errorContent={(<><i className="fas fa-times mr-2" /> Error</>)}
                    />
                </div>
            </React.Fragment>
        )
    }

    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby="create-account-popup"
            aria-describedby="Create an account"
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>Add a character</span>
                    <div className="d-flex ml-auto align-items-center justify-content-center">
                        { status === saveStatuses.NEUTRAL ? (
                            <i className="fas fa-times text-white clickable" onClick={closePopup}></i>
                        ) : null}
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    {content}
                </div>
            </div>
        </Modal>
    );
};

export default AddCharacter;
import React from 'react';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Logo from '../../assets/logo.png';
import MainLogo from '../../assets/mainlogo.jpg';
import RightLogo from '../../assets/rightlogo.jpg';

import './Topbar.scss';

interface Props {
    onToggle: ()  => void;
};

const Topbar: React.FC<Props> = React.memo(({ onToggle }) => {
    return (
        <section id="topbar">
            <div className="d-none d-sm-flex">
                <img src={MainLogo} alt="oberin main logo" />
                <div className="flex-grow-1 topbar-background"></div>
                <img src={RightLogo} alt="oberin right logo" />
            </div>
            <div className="d-flex d-sm-none text-white align-items-center">
                <img src={Logo} alt="oberin logo" className="oberin-logo ml-2"/>
                <div className="ml-auto mr-2">
                    <IconButton
                        color="inherit"
                        aria-label="open sidebar"
                        onClick={onToggle}
                        edge="start"
                    >
                        <MenuIcon />    
                    </IconButton>
                </div>
            </div>
        </section>
    );
});

export default Topbar;
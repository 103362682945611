import { Button } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { ForumCategoryProps } from './Categories';

interface Props {
    forumCategory: ForumCategoryProps;
    onDeleteClicked: (id: string) => void;
    onEditClicked: (id: string) => void;
    onSecurityClicked: (id: string) => void;
};

const CategoryItem: React.FC<Props> = ({ forumCategory, onDeleteClicked, onEditClicked, onSecurityClicked }) => {
    const [display, setDisplay] = useState<boolean>(false);

    /***************************************************
     * Event handlers
     ***************************************************/
    const toggleDisplay = useCallback(() => {
        setDisplay(currentDisplay => !currentDisplay);
    }, []);

    const handleEditClicked = useCallback(() => {
        if (forumCategory.id) {
            onEditClicked(forumCategory.id);
        }
    }, [forumCategory.id, onEditClicked]);

    const handleSecurityClicked = useCallback(() => {
        if (forumCategory.id) {
            onSecurityClicked(forumCategory.id);
        }
    }, [forumCategory.id, onSecurityClicked]);

    const handleDeleteClicked = useCallback(() => {
        let id: string = "";
        if (forumCategory.id) {
            id = forumCategory.id;

            if (window.confirm("Are you sure you want to delete this forum category?")) {
                onDeleteClicked(id);
            }
        }
    }, [forumCategory.id, onDeleteClicked]);

    /***************************************************
     * Render
     ***************************************************/
    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                        { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                    </Button>
                    <span className="ml-2 flex-grow-1">
                        {forumCategory.name}
                    </span>
                </div>

                {/* Detailed information */}
                { display ? (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Category
                        </span>
                        <span>
                            {forumCategory.name}
                        </span>
                        <span className="mt-2 font-small">
                            Description
                        </span>
                        <span>
                            {forumCategory.description}
                        </span>
                        <span className="mt-2 font-small">
                            Sort order
                        </span>
                        <span>
                            {forumCategory.sortOrder}
                        </span>
                        <span className="mt-2 font-small">
                            Actions
                        </span>
                        <div className="mt-2 d-flex">
                            <Button variant="contained" className="action-button bg-warning text-white rounded-0" onClick={handleEditClicked}>
                                <i className="fas fa-pencil-alt"></i>
                            </Button>
                            <Button variant="contained" className="ml-2 action-button bg-primary text-white rounded-0" onClick={handleSecurityClicked}>
                                <i className="fas fa-key"></i>
                            </Button>
                            <Button variant="contained" className="ml-2 action-button bg-danger text-white rounded-0" onClick={handleDeleteClicked}>
                                <i className="fas fa-trash"></i>    
                            </Button> 
                        </div>
                    </div>
                ) : null}
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-3"><span>{forumCategory.name}</span></div>
                <div className="d-flex align-items-center col-5"><span>{forumCategory.description}</span></div>
                <div className="d-flex align-items-center col-2"><span>{forumCategory.sortOrder}</span></div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    <Button variant="contained" className="action-button bg-warning text-white rounded-0" onClick={handleEditClicked}>
                        <i className="fas fa-pencil-alt"></i>
                    </Button>
                    <Button variant="contained" className="ml-2 action-button bg-primary text-white rounded-0" onClick={handleSecurityClicked}>
                        <i className="fas fa-key"></i>
                    </Button>
                    <Button variant="contained" className="ml-2 action-button bg-danger text-white rounded-0" onClick={handleDeleteClicked}>
                        <i className="fas fa-trash"></i>    
                    </Button> 
                </div>
            </div>
        </div>
    );
};

export default CategoryItem;
export interface RequestedProperty {
    property: string;
    defaultValue: string;
}

export interface QueryResultProps {
    query: URLSearchParams | null;
    data: any;
}

const getQueryData = (search: string, requestedProperties: RequestedProperty[] = []) => {
    // Initialise query
    let query: URLSearchParams | null = null;

    // Set query
    if (search && search.trim() !== "") {
        query = new URLSearchParams(search);
    }

    // Create the result
    let result : QueryResultProps = {
        query: query,
        data: {}
    }

    // Loop over the requested properties
    if (requestedProperties && requestedProperties.length > 0) {
        requestedProperties.forEach((requestedProperty) => {
            let value: string | null = requestedProperty.defaultValue;
            if (query && query.has(requestedProperty.property)) {
                value = query.get(requestedProperty.property);
            }

            result.data[requestedProperty.property] = value;
        });
    }

    return result;
}

export default getQueryData;
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Button } from '@material-ui/core';

import api from '../../api/api';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import getQueryData from '../../utilities/query';

import Input from '../../components/MUI/Input';
import Select from '../../components/MUI/Select';
import { IPost } from '../../components/Members/CharacterPosts';
import MemberPost from '../../components/Members/MemberPost';
import PrivateComponent from '../../components/Private/PrivateComponent';
import SearchAuthorModal, { IAuthor } from '../../components/Forums/SearchAuthorModal';

const debounceFunction = () => {return;};
const debounce = AwesomeDebouncePromise(debounceFunction, 350);

interface IData {
    currentPage: number;
    totalPages: number;
    posts: IPost[];
}

interface IOption {
    value: string;
    label: string;
    groupBy: string;
}

let cancelTokenSource = axios.CancelToken.source();

const ForumAdvancedSearch: React.FunctionComponent = () => {
    const [init, setInit] = React.useState<boolean>(false);
    const [author, setAuthor] = React.useState<IAuthor | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [options, setOptions] = React.useState<IOption[]>([]);
    const [inputValue, setInputValue] = React.useState<string>("");
    const [displayAuthorModal, setDisplayAuthorModal] = React.useState<boolean>(false);

    const [data, setData] = React.useState<null | IData>(null);

    const history = useHistory();
    const location = useLocation();

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);
    
    const query = getQueryData(location.search, [
        { property: 'page', defaultValue: '1' },
        { property: 'category', defaultValue: '' }
    ]);

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (init === false) {
            const currentQuery = getQueryData(location.search, [
                { property: 'search', defaultValue: '' }
            ]);

            if (currentQuery.data.search && currentQuery.data.search !== "") {
                setInputValue(currentQuery.data.search);
            }

            setInit(true);
        }
    }, [init, location]);

    React.useEffect(() => {
        api.get('forumCategory/move/request/options')
        .then((response) => {
            setOptions(response.data);
        })
        .catch((err) => {
            console.log('Failed to fetch forum category options');
        });
    }, [uid]);

    React.useEffect(() => {
        if (!init) {
            return;
        }

        // Create query
        const currentQuery = getQueryData(location.search, [
            { property: 'search', defaultValue: '' },
            { property: 'page', defaultValue: '1' },
            { property: 'category', defaultValue: '00000000-0000-0000-0000-000000000000' }
        ]);

        // Init params
        let search: string = currentQuery.data.search;
        let category = "00000000-0000-0000-0000-000000000000";
        let page = 1;

        // Set category
        if (currentQuery.data.category) {
            category = currentQuery.data.category;

            if (!category || category.trim() === "") {
                category = "00000000-0000-0000-0000-000000000000";
            }
        }

        // Set page
        if (currentQuery.data.page) {
            page = Number(currentQuery.data.page);

            if (!page || isNaN(page)) {
                page = 1;
            }
        }

        // Set cancel
        cancelTokenSource.cancel("Operation cancelled due to new advanced search request.");
        cancelTokenSource = axios.CancelToken.source();

        setLoading(true);

        let authorId = '00000000-0000-0000-0000-000000000000';
        let accountOrCharacter = 0;
        if (author) {
            authorId = author.id;
            accountOrCharacter = author.isCharacter ? 0 : 1
        }

        api.get('forumTopic/advancedSearch/' 
            + page + '/' 
            + category + '/' 
            + authorId + '/' 
            + accountOrCharacter 
            + '?search=' + search, 
            { cancelToken: cancelTokenSource.token })
        .then((response) => {
            setData(response.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }, [init, location, author]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleQueryChanged = React.useCallback((page: number, search: string, category: string) => {
        // Create url
        let url = '/forum/search?page=' + page;

        // Add search if necessary
        if (search && search.trim() !== "") {
            url += "&search=" + search;
        }

        // Add category if necessary
        if (category) {
            url += "&category=" + category
        }

        history.push(url);
    }, [history]);

    const handlePageChanged = React.useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        const currentQuery = getQueryData(location.search, [
            { property: "search", defaultValue: "" },
            { property: "category", defaultValue: "" }
        ]);

        let search: string = "";
        if (currentQuery.data.search && typeof currentQuery.data.search === 'string') {
            search = currentQuery.data.search;
        }

        let category: string = '00000000-0000-0000-0000-000000000000';
        if (currentQuery.data.category) {
            category = currentQuery.data.category;

            if (!category || category.trim() === '') {
                category = '00000000-0000-0000-0000-000000000000';
            }
        }

        handleQueryChanged(page, search, category);
    }, [location, handleQueryChanged]);

    const handleInputValueChanged = React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setInputValue(value);

        await debounce();

        const currentQuery = getQueryData(location.search, [
            { property: "category", defaultValue: "" }
        ]);

        let category: string = '00000000-0000-0000-0000-000000000000';
        if (currentQuery.data.category && currentQuery.data.category.trim() !== '') {
            category = currentQuery.data.category;
        }

        handleQueryChanged(1, value, category);
    }, [location, handleQueryChanged]);

    const handleForumCategoryChanged = React.useCallback((event: unknown, value: string | undefined | null) => {
        // Create query
        const currentQuery = getQueryData(location.search, [
            { property: "search", defaultValue: "" },
            { property: "page", defaultValue: "1" }
        ]);

        let page: number = 1;
        if (currentQuery.data.page) {
            page = Number(currentQuery.data.page);

            if (!page || isNaN(page)) {
                page = 1;
            }
        }

        let category: string = '00000000-0000-0000-0000-000000000000';
        if (value && value.trim() !== '') {
            category = value;
        }

        let search: string = "";
        if (currentQuery.data.search && typeof currentQuery.data.search === 'string') {
            search = currentQuery.data.search;
        }

        handleQueryChanged(page, search, category);
    }, [location, handleQueryChanged]);

    const handleSearchCharacterClicked = () => {
        setDisplayAuthorModal(true);
    }

    const handleAuthorSelected = React.useCallback((author: IAuthor) => {
        // Create query
        const currentQuery = getQueryData(location.search, [
            { property: "search", defaultValue: "" },
            { property: "category", defaultValue: "" }
        ]);

        // Get search
        let search: string = "";
        if (currentQuery.data.search && typeof currentQuery.data.search === 'string') {
            search = currentQuery.data.search;
        }

        // Get category
        let category: string = '00000000-0000-0000-0000-000000000000';
        if (currentQuery.data.category && currentQuery.data.category.trim() !== '') {
            category = currentQuery.data.category;
        }

        handleQueryChanged(1, search, category);
        setAuthor(author);
        setDisplayAuthorModal(false);
    }, [location, handleQueryChanged]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (data === null || loading) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading posts...
            </div>
        );
    } else if (data.posts.length === 0) {
        content = (
            <div className="mt-2 alert alert-danger rounded-0 mb-0">
                No posts found
            </div>
        );
    } else {
        content = (
            <div className='mt-2'>
                {/* Desktop Title */}
                <div className="d-none d-md-flex title-bar dark px-2">
                    <div className="d-flex w-100">
                        <div className="post-left-side">
                            <span>Author</span>
                        </div>
                        <div className="flex-grow-1">
                            <span>Message</span>
                        </div>
                    </div>
                </div>

                {/* Posts */}
                {data.posts.map((post) => {
                    return <MemberPost post={post} key={post.postId} />
                })}

                {/* Pagination */}
                <div className="pagination-container w-100 mt-2">
                    <Pagination
                        page={Number(query.data.page)}
                        count={data.totalPages}
                        onChange={handlePageChanged}
                        showFirstButton
                        showLastButton
                        variant='outlined'
                        shape='rounded'
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="w-100 px-2">
            <div className='box-shadow'>
                <div className="title-bar py-1 px-2">
                    Forum and Posts - Advanced Search
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    {/* Search stuff */}
                    <div className="vert-row row">
                        {/* Category */}
                        <div className="vert-col col-12 col-md-6">
                            <Select
                                id='advanced-search-select'
                                label='Select category'
                                className={['w-100', 'mt-2']}
                                value={query.data.category && query.data.category.trim() !== '' ? query.data.category : '00000000-0000-0000-0000-000000000000' }
                                options={[ { value: '00000000-0000-0000-0000-000000000000', label: 'No category' }, ...options ]}
                                grouped
                                onChange={handleForumCategoryChanged}
                            />
                        </div>

                        {/* Account / Character */}
                        <PrivateComponent rules={['isVerified']}>
                            <div className="vert-col col-12 col-md-6 d-flex align-items-center">
                                <Input
                                    id='advanced-search-account'
                                    label='Author'
                                    className='flex-grow-1'
                                    value={author ? author.name : 'No account selected'}
                                    onChange={() => {}}
                                    disabled
                                />
                                <div className="ml-2 d-flex advanced-search-button-padding">
                                    <Button variant='contained' className='select-button dark-red-button text-white rounded-0 mr-2' onClick={() => setAuthor(null)}>
                                        <i className='fas fa-times' />
                                    </Button>
                                    <Button variant="contained" className="select-button dark-red-button text-white rounded-0" onClick={handleSearchCharacterClicked}>
                                        <i className="fas fa-search"></i>
                                    </Button>
                                </div>
                            </div>
                        </PrivateComponent>

                        {/* Text filter */}
                        <div className="vert-col col-12">
                            <Input
                                id='advanced-search-input'
                                label='Search on title or content'
                                value={inputValue}
                                onChange={handleInputValueChanged}
                            />
                        </div>
                    </div>

                    {content}
                </div>
            </div>    

            { displayAuthorModal ? (
                <SearchAuthorModal
                    onClose={() => setDisplayAuthorModal(false)}
                    onAuthorSelected={handleAuthorSelected}
                />
            ) : null}
        </div>
    );
};

export default ForumAdvancedSearch;

import * as React from 'react';
import { Modal } from '@material-ui/core';

import { getDate, getTime } from '../../utilities/date';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

import api from '../../api/api';

interface IViewChangesModalProps {
    categoryId: string,
    topicId: string,
    postId: string,
    onClose: () => void
}

interface IChange {
    changedById: string,
    changedByName: string,
    oldContent: string,
    newContent: string,
    oldCharacterId: string | undefined,
    oldCharacterName: string | undefined,
    newCharacterId: string | undefined,
    newCharacterName: string | undefined,
    changed: number,
    changedByModerator: boolean,
    moderatorNote: string
}

interface IPost {
    id: string,
    content: string,
    authorId: string,
    characterId: string | undefined,
    created: string,
    accountName: string,
    characterName: string | undefined,
    isModerator: boolean,
    changes: IChange[]
}

const ViewChangesModal: React.FunctionComponent<IViewChangesModalProps> = ({ categoryId, topicId, postId, onClose }) => {
    const [data, setData] = React.useState<null | IPost>(null);
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get('forumPostChange/' + categoryId + '/' + topicId + '/' + postId)
        .then((response) => {
            setData(response.data);
        })
        .catch(() => {
            onClose();
        })
    }, [uid, categoryId, topicId, postId, onClose]);
    
    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (!data) {
        content = (
            <div className="alert alert-info mb-0 rounded-0">
                Loading the changes...
            </div>
        );
    } else {
        let posted = new Date(data.created);

        content = (
            <>
                {/* Account name */}
                <span className="font-small">
                    Author
                </span>
                <span>
                    {data.accountName}
                </span>

                {/* Selected character */}
                { data.characterId ? (
                    <>
                        <span className="mt-3 font-small">
                            Character
                        </span>
                        <span>
                            {data.characterName}
                        </span>
                    </>
                ) : null }

                {/* Date posted */}
                <span className='mt-3 font-small'>
                    Created date
                </span>
                <span>
                    {getDate(posted)}, {getTime(posted)}
                </span>

                {/* Content */}
                <span className='mt-3 font-small'>
                    Content
                </span>
                <div className='dangerous-container' dangerouslySetInnerHTML={{ __html: data.content }} />

                {/* Post made by moderator */}
                { data.isModerator ? (
                    <div className="mt-3 alert alert-info rounded-0 mb-0">
                        Post made by moderator
                    </div>
                ) : null }
                
                {/* Horizontal line */}
                <div className="mt-3 bb-xs"></div>

                {/* Display all the changes */}
                { data.changes.map((change, index) => {
                    // Init props
                    let contentChangeContent = null;
                    let characterChangeContent = null;
                    let moderatorNoteContent = null;

                    // Content changed
                    if (change.oldContent !== change.newContent) {
                        contentChangeContent = (
                            <>
                                <span className='font-small'>
                                    Old content
                                </span>
                                <div className='dangerous-container' dangerouslySetInnerHTML={{ __html: change.oldContent }} />
                                <span className='mt-3 font-small'>
                                    New content
                                </span>
                                <div className='dangerous-container' dangerouslySetInnerHTML={{ __html: change.newContent }} />
                            </>
                        );
                    }

                    // Character changed
                    if (change.oldCharacterId || change.newCharacterId) {
                        let marginTop = '';
                        if (contentChangeContent) {
                            marginTop = ' mt-3';
                        }

                        characterChangeContent = (
                            <>
                                <span className={'font-small' + marginTop}>
                                    Old character
                                </span>
                                <span>
                                    {change.oldCharacterId ? change.oldCharacterName : data.accountName}
                                </span>
                                <span className='mt-3 font-small'>
                                    New character
                                </span>
                                <span>
                                    {change.newCharacterId ? change.newCharacterName : data.accountName}
                                </span>
                            </>
                        );
                    }

                    // Moderator note
                    if (change.moderatorNote && change.moderatorNote.trim() !== "") {
                        let marginTop = '';
                        if (contentChangeContent || characterChangeContent) {
                            marginTop = ' mt-3';
                        }

                        moderatorNoteContent = (
                            <div className={'alert alert-info rounded-0 mb-0' + marginTop}>
                                {change.moderatorNote}
                            </div>
                        );
                    }

                    // Changed date time
                    let changed = new Date(change.changed);

                    return (
                        <div key={index} className='alternate-table-entry p-2'>
                            <div className="d-flex flex-column">
                                {contentChangeContent}
                                {characterChangeContent}
                                {moderatorNoteContent}

                                <span className="mt-3 font-small">
                                    Changed date
                                </span>
                                <span>
                                    {getDate(changed)}, {getTime(changed)}
                                </span>

                                <span className="mt-3 font-small">
                                    Changed by
                                </span>
                                <span>
                                    {change.changedByName}
                                </span>
                            </div>
                        </div>
                    )
                }) }
            </>
        );
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby='view-changes'
            aria-describedby='view post changes'
            className='d-flex align-items-center justify-content-center'
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>View post changes</span>
                    <div className="d-flex ml-auto align-items-center justify-content-center">
                        <i className="fas fa-times text-white clickable" onClick={onClose} />
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    {content}
                </div>
            </div>
        </Modal>
    );
};

export default ViewChangesModal;

import { Button } from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../api/api';
import EditParts from './EditParts';

interface IParams {
    id?: string;
    versionId?: string;
}

export interface IVersionGuide {
    name: string;
    description: string;
    versionNumber: string;
    isValidationRequired: boolean;
    isValidationRequestDenied: boolean;
    validationDenyReason: string;
    hasBeenValidated: boolean;
    parts: IEditPart[]
};

export interface IEditPart {
    id: string;
    title: string;
    content: string;
    sortOrder: number;
    parts: IEditPart[];
}

const ManageGuideVersionEdit: React.FunctionComponent = () => {
    const [data, setData] = React.useState<IVersionGuide | null>(null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<number | null>(null);

    const history = useHistory();
    const { id, versionId } = useParams<IParams>();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (!id) {
            history.push('/download');
            return;
        }

        if (versionId === undefined || versionId === '') {
            api.get('installationGuideVersion/manage/' + id + '/info')
            .then((response) => {
                setData({
                    name: response.data.name,
                    description: response.data.description,
                    versionNumber: 'Working version',
                    isValidationRequired: false,
                    isValidationRequestDenied: false,
                    validationDenyReason: '',
                    hasBeenValidated: false,
                    parts: [{
                        id: '',
                        title: 'Your first part',
                        content: 'The content of your first part',
                        sortOrder: 1,
                        parts: []
                    }]
                });
            });
        } else {
            api.get('installationGuideVersion/manage/' + id + '/' + versionId)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log('[ManageGuideVersionEdit] Failed to download the version', error, error.response);
            });
        }
    }, [id, versionId, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleCancelEditingClicked = React.useCallback(() => {
        setEditing(null);
    }, []);

    const handleEditClicked = React.useCallback((index: number) => {
        if (saving) {
            alert("Cannot edit while saving. Please wait a moment.");
            return;
        }

        setEditing(index);
    }, [saving]);

    const handleSaveClicked = () => {
        if (data === null || (data && data.parts.length === 0)) {
            alert("Cannot save an empty version. Please add some parts.");
            return;
        }

        setSaving(true);
        if (versionId === '' || versionId === undefined) {
            api.post('installationGuideVersion/' + id, data.parts)
            .then((response) => {
                history.push('/download/manage/' + id + '/versions/' + response.data + "/display");
            })
            .catch((error) => {
                alert("Saving failed!");
                console.log('[ManageGuideVersionEdit] Failed to post the version.', error, error.response);
                setSaving(false);
            });
        } else {
            api.put('installationGuideVersion/' + id + '/' + versionId, data.parts)
            .then(() => {
                history.push('/download/manage/' + id + '/versions/' + versionId + "/display");
            })
            .catch((error) => {
                alert("Saving failed!");
                console.log('[ManageGuideVersionEdit] Failed to put the version', error, error.response);
                setSaving(false);
            });
        }
    }

    const handleCancelClicked = React.useCallback(() => {
        history.push('/download/manage/' + id + '/versions');
    }, [id, history]);

    const handleBackClicked = React.useCallback(() => {
        // @ts-ignore
        history.goBack();
    }, [history]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (data === null) {
        content = (
            <div className="w-100 px-2">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="alert alert-info rounded-0 mb-0">
                            Loading data...
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        content = (
            <EditParts
                data={data}
                setData={setData}
                editing={editing}
                onCancelClicked={handleCancelEditingClicked}
                onEditClicked={handleEditClicked}
            />
        );
    }

    return (
        <React.Fragment>
            <div className="w-100 px-2 mb-2">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button onClick={handleBackClicked} variant='contained' className='w-100 dark-red-button text-normal rounded-0'>
                            <i className="fas fa-step-backward mr-2"></i>
                            <span>Go back</span>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="w-100 px-2 mb-2">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <span>Installation guide Information</span>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <span className='font-weight-bold'>Main part</span>
                        <span>The main part is the box (like this one) with a title in a red box and some content. Inside each box  are sub parts</span>
                        <span className='font-weight-bold'>Sub part</span>
                        <span>Sub parts are similar to main parts. They reside inside main parts, underneath the content, and, instead of a red header, they have a darker one.</span>
                        <span className='font-weight-bold'>Actions</span>
                        <span>It is possible to create new parts, sub parts inside parts, edit the titles, the contents and re-arrange where you want each item. Do note, however, when you are editing anything, you cannot use the create actions, delete actions or the re-arrange actions.</span>
                        <div className="mt-2 row">
                            <div className="col-12 col-md-6 col-lg-4 d-flex flex-column">
                                <span className='font-weight-bold'>Main version actions:</span>
                                <Button variant='contained' className='mt-2 dark-red-button text-normal rounded-0 w-100' onClick={handleCancelClicked}>
                                    Cancel
                                </Button>
                                <Button disabled={editing !== null} variant='contained' className='mt-2 dark-red-button text-normal rounded-0 w-100' onClick={handleSaveClicked}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {content}
        </React.Fragment>
    );
};

export default ManageGuideVersionEdit;

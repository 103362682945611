import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import { baseApiUrl } from '../../api/api';
import { getDate, getTime } from '../../utilities/date';

import { ForumPostProps } from './ForumTopic';
import ViewChangesModal from './ViewChangesModal';

interface Props {
    post: ForumPostProps;
    allowedToReply: boolean;
    allowedToModerate: boolean;
}

interface ParamProps {
    id: string;
    topicId: string;
}

const Post: React.FC<Props> = ({ post, allowedToReply, allowedToModerate }) => {
    const [display, setDisplay] = useState<boolean>(false);
    const history = useHistory();
    const params = useParams<ParamProps>();

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleViewChangesClicked = () => {
        setDisplay(true);
    }

    const handleEditClicked = () => {
        history.push('/forum/category/' + params.id + '/topic/' + params.topicId + '/post/' + post.id + '/edit'); 
    }

    const handleQuoteClicked = () => {
        alert('Quoting a post has not been implemented yet.');
    }

    const handleReportClicked = () => {
        alert('Reporting a post has not been implemented yet.');
    }

    const handleDeleteClicked = () => {
        alert('Deleting a post has not been implemented yet.');
    }

    /***************************************************
     * Render
     ***************************************************/
    let posted = new Date(post.created);

    let picture = null;
    if (!post.profilePicture || post.profilePicture === "") {
        picture = (
            <i className="fas fa-user fa-2x"></i>
        );
    } else {
        picture = (
            <img src={baseApiUrl + "attachments/profile/" + post.profilePicture} alt="avatar" className="img-fluid" />
        )
    }

    let editPaddingMobile = '';
    let editPaddingDesktop = '';
    if (allowedToReply || allowedToModerate) {
        editPaddingMobile = 'mr-2';
        editPaddingDesktop = 'mr-1';
    }

    let changed = null;
    if (post.changedOn > 0) {
        changed = new Date(post.changedOn);
    }

    let allowedToEdit = false;
    if (post.allowedToEdit && !post.changedByModerator) {
        allowedToEdit = true;
    }

    return (
        <div className="alternate-table-entry p-2">
            { display ? <ViewChangesModal categoryId={params.id} topicId={params.topicId} postId={post.id} onClose={() => setDisplay(false)} /> : null }

            {/* Mobile */}
            <div className="d-flex flex-column d-md-none w-100">
                <div className="d-flex flex-column bb-xs">
                    <div className='d-flex align-items-center'>
                        <div className="mobile-image-post-avatar">
                            {picture}
                        </div>
                        <div className="flex-grow-1 p-2 d-flex flex-column">
                            <span><strong>{post.author}</strong></span>
                            <span>{getDate(posted)}, {getTime(posted)}</span>
                        </div>
                    </div>
                    {changed !== null ? (
                        <span className='font-small pt-1 pb-2'>
                            Edited on <strong>{getDate(changed)}, {getTime(changed)}</strong> by <strong>{post.changedBy}</strong>
                        </span>
                    ) : null}
                </div>

                {/* Message */}
                <div className="pt-2 dangerous-container" dangerouslySetInnerHTML={{ __html: post.content }} />

                {/* Moderator note */}
                { post.moderatorNote && post.moderatorNote.trim() !== "" ? (
                    <div className="py-2">
                        <div className="alert alert-danger rounded-0 mb-0">
                            {post.moderatorNote}
                        </div>
                    </div>
                ) : null}

                {/* Buttons */}
                <div className="d-flex">
                    { allowedToModerate && post.hasBeenChanged ? (
                        <Button
                            variant='contained'
                            className={'action-button dark-red-button rounded-0 mr-2 ' + editPaddingMobile}
                            onClick={handleViewChangesClicked}
                        >
                            <i className='fas fa-history' />
                        </Button>
                    ) : null}
                    { allowedToEdit || allowedToModerate ? (
                        <React.Fragment>
                            <Button 
                                variant='contained' 
                                className={'action-button dark-red-button rounded-0 mr-2 ' + editPaddingMobile}
                                onClick={handleEditClicked}
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </Button>
                        </React.Fragment>
                    ) : null }
                    { allowedToReply ? (
                        <React.Fragment>
                            <Button 
                                variant="contained" 
                                className="action-button dark-red-button rounded-0"
                                onClick={handleQuoteClicked}
                            >
                                <i className="fas fa-quote-right"></i>
                            </Button>
                            <Button 
                                variant="contained" 
                                className="action-button dark-red-button rounded-0 ml-2"
                                onClick={handleReportClicked}
                            >
                                <i className="fas fa-flag"></i>
                            </Button>
                        </React.Fragment>
                    ) : null }
                    { allowedToModerate ? (
                        <Button 
                            variant="contained" 
                            className="action-button dark-red-button rounded-0 ml-2"
                            onClick={handleDeleteClicked}
                        >
                            <i className="fas fa-trash"></i>
                        </Button>
                    ) : null }
                </div>
            </div>

            {/* Desktop */}
            <div className="d-none d-md-block w-100">
                {/* Message header */}
                <div className="d-flex py-1">
                    {/* Author name */}
                    <div className="post-left-side">
                        <strong>{post.author}</strong>
                    </div>

                    {/* Subject &  */}
                    <div className="d-flex flex-grow-1">
                        <span><strong>Posted:</strong> {getDate(posted)}, {getTime(posted)}</span>
                        {changed !== null ? (
                            <span className='ml-auto'>Edited on <strong>{getDate(changed)}, {getTime(changed)}</strong> by <strong>{post.changedBy}</strong></span>
                        ) : null}
                    </div>
                </div>

                {/* Actual message */}
                <div className="d-flex py-1">
                    {/* Profile */}
                    <div className="post-left-side">
                        {picture}
                    </div>

                    {/* Message */}
                    <div className="flex-grow-1 dangerous-container" dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>

                {/* Moderator note if necessary */}
                {post.moderatorNote && post.moderatorNote.trim() !== "" ? (
                    <div className='d-flex py-1'>
                        <div className="post-left-side"></div>
                        <div className="flex-grow-1">
                            <div className='alert alert-danger rounded-0 mb-0'>
                                {post.moderatorNote}
                            </div>
                        </div>
                    </div>
                ) : null}

                {/* Bottom */}
                <div className="d-flex py-1">
                    <div className="post-left-side"></div>
                    <div className="d-flex justify-content-end flex-grow-1">
                        { allowedToModerate && post.hasBeenChanged ? (
                            <Button
                                size='small'
                                className={'text-muted text-normal ' + editPaddingDesktop}
                                onClick={handleViewChangesClicked}
                            >
                                <u>Changes</u>
                            </Button>
                        ) : null}
                        { allowedToEdit || allowedToModerate ? (
                            <React.Fragment>
                                <Button 
                                    size='small' 
                                    className={'text-muted text-normal ' + editPaddingDesktop}
                                    onClick={handleEditClicked}
                                >
                                    <u>Edit</u>
                                </Button>
                            </React.Fragment>
                        ) : null }
                        { allowedToReply ? (
                            <React.Fragment>
                                <Button size="small" className="text-muted text-normal" onClick={handleQuoteClicked}>
                                    <u>Quote</u>
                                </Button>         
                                <Button size="small" className="text-muted text-normal ml-1" onClick={handleReportClicked}>
                                    <u>Report</u>
                                </Button>
                            </React.Fragment>
                        ) : null }
                        { allowedToModerate ? (
                            <Button size="small" className="text-muted text-normal ml-1" onClick={handleDeleteClicked}>
                                <u>Delete</u>
                            </Button>  
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Post;
import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const AdminDashboard: React.FC<{}> = () => {
    const history = useHistory();

    const handleForumsClicked = useCallback(() => {
        history.push("/admin/forums");
    }, [history]);

    const handleUsersClicked = useCallback(() => {
        history.push("/admin/users");
    }, [history]);

    const handleAnnouncementsClicked = useCallback(() => {
        history.push("/admin/announcements");
    }, [history]);

    return (
        <div id="admin" className="w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    Admin Home
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="vert-row row">
                        <div className="vert-col col-12 col-lg-6">
                            <Button variant="contained" className="d-flex justify-content-between dark-red-button admin-button rounded-0 w-100" onClick={handleForumsClicked}>
                                <i className="admin-button-icon mr-3 fas fa-project-diagram"></i>
                                <span className="text-normal">Manage the forums, categories and threads</span>
                            </Button>
                        </div>
                        <div className="vert-col col-12 col-lg-6">
                            <Button variant="contained" className="d-flex justify-content-between dark-red-button admin-button rounded-0 w-100" onClick={handleUsersClicked}>
                                <i className="admin-button-icon mr-3 fas fa-users"></i>
                                <span className="text-normal">Manage the forum users and groups</span>
                            </Button>
                        </div>
                        <div className="vert-col col-12 col-lg-6">
                            <Button variant="contained" className="d-flex justify-content-between dark-red-button admin-button rounded-0 w-100" onClick={handleAnnouncementsClicked}>
                                <i className="admin-button-icon mr-3 fas fa-bullhorn"></i>
                                <span className="text-normal">Manage the announcements</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
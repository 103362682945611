import { Button } from '@material-ui/core';
import * as React from 'react';
import { IMoveRequest } from '../../../containers/Moderator/ModeratorMoveRequests';
import { getDate, getTime } from '../../../utilities/date';

interface IForumTopicMoveRequestProps {
    moveRequest: IMoveRequest;
    onInfoClicked: (moveRequest: IMoveRequest) => void;
    onInfoMobileClicked: (moveRequest: IMoveRequest) => void;
    onAcceptClicked: (moveRequest: IMoveRequest) => void;
    onDenyClicked: (moveRequest: IMoveRequest) => void;
}

const ForumTopicMoveRequest: React.FunctionComponent<IForumTopicMoveRequestProps> = ({
    moveRequest,
    onInfoClicked,
    onInfoMobileClicked,
    onAcceptClicked,
    onDenyClicked
}) => {
    const [display, setDisplay] = React.useState(false);

    /***************************************************
     * Event handlers
     ***************************************************/
    const toggleDisplay = React.useCallback(() => {
        setDisplay((prev) => !prev);
    }, []);

    const handleInfoClicked = () => {
        onInfoClicked(moveRequest);
    }

    const handleMobileInfoClicked = () => {
        onInfoMobileClicked(moveRequest);
    }

    const handleAcceptClicked = () => {
        onAcceptClicked(moveRequest);
    }

    const handleDenyClicked = () => {
        onDenyClicked(moveRequest);
    }

    /***************************************************
     * Render
     ***************************************************/
    let requestTime = new Date(moveRequest.requestedTime);
    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                <div className="d-flex align-items-center">
                    <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={toggleDisplay}>
                        { display ? <i className='fas fa-chevron-down' />: <i className='fas fa-chevron-right' /> }
                    </Button>
                    <div className="ml-2 flex-grow-1">
                        <span className='text-oveflow'>
                            {moveRequest.forumName}{' > '}{moveRequest.categoryName}{' > '}{moveRequest.title}
                        </span>
                    </div>
                </div>

                {!display ? null : (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Topic title
                        </span>
                        <span className='text-overflow'>{moveRequest.title}</span>
                        <span className='mt-2 font-small'>
                            Current forum
                        </span>
                        <span>{moveRequest.forumName}</span>
                        <span className="mt-2 font-small">
                            Current category
                        </span>
                        <span>{moveRequest.categoryName}</span>
                        <span className="mt-2 font-small">
                            Suggested forum
                        </span>
                        <span>{moveRequest.suggestedForumName}</span>
                        <span className="mt-2 font-small">
                            Suggested category
                        </span>
                        <span>{moveRequest.suggestedCategoryName}</span>
                        <span className="mt-2 font-small">
                            Request time
                        </span>
                        <span>
                            {getDate(requestTime)}; {getTime(requestTime)}
                        </span>
                        <span className="mt-2 font-small">
                            Requested by
                        </span>
                        <span>{moveRequest.requestedBy}</span>
                        <span className='mt-2 font-small'>
                            Reason
                        </span>
                        <span>{moveRequest.reason}</span>
                        <span className="mt-2 font-small">
                            Actions
                        </span>
                        <div className="mt-2 d-flex">
                            <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={handleMobileInfoClicked}>
                                <i className='fas fa-info' />
                            </Button>
                            <Button variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleAcceptClicked}>
                                <i className='fas fa-check' />
                            </Button>
                            <Button variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleDenyClicked}>
                                <i className='fas fa-times' />
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-4">
                    <span className='text-overflow'>
                        {moveRequest.forumName}{' > '}{moveRequest.categoryName}{' > '}{moveRequest.title}
                    </span>
                </div>
                <div className="d-flex align-items-center col-4">
                    <span className="text-oveflow">
                        {moveRequest.suggestedForumName}{' > '}{moveRequest.suggestedCategoryName}{' > '}{moveRequest.title}
                    </span>
                </div>
                <div className="d-flex flex-column justify-content-center py-1 col-2">
                    <span>{getDate(requestTime)}; {getTime(requestTime)}</span>
                    <span>{moveRequest.requestedBy}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={handleInfoClicked}>
                        <i className='fas fa-info' />
                    </Button>
                    <Button variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleAcceptClicked}>
                        <i className='fas fa-check' />
                    </Button>
                    <Button variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleDenyClicked}>
                        <i className='fas fa-times' />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ForumTopicMoveRequest;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthReducerProps, UserProps } from '../../store/reducers/authReducer';

const PrivateRoute: React.FC<any> = ({ component: Component, roles, ...rest }) => {
    const user: UserProps | null = useSelector((state: AuthReducerProps) => state.me);
    return (
        <Route {...rest} render={props => {
            // Check if logged on
            if (!user) {
                return <Redirect to="/home/login" />;
            }

            // Check if route is restricted by role
            if (roles && roles.indexOf(user.roleId) === -1) {
                return <Redirect to="/" />;
            }

            // Authorized
            return <Component {...props} />;
        }} />
    );
};

export default PrivateRoute;
import React from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';

import VerifyEmail from './VerifyEmail';
import HomeLogin from './HomeLogin';
import RecoverPassword from './RecoverPassword';
import ResetPassword from './ResetPassword';
import Announcements from '../../components/Home/Announcements';

interface Props {

};

const Home: React.FC<Props> = () => {
    const location = useLocation();

    return (
        <React.Fragment>
            <Switch>
                <Route path="/home/verifyEmail">
                    <VerifyEmail pathname={location.pathname} />
                </Route>
                <Route path="/home/login">
                    <HomeLogin />
                </Route>
                <Route path="/home/recoverPassword">
                    <RecoverPassword />
                </Route>
                <Route path="/home/resetPassword">
                    <ResetPassword />
                </Route>
            </Switch>

            {/* Main welcome */}
            <div className="w-100 px-2 pb-1">
                <div className="box-shadow">
                    <div className="title-bar px-2" />
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <h5 className="text-center">Welcome to Oberin!</h5>
                        <span className="text-center">
                            Oberin is an online fantasy role-playing game and virtual community, available exclusively for Macintosh. Oberin takes place in a persistent, ever evolving world -- your character may come and go, but the world continuously runs.
                            <br />
                            While there are many separate quests that can be completed in Oberin, there is no ultimate goal when playing. The journey is the reward. It's an online fantasy world where the fun comes from interacting with other real players. You can join a group of fellow players to hunt for dragons, spend a quiet day fishing, meet a friend at the pub for a game of chess, or spend some time refining your chosen skills.
                        </span>
                    </div>
                </div>
            </div>

            {/* Announcements */}
            <Announcements />
        </React.Fragment>
    );
};

export default Home;
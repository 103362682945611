import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '../store/actions/index';
import api from '../api/api';

const Auth: React.FC<{}> = ({ children }) => {
    const [authenticating, setAuthenticating] = useState<boolean>(true);
    
    const dispatch = useDispatch();

    useEffect(() => {
        let token = localStorage.getItem("OBERIN_REFRESH_TOKEN");
        if (!token || token.trim() === "") {
            actions.stopRefreshTokenTimer();
            setAuthenticating(false);
            return;
        }

        api.post('account/refreshToken', { token })
        .then((response) => {
            localStorage.setItem("OBERIN_REFRESH_TOKEN", response.data.refreshToken);
            sessionStorage.setItem("OBERIN_BEARER_TOKEN", response.data.jwtToken);
            dispatch(actions.authUpdateUser(response.data));
            dispatch(actions.startRefreshTokenTimer());
        })
        .catch(() => {
            actions.stopRefreshTokenTimer();
        })
        .finally(() => {
            setAuthenticating(false);
        });
    }, [dispatch]);

    if (authenticating) {
        return null;
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default Auth;
import { Button } from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../api/api';
import InstallationGuideUser from './InstallationGuideUser';
import UserModal from './UserModal';

interface IParams {
    id?: string;
}

export interface IUser {
    id: string;
    name: string;
}

const InstallationGuideUsers: React.FunctionComponent = () => {
    const [users, setUsers] = React.useState<IUser[] | null>(null);
    const [display, setDisplay] = React.useState<boolean>(false);

    const { id } = useParams<IParams>();
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (id) {
            api.get<IUser[]>('installationGuideUser/admin/' + id)
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                console.log('Error fetching the installation guide users', error, error.reponse);
            });
        } else {
            history.push('/admin/installationGuides');
        }
    }, [id, history]);
    
    /***************************************************
     * Event handlers
     ***************************************************/
    const handleRemoveUserClicked = React.useCallback((userId: string) => {
        api.delete('installationGuideUser/admin/' + id + '/' + userId);
        setUsers((current) => {
            return current!.filter((c) => c.id !== userId);
        });
    }, [id]);

    const handleAddUserClicked = React.useCallback(() => {
        setDisplay(true);
    }, []);

    const handleUserAdded = React.useCallback((user: IUser) => {
        setDisplay(false);
        api.post('installationGuideUser/admin/' + id, user)
        .catch(() => {
            alert('Failed to add user with name ' + user.name);
        });
        
        setUsers((curr) => {
            let updated = [ ...curr! ];
            updated.push(user);
            return updated;
        });
    }, [id]);

    const handleBackClicked = React.useCallback(() => {
        history.push('/admin/installationGuides');
    }, []);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (users === null) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                <span>Loading the users...</span>
            </div>
        );
    } else if (users.length === 0) {
        content = (
            <div className='mt-2 alert alert-warning rounded-0 mb-0'>
                <span>No users found.</span>
            </div>
        );
    } else {
        content = users.map((user) => {
            return (
                <InstallationGuideUser
                    key={user.id}
                    user={user}
                    onRemoveClicked={handleRemoveUserClicked}
                />
            )
        });
    }

    let modal = null;
    if (display) {
        modal = (
            <UserModal
                users={users}
                onUserAdded={handleUserAdded}
                onClose={() => setDisplay(false)}
            />
        )
    }

    return (
        <div className='w-100'>
            <div className="row mb-2">
                <div className="col-12 col-md-6 col-lg-4">
                    <Button onClick={handleBackClicked} variant='contained' className='w-100 dark-red-button text-normal rounded-0'>
                        <i className="fas fa-step-backward mr-2"></i>
                        <span>Go back</span>
                    </Button>
                </div>
            </div>
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Manage the installation guide users</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <Button variant='contained' className='dark-red-button rounded-0 text-normal w-100' onClick={handleAddUserClicked}>
                                <i className='fas fa-plus mr-2' />
                                <span>Add user</span>
                            </Button>
                        </div>
                    </div>
                    <div className="mt-2 title-bar px-2 dark">
                        <div className="row w-100">
                            <div className="col-8">Name</div>
                            <div className="d-flex justify-content-end col-4">Actions</div>
                        </div>
                    </div>
                    {content}
                    {modal}
                </div>
            </div>
        </div>
    );
};

export default InstallationGuideUsers;

import React, { useCallback, useEffect, useState } from 'react';

import SaveButton, { saveStatuses, saveTypes } from '../../components/MUI/SaveButton';
import Select from '../../components/MUI/Select';
import api from '../../api/api';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

interface SettingsProps {
    forumPlayerArtCategoryId: string | null,
    forumPlayerStoryCategoryId: string | null,
    forumRtqCategoryId: string | null
}

interface ForumCategoryProps {
    value: string,
    label: string,
    forum: string
}

const AdminSettings: React.FC<{}> = () => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [settings, setSettings] = useState<SettingsProps | null>(null);
    const [forumCategories, setForumCategories] = useState<ForumCategoryProps[]>([]);

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    useEffect(() => {
        api.get('settings/admin')
        .then((response) => {
            setSettings(response.data);
        })
        .catch((error) => {
            console.log("[AdminSettings] Failed to fetch the settings.");
            console.log("error", error);
            console.log("error.response", error.response);
        });

        api.get('settings/admin/categories')
        .then((response) => {
            setForumCategories(response.data);
        })
        .catch((error) => {
            console.log("[AdminSettings] Failed to fetch the categories.");
            console.log("error", error);
            console.log("error.response", error.response);
        });
    }, [uid]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            setStatus(saveStatuses.SAVING);

            api.put('settings/admin', {
                forumPlayerArtCategoryId: settings?.forumPlayerArtCategoryId,
                forumPlayerStoryCategoryId: settings?.forumPlayerStoryCategoryId,
                forumRtqCategoryId: settings?.forumRtqCategoryId
            })
            .then(() => {
                setStatus(saveStatuses.SAVED);
            })
            .catch(() => {
                setStatus(saveStatuses.ERROR);
            });
        }
    }, [uid, status, settings]);

    const handleRTQCategoryChanged = useCallback((event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        if (settings) {
            setSettings({
                ...settings,
                forumRtqCategoryId: value
            });
        }
    }, [settings]);

    const handlePlayerArtCategoryChanged = useCallback((event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        if (settings) {
            setSettings({
                ...settings,
                forumPlayerArtCategoryId: value
            });
        }
    }, [settings]);

    const handlePlayerStoryCategoryChanged = useCallback((event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        if (settings) {
            setSettings({
                ...settings,
                forumPlayerStoryCategoryId: value
            });
        }
    }, [settings]);

    let content = null;

    if (settings === null) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading settings...
            </div>
        );
    } else {
        let selectRtqCategory = (
            <Select
                id="select-rtq-category"
                label="RTQ Forum Category"
                className={["w-100", "mt-2"]}
                value={settings.forumRtqCategoryId}
                onChange={handleRTQCategoryChanged}
                options={forumCategories}
                grouped
            />
        );

        let selectArtCategory = (
            <Select
                id="select-story-category"
                label="Player Art Forum Category"
                className={["w-100", "mt-2"]}
                value={settings.forumPlayerArtCategoryId}
                onChange={handlePlayerArtCategoryChanged}
                options={forumCategories}
                grouped
            />
        );

        let selectStoryCategory = (
            <Select
                id="select-art-category"
                label="Player Story Forum Category"
                className={["w-100", "mt-2"]}
                value={settings.forumPlayerStoryCategoryId}
                onChange={handlePlayerStoryCategoryChanged}
                options={forumCategories}
                grouped
            />
        );

        content = (
            <React.Fragment>
                <div className="row w-100">
                    <div className="d-flex flex-column col-12 col-md-6">
                        <div className="d-block w-100 ml-2 mb-2">
                            {selectRtqCategory}
                        </div>
                        <div className="d-block w-100 ml-2 mb-2">
                            {selectStoryCategory}
                        </div>
                        <div className="d-block w-100 ml-2 mb-2">
                            {selectArtCategory}
                        </div>
                        <div className="d-block w-100 ml-2 mt-2 mb-2">
                            <SaveButton
                                type={saveTypes.CONTROLLED}
                                status={status}
                                setStatus={setStatus}
                                showFeedback
                                hideCancel
                                saveClasses={["dark-red-button"]}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div id="admin" className="w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Manage the settings</span>
                </div>
            </div>
            <div className="d-flex align-items-center bg-light-gray p-2">
                {content}
            </div>
        </div>
    )
};

export default AdminSettings;
import * as React from 'react';
import { IMoveRequest } from '../../../containers/Moderator/ModeratorMoveRequests';

import api from '../../../api/api';
import { Button, Modal } from '@material-ui/core';
import { getDate, getTime } from '../../../utilities/date';
import Input from '../../MUI/Input';
import Select from '../../MUI/Select';

interface IMoveTopicAcceptModalProps {
    moveRequest: IMoveRequest;
    onClose: () => void;
    onRequestAccepted: () => void;
}

interface OptionProps {
    value: string;
    label: string;
    groupBy: string;
}

const MoveTopicAcceptModal: React.FunctionComponent<IMoveTopicAcceptModalProps> = ({
    moveRequest,
    onClose,
    onRequestAccepted
}) => {
    const [moveReason, setMoveReason] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    const [saving, setSaving] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<OptionProps[]>([]);
    const [category, setCategory] = React.useState<string>(moveRequest.suggestedCategoryId);

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get('forumCategory/move/options')
        .then((response) => {
            setOptions(response.data);
        })
        .catch(() => {
            setError("Failed to fetch the forum categories.");
        });
    }, []);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleAcceptClicked = () => {
        // Verify if set
        if (moveReason.trim() === "") {
            setError("Please enter a move reason.");
            return;
        }

        // Verify length
        if (moveReason.length > 45) {
            setError("Max length is 45 characters.");
            return;
        }

        // Verify category
        if (category === null || category.trim() === "") {
            setError("Please select a category.");
            return;
        }

        // Re-init
        setError("");
        setSaving(true);

        api.post('forumTopicMoveRequest/moderator/accept', {
            id: moveRequest.id,
            reason: moveReason,
            categoryId: category
        })
        .then(() => {
            onRequestAccepted();
        })
        .catch(() => {
            setError("Failed to accept the request on the server.");
            setSaving(false);
        });
    }

    const handleCategoryChanged = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        let option = options.find(o => o.value === value);
        if (option) {
            setCategory(option.value);
        }
    }

    /***************************************************
     * Render
     ***************************************************/
    let requestedTime = new Date(moveRequest.requestedTime);
    return (
        <Modal
            open
            aria-label='Accept move request modal'
            onClose={onClose}
            className='d-flex align-items-center justify-content-center'
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>Accept move request</span>
                    <div className='d-flex ml-auto align-items-center justify-content-center'>
                        <i className='fas fa-times text-white clickable' onClick={onClose} />
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                <span className="font-small">
                        Topic
                    </span>
                    <span>
                        {moveRequest.title}
                    </span>
                    <span className='mt-2 font-small'>
                        Current
                    </span>
                    <span>
                        {moveRequest.forumName}{' > '}{moveRequest.categoryName}
                    </span>
                    <span className="mt-2 font-small">
                        Suggested
                    </span>
                    <span>
                        {moveRequest.suggestedForumName}{' > '}{moveRequest.suggestedCategoryName}
                    </span>
                    <span className="mt-2 font-small">
                        Requested by
                    </span>
                    <span>
                        {moveRequest.requestedBy}
                    </span>
                    <span className='mt-2 font-small'>
                        Requested time
                    </span>
                    <span>
                        {getDate(requestedTime)}; {getTime(requestedTime)}
                    </span>
                    <span className='mt-2 font-small'>
                        Reason
                    </span>
                    <span>
                        {moveRequest.reason}
                    </span>
                    <Select 
                        id="select-forum-category"
                        label="Select a category"
                        className={["mt-2"]}
                        value={category}
                        options={options.filter(o => o.value !== moveRequest.categoryId)}
                        grouped={true}
                        onChange={handleCategoryChanged}
                    />
                    <Input
                        id='deny-reason'
                        label='Reason to move'
                        className='mt-3'
                        value={moveReason}
                        required
                        onChange={(event) => setMoveReason(event.target.value)}
                    />
                    { error === "" ? null : (
                        <div className="alert alert-danger mt-2 mb-0 rounded-0">
                            <span>{error}</span>
                        </div>
                    )}
                    <Button 
                        variant='contained' 
                        className='mt-2 w-100 dark-red-button text-white text-normal rounded-0' 
                        disabled={saving}
                        onClick={handleAcceptClicked}
                    >
                        Accept
                    </Button>
                </div>
            </div>
        </Modal>
    )
};

export default MoveTopicAcceptModal;

import React, { useState, useEffect, useCallback } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import { Modal } from '@material-ui/core';
import api from '../../api/api';

import SaveButton, { saveStatuses, saveTypes } from '../../components/MUI/SaveButton';
import Input from '../../components/MUI/Input';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

interface Props {
    pathname: string
};

interface ErrorProps {
    status: boolean,
    message: string
}

const VerifyEmail: React.FC<Props> = ({ pathname }) => {
    const [token, setToken] = useState<string>("");
    const [initialised, setInitialised] = useState<boolean>(false);
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [error, setError] = useState<ErrorProps>({ status: false, message: "" });
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const history = useHistory();

    const cancelVerification = useCallback(() => {
        history.push("/home");
    }, [history]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    useEffect(() => {
        if (!initialised) {
            setInitialised(true);

            const match = matchPath<{token: string}>(pathname, { path: "/home/verifyEmail/:token" });
            if (match?.params?.token) {
                setToken(match.params.token);
            }
        }
    }, [initialised, pathname]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Verify the token
            if (token.trim() === "") {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Post the request
            setError({ status: false, message: "" });
            api.post('/account/verifyEmail', { token })
            .then(() => {
                history.push("/home/login");
            })
            .catch((error) => {
                setStatus(saveStatuses.ERROR);
                if (error?.response?.data?.message) {
                    setError({ status: true, message: error.response.data.message });
                } else {
                    setError({ status: true, message: error.response.data.message });
                }
            });
        }
    }, [uid, token, status, history]);

    return (
        <Modal 
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? cancelVerification: () => {}}
            aria-labelledby="login-popup" 
            aria-describedby="log into your account" 
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>Verify your email address</span>
                </div>
                <div className="d-flex flex-column p-2">
                    <Input
                        id="veriy-email-token"
                        label="Token"
                        className="mt-2"
                        value={token} 
                        error={error.status && token.trim() === ""}
                        helperText="Please fill in a token"
                        disabled={status !== saveStatuses.NEUTRAL}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setToken(event.target.value); }} 
                        onKeyDown={handleKeyDown}
                    />

                    { error.status && error.message.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.message}
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                            saveContent="Verify"
                            savingContent={(<><i className="fas fa-sync fa-spin mr-2" /> Verifying...</>)}
                            errorContent={(<><i className="fas fa-times mr-2" /> Error</>)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default VerifyEmail;
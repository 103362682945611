import { Modal } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Input from '../../MUI/Input';
import SaveButton, { saveStatuses, saveTypes } from '../../MUI/SaveButton';
import { ForumProps } from './Forums';

import api from '../../../api/api';

interface Props {
    forum: ForumProps;
    maximum: number;
    closePopup: () => void;
    setForums: React.Dispatch<React.SetStateAction<ForumProps[]>>;
};

interface ErrorProps {
    status: boolean;
    message: string;
}

const ForumModal: React.FC<Props> = ({ forum, maximum, closePopup, setForums }) => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [name, setName] = useState<string>(forum.name);
    const [sortOrder, setSortOrder] = useState<number>(forum.sortOrder);
    const [error, setError] = useState<ErrorProps>({ status: false, message: "" });

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleSortOrderChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSortOrder(Number(event.target.value));
    }

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    const updateForumAsync = useCallback(() => {
        let model = {
            id: forum.id,
            name: name,
            sortOrder: sortOrder
        };

        api.put("forum/admin/" + model.id, model)
        .then((response) => {
            setForums(response.data);
            closePopup();
        })
        .catch((error) => {
            setError({ status: true, message: error.response.data });
            setStatus(saveStatuses.ERROR);
        });
    }, [forum.id, name, sortOrder, setForums, closePopup]);

    const createForumAsync = useCallback(() => {
        let model = {
            id: null,
            name: name,
            sortOrder: sortOrder
        };

        api.post("forum/admin", model)
        .then((response) => {
            setForums(response.data);
            closePopup();
        })
        .catch((error) => {
            setError({ status: true, message: error.response.data });
            setStatus(saveStatuses.ERROR);
        });
    }, [name, sortOrder, setForums, closePopup]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Verify name
            if (name.trim() === "") {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify sort order
            if (isNaN(sortOrder) || sortOrder < 1 || sortOrder > maximum) {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            if (forum.id) {
                updateForumAsync();
            }   
            else {
                createForumAsync();
            }
        }
    }, [status, name, sortOrder, forum.id, maximum, updateForumAsync, createForumAsync]);

    /***************************************************
     * Render
     ***************************************************/
    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby={ forum.id ? "edit-forum-popup" : "create-forum-popup" }
            aria-describedby={ forum.id ? "Edit forum" : "Create a new forum" }
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    { forum.id ? "Edit forum" : "Create forum" }
                </div>
                <div className="d-flex flex-column p-2">
                    {/* Name */}
                    <Input
                        id="forum-name"
                        label="Name"
                        className="mt-2"
                        value={name} 
                        error={error.status && name.trim() === ""}
                        helperText="Please fill in a name"
                        disabled={status !== saveStatuses.NEUTRAL}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setName(event.target.value); }} 
                        onKeyDown={handleKeyDown}
                    />

                    {/* Sort order */}
                    <Input
                        id="forum-sort-order"
                        label="Sort order"
                        className="mt-2"
                        type="number"
                        value={sortOrder} 
                        error={error.status && (sortOrder < 1 || sortOrder > maximum)}
                        helperText={"Sort order must be between 1 and " + maximum}
                        disabled={status !== saveStatuses.NEUTRAL}
                        onChange={handleSortOrderChanged} 
                        onKeyDown={handleKeyDown}
                    />

                    { error.status && error.message.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.message}
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default ForumModal;
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ModeratorMoveRequests from './ModeratorMoveRequests';

const ModeratorRouter: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/moderator/moveRequests" component={ModeratorMoveRequests} />
            <Redirect to="/moderator/moveRequests" />
        </Switch>
    );
};

export default ModeratorRouter;

import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { ForumCategoryTopicProps } from '../../containers/Forum/Category';

import ImgPostHot from '../../assets/post_hot.gif';
import ImgPostLockNew from '../../assets/post_lock_new.gif';
import ImgPostLock from '../../assets/post_lock.gif';
import ImgPostNewHot from '../../assets/post_new_hot.gif';
import ImgPostNew from '../../assets/post_new.gif';
import ImgPostSticky from '../../assets/post_sticky.gif';
import ImgPost from '../../assets/post.gif';
import { getDate, getTime } from '../../utilities/date';

interface Props {
    topic: ForumCategoryTopicProps;
    forumCategoryId: string;
    handleForumTopicClicked: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => void;
};

const CategoryTopicItem: React.FC<Props> = ({ topic, forumCategoryId, handleForumTopicClicked }) => {
    const [display, setDisplay] = useState<boolean>(false);

    /***************************************************
     * Event handlers
     ***************************************************/
    const toggleDisplay = useCallback(() => {
        setDisplay(currentDisplay => !currentDisplay);
    }, []);

    /***************************************************
     * Render
     ***************************************************/
    let link = "/forum/category/" + forumCategoryId + "/topic/" + topic.id;
    let lastPost = null;
    if (display && topic.lastPost) {
        let date = new Date(topic.lastPost.date);
        lastPost = (
            <React.Fragment>
                <span className="mt-2 font-small">
                    Last post
                </span>
                <span>{getDate(date)}, {getTime(date)}</span>
                <span>{topic.lastPost.name}</span>
            </React.Fragment>
        );
    }

    let postImg = null;
    if (topic.isAnnouncement || topic.isSticky) {
        postImg = <img src={ImgPostSticky} alt="sticky post" className="ml-2 img-fluid" />;
    }
    else if (topic.isLocked && topic.isNew) {
        postImg = <img src={ImgPostLockNew} alt="new locked post" className="ml-2 img-fluid" />;
    }
    else if (topic.isLocked) {
        postImg = <img src={ImgPostLock} alt="locked post" className="ml-2 img-fluid" />;
    }
    else if (topic.isNew && topic.replies > 50) {
        postImg = <img src={ImgPostNewHot} alt="new or updated hot post" className="ml-2 img-fluid" />;
    }
    else if (topic.isNew) {
        postImg = <img src={ImgPostNew} alt="new or updated post" className="ml-2 img-fluid" />;
    }
    else if (topic.replies > 50) {
        postImg = <img src={ImgPostHot} alt="hot post" className="ml-2 img-fluid" />;
    }
    else {
        postImg = <img src={ImgPost} alt="post" className="ml-2 img-fluid" />;
    }

    return (
        <div className="alternate-table-entry d-flex flex-column align-items-start px-2">
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                        { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                    </Button>
                    {postImg}
                    <div className="mx-2 flex-grow-1 d-flex justify-content-center">
                        <a className="font-weight-bold forum-category-link text-center" href={link} onClick={(event) => handleForumTopicClicked(event, link)}>{topic.title}</a> 
                    </div>
                </div>

                {/* Detailed information */}
                { display ? (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Title
                        </span>
                        <span>
                            {topic.title}
                        </span>
                        <span className="mt-2 font-small">
                            Replies
                        </span>
                        <span>
                            {topic.replies}
                        </span>
                        <span className="mt-2 font-small">
                            Author
                        </span>
                        <span>
                            {topic.author}
                        </span>
                        <span className="mt-2 font-small">
                            Views
                        </span>
                        <span>
                            {topic.views}
                        </span>
                        {lastPost}
                    </div>
                ) : null }
            </div>
        </div>
    );
};

export default CategoryTopicItem;
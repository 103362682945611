import * as React from 'react';
import { IVersionGuide } from './ManageGuideVersionEdit';

interface IDisplayVersionProps {
    version: IVersionGuide;
}

const DisplayVersion: React.FunctionComponent<IDisplayVersionProps> = ({
    version
}) => {
    return (
        <>
            {/* Title */}
            <div className="w-100 px-2 mb-2">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <span>{version.name}</span>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <span>{version.description}</span>
                    </div>
                </div>
            </div>

            {/* Content */}
            {version.parts.map((part, index) => {
                let classNames = ['w-100', 'px-2'];
                if (index > 0) {
                    classNames.push('mt-2');
                }

                return (
                    <div key={index} className={classNames.join(' ')}>
                        <div className="box-shadow">
                            <div className="title-bar px-2">
                                <span>{part.title}</span>
                            </div>
                            <div className="bg-light-gray pt-2 px-2 bl-xs br-xs bb-xs d-flex flex-column">
                                <div className='dangerous-container' dangerouslySetInnerHTML={{ __html: part.content }} />
                                {part.parts.map((subPart, subPartIndex) => {
                                    let subClassNames = ['alternate-table-entry', 'bg-white', 'px-2'];
                                    if (subPartIndex === part.parts.length - 1) {
                                        subClassNames.push('mb-2');
                                    }

                                    return (
                                        <React.Fragment key={index + '_' + subPartIndex}>
                                            <div className='mt-2 title-bar px-2 dark'>
                                                <span>{subPart.title}</span>
                                            </div>
                                            <div className={subClassNames.join(' ')}>
                                                <div className='dangerous-container' dangerouslySetInnerHTML={{ __html: subPart.content }} />
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
};

export default DisplayVersion;

import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ForumProps } from '../../containers/Forum/ForumOverview';
import { getDate, getTime } from '../../utilities/date';

interface Props {
    data: ForumProps;
    first: boolean;
};

const Forum: React.FC<Props> = ({ data, first }) => {
    const [display, setDisplay] = useState<boolean>(false);
    const [displayedCategories, setDisplayedCategories] = useState<string[]>([]);
    
    const history = useHistory();

    /***************************************************
     * Event handlers
     ***************************************************/
    const toggleDisplay = useCallback(() => {
        if (display) {
            setDisplayedCategories([]);
        }
        setDisplay(!display);
    }, [display]);

    const changeDisplayedCategories = useCallback((id: string) => {
        setDisplayedCategories((currentCategories) => {
            let updatedCategories: string[] = [ ...currentCategories ];

            if (currentCategories.findIndex(c => c === id) === -1) {
                updatedCategories.push(id);
            } else {
                updatedCategories = updatedCategories.filter(c => c !== id);
            }

            return updatedCategories;
        });
    }, []);

    const handleCategoryClicked = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
        event.preventDefault();
        history.push(link);
    }, [history]);

    /***************************************************
     * Render
     ***************************************************/
    return (
        <React.Fragment>
            {/* Mobile */}
            <div className="d-block d-md-none">
                {/* Normal info */}
                <div className={ first ? "title-bar px-2 py-1" : "first-mobile-title title-bar px-2 py-1" }>
                    <div className="d-flex align-items-center">
                        <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                            { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                        </Button>
                        <span className="ml-2 flex-grow-1">
                            {data.name}
                        </span>
                    </div>
                </div>

                {/* Detailed info */}
                { display === false ? null :
                    data.categories.map((category, index) => {
                        let displayCategory = displayedCategories.findIndex(c => c === category.id) !== -1; 
                        let posts = 0;
                        category.posts.forEach((post) => {
                            posts += post;
                        });

                        let link = "/forum/category/" + category.id + "/1";
                        
                        let lastPost = null;
                        if (category.lastPost) {
                            let date = new Date(category.lastPost.date);
                            lastPost = (
                                <React.Fragment>
                                    <span className="pl-2 font-small">
                                        Last post
                                    </span>
                                    <span className="pl-2">{getDate(date)}, {getTime(date)}</span>
                                    <span className="pl-2 mb-2">{category.lastPost.name}</span>
                                </React.Fragment>
                            );
                        }
    
                        return (
                            <div key={index} className="alternate-table-entry d-flex flex-column align-items-start px-2">
                                <div className="d-flex w-100 pt-1 align-items-center">
                                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={() => changeDisplayedCategories(category.id)}>
                                        { displayCategory ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                                    </Button>
                                    <span className="ml-2 flex-grow-1 font-weight-bold">
                                        <a onClick={(event) => handleCategoryClicked(event, link)} className="forum-category-link" href={link}>{category.name}</a>
                                    </span>
                                </div>
                                { displayCategory ? (
                                    <React.Fragment>
                                        <span className="mt-2 pl-2 font-small">
                                            Description
                                        </span>
                                        <span className="pl-2">
                                            {category.description}
                                        </span>
                                        <span className="mt-2 pl-2 font-small">
                                            Topics
                                        </span>
                                        <span className="pl-2">
                                            {category.topics}
                                        </span>
                                        <span className="mt-2 pl-2 font-small">
                                            Posts
                                        </span>
                                        <span className="pl-2 mb-2">
                                            {posts}
                                        </span>
                                        {lastPost}
                                    </React.Fragment>
                                ) : null }
                            </div>
                        );
                    })
                }
            </div>

            {/* Desktop */}
            <div className="d-none d-md-block">
                <div className={ first ? "title-bar px-2" : "first-mobile-title title-bar px-2" }>
                    <span>{data.name}</span>
                </div>
                {data.categories.map((category, index) => {
                    let posts = 0;
                    category.posts.forEach((post) => {
                        posts += post;
                    });

                    let link = "/forum/category/" + category.id + "/1";

                    let lastPost = null;
                    if (category.lastPost) {
                        let date = new Date(category.lastPost.date);

                        lastPost = (
                            <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                <span>{getDate(date)}, {getTime(date)}</span>
                                <span>{category.lastPost.name}</span>
                            </div>
                        );
                    } else {
                        lastPost = <div className="d-flex justify-content-center align-items-center col-2" />;
                    }

                    return (
                        <div key={index} className="alternate-table-entry p-2">
                            <div className="row w-100">
                                <div className="d-flex flex-column col-6">
                                    <a onClick={(event) => handleCategoryClicked(event, link)} 
                                        className="font-weight-bold forum-category-link"
                                        href={link}
                                    >
                                        {category.name}
                                    </a>
                                    <span>{category.description}</span>
                                </div>
                                <div className="d-flex justify-content-center align-items-center col-2">
                                    {category.topics}
                                </div>
                                <div className="d-flex justify-content-center align-items-center col-2">
                                    {posts}
                                </div>
                                {lastPost}
                            </div>
                            
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    );
};

export default Forum;
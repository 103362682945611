import { Button } from '@material-ui/core';
import * as React from 'react';
import { IInstallationGuide } from './ManageGuides';

interface IManageGuideProps {
    guide: IInstallationGuide;
    onEditClicked: (guide: IInstallationGuide) => void;
    onPreviewClicked: (guide: IInstallationGuide) => void;
    onVersionsClicked: (id: string) => void;
}

const ManageGuide: React.FunctionComponent<IManageGuideProps> = ({
    guide,
    onEditClicked,
    onPreviewClicked,
    onVersionsClicked
}) => {
    const [display, setDisplay] = React.useState<boolean>(false);

    /***************************************************
     * Event handlers
     ***************************************************/
    const toggleDisplay = React.useCallback(() => {
        setDisplay((prev) => !prev);
    }, []);

    const handleEditClicked = () => {
        onEditClicked(guide);
    }

    const handlePreviewClicked = () => {
        onPreviewClicked(guide);
    }

    const handleVersionsClicked = () => {
        onVersionsClicked(guide.id);
    }

    /***************************************************
     * Render
     ***************************************************/
    let buttons: JSX.Element[] = [];
    
    // Add default versions button
    buttons.push(
        <Button key='versions' className='action-button dark-red-button rounded-0' onClick={handleVersionsClicked}>
            <i className='fas fa-code-branch' />
        </Button>
    );

    if (guide.liveVersion) {
        buttons.push(
            <Button key='live' className='action-button dark-red-button rounded-0 ml-2' onClick={handlePreviewClicked}>
                <i className='fas fa-tv' />
            </Button>
        );
    }

    if (guide.workingVersion) {
        buttons.push(
            <Button key='edit' className='action-button dark-red-button rounded-0 ml-2' onClick={handleEditClicked}>
                <i className='fas fa-pencil-alt' />
            </Button>
        );
    }

    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={toggleDisplay}>
                        { display ? <i className='fas fa-chevron-down' /> : <i className='fas fa-chevron-right' /> }
                    </Button>
                    <div className="ml-2 flex-grow-1">
                        {guide.name}
                    </div>
                </div>

                {/* Detailed information */}
                { !display ? null : (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Name
                        </span>
                        <span>{guide.name}</span>
                        <span className="mt-2 font-small">
                            Enabled
                        </span>
                        <i className={guide.isEnabled ? 'fas fa-check text-success' : 'fas fa-times text-danger'} />
                        <span className="mt-2 font-small">
                            Live version
                        </span>
                        <span>{guide.liveVersion ? guide.liveVersion : 'N/A'}</span>
                        <span className="mt-2 font-small">
                            Working version
                        </span>
                        <span>{guide.workingVersion ? guide.workingVersion : 'N/A'}</span>
                        <span className="mt-2 font-small">
                            Actions
                        </span>
                        <div className="mt-2 d-flex">
                            {buttons}
                        </div>
                    </div>
                )}
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-4">
                    <span>{guide.name}</span>
                </div>
                <div className="d-flex align-items-center col-2">
                    <i className={guide.isEnabled ? 'fas fa-check text-success' : 'fas fa-times text-danger'} />
                </div>
                <div className="d-flex align-items-center col-2">
                    <span>{guide.liveVersion ? guide.liveVersion : 'N/A'}</span>
                </div>
                <div className="d-flex align-items-center col-2">
                    <span>{guide.workingVersion ? guide.workingVersion : 'N/A'}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    {buttons}
                </div>
            </div>
        </div>
    );
};

export default ManageGuide;

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import api from '../../api/api';
import getQueryData from '../../utilities/query';

import Post from './Post';
import MoveTopicModal from './MoveTopicModal';
import { useSelector } from 'react-redux';
import { AuthReducerProps } from '../../store/reducers/authReducer';

export interface ForumPostProps {
    id: string;
    content: string;
    created: number;
    author: string;
    profilePicture: string;
    allowedToEdit: boolean;
    hasBeenChanged: boolean;
    changedBy: string;
    changedOn: number;
    moderatorNote: string;
    changedByModerator: boolean;
}

interface ForumTopicProps {
    id: string;
    forumCategoryId: string;
    forumId: string;
    forumName: string;
    forumCategoryName: string;
    title: string;
    author: string;
    moveRequestDenyReason: string;
    isSticky: boolean;
    isAnnouncement: boolean;
    isLocked: boolean;
    allowedToReply: boolean;
    allowedToModerate: boolean;
    isMoveRequestDisabled: boolean;
    moveRequestSent: boolean;
    moveRequestDenied: boolean;
    totalPages: number;
    currentPage: number;
    posts: ForumPostProps[];
}

interface ParamProps {
    id: string;
    topicId: string;
    page: string;
}

const ForumTopic: React.FC<{}> = () => {
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [topic, setTopic] = useState<ForumTopicProps | null>(null);
    const [displayMoveTopic, setDisplayMoveTopic] = useState<boolean>(false);
    const [displayRequestMoveTopic, setDisplayRequestMoveTopic] = useState<boolean>(false);
    const [displayReason, setDisplayReason] = useState<boolean>(false);
    
    const history = useHistory();
    const location = useLocation();
    const params = useParams<ParamProps>();
    const query = getQueryData(location.search, [
        { property: "page", defaultValue: "1" }
    ]);

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleRequestMoveTopicClicked = () => {
        setDisplayRequestMoveTopic(true);
    }

    const handleMoveTopicClicked = () => {
        setDisplayMoveTopic(true);
    }

    const goToForum = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push("/forum/overview");
    }

    const goToForumCategory = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push("/forum/category/" + topic?.forumCategoryId);
    }

    const handlePageChanged = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        history.push("/forum/category/" + params.id + "/topic/" + params.topicId + "?page=" + page);
    }, [history, params]);

    const handleReplyClicked = useCallback(() => {
        history.push("/forum/category/reply/" + topic?.forumCategoryId + "/" + topic?.id);
    }, [history, topic]);

    const handleLockTopicClicked = useCallback(() => {
        if (topic) {
            api.put('forumTopic/lock/' + topic.id + "/" + topic?.forumCategoryId)
            .then((response) => {
                setTopic({
                    ...topic,
                    isLocked: response.data
                });
            })
            .catch((error) => {
                console.log("Failed to lock the topic.", error, error.response);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid, topic]);

    useEffect(() => {
        const currentQuery = getQueryData(location.search, [
            { property: "page", defaultValue: "1" }
        ]);
        
        api.get("forumTopic/" + params.id + "/" + params.topicId + "?page=" + currentQuery.data.page)
        .then((response) => {
            setTopic(response.data);
        })
        .catch((error) => {
            console.log("error", error, error.response);
            setError(true);
            setTopic(null);
        })
        .finally(() => {
            setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid, location, params]);

    const handleMoveTopicClosed = (success: boolean, categoryId: string) => {
        if (displayMoveTopic) {
            setDisplayMoveTopic(false);
            if (success) {
                history.push("/forum/category/" + categoryId + "/topic/" + topic?.id);
            }
        } else if (displayRequestMoveTopic) {
            setDisplayRequestMoveTopic(false);
            if (success && topic) {
                setTopic((currentTopic: ForumTopicProps | null) => {
                    if (currentTopic) {
                        return {
                            ...currentTopic,
                            moveRequestSent: true
                        }
                    } else {
                        return null;
                    }
                });
            }
        }
    }

    /***************************************************
     * Render
     ***************************************************/
    if (loading) {
        return (
            <div className="w-100 p-2">
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="mt-2 alert alert-info mb-0 rounded-0">
                            Loading the topic...
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (error) {
        return (
            <div className="w-100 p-2">
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="row">
                            <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                                <i className='fas fa-times fa-5x text-danger' />
                                <h4 className='mx-0 mt-2 mb-0 p-0'>Oops!</h4>
                                <h6 className='mx-0 mt-2 mb-0 p-0'>We couldn't find the topic you were looking for.</h6>
                                <h6 className='mx-0 mb-0 p-0'>It may have been moved or deleted.</h6>
                                <h6 className='mx-0 mb-0 p-0'>If the topic was moved, you will have to refresh the page and go back to the main menu.</h6>
                                <h6 className='mx-0 mt-3 mb-0 p-0'>Sorry for the inconvenience!</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (!topic) {
        return (
            <div className="w-100 p-2">
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="alert alert-danger mb-0 rounded-0">
                            No topic found
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        let replyButton = null;
        if (topic.allowedToReply && (!topic.isLocked || topic.allowedToModerate)) {
            replyButton = (
                <Button variant="contained" className="dark-red-button text-normal rounded-0" onClick={handleReplyClicked}>
                    Post reply
                </Button>
            );
        } 

        let lockButton = null;
        if (topic.allowedToModerate) {
            lockButton = (
                <Button variant="contained" className="ml-2 dark-red-button text-normal rounded-0" onClick={handleLockTopicClicked}>
                    { topic.isLocked ? "Unlock" : "Lock" }
                </Button>
            );
        }

        let modal = null;
        if (displayMoveTopic) {
            modal = (
                <MoveTopicModal
                    title="Move topic"
                    isRequest={false}
                    currentCategory={topic.forumCategoryId}
                    currentTopic={topic.id}
                    onClose={handleMoveTopicClosed}
                />
            );
        } else if (displayRequestMoveTopic) {
            modal = (
                <MoveTopicModal
                    title="Request to move topic"
                    isRequest={true}
                    currentCategory={topic.forumCategoryId}
                    currentTopic={topic.id}
                    onClose={handleMoveTopicClosed}
                />
            );
        }

        let moveButton = null;
        if (topic.moveRequestSent === true && topic.moveRequestDenied === false) {
            moveButton = (
                <div className="alert alert-danger rounded-0 mb-0">
                    Move request sent
                </div>
            );
        } else if (topic.moveRequestSent === true && topic.moveRequestDenied === true) {
            moveButton = (
                <div className='alert alert-warning rounded-0 mb-0 clickable' onClick={() => setDisplayReason((curr) => !curr)}>
                    <span className='disable-text-highlighting'>
                        { displayReason ? topic.moveRequestDenyReason : 'Move request denied' }
                    </span>
                </div>
            );
        } else if  (topic.isMoveRequestDisabled) {
            moveButton = (
                <div className="alert alert-info rounded-0 mb-0">
                    <span>Move requests have been disabled</span>
                </div>
            );
        } else {
            moveButton = (
                <Button size="small" className="text-normal text-muted" onClick={handleRequestMoveTopicClicked}>
                    <u>Request move topic</u>
                </Button>
            );
        }

        return (
            <div className="w-100 px-2">
                {modal}
                <div className="box-shadow">
                    <div className="title-bar py-1 px-2">
                        <div className="flex-grow-1 d-flex align-items-center">
                            <a href={"/forum/overview"} className="breadcrumb-nav" onClick={goToForum}>
                                {topic.forumName}
                            </a>
                            <i className="fas fa-chevron-right mx-2"></i>
                            <a href={"/forum/category/" + topic.forumCategoryId} className="breadcrumb-nav" onClick={goToForumCategory}>
                                {topic.forumCategoryName}
                            </a>
                            <i className="fas fa-chevron-right mx-2"></i>
                            <span className="d-none d-md-inline-block text-overflow">
                                {topic.title}
                            </span>
                        </div>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        {/* Desktop Title */}
                        <div className="d-none d-md-flex title-bar dark px-2">
                            <div className="d-flex w-100">
                                <div className="post-left-side">
                                    <span>Author</span>
                                </div>
                                <div className="flex-grow-1">
                                    <span>Message</span>
                                </div>
                            </div>
                        </div>

                        {/* Mobile title */}
                        <div className="d-md-none title-bar dark px-2">
                            <span>{topic.title}</span>
                        </div>

                        {/* Posts */}
                        {topic.posts.map((post, index) => (
                            <Post 
                                key={index} 
                                post={post}
                                allowedToReply={topic.allowedToReply}
                                allowedToModerate={topic.allowedToModerate}
                            />
                        ))}

                        { topic.isLocked ? (
                            <div className="mt-2 d-flex">
                                <div className="d-flex align-items-center mb-0 w-100 text-white font-weight-bold alert alert-danger bg-warning-red rounded-0">
                                    <i className="fas fa-exclamation-triangle mr-2" />
                                    <span>This topic is locked.</span>
                                </div>
                            </div>
                        ) : null}

                        {/* Buttons */}
                        <div className="mt-2 d-flex">
                            {replyButton}
                            {lockButton}

                            <div className="ml-auto d-flex">
                                { topic.allowedToReply && !topic.allowedToModerate ? moveButton : null }
                                { topic.allowedToModerate ? (
                                    <React.Fragment>
                                        <div className="pl-2 d-flex align-items-center">
                                            {moveButton}
                                            <Button size="small" className="text-normal text-muted" onClick={handleMoveTopicClicked}>
                                                <u>Move topic</u>
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                ) : null }
                            </div>
                        </div>

                        {/* Pagination */}
                        <div className="pagination-container w-100 mt-2">
                            <Pagination 
                                page={Number(query.data.page)}
                                count={topic.totalPages} 
                                onChange={handlePageChanged}
                                showFirstButton 
                                showLastButton 
                                variant="outlined" 
                                shape="rounded" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ForumTopic;
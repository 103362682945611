import axios from 'axios';

// .NET CORE 3.1 website api
// export const baseApiUrl = "https://localhost:44345/";
// .NET 6 website api
// export const baseApiUrl = 'https://localhost:7234/';
// Current live website api
export const baseApiUrl = "https://www.oberin.be/"

let url = baseApiUrl + "api/";

const instance = axios.create({
    baseURL: url,
    headers: {
        
    },
});

instance.interceptors.request.use((config) => {
    let bearerToken = sessionStorage.getItem('OBERIN_BEARER_TOKEN');
    if (bearerToken) {
        config.headers = {
            ...config.headers, 
            Authorization: 'Bearer ' + bearerToken
        }
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default instance;
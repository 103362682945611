import { Button } from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../api/api';
import InstallationGuideVersion from './InstallationGuideVersion';

export interface IInstallationGuideVersion {
    id: string;
    versionNumber: number;
    isLive: boolean;
    isValidationRequired: boolean;
    validationTime: number | undefined;
    createdTime: number;
    validatedBy: string;
    createdBy: string;
}

interface IParams {
    id?: string;
}

const InstallationGuideVersions: React.FunctionComponent = () => {
    const [saving, setSaving] = React.useState(false);
    const [versions, setVersions] = React.useState<IInstallationGuideVersion[] | null>(null);

    const history = useHistory();
    const { id } = useParams<IParams>();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (!id) {
            history.push('/admin/installationGuides');
            return;
        }

        api.get<IInstallationGuideVersion[]>("installationGuideVersion/admin/" + id)
        .then((response) => {
            setVersions(response.data);
        })
        .catch((error) => {
            console.log("Error fetching the installation guide versions", error, error.response);
        });
    }, [id, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleDisplayClicked = React.useCallback((versionId: string) => {
        history.push('/admin/installationGuides/' + id + '/versions/' + versionId);
    }, [id, history]);

    const handleBackClicked = React.useCallback(() => {
        history.push('/admin/installationGuides');
    }, [history]);
    
    const handlePutLiveClicked = React.useCallback((versionId: string) => {
        setSaving(true);

        api.put('installationGuideVersion/admin/live/' + id + '/' + versionId)
        .then(() => {
            setVersions((currentVersions) => {
                if (currentVersions === null) {
                    return null;
                }

                return currentVersions.map((version) => {
                    if (version.id === versionId) {
                        return {
                            ...version,
                            isLive: true
                        };
                    } else if (version.isLive) {
                        return {
                            ...version,
                            isLive: false
                        };
                    } else {
                        return version;
                    }
                });
            });
        })
        .catch(() => {
            alert('Failed to put version live.');
        })
        .finally(() => {
            setSaving(false);
        })
    }, [id]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (versions === null) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                <span>Loading the versions for the installation guide...</span>
            </div>
        );
    } else if (versions.length === 0) {
        content = (
            <div className="mt-2 alert alert-warning rounded-0 mb-0">
                <span>No installation guide versions found.</span>
            </div>
        );
    } else {
        content = versions.map((version) => {
            return (
                <InstallationGuideVersion
                    key={version.id}
                    version={version}
                    saving={saving}
                    onClick={handleDisplayClicked}
                    onPutLiveClicked={handlePutLiveClicked}
                />
            );
        });
    }

    return (
        <div className="w-100">
            <div className="row mb-2">
                <div className="col-12 col-md-6 col-lg-4">
                    <Button onClick={handleBackClicked} variant='contained' className='w-100 dark-red-button text-normal rounded-0'>
                        <i className="fas fa-step-backward mr-2"></i>
                        <span>Go back</span>
                    </Button>
                </div>
            </div>
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Manage the versions of the installation guide</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="mt-2 title-bar px-2 dark">
                        <div className="row w-100">
                            <div className="col-8 col-md-2">
                                <span>Version</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Created</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Creator</span>
                            </div>
                            <div className="col-4 col-md-1">
                                <span>Live</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Validation</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Validation time</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-end col-1">
                                <span>Actions</span>
                            </div>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default InstallationGuideVersions;

import * as React from 'react';
import { useHistory } from 'react-router-dom';

import api, { baseApiUrl } from '../../api/api';
import { getDate, getTime } from '../../utilities/date';

interface ICharacter {
    id: string,
    name: string
}

interface IAccount {
    id: string,
    username: string,
    profilePicture: string,
    created: number,
    characters: ICharacter[]
}

interface IAccountGeneralInfoProps {
    accountId: string
}

const AccountGeneralInfo: React.FunctionComponent<IAccountGeneralInfoProps> = ({ accountId }) => {
    const [account, setAccount] = React.useState<IAccount | null>(null);
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get('members/account/' + accountId)
        .then((response) => {
            setAccount(response.data);
        })
        .catch((error) => {
            console.log('Error fetching account: ', error, error.response, accountId);
        });
    }, [accountId]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleCharacterClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, character: ICharacter) => {
        event?.preventDefault();
        history.push('/members/character/' + character.id);
    }

    /***************************************************
     * Render
     ***************************************************/
    let generalInformationContent = null;
    let charactersContent = null;

    if (account === null) {
        generalInformationContent = (
            <div className="alert alert-info rounded-0 mb-0">
                Loading account...
            </div>
        );

        charactersContent = (
            <div className="alert alert-info rounded-0 mb-0">
                Loading characters...
            </div>
        );
    } else {
        let created = new Date(account.created);

        generalInformationContent = (
            <>
                <span className="font-small">
                    Username
                </span>
                <span>
                    {account.username}
                </span>
                <span className="font-small mt-2">
                    Created
                </span>
                <span>
                    {getDate(created)}, {getTime(created)}
                </span>
                { account.profilePicture && account.profilePicture.trim() !== '' ? (
                    <div>
                        <img className='img-fluid' alt='profile avatar' src={baseApiUrl + 'attachments/profile/' + account.profilePicture} />
                    </div>
                ) : null }
            </>
        );

        let characters = null;

        if (account.characters.length === 0) {
            characters = (
                <div className="alert alert-info rounded-0 mb-0">
                    No characters linked to this account
                </div>
            );
        } else {
            characters = account.characters.map((character) => {
                return (
                    <div key={character.id} className="alternate-table-entry px-2">
                        <a 
                            className='w-100 forum-category-link'
                            href={'/members/character/' + character.id} 
                            onClick={(event) => handleCharacterClicked(event, character)}
                        >
                            {character.name}
                        </a>
                    </div>
                );
            });
        }

        charactersContent = (
            <>
                <div className="title-bar px-2 dark">
                    <span>Name</span>
                </div>
                {characters}
            </>
        );
    }

    return (
        <>
            {/* General info */}
            <div className="w-100 px-2">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        General information
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        {generalInformationContent}
                    </div>
                </div>
            </div>

            {/* Character info */}
            <div className="mt-3 w-100 px-2">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        Characters
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        {charactersContent}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountGeneralInfo;

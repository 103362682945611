import { Button } from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../api/api';
import Checkbox from '../../MUI/Checkbox';
import Input from '../../MUI/Input';
import { IInstallationGuide } from './InstallationGuides';

interface IParams {
    id?: string;
}

const InstallationGuideEdit: React.FunctionComponent = () => {
    const [installationGuide, setInstallationGuide] = React.useState<IInstallationGuide | null>(null);
    const [sortOrder, setSortOrder] = React.useState<string>("");
    const [saving, setSaving] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");

    const { id } = useParams<IParams>();
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (id) {
            api.get<IInstallationGuide>('installationGuide/admin/' + id)
            .then((response) => {
                setInstallationGuide(response.data);
                setSortOrder(response.data.sortOrder.toString());
            })
            .catch((err) => console.log('Failed to fetch the installation guide', err, err.response));
        } else {
            setInstallationGuide({
                id: '',
                name: '',
                description: '',
                sortOrder: -1,
                isEnabled: false
            });
        }
    }, [id]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleNameChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInstallationGuide((current) => {
            return {
                ...current!,
                name: event.target.value
            }
        });
    }, []);

    const handleDescriptionChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInstallationGuide((current) => {
            return {
                ...current!,
                description: event.target.value
            }
        });
    }, []);

    const handleSortOrderChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSortOrder(event.target.value);
    }, []);

    const handleIsEnabledChanged = React.useCallback(() => {
        setInstallationGuide((current) => {
            if (!current) {
                return null;
            }

            return {
                ...current,
                isEnabled: !current.isEnabled
            }
        });
    }, []);

    const handleSaveClicked = () => {
        if (!installationGuide) {
            return;
        }
        setError("");

        // Verify sort order
        let value = Number(sortOrder);
        if (isNaN(value) || value === null || value === undefined) {
            setError("Please provide a valid number for the sort order");
            return;
        }

        // Verify name
        if (installationGuide.name.trim() === '') {
            setError("Please fill in a name");
            return;
        }

        // Verify description
        if (installationGuide.description.trim() === "") {
            setError("Please fill in a description.");
            return;
        }

        let model = {
            id: installationGuide.id,
            name: installationGuide.name,
            description: installationGuide.description,
            isEnabled: installationGuide.isEnabled,
            sortOrder: value
        };

        setSaving(true);
        if (installationGuide.id === '') {
            api.post('installationGuide/admin', model)
            .then(() => {
                history.push('/admin/installationGuides');
            })
            .catch(() => {
                setSaving(false);
                setError("Failed to create the installation guide.");
            });
        } else {
            api.put('installationGuide/admin', model)
            .then(() => {
                history.push('/admin/installationGuides');
            })
            .catch(() => {
                setSaving(false);
                setError("Failed to update the installation guide")
            });
        }
    }

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    // Check if creating
    let isCreating: boolean = installationGuide !== null && installationGuide.id === '';

    if (installationGuide === null) {
        content = (
            <div className='mt-2 alert alert-info rounded--0 mb-0'>
                <span>Loading the installation guide...</span>
            </div>
        );
    } else {
        content = (
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 d-flex flex-column">
                    <Input
                        id='installation-guide-name'
                        label='Name'
                        value={installationGuide.name}
                        onChange={handleNameChanged}
                    />
                    <Input
                        id='installation-guide-description'
                        label='Description'
                        value={installationGuide.description}
                        onChange={handleDescriptionChanged}
                    />
                    { isCreating ? null : (
                        <Input
                            id='installation-guide-sort-order'
                            type='number'
                            label='Sort order'
                            value={installationGuide.sortOrder}
                            onChange={handleSortOrderChanged}
                        />
                    ) }
                    <Checkbox
                        checked={installationGuide.isEnabled}
                        disabled={isCreating}
                        label='Is enabled'
                        onChange={handleIsEnabledChanged}
                    />
                    <Button
                        variant='contained'
                        className='dark-red-button rounded-0'
                        onClick={handleSaveClicked}
                        disabled={saving}
                    >
                        Save
                    </Button>
                    { error === '' ? null : (
                        <div className="mt-2 alert alert-danger rounded-0 mb-0">
                            <span>{error}</span>
                        </div>
                    ) }
                </div>
            </div>
        );
    }

    return (
        <div className="w-100">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>
                        { installationGuide === null ? 'Loading installation guide information': (
                            isCreating ? 'Create an installation guide' : 'Edit the installation guide'
                        ) }
                    </span>
                </div>
                <div className="bg-light-gray p-2 bl-xs bb-xs">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default InstallationGuideEdit;

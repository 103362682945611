import React, { useEffect, useState } from 'react';
import Forum from '../../components/Forums/Forum';
import api from '../../api/api';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export interface LastPostProps {
    id: string;
    name: string;
    date: number;
}

export interface CategoryProps {
    id: string;
    name: string;
    description: string;
    topics: number;
    posts: number[];
    lastPost: LastPostProps | null;
}

export interface ForumProps {
    id: string;
    name: string;
    categories: CategoryProps[];
};

const ForumOverview: React.FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [forums, setForums] = useState<ForumProps[]>([]);

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        api.get('forum')
        .then((response) => {
            setForums(response.data);
        })
        .catch((error) => {
            console.log("error loading forums", error, error.response);
        })
        .finally(() => {
            setLoading(false);
        })
    }, [uid]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleAdvancedSearchClicked = () => {
        history.push('/forum/search');
    }

    /***************************************************
     * Render
     ***************************************************/
    let content = null;
    if (loading) {
        content = (
            <div className="mt-2 alert alert-info mb-0 rounded-0">
                Loading the forums...
            </div>
        );
    }
    else if (forums.length === 0) {
        content = (
            <div className="mt-2 alert alert-danger mb-0 rounded-0">
                No forums found.
            </div>
        );
    }
    else {
        content = forums.map((forum, index) => <Forum key={index} data={forum} first={index === 0} />);
    }

    return (
        <div className="w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    Oberin Forum
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <Button variant='contained' className='dark-red-button text-normal rounded-0 w-100' onClick={handleAdvancedSearchClicked}>
                                <i className="fas fa-search mr-2"></i>
                                Advanced Search
                            </Button>
                        </div>
                    </div>

                    <div className="title-bar dark mt-2 px-2">
                        <div className="row w-100">
                            <div className="col-12 col-md-6">
                                <span>Forum</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-center col-2">
                                <span>Topics</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-center col-2">
                                <span>Posts</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-center col-2">
                                <span>Last post</span>
                            </div>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default ForumOverview;
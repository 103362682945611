import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { AdminRoleProps } from '../../../containers/Admin/AdminRoles';
import Checkbox from '../../MUI/Checkbox';

interface Props {
    role: AdminRoleProps;
    onEditClicked: (id: string) => void;
    onDeleteClicked: (id: string) => void;
};

const RoleItem: React.FC<Props> = ({ role, onEditClicked, onDeleteClicked }) => {
    const [display, setDisplay] = useState<boolean>(false);
    const toggleDisplay = useCallback(() => {
        setDisplay(currentDisplay => !currentDisplay);
    }, []);

    const handleEditClicked = () => {
        if (role.isStatic === false) {
            onEditClicked(role.id);
        }
    }

    const handleDeleteClicked = () => {
        if (role.isStatic === false) {
            onDeleteClicked(role.id);
        }
    }

    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                        { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                    </Button>
                    <span className="ml-2 flex-grow-1">
                        {role.name}
                    </span>
                </div>

                {/* Detailed information */}
                { display ? (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Name
                        </span>
                        <span>
                            {role.name}
                        </span>
                        <Checkbox
                            checked={role.isStatic}
                            disabled={true}
                            label="Is static"
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-red-checkbox"
                            onChange={() => {}} 
                        />
                        <Checkbox
                            checked={role.isAllowedToModerate}
                            disabled
                            label='Is moderator'
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-red-checkbox"
                            onChange={() => {}} 
                        />
                        <span className="mt-2 font-small">
                            Accounts
                        </span>
                        <span>
                            {role.amountOfAccounts}
                        </span>
                        <div className="mt-2 d-flex">
                            { role.isStatic ? null : (
                                <React.Fragment>
                                    <Button variant="contained" className="action-button bg-warning text-white rounded-0" onClick={handleEditClicked}>
                                        <i className="fas fa-pencil-alt"></i>
                                    </Button>
                                    <Button variant="contained" className="ml-2 action-button bg-danger text-white rounded-0" onClick={handleDeleteClicked}>
                                        <i className="fas fa-trash"></i>
                                    </Button>
                                </React.Fragment>
                            ) }
                        </div>
                    </div>
                ) : null}
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-4">
                    <span>{role.name}</span>
                </div>
                <div className="d-flex align-items-center col-2">
                    <Checkbox
                        checked={role.isStatic}
                        disabled={true}
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={() => {}} 
                    />
                </div>
                <div className="d-flex align-items-center col-2">
                    <Checkbox
                        checked={role.isAllowedToModerate}
                        disabled={true}
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={() => {}} 
                    />
                </div>
                <div className="d-flex align-items-center col-2">
                    <span>{role.amountOfAccounts}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    { role.isStatic ? null : (
                        <React.Fragment>
                            <Button variant="contained" className="ml-2 action-button bg-warning text-white rounded-0" onClick={handleEditClicked}>
                                <i className="fas fa-pencil-alt"></i>
                            </Button>
                            <Button variant="contained" className="ml-2 action-button bg-danger text-white rounded-0" onClick={handleDeleteClicked}>
                                <i className="fas fa-trash"></i>
                            </Button>
                        </React.Fragment>
                    ) }
                </div>
            </div>
        </div>
    );
};

export default RoleItem;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AuthReducerProps } from '../../store/reducers/authReducer';

import api from '../../api/api';

import Announcement from './Announcement';

export interface AnnouncementProps {
    forumCategoryId: string;
    id: string;
    title: string;
    replies: number;
    views: number;
    author: string;
    created: number;
    content: string;
}

const Announcements: React.FC<{}> = () => {
    const [data, setData] = useState<null | AnnouncementProps[]>(null);
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    useEffect(() => {
        api.get('announcement')
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.log("Failed to load the announcements");
            console.log("error", error);
            console.log("error.response", error.response);
        });
    }, [uid]);
    
    if (!data) {
        return null;
    }

    let content = data.map((announcement, index) => {
        return <Announcement key={index} announcement={announcement} />;
    });

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    )
};

export default Announcements;
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CharacterGeneralInfo from '../../components/Members/CharacterGeneralInfo';
import CharacterPosts from '../../components/Members/CharacterPosts';

interface IParamProps {
    characterId?: string
};

const CharacterPage: React.FunctionComponent = () => {
    const { characterId } = useParams<IParamProps>();
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (characterId === undefined) {
            // @ts-ignore
            history.goBack();
        }
    }, [history, characterId]);

    /***************************************************
     * Render
     ***************************************************/
    if (!characterId) {
        return null;
    }

    return (
        <>
            <CharacterGeneralInfo characterId={characterId} />
            <CharacterPosts characterId={characterId} />
        </>
    );
};

export default CharacterPage;

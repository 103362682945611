import React, { useCallback, useEffect, useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import api from '../../api/api';

import CategoryTopics from '../../components/Forums/CategoryTopics';
import getQueryData from '../../utilities/query';
import Input from '../../components/MUI/Input';

import { LastPostProps } from './ForumOverview';
import { AuthReducerProps } from '../../store/reducers/authReducer';

const debounceFunction = () => {return;};
const debounce = AwesomeDebouncePromise(debounceFunction, 350);

interface ParamProps {
    id: string;
}

export interface ForumCategoryTopicProps {
    id: string;
    title: string;
    views: number;
    replies: number;
    author: string;
    created: number;
    lastPost: LastPostProps | null;
    isSticky: boolean;
    isLocked: boolean;
    isAnnouncement: boolean;
    isNew: boolean;
}

interface ForumCategoryResponseProps {
    id: string;
    forumId: string;
    name: string;
    forumName: string;
    currentPage: number;
    totalPages: number;
    topics: ForumCategoryTopicProps[];
    allowedToUse: boolean;
    allowedToModerate: boolean;
    allowedToCreate: boolean;
}

const Category: React.FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [init, setInit] = useState<boolean>(false);
    const [forumCategory, setForumCategory] = useState<ForumCategoryResponseProps | null>(null);
    const [inputValue, setInputValue] = useState<string>("");

    const history = useHistory();
    const location = useLocation();
    const params = useParams<ParamProps>();
    const query = getQueryData(location.search, [
        { property: "page", defaultValue: "1 "}
    ]);

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        if (init === false) {
            const currentQuery = getQueryData(location.search, [
                { property: "search", defaultValue: "" }
            ]);

            if (currentQuery.data.search && currentQuery.data.search !== "") {
                setInputValue(currentQuery.data.search);
            }

            setInit(true);
        }
    }, [init, location]);

    useEffect(() => {
        if (!params.id || params.id.trim() === '') {
            history.push("/forum/overview");
            return;
        }

        const currentQuery = getQueryData(location.search, [
            { property: "page", defaultValue: "1" },
            { property: "search", defaultValue: "" }
        ]);

        api.get('forumCategory/' + params.id + "/" + currentQuery.data.page + "?search=" + currentQuery.data.search)
        .then((response) => {
            setForumCategory(response.data);
        })
        .catch((error) => {
            console.log("error", error, error.response);
        })
        .finally(() => {
            setLoading(false);
        });
    }, [uid, params, location, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const goBack = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push("/forum/overview");
    }, [history]);

    const handleCreateTopicClicked = useCallback(() => {
        history.push("/forum/category/create/" + params.id)
    }, [params, history]);

    const handlePageChanged = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        // Create query
        const currentQuery = getQueryData(location.search, [
            { property: "page", defaultValue: "1" },
            { property: "search", defaultValue: "" }
        ]);

        // Create url
        let url = '/forum/category/' + params.id + '?page=' + page;
        if (currentQuery.data.search && currentQuery.data.search !== "") {
            url += "&search=" + currentQuery.data.search;
        }

        // Navigate
        history.push(url);
    }, [history, params, location]);

    const handleInputValueChanged = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        // Save the value
        let value = event.target.value;

        // Update value to display on the frontend
        setInputValue(value);

        // Await debounce
        await debounce();

        if (value.trim() !== "") {
            history.push('/forum/category/' + params.id + '?search=' + value);
        } else {
            history.push('/forum/category/' + params.id);
        }
        
    }, [history, params]);

    const handleAdvancedSearchClicked = () => {
        history.push('/forum/search');
    }

    /***************************************************
     * Render
     ***************************************************/
    let content = null;
    if (loading) {
        return (
            <div className="w-100 p-2">
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="mt-2 alert alert-info mb-0 rounded-0">
                            Loading the topics...
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (!forumCategory) {
        return (
            <div className="w-100 p-2">
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="mt-2 alert alert-danger mb-0 rounded-0">
                            No forum category found
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (forumCategory.topics.length === 0) {
        content = (
            <div className="mt-2 alert alert-danger mb-0 rounded-0">
                No topics found
            </div>
        );
    }
    else {
        let stickyTopics = forumCategory.topics.filter(t => t.isSticky);   
        let normalTopics = forumCategory.topics.filter(t => !t.isSticky);  

        content = (
            <React.Fragment>
                <CategoryTopics forumCategoryId={params.id} topics={stickyTopics} first={stickyTopics.length > 0} title="Sticky" />
                <CategoryTopics forumCategoryId={params.id} topics={normalTopics} first={stickyTopics.length === 0} title="Topics" />
            </React.Fragment>
        );
    }

    return (
        <div className="w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar py-1 px-2">
                    <div className="flex-grow-1 d-flex align-items-center">
                        <a href={"/forum/overview"} className="breadcrumb-nav" onClick={goBack}>
                            {forumCategory?.forumName}
                        </a>
                        <i className="fas fa-chevron-right mx-2"></i>
                        <span>{forumCategory?.name}</span>
                    </div>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="vert-row row">
                        { forumCategory.allowedToCreate ? (
                            <div className="vert-col col-12 col-md-6 col-lg-4">
                                <Button variant="contained" className="dark-red-button rounded-0 text-normal w-100" onClick={handleCreateTopicClicked}>
                                <i className="fas fa-plus mr-2"></i> Create topic
                            </Button>
                        </div>
                        ) : null }
                        <div className="vert-col col-12 col-md-6 col-lg-4">
                            <Button variant='contained' className='dark-red-button rounded-0 text-normal w-100' onClick={handleAdvancedSearchClicked}>
                                <i className="fas fa-search mr-2"></i> Advanced Search
                            </Button>
                        </div>
                        <div className="vert-col col-12">
                            <Input 
                                id='forum-category-search'
                                label='Search on topics'
                                value={inputValue}
                                onChange={handleInputValueChanged}
                            />
                        </div>
                    </div>

                    {/* Header */}
                    <div className="mt-2 title-bar dark px-2 dark">
                        <div className="row w-100">
                            <div className="col-12 col-md-4">
                                <span>Title</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-center col-2">
                                <span>Replies</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-center col-2">
                                <span>Author</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-center col-2">
                                <span>Views</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-center col-2">
                                <span>Last post</span>
                            </div>
                        </div>
                    </div>

                    {/* Content */}
                    {content}

                    {/* Pagination */}
                    <div className="pagination-container w-100 mt-2">
                        <Pagination
                            page={Number(query.data.page)}
                            count={forumCategory.totalPages}
                            onChange={handlePageChanged}
                            showFirstButton
                            showLastButton
                            variant="outlined"
                            shape="rounded"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;
import { Button } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../api/api';
import ManageGuide from './ManageGuide';

export interface IInstallationGuide {
    id: string;
    name: string;
    isEnabled: boolean;
    liveVersion: number;
    liveVersionId: string;
    workingVersion: number;
    workingVersionId: string;
}

const ManageGuides: React.FunctionComponent = () => {
    const [guides, setGuides] = React.useState<IInstallationGuide[] | null>(null);
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get<IInstallationGuide[]>('installationGuide/manage/list')
        .then((response) => {
            setGuides(response.data);
        })
        .catch((error) => {
            console.log('[ManageGuides] Failed to fetch the installation guides', error, error.response);
        })
    }, []);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleVersionsClicked = React.useCallback((id: string) => {
        history.push('/download/manage/' + id + '/versions');
    }, [history]);

    const handlePreviewClicked = React.useCallback((guide: IInstallationGuide) => {
        history.push('/download/manage/' + guide.id + '/versions/' + guide.liveVersionId + '/display');
    }, [history]);

    const handleEditClicked = React.useCallback((guide: IInstallationGuide) => {
        history.push('/download/manage/' + guide.id + '/versions/' + guide.workingVersionId + '/update');
    }, [history]);

    const handleBackClicked = React.useCallback(() => {
        history.push('/download');
    }, [history]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (guides === null) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                <span>Loading the installation guides...</span>
            </div>
        );
    } else if (guides.length === 0) {
        content = (
            <div className="mt-2 alert alert-warning rounded-0 mb-0">
                <span>No installation guides found to manage.</span>
            </div>
        );
    } else {
        content = guides.map((guide) => {
            return (
                <ManageGuide
                    key={guide.id}
                    guide={guide}
                    onEditClicked={handleEditClicked}
                    onPreviewClicked={handlePreviewClicked}
                    onVersionsClicked={handleVersionsClicked}
                />
            );
        });
    }

    return (
        <div className="w-100 px-2">
            <div className="row mb-2">
                <div className="col-12 col-md-6 col-lg-4">
                    <Button onClick={handleBackClicked} variant='contained' className='w-100 dark-red-button text-normal rounded-0'>
                        <i className="fas fa-step-backward mr-2"></i>
                        <span>Go back</span>
                    </Button>
                </div>
            </div>
            <div className="box-shadwo">
                <div className="title-bar px-2">
                    <span>Manage your installation guides</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="mt-2 title-bar dark px-2">
                        <div className="row w-100">
                            <div className="col-12 col-md-4">Name</div>
                            <div className="d-none d-md-flex col-2">Enabled</div>
                            <div className="d-none d-md-flex col-2">Live version</div>
                            <div className="d-none d-md-flex col-2">Working version</div>
                            <div className="d-none d-md-flex justify-content-end col-2">Actions</div>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default ManageGuides;

import React, { useEffect, useState } from 'react';
import { Button, Modal, TextField } from '@material-ui/core';

import Select from '../MUI/Select';
import api from '../../api/api';
import { useSelector } from 'react-redux';
import { AuthReducerProps } from '../../store/reducers/authReducer';

interface Props {
    title: string;
    isRequest: boolean;
    currentCategory: string;
    currentTopic: string;
    onClose: (success: boolean, categoryId: string) => void;
}

interface OptionProps {
    value: string;
    label: string;
    groupBy: string;
}

const MoveTopicModal: React.FC<Props> = ({ title, isRequest, currentCategory, currentTopic, onClose }) => {
    const [value, setValue] = useState<string | null>(null);
    const [reason, setReason] = useState<string>("");
    const [options, setOptions] = useState<OptionProps[]>([]);
    const [error, setError] = useState<string>("");

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const isError = error !== "";

    useEffect(() => {
        let url = "forumCategory/move/";
        if (isRequest) {
            url += "request/options";
        } else {
            url += "options";
        }

        api.get(url)
        .then((response) => {
            setOptions(response.data);
        })
        .catch(() => {
            setError("Failed to fetch the forum categories.");
        });
    }, [uid, isRequest]);

    const handleCategoryChanged = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        let option = options.find(o => o.value === value);
        if (option) {
            setValue(option.value);
        }
    };

    const handleReasonChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setReason(event.target.value);
    }

    const handleMoveTopicClicked = () => {
        // Verify if a caategory was selected
        if (value === null) {
            setError("Please select a forum category.");
            return;
        }

        // Verify if reason is set
        if (reason.trim() === "") {
            setError("Please enter a reason.");
            return;
        }

        // Verify length of reason
        if (reason.length > 45) {
            setError("Max length is 45 characters.");
            return;
        }

        // Create url
        let url = '';
        if (isRequest) {
            url = 'forumCategory/move/request';
        } else {
            url = 'forumTopicMoveRequest/moderator/move';
        }

        api.post(url, {
            forumCategoryId: value,
            forumTOpicId: currentTopic,
            reason: reason
        })
        .then(() => {
            onClose(true, value);
        })
        .catch((error) => {
            if (!error || (error && !error.response) || (error && error.response && !error.response.status)) {
                setError("Unknown server error.");
                return;
            }

            if (error.response.status === 400) {
                setError(error.response.data);
                return;
            }

            if (error.response.status === 401) {
                setError("Not allowed to move topic to that forum category.");
                return;
            }

            setError("Unknown server error.");
        });
    }

    return (
        <Modal
            open={true}
            aria-labelledby="move-topic-popup"
            aria-describedby={title}
            onClose={() => onClose(false, '')}
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>{title}</span>
                    <div className="d-flex ml-auto align-items-center justify-content-center">
                        <i className="fas fa-times text-white clickable" onClick={() => onClose(false, '')}></i>
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <Select 
                        id="select-forum-category"
                        label="Select a category"
                        className={["w-100", "mt-2"]}
                        value={value}
                        options={options.filter(o => o.value !== currentCategory)}
                        grouped={true}
                        error={isError && value === null}
                        helperText="Please select a category"
                        onChange={handleCategoryChanged}
                    />

                    <TextField
                        id="move-category-reason"
                        label="Reason"
                        value={reason}
                        onChange={handleReasonChanged}
                        multiline
                        rows={4}
                        defaultValue=""
                        variant="outlined"
                        margin="dense"
                        type="text"
                        className="textfield-rounded-0 w-100 mt-3"
                        error={isError && reason === ""}
                        helperText={isError && reason === "" ? "Please provide a reason" : ""}
                    />

                    { error !== "" ? (
                        <div className="alert alert-danger rounded-0 mt-3 mb-0">
                            <span>{error}</span>
                        </div>
                    ) : null }

                    <Button variant="contained" className="dark-red-button rounded-0 mt-3 w-100" onClick={handleMoveTopicClicked}>
                        { isRequest ? "Submit request" : "Move topic" }
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default MoveTopicModal;
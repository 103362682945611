import { UserProps } from '../reducers/authReducer';
import api from '../../api/api';

let refreshTokenTimeout: NodeJS.Timeout;

export const authUpdateUser = (updatedUser: UserProps | null) => {
    return {
        type: 'UPDATE_USER',
        updatedUser: updatedUser
    };
}

export const refreshToken = () => {
    return (dispatch: any) => {
        let token = localStorage.getItem("OBERIN_REFRESH_TOKEN");

        console.log('auth actions refresh token', token);
        api.post('account/refreshToken', { token })
        .then((response) => {
            localStorage.setItem("OBERIN_REFRESH_TOKEN", response.data.refreshToken);
            sessionStorage.setItem("OBERIN_BEARER_TOKEN", response.data.jwtToken);
            dispatch(authUpdateUser(response.data));
            dispatch(startRefreshTokenTimer());
        })
        .catch(() => {
            console.log('update user stop refresh token timer');
            dispatch(authUpdateUser(null));
            dispatch(stopRefreshTokenTimer());
        });
    }
}

export const startRefreshTokenTimer = () => {
    return (dispatch: any, getState: any) => {

        let state = getState();
        let token = state.me?.jwtToken;

        if (token) {
            const jwtToken = JSON.parse(atob(token.split('.')[1]));
            const expires = new Date(jwtToken.exp * 1000);
            const timeout = expires.getTime() - Date.now() - (60 * 1000);

            setTimeout(() => {
                dispatch(refreshToken());
            }, timeout);
        }
    }
}

export const stopRefreshTokenTimer = () => {
    return () => {
        sessionStorage.removeItem("OBERIN_BEARER_TOKEN");
        localStorage.removeItem("OBERIN_REFRESH_TOKEN");
        clearTimeout(refreshTokenTimeout);
    }
}

export const authLogout = (history: any) => {
    return (dispatch: any) => {
        let token = localStorage.getItem("OBERIN_REFRESH_TOKEN");
        history.push("/home");

        api.post('account/revokeToken', { token })

        .finally(() => {
            dispatch(authUpdateUser(null));
            dispatch(stopRefreshTokenTimer());
        });
    }
}

export const authLogin = (updatedUser : UserProps) => {
    return (dispatch: any) => {
        localStorage.setItem("OBERIN_REFRESH_TOKEN", updatedUser.refreshToken);
        sessionStorage.setItem("OBERIN_BEARER_TOKEN", updatedUser.jwtToken);

        dispatch(authUpdateUser(updatedUser));
        dispatch(startRefreshTokenTimer());
    }
}
import { Modal } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { AdminRoleProps } from '../../../containers/Admin/AdminRoles';
import { Role } from '../../../utilities/roles';
import Input from '../../MUI/Input';
import SaveButton, { saveStatuses, saveTypes } from '../../MUI/SaveButton';
import Select, { SelectOptionProps } from '../../MUI/Select';

import api from '../../../api/api';
import { AuthReducerProps } from '../../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

interface Props {
    roles: AdminRoleProps[];
    closePopup: () => void;
    setRoles: React.Dispatch<React.SetStateAction<AdminRoleProps[]>>;
};

interface ErrorProps {
    name: boolean;
    inheritFrom: boolean;
    message: string;
}

const CreateRole: React.FC<Props> = ({ roles, closePopup, setRoles }) => {
    const [name, setName] = useState<string>("");
    const [inheritFrom, setInheritFrom] = useState<string | null>(null); 
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [error, setError] = useState<ErrorProps>({ name: false, inheritFrom: false, message: "" });

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    const handleInheritRoleChanged = useCallback((event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        let role = roles.find(r => r.id === value && r.id !== Role.ADMIN);
        if (role) {
            setInheritFrom(role.id);
        }
    }, [roles]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Initialise error data
            let errorData : ErrorProps = {
                name: false,
                inheritFrom: false,
                message: ""
            };

            // Verify name
            if (name.trim() === "") {
                errorData.name = true;
            }

            // Verify inherit from
            if (inheritFrom === null || inheritFrom === Role.ADMIN) {
                errorData.inheritFrom = true;
            }

            // Verify form errors
            setError(errorData);
            if (errorData.name || errorData.inheritFrom) {
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Try to create
            api.post("role", {
                name,
                inheritFrom
            })
            .then((response) => {
                setRoles((currentRoles) => {
                    return [ ...currentRoles, { ...response.data, amountOfAccounts: 0 } ];
                });
                closePopup();
            })
            .catch((error) => {
                let message = "Server error";
                if (error?.response?.data) {
                    message = error.response.data;
                }

                setStatus(saveStatuses.ERROR);
                setError(currentError => {
                    return {
                        ...currentError,
                        message: message
                    }
                });
            })
        }
    }, [uid, status, name, inheritFrom, setRoles, closePopup]);

    let options: SelectOptionProps[] = roles.filter(r => r.id !== Role.ADMIN).map((role) => {
        return {
            value: role.id,
            label: role.name
        }
    });

    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby="create-role-popup"
            aria-describedby="Create a role"
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>Create a role</span>
                </div>
                <div className="d-flex flex-column p-2">
                    <Input
                        id="role-popup-name"
                        label="Name"
                        value={name}
                        error={error.name && name.trim() === ""}
                        helperText={"Please fill in a name"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Select
                        id="create-account-popup-select-role"
                        label="Inherit rights from"
                        className={["w-100", "mt-2"]}
                        value={inheritFrom}
                        options={options}
                        error={error.inheritFrom && (inheritFrom === null || inheritFrom === Role.ADMIN)}
                        helperText="Please select a valid role"
                        onChange={handleInheritRoleChanged}
                        onKeyDown={handleKeyDown}
                    />

                    { error.message.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.message}
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                            saveContent="Create"
                            savingContent={(<><i className="fas fa-sync fa-spin mr-2" /> Creating...</>)}
                            errorContent={(<><i className="fas fa-times mr-2" /> Error</>)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateRole;
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AccountGeneralInfo from '../../components/Members/AccountGeneralInfo';
import AccountPosts from '../../components/Members/AccountPosts';

interface IParamProps {
    accountId?: string
};

const AccountPage: React.FunctionComponent = () => {
    const { accountId } = useParams<IParamProps>();
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
     React.useEffect(() => {
        if (accountId === undefined) {
            // @ts-ignore
            history.goBack();
        }
    }, [history, accountId]);

    /***************************************************
     * Render
     ***************************************************/
     if (!accountId) {
        return null;
    }

    return (
        <>
            <AccountGeneralInfo accountId={accountId} />
            <AccountPosts accountId={accountId} />
        </>
    );
};

export default AccountPage;

import React from 'react';
import { Button } from '@material-ui/core';
import { baseApiUrl } from '../../api/api';

interface Props {
    username: string;
    email: string;
    profilePicture: string;
    onAvatarButtonClicked: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const GeneralInformation: React.FC<Props> = ({ username, email, profilePicture, onAvatarButtonClicked }) => {
    let avatar = null;

    if (profilePicture === "") {
        avatar = (
            <div className="d-flex flex-column">
                <div className="p-3 text-center bg-dark-gray text-white">
                    <i className="fas fa-2x fa-user-circle"></i>
                </div>
                <div className="d-flex mt-2">
                    <Button 
                        variant="contained" 
                        className="dark-red-button rounded-0" 
                        onClick={onAvatarButtonClicked}
                    >
                        Add avatar
                    </Button>
                </div>
                
            </div>
        );
    }
    else {
        avatar = (
            <div className="d-flex flex-column">
                <div>
                    <img className="img-fluid" alt="profile avatar" src={baseApiUrl + "attachments/profile/" + profilePicture} />
                </div>
                <div className="d-flex mt-2">
                    <Button 
                        variant="contained" 
                        className="dark-red-button text-white text-normal rounded-0" 
                        onClick={onAvatarButtonClicked}
                    >
                        Change avatar
                    </Button> 
                </div>
            </div>
        )
    }

    return (
        <div className="w-100 px-2 pb-1">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>General Information</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <span className="font-small">
                        Username
                    </span>
                    <span>
                        {username}
                    </span>
                    <div className="mt-2 font-small">
                        Email
                    </div>
                    <span>
                        {email}
                    </span>
                    <div className="mt-2 font-small">
                        Avatar
                    </div>
                    {avatar}
                </div>
            </div>
        </div>
    );
};

export default GeneralInformation;
import React from 'react';
import Account from '../../components/Layout/Sidebar/Account/Account';
import NavigationLink from '../../components/Layout/Sidebar/NavigationLink';
import RecentPosts from '../../components/Layout/Sidebar/RecentPosts/RecentPosts';
import LatestPosts from '../../components/Layout/Sidebar/LatestPosts/LatestPosts';
import PrivateComponent from '../../components/Private/PrivateComponent';
import OberinCalendar from '../../components/Layout/Sidebar/OberinCalendar/OberinCalendar';
import { Role } from '../../utilities/roles';

import DiscordIcon from '../../assets/discord-icon.svg';
import FacebookIcon from '../../assets/facebook-icon.jpg';
import ServerOnline from '../../assets/online.png';
// import ServerOffline from '../../assets/offline.png';

import './Sidebar.scss';

interface Props {
    toggled: boolean;
    collapse: () => void;
    displaySidebarRight: boolean;
};

const Sidebar: React.FC<Props> = React.memo(({ toggled, collapse, displaySidebarRight }) => {
    const handleDiscordClicked = () => {
        window.open('https://discord.gg/32j3DsT', '_blank');
    }

    const handleFacebookClicked = () => {
        window.open('https://www.facebook.com/groups/OberinGroup/about', '_blank');
    }

    return (
        <section id="sidebar" className={ toggled ? "toggled" : "" }>
            {/* Account information */}
            <Account collapse={collapse} />

            {/* Mobile: Server status (Placeholder) */}
            <div className="d-block d-md-none">
                <div className="w-100 px-2 py-1">
                    <div className="box-shadow">
                        <div className="title-bar px-2">Server Status</div>
                        <div className="bg-light-gray p-1 bl-xs br-xs bb-xs">
                            <img src={ServerOnline} alt="Server is online" />
                            <div className="text-muted">
                                *Approximation only
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Oberin Calendar */}
            <OberinCalendar />

            {/* Admin Menu */}
            <PrivateComponent roles={[Role.ADMIN]}>
                <div className="w-100 px-2 py-1">
                    <div className="box-shadow">
                        <div className="title-bar px-2">
                            <span>Admin Menu</span>
                        </div>
                        <nav className="bg-light-gray p-1 bl-xs br-xs bb-xs">
                            <ul className="sidebar-list">
                                <NavigationLink collapse={collapse} link="/admin/dashboard" label="Dashboard" />
                                <NavigationLink collapse={collapse} link="/admin/users" label="Users" />
                                <NavigationLink collapse={collapse} link="/admin/roles" label="Roles" />
                                <NavigationLink collapse={collapse} link="/admin/forums" label="Forums" />
                                <NavigationLink collapse={collapse} link="/admin/announcements" label="Announcements" />
                                <NavigationLink collapse={collapse} link="/admin/installationGuides" label="Installation Guides" />
                                <NavigationLink collapse={collapse} link="/admin/settings" label="Settings" />
                            </ul>
                        </nav>
                    </div>
                </div>
            </PrivateComponent>

            {/* Moderator menu */}
            <PrivateComponent rules={["isAllowedToModerate"]}>
                <div className="w-100 px-2 py-1">
                    <div className="box-shadow">
                        <div className="title-bar px-2">
                            <span>Moderator Menu</span>
                        </div>
                        <div className="bg-light-gray p-1 bl-xs br-xs bb-xs">
                            <ul className="sidebar-list">
                                <NavigationLink collapse={collapse} link="/moderator/moveRequests" label="Move requests" />
                            </ul>
                        </div>
                    </div>
                </div>
            </PrivateComponent>

            {/* Navigation */}
            <div className="w-100 px-2 py-1">
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <span>Navigation</span>
                    </div>
                    <nav className="bg-light-gray p-1 bl-xs br-xs bb-xs">
                        <ul className="sidebar-list">
                            <NavigationLink collapse={collapse} link="/home" label="Home" />
                            <NavigationLink collapse={collapse} link="/forum" label="Forum" />
                            <PrivateComponent roles={[Role.USER, Role.ADMIN]}>
                                <NavigationLink collapse={collapse} link='/members' label='Members' />
                            </PrivateComponent>
                            <NavigationLink collapse={collapse} link="/download" label="Download" />
                            <NavigationLink collapse={collapse} link="/contact" label="Contact" />
                            <NavigationLink collapse={collapse} link="/moonCycle" label="Moon Cycle" />
                            <NavigationLink collapse={collapse} link="/screenshots" label="Screenshots" />
                            <NavigationLink collapse={collapse} link="https://oberin.fandom.com/wiki/OberinWiki" label="Oberin Wiki" direct />
                            <NavigationLink collapse={collapse} link="/termsOfService" label="Terms of Service" />
                        </ul>
                    </nav>
                </div>
            </div>

            {/* RTQ News */}
            <div className="w-100 px-2 py-1">
                <div className="box-shadow">
                    <div className="title-bar px-2">RTQ News</div>
                    <div className="recent-post-container bg-light-gray bl-xs br-xs bb-xs">
                        <RecentPosts url="forumCategory/rtq" />
                    </div>
                </div>
            </div>

            {/* Latest posts */}
            <div className="w-100 px-2 pt-1">
                <div className="box-shadow">
                    <div className="title-bar px-2">Last Forum Posts</div>
                    <div className="recent-post-container bg-light-gray bl-xs br-xs bb-xs">
                        <LatestPosts />
                    </div>
                </div>
            </div>

            {/* Mobile: Player Stories */}
            <div className="d-block d-md-none">
                <div className="w-100 px-2 pt-2 pb-1">
                    <div className="box-shadow">
                        <div className="title-bar px-2">Player Stories</div>
                        <div className="recent-post-container bg-light-gray bl-xs br-xs bb-xs">
                            <RecentPosts url="forumCategory/story" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile: Player Art */}
            <div className="d-block d-md-none">
                <div className="w-100 px-2 py-1">
                    <div className="box-shadow">
                        <div className="title-bar px-2">Player Art</div>
                        <div className="recent-post-container bg-light-gray bl-xs br-xs bb-xs">
                            <RecentPosts url="forumCategory/art" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile: Random screenshot (Placeholder) */}
            <div className="d-block d-md-none">
                <div className="w-100 px-2 py-1">
                    <div className="box-shadow">
                        <div className="title-bar px-2">Random Screenshot</div>
                        <div className="bg-light-gray p-1 bl-xs br-xs bb-xs" style={{ height: 150 }}>
                        </div>
                    </div>
                </div>   
            </div>

            {/* Mobile: Social media */}
            <div className="d-block d-md-none">
                <div className="w-100 px-2 py-1">
                    <div className="box-shadow">
                        <div className="title-bar px-2">Social media</div>
                        <div className="bg-light-gray p-1 bl-xs br-xs bb-xs d-flex flex-column">
                            <div className="d-flex align-items-center clickable" onClick={handleDiscordClicked}>
                                <img className='discord-image img-fluid' alt='discord invite' src={DiscordIcon} />
                                <span className='ml-2'>Discord Invite</span>
                            </div>
                            <div className="d-flex align-items-center clickable" onClick={handleFacebookClicked}>
                                <img className='discord-image img-fluid' alt='facebook page' src={FacebookIcon} />
                                <span className='ml-2'>Facebook page</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
});

export default Sidebar;
import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { UserProps, CharacterProps } from './Users';

import api from '../../../api/api';

interface Props {
    account: UserProps;
    character: CharacterProps;
    updateAccounts: (accountId: string, characterId: string, deleted?: boolean) => void;
};

const VerificationAccount: React.FC<Props> = ({ account, character, updateAccounts }) => {
    const [display, setDisplay] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const toggleDisplay = useCallback(() => {
        setDisplay(currentDisplay => !currentDisplay);
    }, []);

    const handleVerifyCharacterClicked = useCallback(() => {
        if (saving === true) {
            return;
        }

        setSaving(true);

        api.post("user/admin/character/verify", {
            accountId: account.id,
            characterId: character.id
        })
        .then(() => {
            updateAccounts(account.id, character.id);
            setDisplay(false);
        })
        .catch((error) => {
            alert("Failed to verify the character.");
            console.log(error);
            console.log(error.response);
            console.log(error.response.data);
        })
        .finally(() => {
            setSaving(false);
        });
    }, [saving, updateAccounts, account, character]);
    
    const handleDenyClicked = React.useCallback(() => {
        if (saving === true) {
            return;
        }

        setSaving(true);

        api.post("user/admin/character/deny", {
            accountId: account.id,
            characterId: character.id
        })
        .then(() => {
            updateAccounts(account.id, character.id, true);
            setDisplay(false);
        })
        .catch((error) => {
            alert("Failed to deny the character request.");
            console.log(error);
            console.log(error.response);
            console.log(error.response.data);
        })
        .finally(() => {
            setSaving(false);
        });
    }, [saving, updateAccounts, account, character]);

    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                        { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                    </Button>
                    <span className="ml-2 flex-grow-1">
                        {account.username}
                    </span>
                </div>

                {/* Detailed information */}
                { display ? (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Username
                        </span>
                        <span>
                            {account.username}
                        </span>
                        <span className="mt-2 font-small">
                            Character
                        </span>
                        <span>
                            {character.name}
                        </span>
                        <div className="mt-2 d-flex">
                            <Button disabled={saving} variant="contained" className="action-button dark-red-button rounded-0" onClick={handleVerifyCharacterClicked}>
                                <i className="fas fa-check"></i>
                            </Button>
                            <Button disabled={saving} variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleDenyClicked}>
                                <i className='fas fa-times' />
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-6">
                    <span>{account.username}</span>
                </div>
                <div className="d-flex align-items-center col-4">
                    <span>{character.name}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    <Button disabled={saving} variant="contained" className="action-button dark-red-button rounded-0" onClick={handleVerifyCharacterClicked}>
                        <i className="fas fa-check"></i>
                    </Button>
                    <Button disabled={saving} variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleDenyClicked}>
                        <i className='fas fa-times' />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default VerificationAccount;
import * as React from 'react';
import { Pagination } from '@material-ui/lab';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import api from '../../api/api';

import Input from '../../components/MUI/Input';
import { useHistory } from 'react-router-dom';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

const debounceFunction = () => {return;};
const debounce = AwesomeDebouncePromise(debounceFunction, 350);

interface IAccount {
    id: string,
    username: string
}

interface IData {
    currentPage: number,
    totalPages: number,
    accounts: IAccount[]
}

const AccountList: React.FunctionComponent = () => {
    const [page, setPage] = React.useState<number>(1);
    const [order, setOrder] = React.useState<number>(0);
    const [search, setSearch] = React.useState<string>("");
    const [inputValue, setInputValue] = React.useState<string>("");
    const [data, setData] = React.useState<null | IData>(null);

    const history = useHistory();
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get('/members/accounts/' + order + '/' + page + '?search=' + search)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.log('Error: ', error, error.response);
        });
    }, [uid, order, page, search]);

    /***************************************************
     * Event handlers
     ***************************************************/
     const handlePageChanged = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleOrderClicked = () => {
        setOrder((currentOrder) => {
            if (currentOrder === 0) {
                return 1;
            }

            return 0;
        });

        setPage(1);
    };

    const handleSearchChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // Save the value
        let value = event.target.value;

        // Update the value to display on the frontend
        setInputValue(value);

        // Await
        await debounce();

        // Update the search value
        setSearch(value);
        setPage(1);
    };

    const handleLinkClicked = React.useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
        event?.preventDefault();
        history.push(link);
    }, [history]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (data === null) {
        content = (
            <div className='alert alert-info rounded-0 mb-0'>
                Loading accounts...
            </div>
        );
    } else if (data.accounts.length === 0) {
        content = (
            <div className='alert alert-warning rounded-0 mb-0'>
                No accounts found.
            </div>
        );
    } else {
        let items = data.accounts.map((account: IAccount) => {
            const link = '/members/account/' + account.id;
            return (
                <div key={account.id} className="alternate-table-entry p-2">
                    <a href={link} className='forum-category-link w-100' onClick={(event) => handleLinkClicked(event, link)} >
                        {account.username}
                    </a>
                    
                </div>
            );
        });

        content = (
            <>
                {/* Content */}
                {items}

                {/* Pagination */}
                <div className="pagination-container w-100 mt-2">
                    <Pagination
                        page={page}
                        count={data.totalPages}
                        onChange={handlePageChanged}
                        showFirstButton
                        showLastButton
                        variant='outlined'
                        shape='rounded'
                    />
                </div>
            </>
        )
    }

    // Set icon classes based on order
    let iconClasses = ['fas', 'mr-2'];
    if (order) {
        iconClasses.push('fa-arrow-up');
    } else {
        iconClasses.push('fa-arrow-down');
    }

    return (
        <>
            {/* Search */}
            <Input
                id="search-value"
                label="Search by username"
                className="mb-2"
                value={inputValue} 
                onChange={handleSearchChanged} 
            />

            {/* Header */}
            <div className="title-bar dark px-2">
                <div className="row w-100">
                    <div className="d-flex align-items-center col-12 clickable" onClick={handleOrderClicked}>
                        <i className={iconClasses.join(' ')} />
                        <span>Account name</span>
                    </div>
                </div>
            </div>

            {/* Content */}
            {content}
        </>
    );
};

export default AccountList;

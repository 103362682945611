import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../api/api';

import { IVersionGuide } from './ManageGuideVersionEdit';
import DisplayVersion from './DisplayVersion';
import { Button } from '@material-ui/core';

interface IParams {
    id?: string;
    versionId?: string;
}

const InstallationGuideDisplay: React.FunctionComponent = () => {
    const [data, setData] = React.useState<IVersionGuide | null>(null);
    const [error, setError] = React.useState<boolean>(false);

    const history = useHistory()
    const { id, versionId } = useParams<IParams>();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        if (!id || !versionId) {
            history.push('/download');
            return;
        }
    
        setError(false);
        api.get('installationGuideVersion/display/' + id + '/' + versionId)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.log('Error loading the display installation guide', error, error.response);
            setError(true);
        });
    }, [id, versionId, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleBackClicked = React.useCallback(() => {
        history.push('/download');
    }, [history]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (error === true) {
        content = (
            <div className="w-100 px-2">
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-danger rounded-0 mb-0">
                            Failed to load the installation guide.
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (data === null) {
        content = (
            <div className="w-100 px-2">
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-info rounded-0 mb-0">
                            Loading the installation guide...
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        content = <DisplayVersion version={data} />;
    }

    return (
        <React.Fragment>
            <div className="w-100 px-2 mb-2">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button onClick={handleBackClicked} variant='contained' className='w-100 dark-red-button text-normal rounded-0'>
                            <i className="fas fa-step-backward mr-2"></i>
                            <span>Go back</span>
                        </Button>
                    </div>
                </div>
            </div>
            {content}
        </React.Fragment>
    );
};

export default InstallationGuideDisplay;

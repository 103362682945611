import { Modal } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../api/api';
import Input from '../../MUI/Input';

import SaveButton, { saveStatuses, saveTypes } from '../../MUI/SaveButton';
import { ForumCategoryProps } from './Categories';

interface Props {
    forumId: string;
    forumName: string;
    forumCategory: ForumCategoryProps;
    maximum: number;
    closePopup: () => void;
    updateForum: (forumCategories: ForumCategoryProps[]) => void;
};

interface ErrorProps {
    status: boolean,
    message: string
};

const CategoryModal: React.FC<Props> = ({ forumId, forumName, forumCategory, maximum, closePopup, updateForum }) => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [name, setName] = useState<string>(forumCategory.name);
    const [description, setDescription] = useState<string>(forumCategory.description);
    const [sortOrder, setSortOrder] = useState<number>(forumCategory.sortOrder);
    const [error, setError] = useState<ErrorProps>({ status: false, message: "" });
    
    /***************************************************
     * EZvent handlers
     ***************************************************/
    const handleSortOrderChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSortOrder(Number(event.target.value));
    }, []);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    const updateForumCategoryAsync = useCallback(() => {
        let model = {
            id: forumCategory.id,
            forumId: forumId,
            name: name,
            description: description,
            sortOrder: sortOrder
        };

        api.put("forumCategory/admin/" + model.id, model)
        .then((response) => {
            updateForum(response.data);
            closePopup();
        })
        .catch(() => {
            setError({ status: true, message: "Failedto update the forum category." });
            setStatus(saveStatuses.ERROR);
        });
    }, [forumId, forumCategory.id, name, description, sortOrder, updateForum, closePopup]);

    const createForumCategoryAsync = useCallback(() => {
        let model = {
            id: null,
            forumId: forumId,
            name: name,
            description: description,
            sortOrder: sortOrder
        };

        api.post("forumCategory/admin", model)
        .then((response) => {
            updateForum(response.data);
            closePopup();
        })
        .catch((error) => {
            setError({ status: true, message: "Failed to create the forum category." });
            setStatus(saveStatuses.ERROR);
        });
    }, [forumId, name, description, sortOrder, updateForum, closePopup]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Verify name
            if (name.trim() === "") {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify description
            if (description.trim() === "") {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify sort order
            if (isNaN(sortOrder) || sortOrder < 1 || sortOrder > maximum) {
                setError({ status: true, message: "" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            if (forumCategory.id) {
                updateForumCategoryAsync();
            }   
            else {
                createForumCategoryAsync();
            }
        }   
    }, [description, status, name, sortOrder, maximum, forumCategory.id, updateForumCategoryAsync, createForumCategoryAsync]);

    /***************************************************
     * Render
     ***************************************************/
    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby={ forumCategory.id ? "edit-forum-category-popup" : "create-forum-category-popup" }
            aria-describedby={ forumCategory.id ? "Edit category" : "Create a new category" }
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span className="w-100 text-overflow">{forumName}: {forumCategory.id ? "Edit category": "Create category"}</span>
                </div>
                <div className="d-flex flex-column p-2">
                    {/* Name */}
                    <Input
                        id="forum-category-name"
                        label="Name"
                        className="mt-2"
                        value={name} 
                        error={error.status && name.trim() === ""}
                        helperText="Please fill in a name"
                        disabled={status !== saveStatuses.NEUTRAL}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setName(event.target.value); }} 
                        onKeyDown={handleKeyDown}
                    />

                    {/* Description */}
                    <Input
                        id="forum-category-description"
                        label="Description"
                        className="mt-2"
                        value={description} 
                        error={error.status && description.trim() === ""}
                        helperText="Please fill in a description"
                        disabled={status !== saveStatuses.NEUTRAL}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setDescription(event.target.value); }} 
                        onKeyDown={handleKeyDown}
                    />

                    {/* Sort order */}
                    <Input
                        id="forum-sort-order"
                        label="Sort order"
                        className="mt-2"
                        type="number"
                        value={sortOrder} 
                        error={error.status && (sortOrder < 1 || sortOrder > maximum)}
                        helperText={"Sort order must be between 1 and " + maximum}
                        disabled={status !== saveStatuses.NEUTRAL}
                        onChange={handleSortOrderChanged} 
                        onKeyDown={handleKeyDown}
                    />

                    { error.status && error.message.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.message}
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CategoryModal;
import { Modal } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input from '../../components/MUI/Input';

import api from '../../api/api';
import { validateEmail } from '../../utilities/validateEmail';

import SaveButton, { saveTypes, saveStatuses } from '../../components/MUI/SaveButton';

interface ErrorProps {
    email: boolean,
    webRequest: string | null
};

const RecoverPassword: React.FC<{}> = () => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<ErrorProps>({ email: false, webRequest: null });
    const [submitted, setSubmitted] = useState<boolean>(false);
    const history = useHistory();

    const closePopup = useCallback(() => {
        history.push("/home");
    }, [history]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Initialise error data
            let errorData = {
                email: false,
                webRequest: null
            };

            // Verify email address
            if (!validateEmail(email)) {
                errorData.email = true;
            }

            // Verify form errors
            setError(errorData);
            if (errorData.email) {
                setStatus(saveStatuses.ERROR);
                return;
            }

            api.post('/account/recoverPassword', { email })
            .then(() => {
                setSubmitted(true);
                setStatus(saveStatuses.NEUTRAL);
            })
            .catch((error) => {
                setError({ email: false, webRequest: "Error sending request. Please try again later." });
                setStatus(saveStatuses.ERROR);
            });
        }
    }, [status, email]);

    let content = null;
    if (submitted) {
        content = (
            <React.Fragment>
                <div className="alert alert-info mb-0 rounded-0">
                    Please check your email for further instructions.
                </div>
            </React.Fragment>
        );
    } else {
        content = (
            <React.Fragment>
                <Input 
                    id="login-popup-email"
                    label="Email"
                    className="mt-2"
                    value={email} 
                    error={error.email}
                    helperText={error.email ? "Please fill in a correct email" : undefined}
                    onKeyDown={handleKeyDown}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)} />

                    { error.webRequest && error.webRequest.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.webRequest}
                        </div>
                    ) : null }

                <div className="w-50 ml-auto pl-2">
                    <SaveButton
                        type={saveTypes.CONTROLLED}
                        status={status}
                        setStatus={setStatus}
                        showFeedback
                        hideCancel
                        saveClasses={["dark-red-button"]}
                        saveContent="Submit"
                        savingContent={(<><i className="fas fa-sync fa-spin mr-2" /> Submitting...</>)}
                        savedContent="Submitted"
                        errorContent={(<><i className="fas fa-times mr-2" /> Error</>)}
                    />
                </div>
            </React.Fragment>
        );
    }

    return (
        <Modal 
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby="reover-password-popup" 
            aria-describedby="Recover your password" 
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>Recover your password</span>
                </div>
                <div className="d-flex flex-column p-2">
                    {content}
                </div>
            </div>
        </Modal>
    );
};

export default RecoverPassword;
import * as React from 'react';
import Editor from '../Editor/Editor';
import Input from '../MUI/Input';
import { IEditPart } from './ManageGuideVersionEdit';

interface IEditSubPartProps {
    partIndex: number;
    subPartIndex: number;
    part: IEditPart;
    edit: IEditPart | null;
    displayIcons: boolean;
    onTitleChanged: (event: React.ChangeEvent<HTMLInputElement>, subPartIndex: number) => void;
    onUpClicked: (partIndex: number, subPartIndex: number) => void;
    onDownClicked: (partIndex: number, subPartIndex: number) => void;
    onDeleteClicked: (partIndex: number, subPartIndex: number) => void;
    onImageUploaded: (blobInfo: any, success: any, failure: any) => void;
}

const EditSubPart: React.FunctionComponent<IEditSubPartProps> = ({
    partIndex,
    subPartIndex,
    part,
    edit,
    displayIcons,
    onTitleChanged,
    onUpClicked,
    onDownClicked,
    onDeleteClicked,
    onImageUploaded
}) => {
    /***************************************************
     * Event handlers
     ***************************************************/
    const handleTitleChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        onTitleChanged(event, subPartIndex)
    }, [subPartIndex, onTitleChanged]);

    const handleUpClicked = React.useCallback(() => {
        onUpClicked(partIndex, subPartIndex);
    }, [subPartIndex, partIndex, onUpClicked]);

    const handleDownClicked = React.useCallback(() => {
        onDownClicked(partIndex, subPartIndex);
    }, [subPartIndex, partIndex, onDownClicked]);

    const handleDeleteClicked = React.useCallback(() => {
        if (window.confirm("Are you sure you want to delete this subpart?")) {
            onDeleteClicked(partIndex, subPartIndex);
        }
    }, [subPartIndex, partIndex, onDeleteClicked]);

    /***************************************************
     * Render
     ***************************************************/
    let iconContent = null;
    if (displayIcons) {
        iconContent = (
            <div className="ml-2 justify-content-end d-flex flex-grow-1">
                <i className='mr-2 fas fa-chevron-up clickable' onClick={handleUpClicked} />
                <i className='mr-2 fas fa-chevron-down clickable' onClick={handleDownClicked} />
                <i className='fas fa-trash clickable' onClick={handleDeleteClicked}  />
            </div>
        );
    }

    let titleContent = null;
    if (edit) {
        titleContent = (
            <div className="bg-light-gray w-100 my-2 px-2 py-1">
                <Input
                    id={'part-title-' + subPartIndex}
                    value={edit.title}
                    label='Sub title'
                    onChange={handleTitleChanged}
                />
            </div>
        );
    } else {
        titleContent = <span>{part.title}</span>;
    }

    let descriptionContent = null;
    if (edit) {
        descriptionContent = (
            <div className="py-2 w-100 h-100">
                <Editor
                    initialValue={part.content}
                    minHeight={150}
                    height={300}
                    id={'guide-part-' + partIndex + '-' + subPartIndex}
                    onImageUpload={onImageUploaded}
                />
            </div>
        );
    } else {
        descriptionContent = <div className='dangerous-container' dangerouslySetInnerHTML={{ __html: part.content }} />;
    }

    return (
        <React.Fragment>
            <div className="title-bar px-2 dark">
                {titleContent}
                {iconContent}
            </div>
            <div className='alternate-table-entry bg-white px-2 mb-2'>
                {descriptionContent}
            </div>
        </React.Fragment>
    );
};

export default EditSubPart;

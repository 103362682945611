import { Button } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ICharacter } from '../../containers/Members/CharacterList';

interface ICharacterItemProps {
    character: ICharacter
}

const CharacterItem: React.FunctionComponent<ICharacterItemProps> = ({ character }) => {
    const [display, setDisplay] = React.useState<boolean>(false);
    const link = '/members/character/' + character.id;

    const history = useHistory();

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleDisplayClicked = () => {
        setDisplay(!display);
    }

    const handleLinkClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event?.preventDefault();
        history.push(link);
    }

    /***************************************************
     * Render
     ***************************************************/
    return (
        <div className='alternate-table-entry p-2'>
            {/* Mobile */}
            <div className="d-flex flex-column d-md-none">
                <div className="d-flex align-items-center">
                    <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={handleDisplayClicked}>
                        { display ? <i className='fas fa-chevron-down' /> : <i className='fas fa-chevron-right' /> }
                    </Button>                    
                    <a className='forum-category-link flex-grow-1 ml-2' href={link} onClick={handleLinkClicked}>
                        {character.name}
                    </a>
                </div>
                { display === false ? null : (
                    <>
                        <span className="mt-3 font-small">Account</span>
                        <span>{character.accountId ? character.accountName : ''}</span>
                    </>
                ) }
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-6">
                    <a className='w-100 forum-category-link' href={link} onClick={handleLinkClicked}>
                        {character.name}
                    </a>
                </div>
                <div className="d-flex align-items-center col-6">
                    {character.accountId ? character.accountName : ""}
                </div>
            </div>
        </div>
    );
};

export default CharacterItem;

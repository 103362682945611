import React from 'react';

import BleedingMoon from './Moons/BleedingMoon';
import BlueMoon from './Moons/BlueMoon';
import DancingMoon from './Moons/DancingMoon';
import FightingMoon from './Moons/FightingMoon';
import GrowingMoon from './Moons/GrowingMoon';
import HarvestMoon from './Moons/HarvestMoon';
import LuckyMoon from './Moons/LuckyMoon';
import RedMoon from './Moons/RedMoon';
import SleepingMoon from './Moons/SleepingMoon';
import WakingMoon from './Moons/WakingMoon';

interface Props {
    state: number,
    type: string,
    className?: undefined | string
};

const Moon: React.FC<Props> = ({ state, type, className }) => {
    switch(type) {
        case "Bleeding Moon":
            return <BleedingMoon state={state} className={className} />;
        case "Blue Moon":
            return <BlueMoon state={state} className={className} />;
        case "Dancing Moon":
            return <DancingMoon state={state} className={className} />;
        case "Fighting Moon":
            return <FightingMoon state={state} className={className} />;
        case "Growing Moon":
            return <GrowingMoon state={state} className={className} />;
        case "Lucky Moon":
            return <LuckyMoon state={state} className={className} />;
        case "Harvest Moon":
            return <HarvestMoon state={state} className={className} />;
        case "Red Moon":
            return <RedMoon state={state} className={className} />;
        case "Sleeping Moon":
            return <SleepingMoon state={state} className={className} />;
        case "Waking Moon":
            return <WakingMoon state={state} className={className} />;
        default:
            return null;
    }
};

export default Moon;

// import React from 'react';



// const Moon = () => {

// }

import { Button } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';

import CreateRole from '../../components/Admin/ManageRoles/CreateRole';
import EditRole from '../../components/Admin/ManageRoles/EditRole';
import RoleItem from '../../components/Admin/ManageRoles/RoleItem';
import { Role } from '../../utilities/roles';
import api from '../../api/api';

export interface AdminRoleProps {
    id: string;
    name: string;
    isStatic: boolean;
    isAllowedToModerate: boolean;
    amountOfAccounts: number;
}

interface PopupData {
    status: boolean;
    type: 'create' | 'edit';
    role: AdminRoleProps | null;
}

const AdminRoles: React.FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [roles, setRoles] = useState<AdminRoleProps[]>([]);
    const [popupData, setPopupData] = useState<PopupData>({ status: false, role: null, type: 'edit' });

    /*********************************************************************
     * Startup
     *********************************************************************/
    useEffect(() => {
        api.get('role/admin')
        .then((response) => {
            setRoles(response.data);
        })
        .catch((error) => {
            console.log("Error fetching admin roles", error, error.response);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    /*********************************************************************
     * Event handlers
     *********************************************************************/
     const closePopup = useCallback(() => {
        setPopupData({ role: null, status: false, type: 'edit' });
    }, []);

    const handleCreateRoleClicked = useCallback(() => {
        setPopupData({
            role: null,
            status: true,
            type: 'create'
        });
    }, []);

    const handleEditRoleClicked = useCallback((id: string) => {
        let role = roles.find(r => r.id === id);
        if (role) {
            setPopupData({
                role: role,
                status: true,
                type: 'edit'
            });
        }
    }, [roles]);

    const handleDeleteRoleClicked = useCallback((id: string) => {
        let confirmation = window.confirm("Are you sure you want to delete this role?");
        if (confirmation) {
            api.delete("role/" + id)
            .then(() => {
                setRoles((currentRoles) => {
                    let role = currentRoles.find(r => r.id === id);
                    if (!role) {
                        return currentRoles;
                    }

                    let amount = role ? role.amountOfAccounts : 0;

                    let updatedRoles = currentRoles.filter(r => r.id !== id);
                    return updatedRoles.map((updatedRole) => {
                        if (updatedRole.id === Role.USER) {
                            updatedRole = {
                                ...updatedRole,
                                amountOfAccounts: updatedRole.amountOfAccounts + amount
                            };
                        }

                        return updatedRole;
                    });
                });
            })
            .catch((error) => {
                console.log("Failed to delete the role", error, error.response);
            });
        }
    }, []);

    /*********************************************************************
     * Render
     *********************************************************************/
    let content = null;
    if (loading) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading roles...
            </div>
        );
    }
    else if (roles.length === 0) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                No roles found.
            </div>
        );
    }
    else {
        content = roles.map((role, index) => (
            <RoleItem
                key={index}
                role={role}
                onEditClicked={handleEditRoleClicked}
                onDeleteClicked={handleDeleteRoleClicked}
            />
        ));
    }

    let modal = null;
    if (popupData.status) {
        if (popupData.type === 'edit' && popupData.role) {
            modal = (
                <EditRole
                    role={popupData.role}
                    closePopup={closePopup}
                    setRoles={setRoles}
                />
            );
        }
        else if (popupData.type === 'create') {
            modal = (
                <CreateRole
                    roles={roles}
                    closePopup={closePopup}
                    setRoles={setRoles}
                />
            );
        }
    }

    return (
        <div id="admin" className="w-100 px-2">
            {modal}
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Manage the roles</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <Button variant="contained" className="dark-red-button rounded-0 text-normal w-100" onClick={handleCreateRoleClicked}>
                                <i className="fas fa-plus mr-2"></i> Create role
                            </Button>
                        </div>
                    </div>
                    <div className="mt-2 title-bar px-2 dark">
                        <div className="row w-100">
                            <div className="col-12 col-md-4">
                                <span>Role</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Is Static</span>
                            </div>
                            <div className='d-none d-md-flex col-2'>
                                <span>Is moderator</span>
                            </div>
                            <div className="d-none d-md-flex col-2">
                                <span>Accounts</span>
                            </div>
                            <div className="d-none d-md-flex justify-content-end col-2">
                                <span>Actions</span>
                            </div>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default AdminRoles;
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import AccountLogin from '../../components/Layout/Sidebar/Account/AccountLogin';

interface Props {

};

const HomeLogin: React.FC<Props> = () => {

    console.log("[HomeLogin] Render");

    const history = useHistory();
    const closePopup = useCallback(() => {
        history.push("/home");
    }, [history]);

    return (
        <AccountLogin open={true} closePopup={closePopup} />
    );
};

export default HomeLogin;
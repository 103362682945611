import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { JsxElement } from 'typescript';

export enum saveStatuses {
    NEUTRAL,
    CANCEL_CONFIRM,
    CANCEL_TRIGGERED,
    CANCELING,
    CANCELED,
    SAVE_TRIGGERED,
    SAVING,
    SAVED,
    ERROR
};

export enum saveTypes {
    DEFAULT,
    CONTROLLED,
    IMMEDIATE
};

interface Props {
    type?: saveTypes;
    status?: saveStatuses;
    hideCancel?: boolean;

    showFeedback?: boolean;
    feedbackTimeout?: number;

    setStatus?: (status: saveStatuses) => void;
    onSave?: (onSuccess: () => void, onError: () => void) => void;
    onCancel?: (onSuccess: () => void, onError: () => void) => void;
   
    disabled?: boolean;
    saveDisabled?: boolean;
    cancelDisabled?: boolean;

    errorClasses?: string[];
    savedClasses?: string[];
    savingClasses?: string[];
    saveClasses?: string[];
    cancelClasses?: string[];
    canceledClasses?: string[];
    cancelingClasses?: string[];
    confirmCancelClasses?: string[];
    disabledClasses?: string[];

    errorContent?: JsxElement | string | JSX.Element;
    savedContent?: JsxElement | string | JSX.Element;
    savingContent?: JsxElement | string | JSX.Element;
    saveContent?: JsxElement | string | JSX.Element;
    cancelContent?: JsxElement | string | JSX.Element;
    canceledContent?: JsxElement | string | JSX.Element;
    cancelingContent?: JsxElement | string | JSX.Element;
    confirmCancelContent?: JsxElement | string | JSX.Element;
    disabledContent?: JsxElement | string | JSX.Element;
};

const SaveButton: React.FC<Props> = (props) => {
    /********************************/
    /* LOCAL STATE & PROPS 
    /********************************/
    const [state, setState] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const intervalReference = useRef<any>(null);
    const mounted = useRef<boolean>(true);

    const {
        hideCancel,
        onSave,
        onCancel,
        showFeedback,
        feedbackTimeout,
        status,
        setStatus
    } = props;

    const timeout = feedbackTimeout ? feedbackTimeout : 2000;
    let type = saveTypes.DEFAULT;
    if (props.type === saveTypes.CONTROLLED && status !== undefined && setStatus) {
        type = saveTypes.CONTROLLED;
    } else if (props.type === saveTypes.IMMEDIATE) {
        type = saveTypes.IMMEDIATE;
    }

    // Set disabled properties
    let disabled = false;
    const saveDisabled = props.saveDisabled ? props.saveDisabled : false;
    const cancelDisabled = props.cancelDisabled ? props.cancelDisabled : false;
    if (props.disabled || (saveDisabled && cancelDisabled)) {
        disabled = true;
    }

    /********************************/
    /* GLOBAL FUNCTION
    /********************************/
    const updateControlledStatus = useCallback((updatedStatus) => {
        if (setStatus) {
            setStatus(updatedStatus);
        }
    }, [setStatus]);

    const updateStatus = useCallback((updatedStatus) => {
        switch(type) {
            case saveTypes.DEFAULT:
            case saveTypes.IMMEDIATE:
                setState(updatedStatus);
                break;
            case saveTypes.CONTROLLED:
                updateControlledStatus(updatedStatus);
                break;
            default:
                break;
        }
    }, [type, updateControlledStatus]);

    useEffect(() => {
        return () => {
            if (intervalReference.current) {
                clearInterval(intervalReference.current);
            }

            if (mounted.current) {
                mounted.current = false;
            }
        }
    }, []);

    const onError = useCallback(() => {
        if (!mounted.current) {
            return;
        }

        if (showFeedback) {
            updateStatus(saveStatuses.ERROR);
            if (intervalReference.current) {
                clearInterval(intervalReference.current);
            }

            intervalReference.current = setTimeout(() => {
                updateStatus(saveStatuses.NEUTRAL);
            }, timeout);
        } else {
            updateStatus(saveStatuses.NEUTRAL);
        }
    }, [updateStatus, showFeedback, timeout]);

    useEffect(() => {
        if (type !== saveTypes.CONTROLLED) {
            return;
        }   

        if (status === saveStatuses.SAVED || status === saveStatuses.CANCELED || status === saveStatuses.ERROR) {
            if (showFeedback === false) {
                updateStatus(saveStatuses.NEUTRAL);
            } 
            else {
                if (intervalReference.current) {
                    clearInterval(intervalReference.current);
                }

                intervalReference.current = setTimeout(() => {
                    updateStatus(saveStatuses.NEUTRAL);
                }, timeout);
            }
        }
    }, [type, status, updateStatus, showFeedback, timeout]);

    /********************************/
    /* NEUTRAL FUNCTIONS 
    /********************************/
    const handleCancelClicked = useCallback(() => {
        updateStatus(saveStatuses.CANCEL_CONFIRM);
    }, [updateStatus]);

    /********************************/
    /* CANCEL FUNCTIONS 
    /********************************/
    const onCancelSuccess = useCallback(() => {
        if (!mounted.current) {
            return;
        }

        if (showFeedback) {
            updateStatus(saveStatuses.CANCELED);

            if (intervalReference.current) {
                clearInterval(intervalReference.current);
            }

            intervalReference.current = setTimeout(() => {
                updateStatus(saveStatuses.NEUTRAL);
            }, timeout);
        } else {
            updateStatus(saveStatuses.NEUTRAL);
        }
    }, [updateStatus, showFeedback, timeout]);

    const handleNeutralClicked = useCallback(() => {
        updateStatus(saveStatuses.NEUTRAL);
    }, [updateStatus]);

    const handleConfirmCancelClicked = useCallback(() => {
        switch(type) {
            case saveTypes.CONTROLLED:
                updateStatus(saveStatuses.CANCEL_TRIGGERED);
                break;
            case saveTypes.IMMEDIATE:
                if (onCancel) {
                    onCancel(onCancelSuccess, onError);
                }
                
                break;
            default:
                updateStatus(saveStatuses.CANCELING);
                if (onCancel) {
                    onCancel(onCancelSuccess, onError);
                }
                break;
        }
    }, [updateStatus, type, onCancel, onError, onCancelSuccess]);

    /********************************/
    /* SPECIAL SAVE FUNCTIONS 
    /********************************/
    const onSaveSuccess = useCallback(() => {
        if (!mounted.current) {
            return;
        }

        if (showFeedback) {
            updateStatus(saveStatuses.SAVED);
            if (intervalReference.current) {
                clearInterval(intervalReference.current);
            }

            intervalReference.current = setTimeout(() => {
                updateStatus(saveStatuses.NEUTRAL);
            }, timeout);
        } else {
            updateStatus(saveStatuses.NEUTRAL);
        }
    }, [updateStatus, showFeedback, timeout]);

    const handleSaveClicked = useCallback(() => {
        switch(type) {
            case saveTypes.CONTROLLED:
                updateStatus(saveStatuses.SAVE_TRIGGERED);
                break;
            case saveTypes.IMMEDIATE:
                if (onSave) {
                    onSave(onSaveSuccess, onError);
                }
                break;
            default:
                updateStatus(saveStatuses.SAVING);
                if (onSave) {
                    onSave(onSaveSuccess, onError);
                }
                break;
        }
    }, [updateStatus, type, onSave, onSaveSuccess, onError]);

    /********************************/
    /* RENDER 
    /********************************/
    if (disabled === true) {
        let disabledClasses = [];
        if (props.disabledClasses) {
            disabledClasses = [ ...props.disabledClasses, "rounded-0", "flex-grow-1" ];
        } else {
            disabledClasses = ["bg-disabled", "text-white", "rounded-0", "flex-grow-1"];
        }

        return (
            <div className="d-flex w-100">
                <Button variant="contained" className={disabledClasses.join(' ')} disabled>
                    { props.disabledContent ? props.disabledContent : (
                        <>
                            <i className="fas fa-ban mr-2"></i> 
                            Not available
                        </>
                    ) }
                </Button>
            </div>
        )
    }

    // Initialise classes
    let cancelClasses = [];
    let confirmCancelClasses = [];
    let cancelingClasses = [];
    let canceledClasses = [];
    let saveClasses = [];
    let savingClasses = [];
    let savedClasses = [];
    let errorClasses = [];

    // Initialise content
    let content = null;

    // Get the current status
    let currentStatus = state;
    if (type === saveTypes.CONTROLLED && status !== undefined) {
        currentStatus = status;
    }

    switch(currentStatus) {
        /* NEUTRAL STATUS */
        case saveStatuses.NEUTRAL:
            cancelClasses = [];
            if (props.cancelClasses) {
                cancelClasses = [ ...props.cancelClasses, "rounded-0" ];
            } else {
                cancelClasses = ["text-white", "rounded-0"];

                if (cancelDisabled) {
                    cancelClasses.push("bg-disabled");
                } else {
                    cancelClasses.push("bg-warning");
                }
            }

            saveClasses = [];
            if (props.saveClasses) {
                saveClasses = [ ...props.saveClasses, "rounded-0", "flex-grow-1" ];
            } else {
                saveClasses = ["text-white", "rounded-0", "flex-grow-1"];

                if (saveDisabled) {
                    saveClasses.push("bg-disabled");
                } else {
                    saveClasses.push("bg-success")
                }
            }

            content = (
                <div className="d-flex w-100">
                    <ButtonGroup className="w-100">
                        { hideCancel ? null : (
                            <Button variant="contained" disabled={cancelDisabled} className={cancelClasses.join(' ')} onClick={handleCancelClicked}>
                                { props.cancelContent ? props.cancelContent : (
                                    <i className="fas fa-times"></i>
                                )}
                            </Button>
                        ) }
                        <Button variant="contained" disabled={saveDisabled} className={saveClasses.join(' ')} onClick={handleSaveClicked}>
                                { props.saveContent ? props.saveContent : (
                                    <><i className="fas fa-save mr-2"></i> Save</>
                                )}
                        </Button>
                    </ButtonGroup>
                </div>
            );
            break;

        /* CONFIRM THE CANCEL */
        case saveStatuses.CANCEL_CONFIRM:
            cancelClasses = [];
            if (props.cancelClasses) {
                cancelClasses = [ ...props.cancelClasses, "rounded-0" ];
            } else {
                cancelClasses = ["bg-danger", "text-white", "rounded-0"];
            }

            confirmCancelClasses = [];
            if (props.confirmCancelClasses) {
                confirmCancelClasses = [ ...props.confirmCancelClasses, "rounded-0", "flex-grow-1" ];
            } else {
                confirmCancelClasses = ["bg-warning", "text-white", "rounded-0", "flex-grow-1"];
            }

            content = (
                <div className="d-flex w-100">
                    <ButtonGroup className="w-100">
                        <Button variant="contained" className={cancelClasses.join(' ')} onClick={handleNeutralClicked}>
                            { props.cancelContent ? props.cancelContent : (
                                <i className="fas fa-times"></i>
                            )}
                        </Button>
                        <Button variant="contained" className={confirmCancelClasses.join(' ')} onClick={handleConfirmCancelClicked}>
                            {props.confirmCancelContent ? props.confirmCancelContent : "Confirm"}
                        </Button>
                    </ButtonGroup>
                </div>
            );
            break;

        /* FINISH CANCEL */
        case saveStatuses.CANCEL_TRIGGERED:
        case saveStatuses.CANCELING:
            if (props.cancelingClasses) {
                cancelingClasses = [ ...props.cancelingClasses, "rounded-0", "flex-grow-1" ];
            } else {
                cancelingClasses = ["bg-warning", "text-white", "rounded-0", "flex-grow-1"];
            }

            content = (
                <div className="d-flex w-100">
                    <Button variant="contained" className={cancelingClasses.join(' ')} disabled>
                        { props.cancelingContent ? props.cancelingContent : (
                            <>
                                <i className="fas fa-sync fa-spin mr-2"></i> Canceling...
                            </>
                        ) }
                    </Button>
                </div>
            )
             break;

        /* CANCELED */
        case saveStatuses.CANCELED:
            if (props.canceledClasses) {
                canceledClasses = [ ...props.canceledClasses, "rounded-0", "flex-grow-1" ];
            } else {
                canceledClasses = ["bg-info", "text-white", "rounded-0", "flex-grow-1"];
            }

            content = (
                <div className="d-flex w-100">
                    <Button variant="contained" className={canceledClasses.join(' ')} disabled>
                        { props.canceledContent ? props.canceledContent : (
                            <>
                                <i className="fas fa-check mr-2"></i> Canceled
                            </>
                        )}
                    </Button>
                </div>
            );
            break;

        /* SAVING */
        case saveStatuses.SAVE_TRIGGERED:
        case saveStatuses.SAVING:
            savingClasses = [];
            if (props.savingClasses) {
                savingClasses = [ ...props.savingClasses, "rounded-0", "flex-grow-1" ];
            } else {
                savingClasses = ["bg-success", "text-white", "rounded-0", "flex-grow-1"];
            }

            content = (
                <div className="d-flex w-100">
                    <Button variant="contained" className={savingClasses.join(' ')} disabled>
                        { props.savingContent ? props.savingContent : (
                            <>
                                <i className="fas fa-sync fa-spin mr-2"></i> Saving...
                            </>
                        ) }
                    </Button> 
                </div>
            );
            break;

        /* SAVED */
        case saveStatuses.SAVED:
            if (props.savedClasses) {
                savedClasses = [ ...props.savedClasses, "rounded-0", "flex-grow-1"];
            } else {
                savedClasses = ["bg-info", "text-white", "rounded-0", "flex-grow-1"];
            }

            content = (
                <div className="d-flex w-100">
                    <Button variant="contained" className={savedClasses.join(' ')} disabled>
                        { props.savedContent ? props.savedContent : (
                            <>
                                <i className="fas fa-check mr-2"></i> Saved
                            </>
                        )}
                    </Button>
                </div>
            );
            break;

        /* ERROR */
        case saveStatuses.ERROR:
            errorClasses = [];
            if (props.errorClasses) {
                errorClasses = [ ...props.errorClasses, "rounded-0", "flex-grow-1" ];
            } else {
                errorClasses = ["bg-danger", "text-white", "rounded-0", "flex-grow-1"];
            }
            content = (
                <div className="d-flex w-100">
                    <Button variant="contained" className={errorClasses.join(' ')} disabled>
                        { props.errorContent ? props.errorContent : (
                            <>
                                <i className="fas fa-times mr-2"></i> Error
                            </>
                        )}
                    </Button>
                </div>
            );
            break;
        default: 
            break;
    }

    return content;
};

export default SaveButton;
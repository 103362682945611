import { Button, Modal } from '@material-ui/core';
import * as React from 'react';
import { IMoveRequest } from '../../../containers/Moderator/ModeratorMoveRequests';
import { getDate, getTime } from '../../../utilities/date';

interface IMoveRequestInfoModalProps {
    moveRequest: IMoveRequest;
    onClose: () => void;
}

const MoveRequestInfoModal: React.FunctionComponent<IMoveRequestInfoModalProps> = ({ moveRequest, onClose}) => {
    /***************************************************
     * Event handlers
     ***************************************************/
    const handleViewTopicClicked = () => {
        window.open('/forum/category/' + moveRequest.categoryId + '/topic/' + moveRequest.forumTopicId, '_blank');
    }

    /***************************************************
     * Render
     ***************************************************/
    let requestedTime = new Date(moveRequest.requestedTime);
    return (
        <Modal
            open
            aria-label='Info of a move request'
            onClose={onClose}
            className='d-flex align-items-center justify-content-center'
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>Info move request</span>
                    <div className='d-flex ml-auto align-items-center justify-content-center'>
                        <i className='fas fa-times text-white clickable' onClick={onClose} />
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <span className="font-small">
                        Topic
                    </span>
                    <span>
                        {moveRequest.title}
                    </span>
                    <span className='mt-2 font-small'>
                        Current
                    </span>
                    <span>
                        {moveRequest.forumName}{' > '}{moveRequest.categoryName}
                    </span>
                    <span className="mt-2 font-small">
                        Suggested
                    </span>
                    <span>
                        {moveRequest.suggestedForumName}{' > '}{moveRequest.suggestedCategoryName}
                    </span>
                    <span className="mt-2 font-small">
                        Requested by
                    </span>
                    <span>
                        {moveRequest.requestedBy}
                    </span>
                    <span className='mt-2 font-small'>
                        Requested time
                    </span>
                    <span>
                        {getDate(requestedTime)}; {getTime(requestedTime)}
                    </span>
                    <span className='mt-2 font-small'>
                        Reason
                    </span>
                    <span>
                        {moveRequest.reason}
                    </span>
                    <div className="mt-2">
                        <Button variant='contained' className='w-100 dark-red-button text-white text-normal rounded-0' onClick={handleViewTopicClicked}>
                            View topic
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default MoveRequestInfoModal;

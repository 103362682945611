import React from 'react';
import { JsxElement } from 'typescript';
import TextField from '@material-ui/core/TextField';


interface Props {
    id: string,
    label: JsxElement | JSX.Element | string,
    value?: any;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    disabled?: boolean;
    error?: boolean;
    required?: boolean;
    className?: string;
    margin?: "none" | "dense" | "normal" | undefined;
    type?: string;
    variant?: "standard" | "filled" | "outlined" | undefined;
    helperText?: JsxElement | JSX.Element | string | undefined | null;
};

const Input: React.FC<Props> = (props) => {
    let className = "w-100 textfield-rounded-0";
    if (props.className) {
        className += " " + props.className;
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
    }

    return (
        <TextField
            id={props.id}
            label={props.label}
            value={props.value ? props.value : ""}
            onChange={props.onChange}
            onKeyDown={handleKeyDown}
            disabled={props.disabled}
            error={props.error}
            required={props.required}
            className={className}
            margin={props.margin ? props.margin : "dense"}
            type={props.type ? props.type : "text"}
            variant={props.variant ? props.variant : "outlined"}
            helperText={props.error ? props.helperText : null} />
    );
};

export default Input;
import * as React from 'react';
import { Button } from '@material-ui/core';

import { IUser } from './InstallationGuideUsers';

interface IInstallationGuideUserProps {
    user: IUser;
    onRemoveClicked: (id: string) => void;
}

const InstallationGuideUser: React.FunctionComponent<IInstallationGuideUserProps> = ({
    user,
    onRemoveClicked
}) => {
    const handleRemoveClicked = () => {
        onRemoveClicked(user.id);
    }

    /***************************************************
     * Render
     ***************************************************/
    return (
        <div className="alternate-table-entry px-2">
            <div className="d-flex row w-100">
                <div className="d-flex align-items-center col-8">
                    <span className='text-overflow'>{user.name}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end col-4">
                    <Button variant='contained' className='action-button dark-red-button rounded--0' onClick={handleRemoveClicked}>
                        <i className='fas fa-trash' />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InstallationGuideUser;

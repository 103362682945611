import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import App from './containers/App';
import reducer from './store/reducers/authReducer';

import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

import './css/main.scss';
import 'typeface-roboto';

import Auth from './containers/Auth';

const store = createStore(reducer, applyMiddleware(thunk));

// Create the application
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <Auth>
        <App />
      </Auth>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { getDate, getTime } from '../../../utilities/date';
import Checkbox from '../../MUI/Checkbox';
import { CharacterProps } from './Users';

interface Props {
    character: CharacterProps;
};

const UserCharacter: React.FC<Props> = ({ character }) => {
    const [display, setDisplay] = useState<boolean>(false);
    const toggleDisplay = useCallback(() => {
        setDisplay(currentDisplay => !currentDisplay);
    }, []);

    let verifiedOn : Date | null = null;
    if (character.verifiedOn) {
        verifiedOn = new Date(character.verifiedOn);
    }

    return (
        <div className="alternate-table-entry px-2">
            <div className="d-block w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                        { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                    </Button>
                    <span className="ml-2 flex-grow-1">
                        {character.name}
                    </span>
                </div>

                {/* Detailed information */}
                { display ? (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Character
                        </span>
                        <span>
                            {character.name}
                        </span>
                        <Checkbox
                            checked={character.isVerified}
                            disabled={true}
                            label="Verified"
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-red-checkbox"
                            onChange={() => {}} 
                        />
                        <Checkbox
                            checked={character.isPublic}
                            disabled={true}
                            label="Public"
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-red-checkbox"
                            onChange={() => {}} 
                        />
                        { character.verifiedBy && character.verifiedBy.trim() !== "" ? (
                            <React.Fragment>
                                <span className="mt-2 font-small">
                                    Verified by
                                </span>
                                <span>
                                    {character.verifiedBy}
                                </span>
                            </React.Fragment>
                        ) : null }
                        { verifiedOn ? (
                            <React.Fragment>
                                <span className="mt-2 font-small">
                                    Verified on
                                </span>
                                <span>
                                    {getDate(verifiedOn)}, {getTime(verifiedOn)}
                                </span>
                            </React.Fragment>
                        ) : null }
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default UserCharacter;
import { Button } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../api/api';
import InstallationGuide from './InstallationGuide';

export interface IInstallationGuide {
    id: string;
    name: string;
    description: string;
    isEnabled: boolean;
    sortOrder: number;
}

const InstallationGuides: React.FunctionComponent = () => {
    const [installationGuides, setInstallationGuides] = React.useState<IInstallationGuide[] | null>(null);

    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get<IInstallationGuide[]>('installationGuide/admin')
        .then((response) => {
            setInstallationGuides(response.data);
        })
        .catch((err) => console.log('error fetching installation guides', err, err.response));
    }, []);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleUsersClicked = React.useCallback((id: string) => {
        history.push('/admin/installationGuides/' + id + '/users');
    }, [history]);

    const handleVersionsClicked = React.useCallback((id: string) => {
        history.push('/admin/installationGuides/' + id + '/versions');
    }, [history]);

    const handleEditClicked = React.useCallback((id: string) => {
        history.push('/admin/installationGuides/' + id + '/update');
    }, [history]);

    const handleCreateClicked = React.useCallback(() => {
        history.push('/admin/installationGuides/create');
    }, [history]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (installationGuides === null) {
        content = (
            <div className='mt-2 alert alert-info rounded-0 mb-0'>
                <span>Loading the installation guides...</span>
            </div>
        );
    } else if (installationGuides.length === 0) {
        content = (
            <div className='mt-2 alert alert-warning rounded-0 mb-0'>
                <span>No installation guides found.</span>
            </div>
        );
    } else {
        content = installationGuides.map((installationGuide) => {
            return (
                <InstallationGuide
                    key={installationGuide.id}
                    installationGuide={installationGuide}
                    onEditClicked={handleEditClicked}
                    onVersionsClicked={handleVersionsClicked}
                    onUsersClicked={handleUsersClicked}
                />
            );
        });
    }

    return (
        <div className="w-100">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Manage the various installation guides</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <Button variant='contained' className='dark-red-button rounded-0 text-normal w-100' onClick={handleCreateClicked}>
                                <i className='fas fa-plus mr-2' />
                                <span>Create installation guide</span>
                            </Button>
                        </div>
                    </div>
                    <div className="mt-2 title-bar px-2 dark">
                        <div className="row w-100">
                            <div className="col-12 col-md-4">Name</div>
                            <div className="d-none d-md-flex col-3">Sort order</div>
                            <div className="d-none d-md-flex col-2">Enabled</div>
                            <div className='d-none d-md-flex justify-content-end col-3'>Actions</div>
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default InstallationGuides;

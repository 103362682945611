import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';

import api from '../../../api/api';
import { IVersionGuide } from '../../Downloads/ManageGuideVersionEdit';
import DisplayVersion from '../../Downloads/DisplayVersion';
import ValidationRequestDenyModal from './ValidationRequestDenyModal';

interface IParams {
    id?: string;
    versionId?: string;
}

const InstallationGuideVersionDisplay: React.FunctionComponent = () => {
    const [data, setData] = React.useState<IVersionGuide | null>(null);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [display, setDisplay] = React.useState<boolean>(false);

    const history = useHistory();
    const { id, versionId } = useParams<IParams>();

    /***************************************************
     * Startup
     ***************************************************/
     React.useEffect(() => {
        if (!id || !versionId) {
            history.push('/admin/installationGuides');
            return;
        }

        api.get(('installationGuideVersion/manage/' + id + '/' + versionId))
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.log('[InstallationGuideVersionDisplay] Failed to download the version', error, error.response);
        });
    }, [id, versionId, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleAcceptRequestClicked = () => {
        if (saving || !data) {
            return;
        }

        setSaving(true);
        api.post('installationGuideVersion/admin/accept/' + id + '/' + versionId)
        .then(() => {
            setData({
                ...data!,
                hasBeenValidated: true,
                isValidationRequired: false
            });
        })
        .catch(() => {
            alert("Failed to accept validation request.");
        })
        .finally(() => {
            setSaving(false);
        });
    }

    const handleDenyRequestClicked = () => {
        if (saving || !data) {
            return;
        }

        setDisplay(true);
    }

    const handleModalClosed = React.useCallback(() => {
        setDisplay(false);
    }, []);

    const handleModalDenyRequestClicked = (reason: string) => {
        if (reason.trim() === '') {
            alert("Please provide a reason.");
            return;
        }

        setDisplay(false);
        setSaving(true);

        api.post('installationGuideVersion/admin/deny/' + id + '/' + versionId, { reason })
        .then(() => {
            setData({
                ...data!,
                hasBeenValidated: false,
                isValidationRequired: false,
                isValidationRequestDenied: true,
                validationDenyReason: reason
            });
        })
        .catch(() => {
            alert("Deny validation request failed.");
        })
        .finally(() => {
            setSaving(false);
        });
    }
    
    const handleBackClicked = React.useCallback(() => {
        if (id) {
            history.push('/admin/installationGuides/' + id + '/versions');
        } else {
            // @ts-ignore
            history.goBack();
        }
    }, [id, history]);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (data === null) {
        content = (
            <div className="w-100 px-2">
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-info rounded-0 mb-0">
                            Loading data...
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        let extraContent = null;
        if (data.hasBeenValidated === false && data.isValidationRequired) {
            extraContent = (
                <div className="mt-2 row">
                    <div className="col-12 col-md-6 col-lg-4 d-flex flex-column">
                        <Button variant='contained' className='dark-red-button text-normal rounded-0' onClick={handleAcceptRequestClicked}>
                            Accept request
                        </Button>
                        <Button variant='contained' className='mt-2 dark-red-button text-normal rounded-0' onClick={handleDenyRequestClicked}>
                            Deny request
                        </Button>
                    </div>
                </div>
            );
        } else if (data.hasBeenValidated === false && data.isValidationRequestDenied) {
            extraContent = (
                <div className="mt-2 row">
                    <div className="col-12 col-md-6 col-lg-4 d-flex flex-column">
                        <div className="alert alert-danger mb-0 rounded-0 d-flex flex-column">
                            <strong>Validation request denied.</strong>
                            <span>{data.validationDenyReason}</span>
                        </div>
                    </div>
                </div>
            );
        } else if (data.hasBeenValidated) {
            extraContent = (
                <div className="mt-2 row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="alert alert-success mb-0 rounded-0">
                            <span>This version has been validated.</span>
                        </div>
                    </div>
                </div>
            );
        }

        let modal = null;
        if (display) {
            modal = (
                <ValidationRequestDenyModal
                    onClose={handleModalClosed}
                    onDenyClicked={handleModalDenyRequestClicked}
                />
            );
        }

        content = (
            <React.Fragment>
                {modal}
                <div className="w-100 px-2 mb-2">
                    <div className="box-shadow">
                        <div className="title-bar px-2">
                            <span>Installation Guide Display</span>
                        </div>
                        <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                            <span>This is the preview or display of the version you have selected.</span>
                            {extraContent}
                        </div>
                    </div>
                </div>
                <DisplayVersion version={data} />
            </React.Fragment>
        )
    }
 
    return (
        <React.Fragment>
            <div className="w-100 px-2 mb-2">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button onClick={handleBackClicked} variant='contained' className='w-100 dark-red-button text-normal rounded-0'>
                            <i className="fas fa-step-backward mr-2"></i>
                            <span>Go back</span>
                        </Button>
                    </div>
                </div>
            </div>
            {content}
        </React.Fragment>
    );
};

export default InstallationGuideVersionDisplay;

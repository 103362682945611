import * as React from 'react';
import { Pagination } from '@material-ui/lab';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import Input from '../../components/MUI/Input';
import CharacterItem from '../../components/Members/CharacterItem';
import api from '../../api/api';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

const debounceFunction = () => {return;};
const debounce = AwesomeDebouncePromise(debounceFunction, 350);

export interface ICharacter {
    id: string,
    name: string,
    accountId: null | number,
    accountName: string,
    isPublic: boolean
}

interface IData {
    currentPage: number,
    totalPages: number,
    characters: ICharacter[]
}

const CharacterList: React.FunctionComponent = () => {
    const [page, setPage] = React.useState<number>(1);
    const [order, setOrder] = React.useState<number>(0);
    const [search, setSearch] = React.useState<string>("");
    const [inputValue, setInputValue] = React.useState<string>("");
    const [data, setData] = React.useState<null | IData>(null);

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get('/members/characters/' + order + '/' + page + '?search=' + search)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.log('Error: ', error, error.response);
        });
    }, [uid, page, order, search]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handlePageChanged = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleOrderClicked = () => {
        setOrder((currentOrder) => {
            if (currentOrder === 0) {
                return 1;
            }

            return 0;
        });

        setPage(1);
    };

    const handleSearchChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // Save the value
        let value = event.target.value;

        // Update the value to display on the frontend
        setInputValue(value);

        // Await
        await debounce();

        // Update the search value
        setSearch(value);
        setPage(1);
    };

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (data === null) {
        content = (
            <div className="alert alert-info rounded-0 mb-0">
                Loading characters...
            </div>
        );
    } else if (data.characters.length === 0) {
        content = (
            <div className="alert alert-warning rounded-0 mb-0">
                No characters found.
            </div>
        );
    } else {
        content = (
            <>
                {/* Items */}
                {data.characters.map((character) => <CharacterItem key={character.id} character={character} />)}

                {/* Pagination */}
                <div className="pagination-container w-100 mt-2">
                    <Pagination
                        page={page}
                        count={data.totalPages}
                        onChange={handlePageChanged}
                        showFirstButton
                        showLastButton
                        variant='outlined'
                        shape='rounded'
                    />
                </div>
            </>
        );
    }

    // Set icon classes based on order
    let iconClasses = ['fas', 'mr-2'];
    if (order) {
        iconClasses.push('fa-arrow-up');
    } else {
        iconClasses.push('fa-arrow-down');
    }

    return (
        <>
            {/* Search */}
            <Input
                id="search-value"
                label="Search by character name"
                className="mb-2"
                value={inputValue} 
                onChange={handleSearchChanged} 
            />

            {/* Header */}
            <div className="title-bar dark px-2">
                <div className="row w-100">
                    <div className="col-12 col-md-6 clickable d-flex align-items-center" onClick={handleOrderClicked}>
                        <i className={iconClasses.join(' ')} />
                        <span>Name</span>
                    </div>
                    <div className="d-none d-md-flex col-md-6">
                        <span>Account</span>
                    </div>
                </div>
            </div>

            {/* Content */}
            {content}
        </>
    );
};

export default CharacterList;

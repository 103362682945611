import * as React from 'react';
import { useHistory } from 'react-router-dom';

import api, { baseApiUrl } from '../../api/api';

interface ICharacter {
    id: string,
    name: string,
    isPublic: boolean,
    accountId: null | number,
    accountName: string,
    profilePicture: string
}

interface ICharacterGeneralInfoProps {
    characterId: string
}

const CharacterGeneralInfo: React.FunctionComponent<ICharacterGeneralInfoProps> = ({ characterId }) => {
    const [character, setCharacter] = React.useState<ICharacter | null>(null);
    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
     React.useEffect(() => {
        api.get('members/character/' + characterId)
        .then((response) => {
            setCharacter(response.data);
        })
        .catch((error) => {
            console.log('error: ', error, error.response);
        });
    }, [characterId]);

    /***************************************************
     * Startup
     ***************************************************/
    const handleLinkClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event?.preventDefault();
        history.push('/members/account/' + character?.accountId);
    }

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (character === null) {
        content = (
            <div className="alert alert-info rounded-0 mb-0">
                Loading info...
            </div>
        );
    } else {
        content = (
            <>
                <span className="font-small">
                    Character name
                </span>
                <span>
                    {character.name}
                </span>
                <span className='mt-3 font-small'>
                    Account
                </span>
                { character.isPublic ? (
                    <a className='w-100 forum-category-link' href={'/members/account/' + character.accountId} onClick={handleLinkClicked}>
                        {character.accountName}
                    </a>
                ) : (
                    <span>
                        This character is private
                    </span>
                )}
                { !(character.profilePicture && character.profilePicture.trim() !== '') ? null : (
                    <div>
                        <img className='img-fluid' alt='character avatar' src={baseApiUrl + 'attachments/profile/' + character.profilePicture} />
                    </div>
                ) }
            </>
        );
    }

    return (
        <div className="w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    General information
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default CharacterGeneralInfo;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getDate, getTime } from '../../../../utilities/date';
import api from '../../../../api/api';
import { AuthReducerProps } from '../../../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

interface ForumPostProps {
    id: string;
    name: string;
    date: number;
}

interface ForumTopicProps {
    author: string;
    created: number;
    id: string;
    isAnnouncement: boolean;
    isLocked: boolean;
    isSticky: boolean;
    lastPost: ForumPostProps | null,
    replies: number;
    title: string;
    views: number;
}

interface RecentPostsResponseData {
    forumCategoryId: null;
    recentTopics: ForumTopicProps[];
}

interface Props {
    url: string
}

const RecentPosts: React.FC<Props> = ({ url }) => {
    const [data, setData] = useState<RecentPostsResponseData | null>(null);

    const history = useHistory();
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const handleLinkClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
        event.preventDefault();
        history.push(link);
    }

    useEffect(() => {
        api.get(url)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.log("Failed to load the recent posts");
            console.log("error", error);
            console.log("error.response", error.response);
        });
    }, [url, uid]);

    let content = null;

    if (data) {
        content = data.recentTopics.map((forumTopic, index) => {
            let title = forumTopic.title;    
            let date = "";
            let author = "";
            let link = "/forum/category/" + data.forumCategoryId + "/topic/" + forumTopic.id;
    
            if (forumTopic.lastPost) {
                let created : Date = new Date(forumTopic.lastPost.date);
                author = forumTopic.lastPost.name;
                date = getDate(created) + ", " + getTime(created);
            }
    
            return (
                <div key={index} className="alternate-table-entry flex-column p-2">
                    <a href={link} className="w-100 d-flex align-items-center sidebar-navigation-list-item-link" 
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleLinkClicked(event, link)}
                    >
                        <i className="fas fa-angle-right mr-2"></i>
                        <span className="text-overflow font-weight-light-bold">
                            {forumTopic.replies > 0 ? "RE: " : ""}{title}
                        </span>
                    </a>
                    <div className="w-100 d-flex">
                        <strong className="mr-2">by</strong> 
                        <span className="text-overflow">{author}</span> 
                    </div>
                    <div className="w-100 d-flex">
                        <strong className="mr-2">on</strong> 
                        <span className="text-overflow">{date}</span>
                    </div>
                </div>
            );
        });
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
};

export default RecentPosts;
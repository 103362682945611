import { Button } from '@material-ui/core';
import * as React from 'react';
import { IInstallationGuide } from './InstallationGuides';

interface IInstallationGuideProps {
    installationGuide: IInstallationGuide;
    onEditClicked: (id: string) => void;
    onUsersClicked: (id: string) => void;
    onVersionsClicked: (id: string) => void;
}

const InstallationGuide: React.FunctionComponent<IInstallationGuideProps> = ({
    installationGuide,
    onEditClicked,
    onUsersClicked,
    onVersionsClicked
}) => {
    const [display, setDisplay] = React.useState(false);

    /***************************************************
     * Event handlers
     ***************************************************/
    const toggleDisplay = React.useCallback(() => {
        setDisplay((prev) => !prev);
    }, []);

    const handleEditClicked = () => {
        onEditClicked(installationGuide.id);
    }

    const handleUsersClicked = () => {
        onUsersClicked(installationGuide.id);
    }

    const handleVersionsClicked = () => {
        onVersionsClicked(installationGuide.id);
    }

    /***************************************************
     * Render
     ***************************************************/
    return (
        <div className='alternate-table-entry px-2'>
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className='d-flex align-items-center'>
                    <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={toggleDisplay}>
                        { display ? <i className='fas fa-chevron-down' /> : <i className='fas fa-chevron-right' />}
                    </Button>
                    <div className="ml-2 flex-grow-1">
                        {installationGuide.name}
                    </div>
                </div>

                {/* Detailed information */}
                { !display ? null : (
                    <div className="d-flex flex-column">
                        <span className='mt-2 font-small'>
                            Name
                        </span>
                        <span>{installationGuide.name}</span>
                        <span className='mt-2 font-small'>
                            Sort order
                        </span>
                        <span>{installationGuide.sortOrder}</span>
                        <span className="mt-2 font-small">
                            Enabled
                        </span>
                        <i className={installationGuide.isEnabled ? 'fas fa-check text-success': 'fas fa-times text-danger'} />
                        <span className='mt-2 font-small'>
                            Actions
                        </span>
                        <div className="mt-2 d-flex">
                            <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={handleEditClicked}>
                                <i className='fas fa-pencil-alt' />
                            </Button>
                            <Button variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleUsersClicked}>
                                <i className='fas fa-users' />
                            </Button>
                            <Button variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleVersionsClicked}>
                                <i className='fas fa-code-branch' />
                            </Button>
                        </div>
                    </div>
                ) }
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-4">
                    <span>{installationGuide.name}</span>
                </div>
                <div className="d-flex align-items-center col-3">
                    <span>{installationGuide.sortOrder}</span>
                </div>
                <div className="d-flex align-items-center col-2">
                    <i className={installationGuide.isEnabled ? 'fas fa-check text-success' : 'fas fa-times text-danger'} />
                </div>
                <div className="d-flex align-items-center justify-content-end col-3">
                    <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={handleEditClicked}>
                        <i className='fas fa-pencil-alt' />
                    </Button>
                    <Button variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleUsersClicked}>
                        <i className='fas fa-users' />
                    </Button>
                    <Button variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleVersionsClicked}>
                        <i className='fas fa-code-branch' />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InstallationGuide;

import * as React from 'react';
import { Button, Modal } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import api from '../../api/api';
import { IVersion } from './ManageGuideVersions';

interface ICreateVersionModalProps {
    id: string;
    versions: IVersion[]
    onClose: () => void;
}

const CreateVersionModal: React.FunctionComponent<ICreateVersionModalProps> = ({ id, versions, onClose }) => {
    const [saving, setSaving] = React.useState(false);
    const history = useHistory();

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleCreateFromVersionClicked = (versionId: string) => {
        if (saving) {
            return;
        }

        setSaving(true);
        api.post('installationGuideVersion/manage/' + id + '/' + versionId + '/create')
        .then((response) => {
            history.push('/download/manage/' + id + '/versions/' + response.data + '/update');
        })
        .catch((error) => {
            console.log('Error creating new version', error, error.response);
            alert("Error creating a version from that version.");
            setSaving(false);
        });
    }

    const handleCreateClicked = () => {
        history.push('/download/manage/' + id + '/versions/create');
    }

    /***************************************************
     * Render
     ***************************************************/
    return (
        <Modal
            open
            onClose={saving ? () => {} : onClose}
            aria-labelledby='create-version-modal'
            aria-describedby='create-version-modal'
            className='d-flex align-items-center justify-content-center'
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white d-flex flex-column">
                    <span>Create a new version</span>
                </div>
                <div className="d-flex flex-column p-2">
                    <span className='font-weight-bold mb-2'>Versions:</span>
                    {versions.map((version, index) => (
                        <div key={index} className="alternate-table-entry p-2 d-flex align-items-center">
                            <span className="flex-grow-1 text-overflow">
                                {version.versionNumber}
                            </span>
                            <Button 
                                variant='contained' 
                                className='ml-auto action-button dark-red-button rounded-0 text-normal'
                                disabled={saving}
                                onClick={() => handleCreateFromVersionClicked(version.id)}
                            >
                                <i className='fas fa-plus' />
                            </Button>
                        </div>
                    ))}
                    <Button disabled={saving} variant='contained' className='mt-2 dark-red-button text-normal text-white rounded-0' onClick={handleCreateClicked}>
                        Create version
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateVersionModal;

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ForumOverview from './ForumOverview';

import './Forum.scss';

import Category from './Category';
import CreateTopic from '../../components/Forums/CreateTopic';
import ReplyTopic from '../../components/Forums/ReplyTopic';
import ForumTopic from '../../components/Forums/ForumTopic';
import ForumTopicPostEdit from '../../components/Forums/ForumTopicPostEdit';
import ForumAdvancedSearch from './ForumAdvancedSearch';

const Forum: React.FC<{}> = () => {
    return (
        <Switch>
            <Route path='/forum/category/:id/topic/:topicId/post/:postId/edit' component={ForumTopicPostEdit} />
            <Route path="/forum/category/:id/topic/:topicId/:page" component={ForumTopic} />
            <Route path="/forum/category/:id/topic/:topicId" component={ForumTopic} />
            <Route path="/forum/category/reply/:forumCategoryId/:topicId" component={ReplyTopic} />
            <Route path="/forum/category/create/:id" component={CreateTopic} />
            <Route path="/forum/category/:id/:page" component={Category} />
            <Route path="/forum/category/:id" component={Category} />
            <Route path="/forum/search" component={ForumAdvancedSearch} />
            <Route path="/forum/overview" component={ForumOverview} />
            <Redirect to="/forum/overview" />
        </Switch>
    );
};

export default Forum;
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import api from '../../api/api';

import Input from '../../components/MUI/Input';
import SaveButton, { saveStatuses, saveTypes } from '../../components/MUI/SaveButton';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

interface ErrorProps {
    token: boolean;
    password: boolean;
    confirmPassword: boolean;
    matchPasswords: boolean;
    webRequest: string | null;
};

const ResetPassword: React.FC<{}> = () => {
    const [initialised, setInitialised] = useState<boolean>(false);
    const [token, setToken] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [error, setError] = useState<ErrorProps>({ token: false, password: false, confirmPassword: false, matchPasswords: false, webRequest: null });

    const history = useHistory();
    const location = useLocation();
    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    const closePopup = useCallback(() => {
        history.push("/home");
    }, [history]);

    useEffect(() => {
        if (!initialised) {
            setInitialised(true);
            const match = matchPath<{token: string}>(location.pathname, { path: "/home/resetPassword/:token" });
            if (match?.params?.token) {
                setToken(match.params.token);
                history.replace("/home/resetPassword");
            }
        }
    }, [initialised, location, history]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            let errorData : ErrorProps = {
                token: false,
                password: false,
                confirmPassword: false,
                matchPasswords: false,
                webRequest: null
            }

            // Verify the token
            if (token.trim() === "") {
                errorData.token = true;
            }

            // Verify the password
            if (password.trim().length < 6) {
                errorData.password = true;
            }

            // Verify the confirm
            if (confirmPassword.trim().length < 6) {
                errorData.confirmPassword = true;
            }

            // Verify match passwords
            if (password.trim().length >= 6 && confirmPassword.trim().length >= 6 && password !== confirmPassword) {
                errorData.matchPasswords = true;
            }

            // Verify error props
            if (errorData.matchPasswords || errorData.password || errorData.confirmPassword || errorData.token) {
                setError(errorData);
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Save the model
            let model = {
                token: token,
                password: password,
                confirmPassword: confirmPassword
            };

            // Post data
            api.post("account/validateResetToken", { token })
            .then(() => {
                api.post("account/resetPassword", model)
                .then(() => {
                    history.push("/home/login");
                })
                .catch(() => {
                    errorData.webRequest = "Failed to reset the password";
                    setError(errorData);
                });
            })
            .catch(() => {
                errorData.token = true; 
                setError(errorData);
            })
        }
    }, [uid, status, password, confirmPassword, history, token]);

    let passwordError : string | null = null;
    if (error.password) {
        if (password.trim().length < 6) {
            passwordError = "The password must be at least 6 characters long";
        }
    }

    let confirmPasswordError : string | null = null;
    if (error.confirmPassword) {
        if (confirmPassword.trim().length < 6) {
            confirmPasswordError = "The password must be at least 6 characters long";
        }
    }

    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby="reset-password-popup" 
            aria-describedby="Reset your password" 
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>Reset your password</span>
                </div>
                <div className="d-flex flex-column p-2">
                    {/* Reset token */}
                    <Input
                        id="reset-password-token"
                        label="Reset token"
                        className="mt-2"
                        value={token} 
                        error={error.token && token.trim() === ""}
                        helperText="This token is not valid"
                        disabled={status !== saveStatuses.NEUTRAL}
                        onKeyDown={handleKeyDown}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setToken(event.target.value)} 
                    />

                    {/* Passwords */}
                    <Input 
                        id="login-popup-password"
                        label="Password"
                        className="mt-2"
                        value={password}
                        error={passwordError ? true : false}
                        helperText={passwordError}
                        disabled={status !== saveStatuses.NEUTRAL}
                        onKeyDown={handleKeyDown}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        type="password"
                    />
                    <Input 
                        id="login-popup-confirm-password"
                        label="Confirm password"
                        className="mt-2"
                        value={confirmPassword}
                        error={confirmPasswordError ? true : false}
                        helperText={confirmPasswordError}
                        disabled={status !== saveStatuses.NEUTRAL}
                        onKeyDown={handleKeyDown}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value)}
                        type="password"
                    />

                    { error.webRequest && error.webRequest.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.webRequest}
                        </div>
                    ) : null }

                    { error.matchPasswords ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            The passwords do not currently match
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                            saveContent="Reset password"
                            savingContent={(<><i className="fas fa-sync fa-spin mr-2" /> Resetting...</>)}
                            errorContent={(<><i className="fas fa-times mr-2" /> Error</>)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ResetPassword;
import React, { useEffect, useState }  from 'react';
import { Modal } from '@material-ui/core';

import { Role } from '../../../utilities/roles';
import Checkbox from '../../MUI/Checkbox';
import SaveButton, { saveStatuses, saveTypes } from '../../MUI/SaveButton';

import { ForumRightProps } from './ForumSecurity';
import api from '../../../api/api';
import { useSelector } from 'react-redux';
import { AuthReducerProps } from '../../../store/reducers/authReducer';

interface Props {
    forumRight: ForumRightProps;
    name: string;
    role: string;
    closePopup: () => void;
    update: (updatedForumRight : ForumRightProps) => void;
};

const ForumRightModal: React.FC<Props> = ({ forumRight, name, role, closePopup, update }) => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [allowedToView, setAllowedToView] = useState<boolean>(forumRight.allowedToView);
    const [allowedToUse, setAllowedToUse] = useState<boolean>(forumRight.allowedToUse);
    const [error, setError] = useState<string>("");

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Event handlers
     ***************************************************/
    useEffect(() => {
        if (forumRight.roleId === Role.ADMIN) {
            closePopup();
        }
    }, [forumRight.roleId, closePopup]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Verify role
            if (forumRight.roleId === Role.ADMIN) {
                setError("Admin role can't be edited.");
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify booleans
            if (allowedToUse && !allowedToView) {
                setError("You can only use something if you can view it.");
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Create model
            let model = {
                ...forumRight,
                allowedToUse: allowedToUse,
                allowedToView: allowedToView
            };

            api.put("forumRight/admin/" + model.id, model)
            .then(() => {
                update(model);
                closePopup();
            })
            .catch((error) => {
                let message = "Error on the server.";
                if (error?.response?.data?.message) {
                    message = error.response.data.message;
                }

                setError(message);
            });
        }
    }, [uid, status, forumRight, allowedToUse, allowedToView, update, closePopup]);

    /***************************************************
     * Render
     ***************************************************/
    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby={"edit-forum-rights-popup"}
            aria-describedby={"Edit the forum rights"}
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span className="w-100 text-overflow">{name} - {role}</span>
                </div>
                <div className="d-flex flex-column p-2">
                     <Checkbox
                        checked={allowedToView}
                        label="Allowed to view"
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllowedToView(event.target.checked)} 
                    />
                    <Checkbox
                        checked={allowedToUse}
                        label="Allowed to use"
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllowedToUse(event.target.checked)}  
                    />

                    { error.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error}
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default ForumRightModal;
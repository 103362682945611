import { Button } from '@material-ui/core';
import * as React from 'react';
import { getDate } from '../../utilities/date';
import { IVersion } from './ManageGuideVersions';

interface IManageGuideVersionProps {
    version: IVersion;
    onPreviewClicked: (versionId: string) => void;
    onEditClicked: (versionId: string) => void;
    onDeleteClicked: (versionId: string) => void;
}

const ManageGuideVersion: React.FunctionComponent<IManageGuideVersionProps> = ({
    version,
    onEditClicked,
    onDeleteClicked,
    onPreviewClicked
}) => {
    const [display, setDisplay] = React.useState<boolean>(false);

    /***************************************************
     * Event handlers
     ***************************************************/
     const toggleDisplay = React.useCallback(() => {
        setDisplay((prev) => !prev);
    }, []);

    const handleEditClicked = () => {
        onEditClicked(version.id);
    }

    const handleDeleteClicked = () => {
        if (window.confirm("Are you sure you want to delete your working version?")) {
            onDeleteClicked(version.id);
        }
    }

    const handlePreviewClicked = () => {
        onPreviewClicked(version.id);
    }

    /***************************************************
     * Render
     ***************************************************/
    let createdTime = new Date(version.createdTime);
    let validationTime: null | Date = null;
    if (version.validationTime && version.validatedBy) {
        validationTime = new Date(version.validationTime);
    }

    let buttons: JSX.Element[] = [];
    buttons.push(
        <Button key='preview' variant='contained' className='action-button dark-red-button rounded-0' onClick={handlePreviewClicked}>
            <i className='fas fa-tv' />
        </Button>
    );

    if (!version.validatedBy && version.isValidationRequired === false) {
        buttons.push(
            <Button key='edit' variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleEditClicked}>
                <i className='fas fa-pencil-alt' />
            </Button>
        );
        buttons.push(
            <Button key='delete' variant='contained' className='ml-2 action-button dark-red-button rounded-0' onClick={handleDeleteClicked}>
                <i className='fas fa-trash' />
            </Button>
        );
    }

    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="row w-100">
                    <div className="d-flex align-items-center col-8">
                        <Button variant='contained' className='action-button dark-red-button rounded-0' onClick={toggleDisplay}>
                            { display ? <i className='fas fa-chevron-down' />: <i className='fas fa-chevron-right' />  }
                        </Button>
                        <div className="ml-2 flex-grow-1">
                            <span>{version.versionNumber}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center col-4">
                        { version.isLive ? <i className='fas fa-signal fa-2x' /> : null }
                    </div>
                </div>

                {/* Detailed information */}
                { !display ? null : (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Version
                        </span>
                        <span>{version.versionNumber}</span>
                        <span className="mt-2 font-small">
                            Created
                        </span>
                        <span>{getDate(createdTime)}</span>
                        <span className="mt-2 font-small">
                            Created by
                        </span>
                        <span>{version.createdBy}</span>
                        <span className="mt-2 font-small">
                            Is live
                        </span>
                        <i className={version.isLive ? 'fas fa-check text-success' : 'fas fa-times text-danger'} />
                        <span className="mt-2 font-small">
                            Is validation required
                        </span>
                        { validationTime === null ? (
                            version.isValidationRequired ? 'Request sent' : 'Working version'
                        ) : (
                            <i className='fas fa-times' />
                        )}
                        <span className="mt-2 font-small">
                            Validation time
                        </span>
                        <span>
                            { validationTime === null ? 'Not yet validated' : getDate(validationTime) }
                        </span>
                        <span className="mt-2 font-small">
                            Validated by
                        </span>
                        <span>
                            { validationTime === null ? 'Not yet validated' : version.validatedBy }
                        </span>
                        <i className={version.isValidationRequired ? 'fas fa-check text-success' : 'fas fa-times text-danger'} />
                        <div className="mt-2 d-flex">
                            {buttons}
                        </div>
                    </div>
                ) }
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-2">
                    <span>{version.versionNumber}</span>
                </div>
                <div className="d-flex align-items-center col-2">
                    <span>{getDate(createdTime)}</span>
                </div>
                <div className="d-flex align-items-center col-2">
                    <span>{version.createdBy}</span>
                </div>
                <div className="d-flex align-items-center col-1">
                    <i className={version.isLive ? 'fas fa-check text-success' : 'fas fa-times text-danger'} />
                </div>
                <div className="d-flex align-items-center col-2">
                    { validationTime === null ? (
                        version.isValidationRequired ? 'Request sent' : 'Working version'
                    ) : (
                        <span className='text-overflow'>{version.validatedBy}</span>
                    )}
                </div>
                <div className="d-flex align-items-center col-2">
                    { validationTime === null ? null : <span>{getDate(validationTime)}</span> }
                </div>
                <div className="d-flex align-items-center justify-content-end col-1">
                    {buttons}
                </div>
            </div>
        </div>
    );
};

export default ManageGuideVersion;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { getTime } from '../../utilities/date';

import { AnnouncementProps } from './Announcements';

interface Props {
    announcement: AnnouncementProps
};

const Announcement: React.FC<Props> = ({ announcement }) => {
    const history = useHistory();

    const handleLinkClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push("/forum/category/" + announcement.forumCategoryId + "/topic/" + announcement.id);
    }

    // Get date
    let created : Date = new Date(announcement.created);

    return (
        <div className="w-100 px-2 py-1">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <a 
                        href={"/forum/category/" + announcement.forumCategoryId + "/topic/" + announcement.id} 
                        className="title-navigation-link text-white"
                        onClick={handleLinkClicked}
                    >
                        <span>{announcement.title} - {created.toLocaleDateString([], { year: 'numeric', month: 'long' })}</span>
                    </a>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <span className="font-weight-bold mb-2">
                        On {getTime(created)}, {created.toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}, Bobley wrote:
                    </span>

                    <div className='dangerous-container' dangerouslySetInnerHTML={{ __html: announcement.content }} />

                    <span className="mt-2 text-muted">
                        Replies: {announcement.replies} <br />
                        Views: {announcement.views}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Announcement;
import * as React from 'react';
import { Modal, Switch } from '@material-ui/core';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';

import Input from '../MUI/Input';
import api from '../../api/api';
import { Pagination } from '@material-ui/lab';

const debounceFunction = () => {return;};
const debounce = AwesomeDebouncePromise(debounceFunction, 350);

let cancelTokenSource = axios.CancelToken.source();

interface ISearchAuthorModalProps {
    onClose: () => void;
    onAuthorSelected: (author: IAuthor) => void;
}

export interface IAuthor {
    id: string;
    name: string;
    isCharacter: boolean;
}

interface IData {
    page: number;
    totalPages: number;
    authors: IAuthor[];
}

const SearchAuthorModal: React.FunctionComponent<ISearchAuthorModalProps> = (props) => {
    const [page, setPage] = React.useState<number>(1);
    const [search, setSearch] = React.useState<string>("");
    const [inputValue, setInputValue] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [searchForCharacters, setSearchForCharacters] = React.useState<boolean>(false);

    const [data, setData] = React.useState<IData>({
        page: 1,
        totalPages: 1,
        authors: []
    });

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        // Set cancel
        cancelTokenSource.cancel("Operation cancelled due to new advanced search request.");
        cancelTokenSource = axios.CancelToken.source();

        // Set loading
        setLoading(true);

        api.get<IData>('/members/authors/' + page + '/' + (searchForCharacters ? '0' : '1') + '?search=' + search)
        .then((response) => {
            setData(response.data);
        })
        .catch(() => {
            setData({
                page: 1,
                totalPages: 1,
                authors: []
            });
        })
        .finally(() => {
            setLoading(false);
        });
    }, [page, search, searchForCharacters]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleInputChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        setInputValue(value);

        await debounce();

        setSearch(value);
        setPage(1);
    }

    const handleSwitchToggled = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchForCharacters(event.target.checked);
        setPage(1);
    }

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (loading) {
        content = (
            <div className='alert alert-info rounded-0 mb-0'>
                Loading authors...
            </div>
        );
    } else if (data.authors.length === 0) {
        content = (
            <div className='alert alert-danger rounded-0 mb-0'>
                No authors found.
            </div>
        );
    } else {
        let items = data.authors.map((author) => {
            return (
                <div key={author.id} className='alternate-table-entry p-2 d-flex align-items-center'>
                    <span>{author.name}</span>
                    <i className='ml-auto px-3 h-100 fas fa-check text-success clickable' onClick={() => props.onAuthorSelected(author)} />
                </div>
            );
        });

        content = (
            <>
                <div className="title-bar dark px-2">
                    <div className="row w-100">
                        <div className="d-flex align-items-center col-12">
                            <span>Author</span>
                        </div>
                    </div>
                </div>
                {items}
                <div className='pagination-container w-100 mt-2'>
                    <Pagination
                        page={page}
                        count={data.totalPages}
                        onChange={(event, page) => setPage(page)}
                        siblingCount={0}
                        boundaryCount={1}
                        variant='outlined'
                        shape='rounded'
                    />
                </div>
            </>
        )
    }

    return (
        <Modal
            open
            aria-label="Search an author"
            aria-describedby="Search an author"
            onClose={() => props.onClose}
            className='d-flex align-items-center justify-content-center'
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>Search an author</span>
                    <div className='d-flex ml-auto align-items-center justify-content-center'>
                        <i className="fas fa-times text-white clickable" onClick={props.onClose} />
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <Input
                        id='search-author'
                        label='Search by name'
                        value={inputValue}
                        onChange={handleInputChanged}
                    />

                    <div className='d-flex align-items-center'>
                        <span>Accounts</span>
                        <Switch checked={searchForCharacters} onChange={handleSwitchToggled} />
                        <span>Characters</span>
                    </div>

                    {content}
                </div>
            </div>
        </Modal>
    );
};

export default SearchAuthorModal;

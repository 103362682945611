import { Button, Modal } from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from '../../../../api/api';
import { AuthReducerProps } from '../../../../store/reducers/authReducer';

import { validateEmail } from '../../../../utilities/validateEmail';
import Checkbox from '../../../MUI/Checkbox';
import Input from '../../../MUI/Input';
import SaveButton, { saveStatuses, saveTypes } from '../../../MUI/SaveButton';

interface Props {
    open: boolean;
    closePopup: () => void;
};

interface ErrorData {
    email: boolean,
    password: boolean,
    confirmPassword: boolean,
    username: boolean,
    passwordsMatch: boolean,
    termsOfService: boolean,
    webRequest: boolean
};

const AccountRegister: React.FC<Props> = ({ open, closePopup }) => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [email, setEmail] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [termsOfService, setTermsOfService] = useState<boolean>(false);
    const [response, setResponse] = useState<any>(null);
    const [error, setError] = useState<ErrorData>({ 
        email: false, 
        password: false,
        confirmPassword: false,
        username: false, 
        passwordsMatch: false,
        termsOfService: false,
        webRequest: false
    });

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Initialise error data
            let errorData = {
                email: false,
                password: false,
                confirmPassword: false,
                username: false,
                passwordsMatch: false,
                termsOfService: false,
                webRequest: false
            };

            // Verify email address
            if (!validateEmail(email)) {
                errorData.email = true;
            }

            // Verify password
            if (password.trim().length < 6) {
                errorData.password = true;
            }

            // Verify confirm password
            if (confirmPassword.trim().length < 6) {
                errorData.confirmPassword = true;
            }

            // Verify if passwords match
            if (password.trim().length > 5 && confirmPassword.trim().length > 5 && password !== confirmPassword) {
                errorData.passwordsMatch = true;
            }

            // Verify username
            if (username.trim().length < 2) {
                errorData.username = true;
            }

            // Verify form errors
            setError(errorData);
            if (errorData.email || errorData.username || errorData.password || errorData.confirmPassword || errorData.passwordsMatch || errorData.termsOfService) {
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Try to login
            api.post('account/register', {
                username,
                email,
                password,
                confirmPassword,
                termsOfService
            })
            .then((response) => {
                if (response.data !== "Please follow the instructions in the email.") {
                    closePopup();
                } else {
                    setResponse(response.data);
                }
            })
            .catch(() => {
                setStatus(saveStatuses.ERROR);
                setError(currentError => {
                    return {
                        ...currentError,
                        webRequest: true
                    }
                });
            })
        }
    }, [closePopup, uid, status, username, email, password, confirmPassword, termsOfService]);

    let content = null;

    if (response) {
        content = (
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>Register your account</span>
                    <div className="d-flex ml-auto align-items-center justify-content-center">
                        { status === saveStatuses.NEUTRAL ? (
                            <i className="fas fa-times text-white clickable" onClick={closePopup}></i>
                        ) : null}
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <div className="mb-0 alert alert-info rounded-0">
                        {response}
                    </div>
                    <div className="w-50 mt-2 ml-auto">
                        <Button variant="contained" className="w-100 dark-red-button" onClick={closePopup}>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>Register your account</span>
                    <div className="d-flex ml-auto align-items-center justify-content-center">
                        { status === saveStatuses.NEUTRAL ? (
                            <i className="fas fa-times text-white clickable" onClick={closePopup}></i>
                        ) : null}
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <Input
                        id="register-popup-username"
                        label="Username"
                        value={username}
                        error={error.username && username.trim().length < 2}
                        helperText={"The username must be a minimum length of 2"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Input
                        id="register-popup-email"
                        label="Email"
                        value={email}
                        error={error.email && !validateEmail(email)}
                        helperText={"Please fill in a correct email"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <Input 
                        id="login-popup-password"
                        label="Password"
                        className="mt-2"
                        value={password}
                        error={error.password && password.trim().length < 6}
                        helperText={"The password must be a minimum length of 6"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        onKeyDown={handleKeyDown}
                        type="password"
                    />
                    <Input 
                        id="login-popup-confirm-password"
                        label="Confirm password"
                        className="mt-2"
                        value={confirmPassword}
                        error={error.confirmPassword && confirmPassword.trim() === ""}
                        helperText={"Please fill in the confirmation password"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value)}
                        onKeyDown={handleKeyDown}
                        type="password"
                    />
                    <Checkbox
                        checked={termsOfService}
                        error={error.termsOfService && !termsOfService}
                        label={<span className={error.termsOfService && !termsOfService ? "text-danger" : ""}>I agree to the terms of service and the privacy policy</span>}
                        controlClasses="dark-red-checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTermsOfService(event.target.checked)} 
                    />

                    { error.webRequest ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            Failed to create the account
                        </div>
                    ) : null }

                    { error.passwordsMatch ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            The passwords must match
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                            saveContent="Register"
                            savingContent={(<><i className="fas fa-sync fa-spin mr-2" /> Registering...</>)}
                            errorContent={(<><i className="fas fa-times mr-2" /> Error</>)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Modal
            open={open}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby="register-popup"
            aria-describedby="Register an account"
            className="d-flex align-items-center justify-content-center"
        >
            {content}
        </Modal>
    );
};

export default AccountRegister;
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
    link: string,
    label: string,
    direct?: boolean,
    collapse: () => void;
};

const NavigationLink: React.FC<Props> = ({ link, label, direct, collapse }) => {
    const history = useHistory();

    const handleLinkClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        collapse();
        if (!direct) {
            event.preventDefault();
            history.push(link);
            // window.open(link);
        }
    }

    return (
        <li className="sidebar-navigation-list-item">
            <a href={link} className="sidebar-navigation-list-item-link" onClick={handleLinkClicked}>
                <span>{label}</span>
            </a>
        </li>
    );
};

export default NavigationLink;

import React, { useState, useEffect } from 'react';
import Moon from '../../Shared/Moon';

import getOberinDate from '../../../../utilities/calendar';

const OberinCalendar: React.FC<{}> = () => {
    const [date, setDate] = useState(new Date());

    /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    /***************************************************
     * Render
     ***************************************************/
    let oberinDate = getOberinDate(date);
    let hours = oberinDate.currentHours < 10 ? '0' + oberinDate.currentHours : oberinDate.currentHours;
    let minutes = oberinDate.currentMinutes < 10 ? '0' + oberinDate.currentMinutes : oberinDate.currentMinutes;

    return (
        <div className='w-100 px-2 py-1'>
            <div className='box-shadow'>
                <div className='title-bar px-2'>
                    <span>Calendar</span>
                </div>
                <div className="bg-light-gray">
                    <div className="d-flex">
                        <div className='pl-2 d-flex flex-column justify-content-center flex-grow-1'>
                            <span className="font-weight-bold">{oberinDate.currentMoon.name}</span>
                            <div className="d-flex">
                                <div className="d-flex flex-column mr-2">
                                    <span>Year:</span>
                                    <span>Day:</span>
                                    <span>Time:</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span>{oberinDate.currentYear}</span>
                                    <span>{oberinDate.currentDay}</span>
                                    <span>{hours}:{minutes}</span>
                                </div>
                            </div>
                        </div>
                        <Moon className='img-fluid' type={oberinDate.currentMoon.name} state={oberinDate.moonState + 1} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OberinCalendar;
export interface UserProps {
    id: string;
    username: string;
    email: string;
    roleId: string;
    created: string;
    updated: string | null;
    isVerified: boolean;
    jwtToken: string;
    refreshToken: string;

    isAllowedToModerate: boolean;

    role: RoleProps | null;
};

export interface RoleProps {
    id: string;
    name: string;
    isStatic: boolean;
}

export interface AuthReducerProps {
    me: UserProps | null;
    uid: string | null;
}

interface ActionProps {
    type: string;
    updatedUser: UserProps | null;
}

const initialState: AuthReducerProps = {
    me: null,
    uid: null,
}

const reducer = (state = initialState, action: ActionProps) => {
    switch(action.type) {
        case "UPDATE_USER": return updateUser(state, action.updatedUser);
        default: break;
    }

    return state;
}

const updateUser = (state: AuthReducerProps, updatedUser: UserProps | null) => {
    if (updatedUser === null) {
        return {
            ...state,
            me: updatedUser,
            uid: null
        };
    }
    
    if (state.uid !== updatedUser.id) {
        return {
            ...state,
            me: updatedUser,
            uid: updatedUser.id
        };
    } else {
        return {
            ...state,
            me: updatedUser
        };
    }
}

export default reducer;
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import User from '../../components/Admin/ManageUsers/User';
import Users from '../../components/Admin/ManageUsers/Users';

const AdminUsers: React.FC<{}> = () => {
    return (
        <div id="admin" className="w-100 px-2">
            <Switch>
                <Route path="/admin/users/user/:id" component={User} />
                <Route path="/admin/users/overview" component={Users} />
                <Redirect to="/admin/users/overview" />
            </Switch>
        </div>
    );
};

export default AdminUsers;
import React, { useCallback, useEffect, useState } from 'react';
import { AdminRoleProps } from '../../../containers/Admin/AdminRoles';
import SaveButton, { saveStatuses, saveTypes } from '../../MUI/SaveButton';

import Input from '../../MUI/Input';
import { Modal } from '@material-ui/core';
import api from '../../../api/api';
import { useSelector } from 'react-redux';
import { AuthReducerProps } from '../../../store/reducers/authReducer';
import Checkbox from '../../MUI/Checkbox';

interface Props {
    role: AdminRoleProps;
    closePopup: () => void;
    setRoles: React.Dispatch<React.SetStateAction<AdminRoleProps[]>>
};

interface ErrorProps {
    name: boolean;
    message: string;
}

const EditRole: React.FC<Props> = ({ role, closePopup, setRoles }) => {
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [name, setName] = useState<string>(role.name);
    const [isAllowedToModerate, setIsAllowedToModerate] = useState<boolean>(role.isAllowedToModerate);
    const [error, setError] = useState<ErrorProps>({ name: false, message: "" });

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /*********************************************************************
     * Startup
     *********************************************************************/
    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Initialise error data
            let errorData: ErrorProps = {
                name: false,
                message: ""
            };

            // Verify role name
            if (name.trim() === "") {
                errorData.name = true;
            }

            // Verify form errors
            setError(errorData);
            if (errorData.name) {
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Try to create
            api.put("role/" + role.id, {
                id: role.id,
                name: name,
                isAllowedToModerate: isAllowedToModerate
            })
            .then((response) => {
                setRoles((currentRoles) => {
                    return currentRoles.map((updatedRole) => {
                        if (updatedRole.id === role.id) {
                            return {
                                ...updatedRole,
                                ...response.data
                            }
                        }

                        return updatedRole;
                    });
                });
                closePopup();
            })
            .catch((error) => {
                let message = "Server error";
                if (error?.response?.data) {
                    message = error.response.data;
                }

                setStatus(saveStatuses.ERROR);
                setError(currentError => {
                    return {
                        ...currentError,
                        message: message
                    }
                });
            });
        }
    }, [uid, role.id, status, name, isAllowedToModerate, closePopup, setRoles]);

    /*********************************************************************
     * Event handlers
     *********************************************************************/
    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    /*********************************************************************
     * Render
     *********************************************************************/
    return (
        <Modal
            open={true}
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby="edit-role-popup"
            aria-describedby="Edit a role"
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>{role.name}</span>
                </div>
                <div className="d-flex flex-column p-2">
                    {/* Name input */}
                    <Input
                        id="create-account-popup-username"
                        label="Name"
                        value={name}
                        error={error.name && name.trim() === ""}
                        helperText={"The username must be a minimum length of 2"}
                        onChange={(event) => setName(event.target.value)}
                        onKeyDown={handleKeyDown}
                        disabled={status !== saveStatuses.NEUTRAL}
                    />

                    {/* Allowed to moderate checkbox */}
                    <Checkbox
                        checked={isAllowedToModerate}
                        label='Allowed to moderate'
                        checkboxClasses="mt-2 table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={(event) => setIsAllowedToModerate(event.target.checked)}
                        disabled={status !== saveStatuses.NEUTRAL}
                    />

                    { error.message.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.message}
                        </div>
                    ) : null }

                    <div className="w-50 mt-2 ml-auto">
                        <SaveButton
                            type={saveTypes.CONTROLLED}
                            status={status}
                            setStatus={setStatus}
                            showFeedback
                            hideCancel
                            saveClasses={["dark-red-button"]}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EditRole;
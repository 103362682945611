import React from 'react';
import { useSelector } from 'react-redux';
import { AuthReducerProps, UserProps } from '../../store/reducers/authReducer';

interface Props {
    roles?: string[] | null | undefined
    rules?: string[] | null | undefined
}

const PrivateComponent: React.FC<Props> = ({ rules, roles, children }) => {
    const user: UserProps | null = useSelector((state: AuthReducerProps) => state.me);
    
    // Check if logged in
    if (!user) {
        return null;
    }

    // Check if component restricted by role
    if (roles && roles.indexOf(user.roleId) === -1) {
        return null;
    }

    // Check if component is restricted by rule
    if (rules) {
        let failed = false;
        rules.forEach((rule) => {
            if (rule === 'isAllowedToModerate' && user.isAllowedToModerate === false) {
                failed = true;
            }

            if (rule === 'isVerified' && user.isVerified === false) {
                failed = true;
            }
        });

        if (failed) {
            return null;
        }
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default PrivateComponent;
import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { CharacterProps } from '../../containers/Profile/Profile';
import AddCharacter from './AddCharacter';
import Character from './Character';

interface Props {
    characters: CharacterProps[];
    isAllowedToAddCharacters: boolean;
    addCharacter: (character: CharacterProps) => void;
    onChangePicture: (id: string) => void;
};

const CharacterInformation: React.FC<Props> = ({ 
    characters, 
    isAllowedToAddCharacters, 
    addCharacter, 
    onChangePicture 
}) => {
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const closePopup = useCallback(() => {
        setShowPopup(false);
    }, []);

    const handleAddCharacterClicked = useCallback(() => {
        setShowPopup(true);
    }, []);

    let content = null;
    if (characters.length > 0) {
        content = (
            <React.Fragment>
                <div className="mt-2 title-bar px-2 dark">
                    <div className="row w-100">
                        <div className="col-12 col-md-6">
                            <span>Name</span>
                        </div>
                        <div className="d-none d-md-flex col-2">
                            <span>Verified</span>
                        </div>
                        <div className="d-none d-md-flex col-2">
                            <span>Public (*)</span>
                        </div>
                        <div className="d-none d-md-flex justify-content-end col-2">
                            <span>Actions</span>
                        </div>
                    </div>
                </div>
                {characters.map((character, index) => <Character key={index} character={character} onChangePicture={onChangePicture} />)}
                <div className="d-lg-flex d-none px-2 bg-white">
                    <span className="font-small">
                        * When a character is public, other people will not know to which forum profile it is linked.
                    </span>
                </div>
            </React.Fragment>
        )
    }

    let modal = null;
    if (showPopup) {
        modal = (
            <AddCharacter
                closePopup={closePopup}
                addCharacter={addCharacter}
            />
        );
    }

    let characterButton = null;
    if (characters.filter(c => c.isVerified === false).length > 2) {
        characterButton = (
            <div className="alert alert-warning rounded-0 mb-0 w-100">
                You can only requests 3 characters at a time. Please wait for the 3 character requests to be handled.
            </div>
        );
    } else if (isAllowedToAddCharacters === false) {
        characterButton = (
            <div className='alert alert-danger rounded-0 mb-0 w-100'>
                Your account is locked out of creating characters. This can be either due to not yet having verified your account, or an admin locked it. Please contact an admin for more information.
            </div>
        );
    } else {
        characterButton = (
            <Button variant="contained" className="dark-red-button w-100 rounded-0" onClick={handleAddCharacterClicked}>
                Add Character
            </Button>
        );
    }

    return (
        <div className="w-100 px-2 pt-2 pb-1">
            {modal}
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Characters ({characters.length})</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            {characterButton}
                        </div>
                    </div>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default CharacterInformation;
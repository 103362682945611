import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { CharacterProps } from '../../containers/Profile/Profile';
import Checkbox from '../MUI/Checkbox';

import useDebounce from '../../utilities/debounce';
import api from '../../api/api';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { useSelector } from 'react-redux';

interface Props {
    character: CharacterProps;
    onChangePicture: (id: string) => void;
};

interface EditPublicProps {
    id: string;
    isPublic: boolean;
}

const Character: React.FC<Props> = ({ character, onChangePicture }) => {
    const [display, setDisplay] = useState<boolean>(false);

    const mounted = useRef(false);

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    const [debouncedIsPublic, isPublic, setIsPublic] = useDebounce<EditPublicProps>({ 
        id: character.id, 
        isPublic: character.isPublic }, 
        350
    );
    
    const handleChangePictureClicked = () => {
        onChangePicture(character.id);
    }

    const toggleDisplay = useCallback(() => {
        setDisplay(currentDisplay => !currentDisplay);
    }, []);

    const toggleIsPublic = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPublic({
            ...isPublic,
            isPublic: event.target.checked
        });
    };

    useEffect(() => {
        if (mounted.current === false) {
            mounted.current = true;
            return;
        }

        api.put('character/public', {
            id: debouncedIsPublic.id,
            name: '',
            profilePicture: '',
            isVerified: true,
            isPublic: debouncedIsPublic.isPublic
        })
        .then(() => {
        })
        .catch((error) => {
            console.log("Failed to change 'isPublic'", error, error.response);
        });
    }, [uid, debouncedIsPublic]);

    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                        { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                    </Button>
                    <span className="ml-2 flex-grow-1">
                        {character.name}
                    </span>
                </div>

                {/* Detailed information */}
                { display ? (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Name
                        </span>
                        <span>
                            {character.name}
                        </span>
                        <Checkbox
                            checked={character.isVerified}
                            disabled={true}
                            label="Is verified"
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-gray-checkbox"
                            onChange={() => {}} 
                        />
                        <Checkbox
                            checked={isPublic.isPublic}
                            disabled={character.isVerified === false}
                            label="Is public (*)"
                            checkboxClasses="table-checkbox"
                            controlClasses={ character.isVerified ? "dark-red-checkbox" : "dark-gray-checkbox" }
                            onChange={toggleIsPublic} 
                        />
                        <span className="mt-2 font-small">
                            * When a character is public, other people will not know to which forum profile it is linked.
                        </span>
                        <Button 
                            variant="contained" 
                            className="dark-red-button rounded-0 text-normal" 
                            disabled={!character.isVerified}
                            onClick={handleChangePictureClicked}
                        >
                            Change picture
                        </Button>
                    </div>
                ) : null}
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-6">
                    <span>{character.name}</span>
                </div>
                <div className="d-flex align-items-center col-2">
                    <Checkbox
                        checked={character.isVerified}
                        disabled={true}
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-gray-checkbox"
                        onChange={() => {}} 
                    />
                </div>
                <div className="d-flex align-items-center col-2">
                    <Checkbox
                        checked={isPublic.isPublic}
                        disabled={!character.isVerified}
                        checkboxClasses="table-checkbox"
                        controlClasses={ character.isVerified ? "dark-red-checkbox" : "dark-gray-checkbox" }
                        onChange={toggleIsPublic} 
                    />
                </div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    <Button 
                        variant="contained" 
                        className="dark-red-button text-normal rounded-0" 
                        disabled={!character.isVerified}
                        onClick={handleChangePictureClicked}
                    >
                        Change picture
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Character;
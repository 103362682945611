import { Pagination } from '@material-ui/lab';
import * as React from 'react';
import { useSelector } from 'react-redux';
import api from '../../api/api';
import { AuthReducerProps } from '../../store/reducers/authReducer';

import MemberPost from './MemberPost';

interface ICharacterPostsProps {
    characterId: string
}

export interface IPost {
    // Base props
    forumId: string,
    forumName: string,
    forumCategoryId: string,
    forumCategoryName: string,
    forumTopicId: string,
    forumTopicTitle: string,
    totalPosts: string[],
    elementsPerPage: number,

    // Post props
    postId: string,
    content: string,
    author: string,
    created: number,
    hasBeenChanged: boolean,
    changedBy: string,
    changedOn: number,
    moderatorNote: string,
    changedByModerator: false
}

interface IData {
    currentPage: number,
    totalPages: number,
    posts: IPost[]
}

const CharacterPosts: React.FunctionComponent<ICharacterPostsProps> = ({ characterId }) => {
    const [data, setData] = React.useState<null | IData>(null);
    const [page, setPage] = React.useState<number>(1);

    const uid: string | null = useSelector((state: AuthReducerProps) => state.uid);

    /***************************************************
     * Startup
     ***************************************************/
    React.useEffect(() => {
        api.get('members/character/posts/' + characterId + '/' + page)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.log('Error: ', error, error.response);
        });
    }, [uid, page, characterId]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handlePageChanged = React.useCallback((event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
    }, []);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;

    if (data === null) {
        content = (
            <div className="alert alert-info rounded-0 mb-0">
                Loading posts...
            </div>
        );
    } else if (data.posts.length === 0) {
        content = (
            <div className='alert alert-danger rounded-0 mb-0'>
                No posts found
            </div>
        );
    } else {
        content = (
            <>
                {/* Desktop title */}
                <div className='d-none d-md-flex title-bar dark px-2'>
                    <div className="d-flex w-100">
                        <div className="post-left-side">
                            <span>Author</span>
                        </div>
                        <div className="flex-grow-1">
                            <span>Message</span>
                        </div>
                    </div>
                </div>

                {/* No mobile title */}

                {/* Posts */}
                {data.posts.map((post) => (
                    <MemberPost post={post} key={post.postId} />
                ))}

                {/* Pagination */}
                <div className="pagination-container w-100 mt-2">
                    <Pagination
                        page={page}
                        count={data.totalPages}
                        onChange={handlePageChanged}
                        showFirstButton
                        showLastButton
                        variant='outlined'
                        shape='rounded'
                    />
                </div>
            </>
        );
    }

    return (
        <div className="mt-3 w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    Recent posts
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default CharacterPosts;

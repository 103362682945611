import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { FormControlLabel, Switch } from '@material-ui/core';

import { AuthReducerProps, UserProps } from '../../store/reducers/authReducer';
import { getDate, getTime } from '../../utilities/date';
import api from '../../api/api';

import Editor from '../Editor/Editor';
import Checkbox from '../MUI/Checkbox';
import tinymce from 'tinymce';
import Select, { SelectOptionProps } from '../MUI/Select';
import SaveButton, { saveStatuses, saveTypes } from '../MUI/SaveButton';
import Input from '../MUI/Input';

interface ParamProps {
    id: string;
    topicId: string;
    postId: string;
}

interface PostProps {
    id: string;
    forumTopicId: string;
    content: string;
    authorName: string;
    forumTopicTitle: string;
    forumCategoryName: string;
    forumName: string;
    created: number;
    characterId: null | string;
    characterName: string;
    authorId: string;
    isModerator: boolean;
}

interface CharacterProps {
    id: string;
    name: string;
    isPublic: boolean;
    isVerified: boolean;
}

interface IErrorProps {
    status: boolean,
    message: string
};

const ForumTopicPostEdit: React.FC<{}> = () => {
    const [error, setError] = useState<IErrorProps>({ status: false, message: "" });
    const [status, setStatus] = useState<saveStatuses>(saveStatuses.NEUTRAL);
    const [loading, setLoading] = useState<boolean>(false);
    const [displayPreview, setDisplayPreview] = useState<boolean>(false);
    const [postAsCharacter, setPostAsCharacter] = useState<boolean>(false);
    const [moderatorNote, setModeratorNote] = useState<string>("");
    const [post, setPost] = useState<PostProps | null>(null);
    const [characters, setCharacters] = useState<CharacterProps[]>([]);

    const tinyMceRef = useRef<any>(null);
    const params = useParams<ParamProps>();
    const history = useHistory();

    const me: UserProps | null = useSelector((state: AuthReducerProps) => state.me);

    /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        setLoading(true);
        api.get('forumPost/' + params.id + '/' + params.topicId + '/' + params.postId)
        .then((response) => {
            setLoading(false);
            setPost(response.data);

            if (response.data.characterId) {
                setPostAsCharacter(true);
            }
        })
        .catch((error) => {
            console.log('Error: ', error, error.response);

            // @ts-ignore
            history.goBack();
        })
    }, [me, params, history]);

    useEffect(() => {
        api.get('character')
        .then((response) => {
            setCharacters(response.data);
        })
        .catch((error) => {
            console.log('Failed to fetch characters', error, error.response);
        })
    }, [me]);

    /***************************************************
     * Save button states
     ***************************************************/
    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED && post) {

            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Verify content
            let content = '';
            if (displayPreview) {
                content = post.content;
            } else if (tinyMceRef.current?.editor?.id) {
                let id = tinyMceRef.current.editor.id;
                let editor = tinymce.get(id);

                if (editor) {
                    content = editor.getContent();
                }
            }

            // Verify content
            if (!content || (content && content.trim() === "")) {
                setError({ status: true, message: "Please fill in some content" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify character
            if (postAsCharacter && !post.characterId) {
                setError({ status: true, message: "Please select a character" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Verify moderator note
            if (me && post.authorId !== me.id && post.isModerator === false && (!moderatorNote || (moderatorNote && moderatorNote.trim() === ""))) {
                setError({ status: true, message: "Please fill in a moderator note" });
                setStatus(saveStatuses.ERROR);
                return;
            }

            setError({ status: false, message: "" });

            api.post('forumPost/edit', {
                forumCategoryId: params.id,
                forumTopicId: params.topicId,
                forumPostId: post.id,
                content: content,
                moderatorNote: moderatorNote,
                characterId: post.characterId
            })
            .then(() => {
                // @ts-ignore
                history.goBack();
            })
            .catch((error) => {
                console.log('Failed to edit', error, error.response);
                setError({ status: true, message: "Error editing the forum post" });
                setStatus(saveStatuses.ERROR);
            });
        }
    }, [status, post, displayPreview, postAsCharacter, params, moderatorNote, me, history]);

    useEffect(() => {
        if (status === saveStatuses.CANCEL_TRIGGERED) {
            // @ts-ignore
            history.goBack();
        }
    }, [status, history]);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handlePreviewChanged = () => {
        if (displayPreview) {
            setDisplayPreview(false);
        } else {
            let editorContent = '';
            if (tinyMceRef.current?.editor?.id) {
                let id = tinyMceRef.current.editor.id;
                let editor = tinymce.get(id);

                if (editor) {
                    editorContent = editor.getContent();
                }
            }

            setPost((currentPost) => {
                if (!currentPost) {
                    return null;
                }
    
                return {
                    ...currentPost,
                    content: editorContent
                }
            });

            setDisplayPreview(true);
        }
    }

    const handlePostCharacterChanged = () => {
        if (postAsCharacter) {
            setPostAsCharacter(false);
            setPost((currentPost) => {
                if (!currentPost) {
                    return null;
                }

                return {
                    ...currentPost,
                    characterId: null
                };
            });
        } else {
            setPostAsCharacter(true);
        }
    }

    const handleCharacterChanged = useCallback((event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, value: any) => {
        setPost((currentPost) => {
            if (!currentPost) {
                return null;
            }

            return {
                ...currentPost,
                characterId: value
            };
        });
    }, []);

    /***************************************************
     * Render
     ***************************************************/
    if (loading) {
        return (
            <div className='w-100 p-2'>
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs brt-xs bb-xs d-flex flex-column">
                        <div className="mt-2 alert alert-info mb-0 rounded">
                            Loading the post to edit...
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (!post) {
        return (
            <div className="w-100 p-2">
                <div className="box-shadow">
                    <div className="bg-light-gray p-2 bl-xs brt-xs bb-xs d-flex flex-column">
                        <div className="mt-2 alert alert-danger mb-0 rounded-0">
                            No post found to edit!
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let content = null;
    let posted = new Date(post.created);
    let disabled = me ? me.id !== post.authorId : true;

    let options: SelectOptionProps[] = [];
    if (characters && characters.length > 0) {
        options = characters.map((character) => {
            return {
                value: character.id,
                label: character.name
            };
        });
    }

    // Warning if made by another user
    let warning = null;
    let moderatorNoteContent = null;

    if (me && me.id !== post.authorId) {
        if (displayPreview) {
            moderatorNoteContent = (
                <div className="d-flex py-1">
                    <div className='post-left-side' />
                    <div className='flex-grow-1'>
                        <div className='alert alert-danger rounded-0 mb-0'>
                            {moderatorNote}
                        </div>
                    </div>
                </div>
            );
        } else {
            moderatorNoteContent = (
                <Input
                    id='moderator-note'
                    label='Moderator note'
                    className='mt-3'
                    value={moderatorNote}
                    onChange={(event) => setModeratorNote(event.target.value)}
                />
            );
        }
        
        if (post.isModerator) {
            warning = (
                <div className='my-3 alert alert-warning rounded-0 mb-0'>
                    This post was posted by a different moderator
                </div>
            );
        } else {
            warning = (
                <div className='my-3 alert alert-warning rounded-0 mb-0'>
                    This post was posted by a different user
                </div>
            );
        }
    }

    // Render display
    if (displayPreview) {
        let author:string|undefined = post.authorName;
        if (post.characterId) {
            if (me?.id === post.authorId) {
                author = characters.find(c => c.id === post.characterId)?.name;
            } else {
                author = post.characterName;
            }
        }

        content = (
            <>
                {/* Desktop title */}
                <div className="d-none d-md-flex title-bar dark px-2">
                    <div className="d-flex w-100">
                        <div className="post-left-side">
                            <span>Author</span>
                        </div>
                        <div className="flex-grow-1">
                            <span>Message</span>
                        </div>
                    </div>
                </div>

                {/* Mobile title */}
                <div className="d-md-none title-bar dark px-2">
                    <span>{post.forumTopicTitle}</span>
                </div>

                <div className="alternate-table-entry bg-white p-2">
                    {/* Mobile */}
                    <div className="d-flex flex-column d-md-none w-100">
                        <div className="d-flex align-items-center bb-xs">
                            <div className="mobile-image-post-avatar">
                                <i className="fas fa-user-circle text-dark fa-2x"></i>
                            </div>
                            <div className="flex-grow-1 d-flex flex-column p-2">
                                <span className="font-weight-bold">{author}</span>
                                <span>{getDate(posted)}, {getTime(posted)}</span>
                            </div>
                        </div>
                        <div className="pt-2 dangerous-container" dangerouslySetInnerHTML={{ __html: post.content }} />
                    </div>

                    {/* Desktop */}
                    <div className="d-none d-md-block w-100">
                        {/* Message header */}
                        <div className="d-flex py-1">
                            {/* Author name */}
                            <div className="post-left-side">
                                <strong>{author}</strong>
                            </div>

                            {/* Subject */}
                            <div className="d-flex flex-grow-1">
                                <span>
                                    <strong>Posted:</strong> {getDate(posted)}, {getTime(posted)}
                                </span>
                            </div>
                        </div>

                        {/* Actual message */}
                        <div className="d-flex py-1">
                            {/* Profile picture */}
                            <div className="post-left-side">
                                <i className="fas fa-user-circle text-dark fa-5x"></i>
                            </div>

                            {/* Message */}
                            <div className="flex-grow-1 dangerous-container" dangerouslySetInnerHTML={{ __html: post.content }} />
                        </div>

                        {/* Moderator note */}
                        {moderatorNoteContent}
                    </div>
                </div>
            </>
        )
    } 
    // Render input
    else {
        content = (
            <>
                <Editor
                    initialValue={post.content}
                    tinyMceRef={tinyMceRef}
                    minHeight={150}
                    height={500}
                    onImageUpload={() => {}}
                />

                {moderatorNoteContent}
            </>
            
        );
    }

    

    return (
        <div className="w-100 p-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    {post.forumName} 
                    <i className="fas mx-2 fa-chevron-right"></i>
                    {post.forumCategoryName}
                </div>
                <div className="title-bar light-dark px-2">
                    {post.forumTopicTitle}
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    {/* Title */}
                    <span>
                        Editing the post made by <strong>{post.authorName}</strong> at <strong>{getDate(posted)}, {getTime(posted)}</strong>
                    </span>

                    {/* Warning */}
                    {warning}

                    {/* Display preview */}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={displayPreview}
                                onChange={handlePreviewChanged}
                                color='primary'
                            />
                        }
                        label='Display preview'
                    />

                    {/* Post as character */}
                    <Checkbox
                        label='Post as Character'
                        checked={postAsCharacter}
                        disabled={disabled}
                        checkboxClasses='table-checkbox'
                        controlClasses='dark-red-checkbox'
                        onChange={handlePostCharacterChanged}
                    />

                    {/* Select the character */}
                    <Select
                        id='edit-topic-character'
                        label='Character'
                        className={["w-100", "mb-3"]}
                        value={post.characterId}
                        disabled={!postAsCharacter}
                        options={options}
                        onChange={handleCharacterChanged}
                        helperText="Please select a character"
                    />
                    
                    {/* Editor or preview */}
                    {content}

                    {/* Error content */}
                    { error.status && error.message.trim() !== "" ? (
                        <div className='my-3 alert alert-danger rounded-0 mb-0'>
                            {error.message}
                        </div>
                    ) : null }


                    {/* Save button */}
                    <div className='mt-3 row justify-content-end'>
                        <div className="col-12 col-md-6 col-lg-4">
                            <SaveButton
                                type={saveTypes.CONTROLLED}
                                status={status}
                                setStatus={setStatus}
                                showFeedback
                                saveClasses={['dark-red-button']}
                            />
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    );
};

export default ForumTopicPostEdit;
import * as React from 'react';
import tinymce from 'tinymce';

import api, { baseApiUrl } from '../../api/api';

import Input from '../MUI/Input';
import Editor from '../Editor/Editor';
import { IEditPart } from './ManageGuideVersionEdit';
import EditSubPart from './EditSubPart';
import { Button } from '@material-ui/core';

interface IEditPartProps {
    part: IEditPart;
    partIndex: number;
    editing: number | null;
    onCancelClicked: () => void;
    onEditClicked: (index: number) => void;
    onSaveClicked: (part: IEditPart, partIndex: number) => void;
    onDeleteClicked: (partIndex: number, subPartIndex: number | null) => void;
    onUpClicked: (partIndex: number) => void;
    onDownClicked: (partIndex: number) => void;
    onSubPartUpClicked: (partIndex: number, subPartIndex: number) => void;
    onSubPartDownClicked: (partIndex: number, subPartIndex: number) => void;
}

const EditPart: React.FunctionComponent<IEditPartProps> = ({ 
    part, 
    partIndex, 
    editing, 
    onCancelClicked,
    onEditClicked,
    onSaveClicked,
    onDeleteClicked,
    onUpClicked,
    onDownClicked,
    onSubPartUpClicked,
    onSubPartDownClicked
}) => {
    const [edit, setEdit] = React.useState<IEditPart | null>(null);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleCancelClicked = React.useCallback(() => {
        setEdit(null);
        onCancelClicked();
    }, [onCancelClicked]);

    const handleEditClicked = React.useCallback(() => {
        setEdit({
            ...part,
            parts: part.parts.map((subPart) => {
                return {
                    ...subPart
                }
            })
        });
        onEditClicked(partIndex);
    }, [part, partIndex, onEditClicked]);

    const handleTitleChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEdit((curr) => {
            if (!curr) {
                return null;
            }

            return {
                ...curr,
                title: event.target.value
            };
        });
    }, []);

    const handleSaveClicked = () => {
        if (!edit) {
            return;
        }

        // Change the edit part based on the tinymce content
        let content = '';
        let editor = tinymce.get('guide-part-' + partIndex);
        if (editor) {
            content = editor.getContent();
        }

        let updatedPart = {
            ...edit,
            content: content,
            parts: edit.parts.map((subPart, index) => {
                let subPartContent = '';
                editor = tinymce.get('guide-part-' + partIndex + '-' + index);
                if (editor) {
                    subPartContent = editor.getContent();
                }

                return {
                    ...subPart,
                    content: subPartContent
                }
            })
        };

        setEdit(null);
        onSaveClicked(updatedPart, partIndex);
        onCancelClicked();
    }

    const handleDeleteClicked = React.useCallback(() => {
        let confirmation = window.confirm("Are you sure you want to delete this part?");
        if (confirmation) {
            onDeleteClicked(partIndex, null);
        }
    }, [partIndex, onDeleteClicked]);

    const handleSubTitleChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, subPartIndex: number) => {
        setEdit((curr) => {
            if (!curr) {
                return null;
            }

            return {
                ...curr,
                parts: curr.parts.map((subPart, index) => {
                    if (index !== subPartIndex) {
                        return subPart;
                    }

                    return {
                        ...subPart,
                        title: event.target.value
                    }
                })
            }
        });
    }, []);

    const handleImageUpoloaded = React.useCallback((blobInfo: any, success: any, failure: any) => {
        // Calculate sizes
        let imageSize = blobInfo.blob().size / 1000; // size in kb
        let maximumSize = 5000;

        // Throw error if too large
        if (imageSize > maximumSize) {
            failure("Your image is too large (" + imageSize + "kb). Maximum size is 5MB. Please use an external image upload service or lower your image resolution.");
            return;
        }

        // Create data
        let data = new FormData();
        data.append('file', blobInfo.blob(), blobInfo.filename());

        // Send request to the server
        api.post("attachment/installationGuide", data)
        .then((response) => {
            success(baseApiUrl + "attachments/installationguides/" + response.data);
        })
        .catch((error) => {
            console.log("error", error, error.response, error.response.data);
            if (error.response.data) {
                failure(error.response.data);
            } else {
                failure("Failed to upload the image.");
            }
        });
    }, []);

    const handleAddSubPartAtStartClicked = React.useCallback(() => {
        if (editing) {
            alert('Please save changes before adding new sub parts');
            return;
        }

        let subParts: IEditPart[] = [{
            id: '',
            title: '',
            content: '',
            sortOrder: 1,
            parts: []
        }];

        part.parts.forEach((subPart) => {
            subParts.push({
                ...subPart,
                sortOrder: subPart.sortOrder + 1
            });
        });

        let updatedPart = {
            ...part,
            parts: subParts
        };

        onSaveClicked(updatedPart, partIndex);
    }, [editing, part, partIndex, onSaveClicked]);

    const handleSubPartAdded = React.useCallback((subPartIndex: number) => {
        if (editing) {
            alert('Please save changes before adding new sub parts');
            return;
        }

        // Init updated parts
        let subParts: IEditPart[] = [];

        // Add parts up to the one we clicked the button from
        let sortOrder = 0;
        for(let i = 0; i <= subPartIndex && i < part.parts.length; i++) {
            sortOrder = part.parts[subPartIndex].sortOrder;
            subParts.push(part.parts[i]);
        }

        // Add new part
        subParts.push({
            id: '',
            title: '',
            content: '',
            sortOrder: sortOrder + 1,
            parts: []
        });

        // Add the remaining parts if necessary
        for(let i = subPartIndex + 1; i < part.parts.length; i++) {
            subParts.push({
                ...part.parts[i],
                sortOrder: part.parts[subPartIndex].sortOrder + 1
            });
        }

        // Update the part
        let updatedPart = {
            ...part,
            parts: subParts
        };

        onSaveClicked(updatedPart, partIndex);
    }, [editing, part, partIndex, onSaveClicked]);

    const handleUpClicked = React.useCallback(() => {
        onUpClicked(partIndex);
    }, [onUpClicked, partIndex]);

    const handleDownClicked = React.useCallback(() => {
        onDownClicked(partIndex);
    }, [onDownClicked, partIndex]);

    /***************************************************
     * Render
     ***************************************************/
    let iconContent = null;
    if (editing !== null && editing === partIndex) {
        iconContent = (
            <div className="ml-2 justify-content-end d-flex flex-grow-1">
                <i className='mr-2 fas fa-times clickable' onClick={handleCancelClicked} />
                <i className='fas fa-save clickable' onClick={handleSaveClicked} />
            </div>
        );
    } else if (editing === null) {
        iconContent = (
            <div className="ml-2 justify-content-end d-flex flex-grow-1">
                <i className='mr-2 fas fa-chevron-up clickable' onClick={handleUpClicked} />
                <i className='mr-2 fas fa-chevron-down clickable' onClick={handleDownClicked} />
                <i className='mr-2 fas fa-pencil-alt clickable' onClick={handleEditClicked} />
                <i className='fas fa-trash clickable' onClick={handleDeleteClicked} />
            </div>
        );
    }

    let titleContent = null;
    if (editing !== null && editing === partIndex && edit) {
        titleContent = (
            <div className="bg-light-gray w-100 my-2 px-2 py-1">
                <Input
                    id='part-title'
                    value={edit.title}
                    label='Title'
                    onChange={handleTitleChanged}
                />
            </div>
        );
    } else {
        titleContent = <span>{part.title}</span>;
    }

    let descriptionContent = null;
    if (editing !== null && editing === partIndex && edit) {
        descriptionContent = (
            <div className="w-100 h-100 mb-2">
                <Editor
                    initialValue={part.content}
                    minHeight={150}
                    height={300}
                    id={'guide-part-' + partIndex}
                    onImageUpload={handleImageUpoloaded}
                />
            </div>
        );
    } else {
        descriptionContent = <div className='dangerous-container' dangerouslySetInnerHTML={{ __html: part.content }} />
    }

    let subParts = part.parts.map((subPart, subPartIndex) => {
        return (
            <React.Fragment  key={subPartIndex}>
                <EditSubPart
                    partIndex={partIndex}
                    subPartIndex={subPartIndex}
                    part={subPart}
                    edit={edit ? edit.parts[subPartIndex] : null}
                    displayIcons={editing === null}
                    onTitleChanged={handleSubTitleChanged}
                    onUpClicked={onSubPartUpClicked}
                    onDownClicked={onSubPartDownClicked}
                    onDeleteClicked={onDeleteClicked}
                    onImageUploaded={handleImageUpoloaded}
                />
                <div className="row mb-2">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Button disabled={editing !== null} variant='contained' className='dark-red-button text-normal rounded-0 w-100' onClick={() => handleSubPartAdded(subPartIndex)}>
                            Add sub part
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    })

    return (
        <div className='w-100 px-2 mt-2'>
            <div className="box-shadow">
                <div className="d-flex title-bar px-2">
                    {titleContent}
                    {iconContent}
                </div>
                <div className="bg-light-gray pt-2 px-2 bl-xs br-xs bb-xs d-flex flex-column">
                    {descriptionContent}
                    <div className="row mb-2">
                        <div className="col-12 col-md-6 col-lg-4">
                            <Button disabled={editing !== null} variant='contained' className='dark-red-button text-normal rounded-0 w-100' onClick={handleAddSubPartAtStartClicked}>
                                Add sub part
                            </Button>
                        </div>
                    </div>
                    {subParts}
                </div>
            </div>
        </div>
    );
};

export default EditPart;

import { Modal } from '@material-ui/core';
import React from 'react';

import { getDate, getTime } from '../../../utilities/date';
import { RoleProps } from '../../../store/reducers/authReducer';
import { UserProps } from './Users';

interface Props {
    user: UserProps;
    roles: RoleProps[];
    closePopup: () => void;
};

const InfoUser: React.FC<Props> = ({ user, roles, closePopup }) => {
    const role = roles.find(r => r.id === user.roleId);
    const created: Date = new Date(user.created);

    return (
        <Modal
            open={true}
            onClose={closePopup}
            aria-labelledby="info-account-popup"
            aria-describedby="Accountt information"
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="py-1 px-2 bg-dark-red text-white">
                    <span>Info: {user.username}</span>
                </div>
                <div className="d-flex flex-column p-2">
                    <span className="mt-2 font-small">
                        Username
                    </span>
                    <span>
                        {user.username}
                    </span>
                    <span className="mt-2 font-small">
                        Email
                    </span>
                    <span>
                        {user.email}
                    </span>
                    <span className="mt-2 font-small">
                        Is allowed to add characters
                    </span>
                    {user.isAllowedToAddCharacters ? (
                        <i className='fas fa-check text-success' />
                    ) : (
                        <i className='fas fa-times text-danger' />
                    )}
                    <span className="mt-2 font-small">
                        Role
                    </span>
                    <span>
                        {role ? role.name : ""}
                    </span>
                    <span className="mt-2 font-small">
                        Created
                    </span>
                    <span>
                        {getDate(created)}, {getTime(created)}
                    </span>
                </div>
            </div>
        </Modal>
    );
};

export default InfoUser;
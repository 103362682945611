import React from 'react';
import { Editor as TinyMceEditor } from '@tinymce/tinymce-react';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';

interface Props {
    initialValue: string;
    tinyMceRef?: any
    height: number;
    minHeight: number;
    id?: string;
    onImageUpload: (blobInfo: any, success: any, failure: any) => void;
};

const Editor: React.FC<Props> = ({ initialValue, tinyMceRef, height, minHeight, id, onImageUpload }) => {
    return (
        <TinyMceEditor
            initialValue={initialValue}
            ref={tinyMceRef}
            id={id}
            init={{
                skin: false,
                content_css: false,
                content_style: "body { font-family: Roboto, Helvetica, Arial, sans-serif; }",
                height: height,
                min_height: minHeight,
                paste_as_text: true,
                paste_data_images: true,
                relative_urls: false,
                remove_script_host: true,
                document_base_url: '/',
                convert_urls: true,
                images_upload_handler: onImageUpload,
                menu: {
                    insert: { 
                        title: 'Insert',
                        items: 'image link'
                    },
                    table: { 
                        title: 'Table', 
                        items: 'inserttable | cell row column | tableprops deletetable' 
                    },
                    format: { 
                        title: 'Format', 
                        items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontsizes align | forecolor backcolor | removeformat'
                    }
                },
                plugins: ['link image', 'table paste', 'lists'],
                toolbar: 'undo redo | formatselect | image link | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
            }}
        />
    );
};

export default Editor;
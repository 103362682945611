import { Button } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../../api/api';

import ForumDesktopItem from './ForumItem';
import ForumModal from './ForumModal';

export interface ForumProps {
    id?: string;
    name: string;
    sortOrder: number;
};

interface PopupDataProps {
    forum: ForumProps | null,
    status: boolean
}

const Forums: React.FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [forums, setForums] = useState<ForumProps[]>([]);
    const [popupData, setPopupData] = useState<PopupDataProps>({ forum: null, status: false });

    const history = useHistory();

    /***************************************************
     * Startup
     ***************************************************/
    useEffect(() => {
        api.get('forum/admin')
        .then((response) => {
            setForums(response.data);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    /***************************************************
     * Event handlers
     ***************************************************/
    const closePopup = useCallback(() => {
        setPopupData({ forum: null, status: false });
    }, []);

    const viewCategories = useCallback((id: string) => {
        history.push("/admin/forums/" + id + "/categories");
    }, [history]);

    const viewSecurity = useCallback((id: string) => {
        history.push("/admin/forums/" + id + "/security");
    }, [history]);

    const createForum = useCallback(() => {
        setPopupData({
            forum: {
                name: "",
                sortOrder: forums.length + 1
            },
            status: true
        });
    }, [forums]);

    const editForum = useCallback((id: string) => {
        let forum = forums.find(f => f.id === id);
        if (forum) {
            setPopupData({
                forum: { ...forum },
                status: true
            });
        }
    }, [forums]);

    const deleteForum = useCallback((id: string) => {
        api.delete("forum/admin/" + id)
        .then(() => {
            setForums((currentForums) => {
                let count = 1;
                let updatedForums: ForumProps[] = [];
                currentForums.forEach((f) => {
                    if (f.id === id) {
                        return;
                    }

                    // Update sort order
                    let updatedForum = {
                        ...f,
                        sortOrder: count
                    };

                    // Increment count
                    count++;

                    updatedForums.push(updatedForum);
                });

                return updatedForums;
            });
        });
    }, []);

     /***************************************************
     * Render
     ***************************************************/
    let content = null;
    if (loading) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                Loading data...
            </div>
        )
    }
    else if (forums.length === 0) {
        content = (
            <div className="alert alert-info rounded-0 mb-0">
                No forums found.
            </div>
        );
    }
    else {
        content = forums.map((forum, index) => <ForumDesktopItem 
            key={index}
            forum={forum} 
            onDeleteClicked={deleteForum} 
            onEditClicked={editForum} 
            onViewCategoriesClicked={viewCategories}
            onSecurityClicked={viewSecurity}  />
        );
    }

    let modal = null;
    if (popupData.status && popupData.forum) {
        modal = (
            <ForumModal
                forum={popupData.forum}
                closePopup={closePopup}
                maximum={popupData.forum.id ? forums.length : forums.length + 1}
                setForums={setForums}
            />
        );
    }

    return (
        <React.Fragment>
            {modal}
            <div className="w-100">
                {/* Base forum management */}
                <div className="box-shadow">
                    <div className="title-bar px-2">
                        <span>Manage the various forums</span>
                    </div>
                    <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4">
                                <Button variant="contained" className="dark-red-button rounded-0 text-normal w-100" onClick={createForum}>
                                    <i className="fas fa-plus mr-2"></i> Create forum
                                </Button>
                            </div>
                        </div>
                        <div className="mt-2 title-bar px-2 dark">
                            <div className="row w-100">
                                <div className="col-12 col-md-6"><span>Forum name</span></div>
                                <div className="d-none d-md-flex col-4"><span>Sort order</span></div>
                                <div className="d-none d-md-flex justify-content-end col-2"><span>Actions</span></div>
                            </div>
                        </div>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Forums;
import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { RoleProps } from '../../../store/reducers/authReducer';
import { getDate, getTime } from '../../../utilities/date';
import { UserProps } from './Users';

interface Props {
    user: UserProps;
    roles: RoleProps[];
    onEditClicked: (id: string) => void;
    onInfoClicked: (id: string) => void;
    onCharactersClicked: (id: string) => void;
}

const User: React.FC<Props> = ({ user, roles, onEditClicked, onInfoClicked, onCharactersClicked }) => {
    const [display, setDisplay] = useState<boolean>(false);
    const role = roles.find(r => r.id === user.roleId);
    const created: Date = new Date(user.created);

    const toggleDisplay = useCallback(() => {
        setDisplay(currentDisplay => !currentDisplay);
    }, []);

    const handleEditClicked = () => {
        onEditClicked(user.id);
    }

    const handleViewCharactersClicked = () => {
        onCharactersClicked(user.id);
    }

    const handleViewInfoClicked = () => {
        onInfoClicked(user.id);
    }
    
    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                        { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                    </Button>
                    <span className="ml-2 flex-grow-1">
                        {user.username}
                    </span>
                </div>

                {/* Detailed information */}
                { display ? (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Username
                        </span>
                        <span>
                            {user.username}
                        </span>
                        <span className="mt-2 font-small">
                            Email
                        </span>
                        <span>
                            {user.email}
                        </span>
                        <span className="mt-2 font-small">
                            Is allowed to add characters
                        </span>
                        {user.isAllowedToAddCharacters ? (
                            <i className='fas fa-check text-success' />
                        ) : (
                            <i className='fas fa-times text-danger' />
                        )}
                        <span className="mt-2 font-small">
                            Role
                        </span>
                        <span>
                            {role ? role.name : ""}
                        </span>
                        <span className="mt-2 font-small">
                            Created
                        </span>
                        <span>
                            {getDate(created)}, {getTime(created)}
                        </span>
                        <div className="mt-2 d-flex">
                            <Button variant="contained" className="action-button bg-warning text-white rounded-0" onClick={handleEditClicked}>
                                <i className="fas fa-pencil-alt"></i>
                            </Button>
                            <Button variant="contained" className="ml-2 action-button dark-red-button rounded-0" onClick={handleViewCharactersClicked}>
                                <i className="fas fa-bars"></i>
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-6">
                    <span>{user.username}</span>
                </div>
                <div className="d-flex align-items-center col-4">
                    <span>{role ? role.name : ""}</span>
                </div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    <Button variant="contained" className="action-button bg-primary text-white rounded-0" onClick={handleViewInfoClicked}>
                        <i className="fas fa-info"></i>
                    </Button>
                    <Button variant="contained" className="ml-2 action-button bg-warning text-white rounded-0" onClick={handleEditClicked}>
                        <i className="fas fa-pencil-alt"></i>
                    </Button>
                    <Button variant="contained" className="ml-2 action-button dark-red-button rounded-0" onClick={handleViewCharactersClicked}>
                        <i className="fas fa-bars"></i>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default User;
import * as React from 'react';

import api from '../../api/api';
import ForumTopicMoveRequest from '../../components/Moderators/ManageMoveRequests/ForumTopicMoveRequest';
import MoveRequestAcceptModal from '../../components/Moderators/ManageMoveRequests/MoveRequestAcceptModal';
import MoveRequestDenyModal from '../../components/Moderators/ManageMoveRequests/MoveRequestDenyModal';
import MoveRequestInfoModal from '../../components/Moderators/ManageMoveRequests/MoveRequestInfoModal';

export interface IMoveRequest {
    id: string;
    reason: string;
    requestedTime: number;
    requestedBy: string;
    forumTopicId: string;
    title: string;
    categoryName: string;
    categoryId: string;
    forumName: string;
    forumId: string;
    suggestedCategoryName: string;
    suggestedCategoryId: string;
    suggestedForumName: string;
    suggestedForumId: string;
}

const ModeratorMoveRequests: React.FunctionComponent = () => {
    const [error, setError] = React.useState(false);
    const [moveRequests, setMoveRequests] = React.useState<IMoveRequest[] | null>(null);
    const [infoMoveRequest, setInfoMoveRequest] = React.useState<IMoveRequest | null>(null);
    const [acceptMoveRequest, setAcceptMoveRequest] = React.useState<IMoveRequest | null>(null);
    const [denyMoveRequest, setDenyMoveRequest] = React.useState<IMoveRequest | null>(null);

    /***************************************************
     * Render
     ***************************************************/
    React.useEffect(() => {
        api.get<IMoveRequest[]>('forumTopicMoveRequest/moderator')
        .then((response) => {
            setMoveRequests(response.data)
        })
        .catch(() => {
            setError(true);
        });
    }, []);

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleInfoClicked = React.useCallback((moveRequest: IMoveRequest) => {
        setInfoMoveRequest(moveRequest);
    }, []);

    const handleInfoMobileClicked = React.useCallback((moveRequest: IMoveRequest) => {
        window.open('/forum/category/' + moveRequest.categoryId + '/topic/' + moveRequest.forumTopicId, '_blank');
    }, []);

    const handleAcceptClicked = React.useCallback((moveRequest: IMoveRequest) => {
        setAcceptMoveRequest(moveRequest);
    }, []);

    const handleDenyClicked = React.useCallback((moveRequest: IMoveRequest) => {
        setDenyMoveRequest(moveRequest);
    }, []);

    const handleRequestDenied = React.useCallback(() => {
        // Re-initialise
        setMoveRequests(null);
        setDenyMoveRequest(null);

        // Fetch move requests
        api.get<IMoveRequest[]>('forumTopicMoveRequest/moderator')
        .then((response) => {
            setMoveRequests(response.data)
        })
        .catch(() => {
            setError(true);
        });
    }, []);

    const handleRequestAccepted = React.useCallback(() => {
        // Re-initialise
        setMoveRequests(null);
        setAcceptMoveRequest(null);

        // Fetch move requests
        api.get<IMoveRequest[]>('forumTopicMoveRequest/moderator')
        .then((response) => {
            setMoveRequests(response.data)
        })
        .catch(() => {
            setError(true);
        });
    }, []);

    /***************************************************
     * Render
     ***************************************************/
    let content = null;
    let modal = null;

    if (error) {
        content = (
            <div className="mt-2 alert alert-danger rounded-0 mb-0">
                <span>Failed to load the move requests</span>
            </div>
        );
    } else if (!moveRequests) {
        content = (
            <div className="mt-2 alert alert-info rounded-0 mb-0">
                <span>Loading move requests...</span>
            </div>
        );
    } else if (moveRequests.length === 0) {
        content = (
            <div className="mt-2 alert alert-success rounded-0 mb-0">
                <span>No move requests</span>
            </div>
        );
    } else {
        content = moveRequests.map((moveRequest) => {
            return (
                <ForumTopicMoveRequest
                    key={moveRequest.id}
                    moveRequest={moveRequest}
                    onInfoClicked={handleInfoClicked}
                    onInfoMobileClicked={handleInfoMobileClicked}
                    onAcceptClicked={handleAcceptClicked}
                    onDenyClicked={handleDenyClicked}
                />
            )
        });

        if (infoMoveRequest) {
            modal = (
                <MoveRequestInfoModal
                    moveRequest={infoMoveRequest}
                    onClose={() => setInfoMoveRequest(null)}
                />
            );
        } else if (acceptMoveRequest) {
            modal = (
                <MoveRequestAcceptModal
                    moveRequest={acceptMoveRequest}
                    onClose={() => setAcceptMoveRequest(null)}
                    onRequestAccepted={handleRequestAccepted}
                />
            );
        } else if (denyMoveRequest) {
            modal = (
                <MoveRequestDenyModal
                    moveRequest={denyMoveRequest}
                    onClose={() => setDenyMoveRequest(null)}
                    onRequestDenied={handleRequestDenied}
                />
            );
        }
    }

    return (
        <div className="w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    Manage the various forum topic move requests
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <div className="title-bar px-2 dark">
                        <div className="row w-100">
                            <div className="col-12 col-md-4">Current</div>
                            <div className="d-none d-md-flex col-4">Suggested</div>
                            <div className="d-none d-md-flex col-2">Request</div>
                            <div className="d-none d-md-flex col-2 justify-content-end">Actions</div>
                        </div>
                    </div>
                    {content}
                    {modal}
                </div>
            </div>
        </div>
    )
};

export default ModeratorMoveRequests;

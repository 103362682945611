import { Button } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import {  RoleProps } from '../../../store/reducers/authReducer';
import { Role } from '../../../utilities/roles';
import Checkbox from '../../MUI/Checkbox';
import { ForumRightProps } from './ForumSecurity';

interface Props {
    forumRight: ForumRightProps;
    roles: RoleProps[];
    onEditClicked: (id: string) => void;
};

const ForumRightItem: React.FC<Props> = ({ forumRight, roles, onEditClicked }) => {
    const [display, setDisplay] = useState<boolean>(false);
    const role : RoleProps | undefined = roles.find(r => r.id === forumRight.roleId);

    /***************************************************
     * Event handlers
     ***************************************************/
    const toggleDisplay = useCallback(() => {
        setDisplay(currentDisplay => !currentDisplay);
    }, []);

    const handleEditClicked = useCallback(() => {
        if (forumRight.roleId !== Role.ADMIN) {
            onEditClicked(forumRight.id);
        }
    }, [forumRight, onEditClicked]);

    /***************************************************
     * Render
     ***************************************************/
    return (
        <div className="alternate-table-entry px-2">
            {/* Mobile */}
            <div className="d-block d-md-none w-100 py-2">
                {/* Normal information */}
                <div className="d-flex align-items-center">
                    <Button variant="contained" className="action-button dark-red-button rounded-0" onClick={toggleDisplay}>
                        { display ? <i className="fas fa-chevron-down" /> : <i className=" fas fa-chevron-right" /> }
                    </Button>
                    <span className="ml-2 flex-grow-1">
                        {role?.name}
                    </span>
                </div>

                {/* Detailed information */}
                { display ? (
                    <div className="d-flex flex-column">
                        <span className="mt-2 font-small">
                            Role
                        </span>
                        <span>
                            {role?.name}
                        </span>
                        <Checkbox
                            checked={forumRight.allowedToUse}
                            disabled={true}
                            label="Can view"
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-red-checkbox"
                            onChange={() => {}} 
                        />
                        <Checkbox
                            checked={forumRight.allowedToUse}
                            disabled={true}
                            label="Can use"
                            checkboxClasses="table-checkbox"
                            controlClasses="dark-red-checkbox"
                            onChange={() => {}} 
                        />
                        <span className="mt-2 font-small">
                            Actions
                        </span>
                        <div className="mt-2 d-flex">
                            { forumRight.roleId !== Role.ADMIN ? (
                                <Button variant="contained" className="action-button bg-warning text-white rounded-0" onClick={handleEditClicked}>
                                    <i className="fas fa-pencil-alt"></i>
                                </Button>
                            ) : null }
                        </div>
                    </div>
                ) : null}
            </div>

            {/* Desktop */}
            <div className="d-none d-md-flex row w-100">
                <div className="d-flex align-items-center col-6"><span>{role?.name}</span></div>
                <div className="d-flex align-items-center col-2">
                    <Checkbox
                        checked={forumRight.allowedToView}
                        disabled={true}
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={() => {}} 
                    />
                </div>
                <div className="d-flex align-items-center col-2">
                    <Checkbox
                        checked={forumRight.allowedToUse}
                        disabled={true}
                        checkboxClasses="table-checkbox"
                        controlClasses="dark-red-checkbox"
                        onChange={() => {}} 
                    />
                </div>
                <div className="d-flex align-items-center justify-content-end col-2">
                    { forumRight.roleId !== Role.ADMIN ? (
                        <Button 
                        variant="contained" 
                        className="action-button bg-warning text-white rounded-0" 
                        onClick={handleEditClicked}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </Button>
                    ) : null }
                </div>
            </div>
        </div>
    );
};

export default ForumRightItem;
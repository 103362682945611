import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ForumCategoryTopicProps } from '../../containers/Forum/Category';
import CategoryTopicItem from './CategoryTopicItem';

import ImgPostHot from '../../assets/post_hot.gif';
import ImgPostLockNew from '../../assets/post_lock_new.gif';
import ImgPostLock from '../../assets/post_lock.gif';
import ImgPostNewHot from '../../assets/post_new_hot.gif';
import ImgPostNew from '../../assets/post_new.gif';
import ImgPostSticky from '../../assets/post_sticky.gif';
import ImgPost from '../../assets/post.gif';
import { getDate, getTime } from '../../utilities/date';

interface Props {
    forumCategoryId: string;
    first: boolean;
    title: string;
    topics: ForumCategoryTopicProps[];
};

const CategoryTopics: React.FC<Props> = ({ forumCategoryId, first, title, topics }) => {
    const history = useHistory();

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleForumTopicClicked = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
        event?.preventDefault();
        history.push(link);
    }, [history]);

    /***************************************************
     * Render
     ***************************************************/
    // No need to show anything if empty
    if (topics.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            {/* Mobile */}
            <div className="d-block d-md-none">
                <div className={first ? "title-bar px-2 py-1" : "first-mobile-title title-bar px-2 py-1"}>
                    <span>{title}</span>
                </div>
                { topics.map((topic, index) => (
                    <CategoryTopicItem key={index} topic={topic} forumCategoryId={forumCategoryId} handleForumTopicClicked={handleForumTopicClicked} />
                ))}
            </div>
    
            {/* Desktop */}
            <div className="d-none d-md-block">
                <div className={first ? "title-bar p-2" : "first-mobile-title title-bar px-2"}>
                    <span>{title}</span>
                </div>
                {topics.map((topic, index) => {
                    let link = "/forum/category/" + forumCategoryId + "/topic/" + topic.id;
                    let lastPost = null;
                    if (topic.lastPost) {
                        let date = new Date(topic.lastPost.date);

                        lastPost = (
                            <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                                <span>{getDate(date)}, {getTime(date)}</span>
                                <span>{topic.lastPost.name}</span>
                            </div>
                        );
                    } else {
                        lastPost = <div className="d-flex justify-content-center align-items-center col-2" />;
                    }

                    let postImg = null;
                    if (topic.isAnnouncement || topic.isSticky) {
                        postImg = <img src={ImgPostSticky} alt="sticky post" className="mr-2 img-fluid" />;
                    }
                    else if (topic.isLocked && topic.isNew) {
                        postImg = <img src={ImgPostLockNew} alt="new locked post" className="mr-2 img-fluid" />;
                    }
                    else if (topic.isLocked) {
                        postImg = <img src={ImgPostLock} alt="locked post" className="mr-2 img-fluid" />;
                    }
                    else if (topic.isNew && topic.replies > 50) {
                        postImg = <img src={ImgPostNewHot} alt="new or updated hot post" className="mr-2 img-fluid" />;
                    }
                    else if (topic.isNew) {
                        postImg = <img src={ImgPostNew} alt="new or updated post" className="mr-2 img-fluid" />;
                    }
                    else if (topic.replies > 50) {
                        postImg = <img src={ImgPostHot} alt="hot post" className="mr-2 img-fluid" />;
                    }
                    else {
                        postImg = <img src={ImgPost} alt="post" className="mr-2 img-fluid" />;
                    }

                    return (
                        <div key={index} className="alternate-table-entry p-2">
                            <div className="row w-100">
                                <div className="col-4 d-flex align-items-center">
                                    {/* Post image */}
                                    {postImg}

                                    {/* Link */}
                                    <a onClick={(event) => handleForumTopicClicked(event, link)} 
                                        className="font-weight-bold forum-category-link"
                                        href={link}
                                    >
                                        {topic.title}
                                    </a>
                                </div>
                                <div className="d-flex justify-content-center align-items-center col-2">
                                    <span>{topic.replies}</span>
                                </div>
                                <div className="d-flex justify-content-center align-items-center col-2">
                                    <span>{topic.author}</span>
                                </div>
                                <div className="d-flex justify-content-center align-items-center col-2">
                                    <span>{topic.views}</span>
                                </div>
                                {lastPost}
                            </div>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    ); 
};

export default CategoryTopics;
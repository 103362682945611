import React, { useState } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { Modal } from '@material-ui/core';

import api, { baseApiUrl } from '../../api/api';

interface Props {
    url: string,
    label: string,
    picture: string | null,
    onPictureUploaded: (link: string) => void,
    onClosePopup: () => void,
};

const AvatarUpload: React.FC<Props> = ({ url, label, picture, onPictureUploaded, onClosePopup }) => {
    const [error, setError] = useState<string>("");

    const handleFileDropped = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => {
        if (acceptedFiles.length === 1) {
            let data = new FormData();
            data.append('file', acceptedFiles[0], acceptedFiles[0].name);

            setError("");
            api.post(url, data)
            .then((response) => {
                onPictureUploaded(response.data);
            })
            .catch((error) => {
                console.log("[handleFileDropped] error: ", error, error.response);
            });
        }
    }

    let currentImage = null;
    if (picture && picture !== "") {
        currentImage = (
            <div className="d-flex flex-column">
                <div>
                    <img className="img-fluid" alt="avatar popup" src={baseApiUrl + "attachments/profile/" + picture} />
                </div>
            </div>
        );
    }

    return (
        <Modal
            open={true}
            onClose={onClosePopup}
            aria-labelledby="avatar-upload-popup"
            aria-describedby="avatar upload popup"
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span className="flex-grow-1 text-overflow">
                        {label}
                    </span>
                    <div className="d-flex ml-auto align-items-center justify-content-center">
                        <i className="fas fa-times text-white clickable" onClick={onClosePopup}></i>
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <span>Max size: 2MB</span>
                    <span>Max width: 256px</span>
                    <span>Max height: 256px</span>
                    <Dropzone onDrop={handleFileDropped} onDropRejected={() => setError("Maximum file upload size is 2MB")} accept="image/*" maxFiles={1} maxSize={2097152}>
                        {({ getRootProps, getInputProps, isDragActive }) => {
                            return (
                                <div {...getRootProps()} className="clickable d-flex justify-content-center align-items-center dropzone dropzone--isActive">
                                    <input {...getInputProps()} />
                                    Click here to upload file
                                </div>
                            )
                        }}
                    </Dropzone>

                    { error.trim() === "" ? null : (
                        <div className="alert alert-danger rounded-0 mt-2 mb-0">
                            {error}
                        </div>
                    )}

                    {currentImage}
                </div>
            </div>
        </Modal>
    );
};

export default AvatarUpload;
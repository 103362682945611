import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Categories from '../../components/Admin/ManageForums/Categories';
import CategorySecurity from '../../components/Admin/ManageForums/CategorySecurity';
import Forums from '../../components/Admin/ManageForums/Forums';
import ForumSecurity from '../../components/Admin/ManageForums/ForumSecurity';

interface Props {

};

const AdminForums: React.FC<Props> = () => {
    return (
        <div id="admin" className="w-100 px-2">
            <Switch>
                <Route path="/admin/forums/:id/categories/:categoryId/security" component={CategorySecurity} />
                <Route path="/admin/forums/:id/categories" component={Categories} />
                <Route path="/admin/forums/:id/security" component={ForumSecurity} />
                <Route path="/admin/forums" component={Forums} />
                <Redirect to="/admin/forums" />
            </Switch>
        </div>
    );
};

export default AdminForums;
import * as React from 'react';

interface IContactPageProps {
}

const ContactPage: React.FunctionComponent<IContactPageProps> = (props) => {
    /***************************************************
     * Render
     ***************************************************/
    return (
        <div className='w-100 px-2'>
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Contact us!</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    <span>Hello world!</span>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;

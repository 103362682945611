import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { getDate, getTime } from '../../utilities/date';
import { IPost } from './CharacterPosts';

interface IMemberPostProps {
    post: IPost
}

const MemberPost: React.FunctionComponent<IMemberPostProps> = ({ post }) => {
    // Get post index
    const postIndex = post.totalPosts.findIndex(tp => tp === post.postId);

    // Can't divide by 0
    let page = 1;
    if (postIndex > 0) {
        page = Math.floor(postIndex / post.elementsPerPage) + 1;
    }

    const history = useHistory();

    /***************************************************
     * Event handlers
     ***************************************************/
    const handlePostClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event?.preventDefault();
        history.push('/forum/category/' + post.forumCategoryId + '/topic/' + post.forumTopicId + '?page=' + page);
    }

    /***************************************************
     * Render
     ***************************************************/
    let posted = new Date(post.created);
    let changed = null;
    if (post.changedOn > 0) {
        changed = new Date(post.changedOn);
    }

    // http://localhost:3000/forum/category/1/topic/35?page=2

    return (
        <div className='alternate-table-entry d-flex flex-column'>
            {/* Title */}
            <div className="w-100 title-bar light-dark px-2">
                <a 
                    className='w-100 forum-category-link'
                    href={'/forum/category/' + post.forumCategoryId + '/topic/' + post.forumTopicId + '?page=' + page} 
                    onClick={handlePostClicked}
                >
                    {post.forumName} {'>'} {post.forumCategoryName} {'>'} {post.forumTopicTitle}
                </a>
            </div>

            {/* Content */}
            <div className="w-100 p-2">
                {/* Mobile */}
                <div className="d-flex flex-column d-md-none w-100">
                    {/* Header */}
                    <div className="d-flex flex-column bb-xs">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 p-2 d-flex flex-column">
                                <span><strong>{post.author}</strong></span>
                                <span>{getDate(posted)}, {getTime(posted)}</span>
                            </div>
                        </div>
                        { changed !== null ? (
                            <span className='font-small pt-1 pb-2'>
                                Edited on <strong>{getDate(changed)}, {getTime(changed)}</strong> by <strong>{post.changedBy}</strong>
                            </span>
                        ) : null}
                    </div>

                    {/* Message */}
                    <div  className="dangerous-container pt-2" dangerouslySetInnerHTML={{ __html: post.content }}></div>

                    {/* Moderator note */}
                    { post.moderatorNote && post.moderatorNote.trim() !== "" ? (
                        <div className="py-2">
                            <div className="alert alert-danger rounded-0 mb-0">
                                {post.moderatorNote}
                            </div>
                        </div>
                    ) : null}
                </div>

                {/* Desktop */}
                <div className="d-none d-md-block w-100">
                    {/* Message header */}
                    <div className="d-flex py-1">
                        <div className="post-left-side">
                            <strong>{post.author}</strong>
                        </div>
                        <div className="d-flex flex-grow-1">
                            <span><strong>Posted:</strong> {getDate(posted)}, {getTime(posted)}</span>
                            {changed !== null ? (
                                <span className='ml-auto'>Edited on <strong>{getDate(changed)}, {getTime(changed)}</strong> by <strong>{post.changedBy}</strong></span>
                            ) : null}
                        </div>
                    </div>

                    {/* Message */}
                    <div className="d-flex py-1">
                        <div className="post-left-side"></div>
                        <div className="dangerous-container flex-grow-1" dangerouslySetInnerHTML={{ __html: post.content }} />
                    </div>

                    {/* Moderator note */}
                    { post.moderatorNote && post.moderatorNote.trim() !== '' ? (
                        <div className='d-flex py-1'>
                            <div className="post-left-side"></div>
                            <div className="flex-grow-1">
                                <div className='alert alert-danger rounded-0 mb-0'>
                                    {post.moderatorNote}
                                </div>
                            </div>
                        </div>
                    ) : null }
                </div>
            </div>
        </div>
    );
};

export default MemberPost;

import React from 'react';
import { UserProps } from './Users';
import VerificationAccount from './VerificationAccount';

interface Props {
    accounts: UserProps[];
    updateAccounts: (accountId: string, characterId: string) => void;
};

const VerificationAccounts: React.FC<Props> = ({ accounts, updateAccounts }) => {
    return (
        <React.Fragment>
            <div className="mt-2 title-bar px-2 dark">
                <div className="row w-100">
                    <div className="col-12 col-md-6">
                        <span>Username</span>
                    </div>
                    <div className="d-none d-md-flex col-4">
                        <span>Character name</span>
                    </div>
                    <div className="d-none d-md-flex justify-content-end col-2">
                        <span>Actions</span>
                    </div>
                </div>
            </div>
            { accounts.map((account) => {
                return account.characters.map((character) => (
                    <VerificationAccount
                        key={account.id + "_" + character.id}
                        account={account}
                        character={character}
                        updateAccounts={updateAccounts}
                    />
                ))
            }) }
        </React.Fragment>
    );
};

export default VerificationAccounts;
import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserProps, AuthReducerProps } from '../../../../store/reducers/authReducer';
import * as actions from '../../../../store/actions/index';
import api from '../../../../api/api';

import { validateEmail } from '../../../../utilities/validateEmail';
import Input from '../../../MUI/Input';
import SaveButton, { saveStatuses, saveTypes } from '../../../MUI/SaveButton';

interface Props {
    open: boolean;
    closePopup: () => void;
};

interface ErrorData {
    email: boolean,
    password: boolean,
    webRequest: string
}

const AccountLogin: React.FC<Props> = ({ open, closePopup }) => {
    const [status, setStatus] = useState(saveStatuses.NEUTRAL);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<ErrorData>({ email: false, password: false, webRequest: "" });

    const history = useHistory();
    const dispatch = useDispatch();

    const me: UserProps | null = useSelector((state: AuthReducerProps) => state.me);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && status === saveStatuses.NEUTRAL) {
            setStatus(saveStatuses.SAVE_TRIGGERED);
        }
    }, [status]);

    const handleVerifyEmailClicked = useCallback(() => {
        closePopup();
        history.push("/home/verifyEmail");
    }, [history, closePopup]);

    const handlePasswordLostClicked = useCallback(() => {
        closePopup();
        history.push("/home/recoverPassword");
    }, [history, closePopup]);

    useEffect(() => {
        if (me) {
            closePopup();
        }
    }, [me, closePopup]);

    useEffect(() => {
        if (status === saveStatuses.SAVE_TRIGGERED) {
            // Set to saving
            setStatus(saveStatuses.SAVING);

            // Initialise error data
            let errorData = {
                email: false,
                password: false,
                webRequest: ""
            };

            // Verify email address
            if (!validateEmail(email)) {
                errorData.email = true;
            }

            // Verify password
            if (!password || (password && password.trim() === "")) {
                errorData.password = true;
            }

            // Verify form errors
            setError(errorData);
            if (errorData.email || errorData.password) {
                setStatus(saveStatuses.ERROR);
                return;
            }

            // Try to login
            api.post('/account/authenticate', { email, password })
            .then((response) => {
                dispatch(actions.authLogin(response.data));
                closePopup();
            })
            .catch((error) => {
                setError({
                    email: false,
                    password: false,
                    webRequest: error?.response?.data?.message ? error.response.data.message : "Unknown error"
                });
                setStatus(saveStatuses.ERROR);
            });
        }
    }, [me, closePopup, email, password, status, dispatch]);

    return (
        <Modal 
            open={open} 
            onClose={status === saveStatuses.NEUTRAL ? closePopup : () => {}}
            aria-labelledby="login-popup" 
            aria-describedby="log into your account" 
            className="d-flex align-items-center justify-content-center"
        >
            <div className="bg-white outline-0 oberin-popup">
                <div className="d-flex py-1 px-2 bg-dark-red text-white">
                    <span>Log into your account</span>
                    <div className="d-flex ml-auto align-items-center justify-content-center">
                        { status === saveStatuses.NEUTRAL ? (
                            <i className="fas fa-times text-white clickable" onClick={closePopup}></i>
                        ) : null}
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <Input 
                        id="login-popup-email"
                        label="Email"
                        className="mt-2"
                        value={email} 
                        error={error.email}
                        helperText={error.email ? "Please fill in a correct email" : undefined}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)} 
                        onKeyDown={handleKeyDown}
                    />
                    <Input 
                        id="login-popup-password"
                        label="Password"
                        className="mt-2"
                        value={password}
                        error={error.password}
                        helperText={error.password ? "Please fill in a password" : undefined}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        onKeyDown={handleKeyDown}
                        type="password"
                    />

                    { error.webRequest && error.webRequest.trim() !== "" ? (
                        <div className="mt-2 mb-0 alert alert-danger rounded-0">
                            {error.webRequest}
                        </div>
                    ) : null }

                    <div className="d-flex w-100 mt-2">
                        { error.webRequest !== "Please verify your email account" ? null : (
                            <div className="w-50 mr-auto pr-2">
                                <Button variant="contained" className="w-100 dark-red-button rounded-0" onClick={handleVerifyEmailClicked}>
                                    Verify email
                                </Button>
                            </div>
                        ) }

                        { error.webRequest !== "Email or password is incorrect" ? null : (
                            <div className="w-50 mr-auto pr-2">
                                <Button variant="contained" className="w-100 dark-red-button rounded-0" onClick={handlePasswordLostClicked}>
                                    Recover Password
                                </Button>
                            </div>
                        ) }

                        <div className="w-50 ml-auto pl-2">
                            <SaveButton
                                type={saveTypes.CONTROLLED}
                                status={status}
                                setStatus={setStatus}
                                showFeedback
                                hideCancel
                                saveClasses={["dark-red-button"]}
                                saveContent="Login"
                                savingContent={(<><i className="fas fa-sync fa-spin mr-2" /> Logging in...</>)}
                                errorContent={(<><i className="fas fa-times mr-2" /> Error</>)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AccountLogin;
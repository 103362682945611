import React from 'react';

import Img1 from '../../../../assets/moons/sleeping/1.png';
import Img2 from '../../../../assets/moons/sleeping/2.png';
import Img3 from '../../../../assets/moons/sleeping/3.png';
import Img4 from '../../../../assets/moons/sleeping/4.png';
import Img5 from '../../../../assets/moons/sleeping/5.png';
import Img6 from '../../../../assets/moons/sleeping/6.png';
import Img7 from '../../../../assets/moons/sleeping/7.png';
import Img8 from '../../../../assets/moons/sleeping/8.png';

interface Props {
    state: number
    className?: undefined | string
};

const SleepingMoon: React.FC<Props> = ({ state, className }) => {
    switch(state) {
        case 1: return <img src={Img1} className={className} alt='oberin-moon' />;
        case 2: return <img src={Img2} className={className} alt='oberin-moon' />;
        case 3: return <img src={Img3} className={className} alt='oberin-moon' />;
        case 4: return <img src={Img4} className={className} alt='oberin-moon' />;
        case 5: return <img src={Img5} className={className} alt='oberin-moon' />;
        case 6: return <img src={Img6} className={className} alt='oberin-moon' />;
        case 7: return <img src={Img7} className={className} alt='oberin-moon' />;
        case 8: return <img src={Img8} className={className} alt='oberin-moon' />;
        default: return null;
    }
};

export default SleepingMoon;
import React, { useState } from 'react';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import Moon from '../../components/Layout/Shared/Moon';

import getOberinDate from '../../utilities/calendar';

const MoonCycle: React.FC<{}> = () => {
    const [date, setDate] = useState<Date | null>(new Date());

    /***************************************************
     * Event handlers
     ***************************************************/
    const handleDateChanged = (value: Date | null) => {
        setDate(value);
    }

    console.log('offset: ', date?.getTimezoneOffset());

    /***************************************************
     * Render
     ***************************************************/
    // Grab a copy of the current date
    let currentDate = date;
    let badDate = false;
    if (!currentDate || isNaN(currentDate.valueOf())) {
        currentDate = new Date();
        badDate = true;
    }

    // Get oberin date
    let oberinDate = getOberinDate(currentDate);
    let hours = oberinDate.currentHours < 10 ? '0' + oberinDate.currentHours : oberinDate.currentHours;
    let minutes = oberinDate.currentMinutes < 10 ? '0' + oberinDate.currentMinutes : oberinDate.currentMinutes;

    // Get error content
    let errorContent = null;
    if (badDate) {
        errorContent = (
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <div className="w-100 w-md-50 alert alert-danger rounded-0 mb-0">
                        <span className="text-overflow">Please enter a correct date. Currently displaying the Oberin Calendar for 'right now'.</span>
                    </div>
                </div>
            </div>
        );
    }

    // Get calendar content
    let calendarContent = (
        <div className="d-flex mt-2">
            <Moon className="img-fluid" type={oberinDate.currentMoon.name} state={oberinDate.moonState + 1} />
            <div className="pl-2 d-flex flex-column justify-content-center flex-grow-1">
                <span className='font-weight-bold'>{oberinDate.currentMoon.name}</span>
                <div className="d-flex">
                    <div className="d-flex flex-column mr-2">
                        <span>Year:</span>
                        <span>Day:</span>
                        <span>Time:</span>
                    </div>
                    <div className="d-flex flex-column">
                        <span>{oberinDate.currentYear}</span>
                        <span>{oberinDate.currentDay}</span>
                        <span>{hours}:{minutes}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="w-100 px-2">
            <div className="box-shadow">
                <div className="title-bar px-2">
                    <span>Moon Cycles</span>
                </div>
                <div className="bg-light-gray p-2 bl-xs br-xs bb-xs d-flex flex-column">
                    {/* Informatie text */}
                    <span>
                        Oberin is regulated by the cycles of Lady Moon. 
                        One of Oberin's years lasts ten of her cycles, 
                        during which her magical flows influence our world.
                    </span>
                    <br />
                    <span>
                        At the beginning, the Lady stirs and we have the <strong>Waking Moon</strong>. Her magic is distant and feeble. 
                        Over the days the <strong>Growing Moon</strong> comes and then reaches that strange, 
                        repulsive sanguine color, the <strong>Bleeding Moon</strong>. 
                        It is rumored that during these days, her magical flows attract more monsters, 
                        and many adventurers postpone their travels to another cycle.
                    </span>
                    <br />
                    <span>
                        In time blood becomes the <strong>Red Moon</strong>, 
                        which slowly fades away into a pleasant, 
                        orangeish color. We call it the <strong>Harvest Moon</strong>. 
                        This is a time of peace and prosperity for the lands. 
                        Crafters, fishermen, and farmers are blessed by the Lady Moon. 
                        This is cycle marks the halfway point of the year and leads into a 
                        joyous and prosperous cycle: the <strong>Dancing Moon</strong>. 
                        The name is both due to the exaltation and many 
                        festivals resulting from the accumulated wealth 
                        and the fact that the Lady Moon seems to be "dancing" as well. 
                        Some nights she will be of that typical Orange color everyone is so used to see, 
                        some others one can see funny green reflections that the imaginative mind can 
                        turn into shapes of animals or monsters.
                    </span>
                    <br />
                    <span>
                        Slowly, the orange fades away and that pale green 
                        invades Lady Moon for the <strong>Lucky Moon</strong> cycle. 
                        Traders and treasure hunters of all kinds roam the 
                        lands looking for rare magical items as the tradition wants. 
                        The color changes again and then comes the infamous <strong>Blue Moon</strong>, 
                        and the festival during which traders sell the many results of their past hunts.
                    </span>
                    <br />
                    <span>
                        The color slowly fades to a very pale blue over the <strong>Fighting Moon</strong>, 
                        where many warriors report the magical flows give them strength and 
                        courage to fight the greatest enemies. It is alas this cycle that has 
                        known the greatest and bloodiest wars, the most recent one including Andris and Senator Lancaster.
                    </span>
                    <br />
                    <span>
                        The wars and fighting often come to an end with the <strong>Sleeping Moon</strong>, 
                        where gray invades Lady Moon and the year's end marks the blood of the vanquished, 
                        before a new cycle starts...
                    </span>

                    {/* Separator line */}
                    <div className='my-2 bb-xs' />

                    <span>Select the date and time to calculate Oberin's date</span>
                    <span className='font-small'>This is your local time and date</span>
                    <span className='font-small'>Format: year/month/day hour:minute</span>
                    { !badDate ? <span className='font-small'>Timezone offset: {date?.getTimezoneOffset()}</span> : null }

                    {/* Calculator */}
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker 
                                    variant='dialog'
                                    format='yyyy/MM/dd HH:mm'
                                    className='w-100'
                                    margin='dense'
                                    id='moon-cycle-picker'
                                    value={date}
                                    ampm={false}
                                    onChange={handleDateChanged}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date'
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    
                    {errorContent}
                    {calendarContent}
                </div>
            </div>
        </div>
    );
};

export default MoonCycle;